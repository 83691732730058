import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      fontSize: "20px",
      marginTop: "20px",
      borderLeft: "3px solid #8DA1B9",
      borderBottom: "3px solid #8DA1B9",
      paddingLeft: "5px"
    },
    paper: {
      padding: theme.spacing(0),
      backgroundColor: "#F9F9F9",
      boxShadow: "none",
      fontSize: "16px",
      display: "flex",
      borderRadius: "0px",
      justifyContent: "space-between",
      borderBottom: "1px solid #D5B4B4",
      "& :first-child": {
        fontWeight: "bold"
      }
    },
    paperOther: {
      backgroundColor: "#F9F9F9",
      borderRadius: "0px",
      boxShadow: "none",
      fontSize: "16px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #D5B4B4",
      "& :first-child": {
        fontWeight: "bold"
      },
      marginTop: "-24px"
    },
    paperOtherNoData: {
      backgroundColor: "#F9F9F9",
      boxShadow: "none",
      fontSize: "16px",
      display: "flex",
      justifyContent: "space-between",
      "& :first-child": {
        fontWeight: "bold"
      },
      marginTop: "-24px"
    },
  })
);