import { http, downloadDZ } from "./Http";
import { getDomain } from "../common.methods";

// To get an overview of the end users linked to the dealer. Authorization is through the Auth0-token created when logging into DZ.
export const GetEndusersOverview = (config: any) => {
  const { token, affiliate_cd } = config;
  const dzUrl = getDomain(affiliate_cd);
  const param = {
    token: token,
    url: `${dzUrl}/api/v1/wco/endusers`,
  };
  return http(param);
};

// ENDU_0
// To get detail information of an end user.a Authorization is through the Auth0-token created when logging into DZ.
export const GetEndusersDetail = (config: any, enduser_no: any) => {
  const { token, affiliate_cd } = config;
  const dzUrl = getDomain(affiliate_cd);
  const param = {
    token: token,
    url: `${dzUrl}/api/v1/wco/endusers/${enduser_no}`,
  };
  return http(param);
};

// To get an overview of the warranty registrations created by the end user. Authorization is through the Auth0-token created when logging into DZ.
export const GetTools = (config: any, enduser_no: any) => {
  const { token, affiliate_cd } = config;
  const dzUrl = getDomain(affiliate_cd);
  const param = {
    token: token,
    url: `${dzUrl}/api/v1/wco/tools/${enduser_no}`,
  };
  return http(param);
};

export const GetFileDZ = (config: any, url: any) => {
  const { token } = config;
  const param = {
    token: token,
    url: url,
  };
  return downloadDZ(param);
};
