export const defaultErrorValueBlank = {
  enduser_no_error: "",
  company_name_error: "",
  last_name_error: "",
  customer_type_error: "",
  mail_address_error: "",
  attention_check_flg_error: "",
  claim_line_error: "",
  postal_code_error: "",
  condition_of_tool_error: "",
  error_type_error: "",
  guarantee_type_error: "",
  privacy_check_flg_error: "",
  transport_message_error: "",
  transport_message_error2: "",
  upload_claim_media_invoice_error: "",
  upload_error: "",
};

export const CustomerMemorandum = {
  name: "",
  memo: "CustomerMemorandum",
  date: "",
  buttonFlg: false,
};

export const OtherContents = {
  title: "Other Contents",
  name: "Giver_name Middle_name Family_name",
  memo: "Other Contents",
  date: "21/10/2020 11:40",
  buttonFlg: false,
};

export const InternalUseValue = [
  {
    name: "Giver_name Middle_name Family_name",
    memo: "Memo for Internal use",
    date: "21/10/2020 11:40",
    buttonFlg: true,
  },
];

export const dumiData = {
  claim_no: 1,
  claim_media: {
    claim_media_invoice: [
      {
        claim_no: 1,
        sub_no: 1,
        media_type: 1,
        media_name: "invoice.pdf",
        media_url: "/xxxx/yyyy/invoice.pdf",
        delete_flg: false,
        created_date: "2021-08-23T03:16:07Z",
        created_by: "dGVzdE0",
        updated_date: "2021-08-23T03:16:07Z",
        updated_by: "dGVzdFU",
      },
      {
        claim_no: 1,
        sub_no: 1,
        media_type: 1,
        media_name: "invoice.pdf",
        media_url: "/xxxx/yyyy/invoice.pdf",
        delete_flg: false,
        created_date: "2021-08-23T03:16:07Z",
        created_by: "dGVzdE0",
        updated_date: "2021-08-23T03:16:07Z",
        updated_by: "dGVzdFU",
      },
    ],
    claim_media_picture: [
      {
        claim_no: 1,
        sub_no: 2,
        media_type: 2,
        media_name: "quotor.pdf",
        media_url: "/xxxx/yyyy/quotor.pdf",
        delete_flg: false,
        created_date: "2021-08-23T03:16:45Z",
        created_by: "dGVzdE0",
        updated_date: "2021-08-26T01:47:43Z",
        updated_by: "dGVzdFU",
      },
      {
        claim_no: 1,
        sub_no: 2,
        media_type: 2,
        media_name: "quotor.pdf",
        media_url: "/xxxx/yyyy/quotor.pdf",
        delete_flg: false,
        created_date: "2021-08-23T03:16:45Z",
        created_by: "dGVzdE0",
        updated_date: "2021-08-26T01:47:43Z",
        updated_by: "dGVzdFU",
      },
    ],
  },
};

export const TRANSPORT_MESSAGE = {
  transport_way_pick_up: false,
  transport_way_return: false,
  weight: 0,
  shipping_method: 0,
  transport_cost_flg: false,
};
