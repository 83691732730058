import React, { useState, useCallback, useEffect } from "react";
// UI
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "10px 20px 0 20px",
    },
    area: {
      display: "flex",
      alignItems: "center",
      paddingBottom: "10px",
    },
    item: {
      paddingRight: "10px",
    },
  })
);

export const ClaimResultTitle = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.claim" });
  const { handleCreate } = props;

  const selected = (
    <div className={classes.item}>
      <span>{props.numSelected}</span>
      {t("txt_claimSelected")}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.area}>
        {props.numSelected > 0 ? selected : null}
      </div>
      <div className={classes.area}>
        <div className={classes.item}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddBoxIcon />}
            onClick={handleCreate}
          >
            {t("btn_new_cliam")}
          </Button>
        </div>
        <div>
          <Button variant="contained"
           color="primary"
           startIcon={<CloudDownloadIcon />}
           onClick={props.downloadCSV}
           >{t("btn_download_cliam")}</Button>
        </div>
      </div>
    </div>
  );
};
