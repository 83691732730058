import React, { useState } from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { ClaimEndUserSearchControlTable } from "./ClaimDetail.EndUser.Search.Table";
import { ClaimEndUserSearchControl } from "./ClaimDetail.EndUser.Search.SearchControl";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CSearchModelContainer: {
      width: "1300px",
      height: "700px",
      overflow: "auto",
    },
    title: {
      background: "rgba(240, 242, 244, 0.5)",
      textAlign: "right",
      padding: "10px",
    },
    cell: {
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "5px 10px",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      "& .MuiButton-containedPrimary": {
        marginRight: "10px",
        marginLeft: "15px",
      },
    },
    buttonSetting: {
      marginLeft: "15px",
    },
  })
);

const defaultValue = {
  customer_no: "",
  customer_name: "",
  address: "",
  city: "",
  postal_cd: "",
  state_cd: "",
  tel_no: "",
};

export const ClaimDetailEndUserSearch = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { searchStatus } = props;

  // radio selected value
  const [radioSelectedValue, setRadioSelectedValue] = useState("");
  const { getCommonConfig, setCommonInfo } = useCommonInfo();

  const handleClose = () => {
    props.CSearchClose(false);
  };

  const handleClickOk = () => {
    const data = radioSelectedValue
      ? radioSelectedValue
      : props.endUserInfoList[0];
    if (props.formErrorList.customer_type_error !== "") {
      props.setFormErrorListValue("customer_type_error", "");
    }
    if (props.formErrorList.postal_code_error !== "") {
      props.setFormErrorListValue("postal_code_error", "");
    }
    // setConditionList(defaultValue);
    // setResultList([]);
    // setOrder("asc");
    // setOrderBy("");
    props.handleSend(data);
  };

  const handleClickOkToSearchEndUserDetail = () => {
    const data = radioSelectedValue
      ? radioSelectedValue
      : props.endUserInfoList[0];
    console.log(
      "handleEndUserDetailSearch handleClickOkToSearchEndUserDetail",
      data,
      radioSelectedValue
    );
    if (props.formErrorList.customer_type_error !== "") {
      props.setFormErrorListValue("customer_type_error", "");
    }
    props.handleEndUserDetailSearch(data.enduser_no);
  };

  const CSearchModelWindow = (
    <div className={classes.CSearchModelContainer}>
      <ClaimEndUserSearchControl
        handleRefresh={props.handleRefresh}
        handleChangeKey={props.handleChangeKey}
      />
      <ClaimEndUserSearchControlTable
        endUserInfoList={props.endUserInfoList}
        endUserInfoFlg={props.endUserInfoFlg}
        setEndUserInfoFlg={props.setEndUserInfoFlg}
        setRadioSelectedValue={setRadioSelectedValue}
        searchStatus={searchStatus}
      />
    </div>
  );

  const CSearchModelWindowButton = (
    <div className={classes.buttonControl}>
      <Button
        className={classes.buttonSetting}
        variant="contained"
        onClick={handleClose}
      >
        {t("prompt_button_Cancel")}
      </Button>
      {props.endUserInfoFlg === 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOkToSearchEndUserDetail}
        >
          {t("prompt_button_OK")}
        </Button>
      )}
      {props.endUserInfoFlg === 1 && (
        <Button variant="contained" color="primary" onClick={handleClickOk}>
          {t("prompt_button_OK")}
        </Button>
      )}
    </div>
  );

  return (
    <ModelWindow
      title={
        searchStatus === "list"
          ? "claim_detail_Esearch"
          : "claim_detail_Esearch2"
      }
      contents={CSearchModelWindow}
      button={CSearchModelWindowButton}
      openFlg={props.CSearchOpen}
      handleCloseEvent={handleClose}
    />
  );
};
