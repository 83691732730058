import React, { useState, useCallback, useEffect } from "react";

// component
import { ClaimResultTitle } from "./Claim.Result.Title";
import { ClaimResultTable } from "./Claim.Result.Table";

export const ClaimResult = (props: any) => {
  const {
    data,
    selectedArray,
    setSelectedArray,
  } = props;

  const [numSelected, setNumSelected] = useState(0);
  // const [selectedArray, setSelectedArray] = useState<string[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [needResetOrderBy, setNeedResetOrderBy] = useState(false);

  // change the selected number
  const changeNumSelected = (num: number) => {
    setNumSelected(num);
  };

  // Cancel button click event
  const handleCancel = () => {
    setSelectedArray([]);
    setSelectedRowArray([]);
    changeNumSelected(0);
    setIndeterminate(false);
    setAllChecked(false);
  };

  useEffect(() => {
    // init search
    if (props.searchFlg) {
      handleCancel();
      props.setSearchFlg(false);
      setNeedResetOrderBy(true);
    }
  }, [props.searchFlg]);

  return (
    <>
      <ClaimResultTitle
        numSelected={numSelected}
        selectedArray={selectedArray}
        selectedRowArray={selectedRowArray}
        handleCreate={props.handleCreate}
        downloadCSV={props.downloadCSV}

      />
      <ClaimResultTable
        data={data}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        selectedRowArray={selectedRowArray}
        setSelectedRowArray={setSelectedRowArray}
        indeterminate={indeterminate}
        setIndeterminate={setIndeterminate}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        changeNumSelected={(num: number) => changeNumSelected(num)}
        isloadingCompleted={props.isloadingCompleted}
        searchClickFlg={props.searchClickFlg}
        setSearchClickFlg={props.setSearchClickFlg}
        needResetOrderBy={needResetOrderBy}
        setNeedResetOrderBy={setNeedResetOrderBy}
        setClaimsSearchMessage={props.setClaimsSearchMessage}
      />
    </>
  );
};
