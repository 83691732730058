import React, { useState, useCallback, useEffect } from "react";
import { SummaryUsedPartsTableTbody } from "./Summary.UsedParts.Table.Tbody";
import { SummaryUsedPartsTableThead } from "./Summary.UsedParts.Table.Thead";
// UI
import { useStyles } from "../hooks/usedParts.userStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

interface Data {
  warrantyCharges: string;
  laborTime: string;
  freight: string;
  partsQtyUsed: string;
  partsAmount: string;
  partsVAT: string;
  partsSubtotal: string;
}

export const SummaryUsedPartdTable = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { dataList, formList, userInfoDZ, partsListAll } = props;

  return (
    <form noValidate autoComplete="off">
      <div>
        <div className={classes.box}>{t("summary_use_parts")}</div>
        <SummaryUsedPartsTableTbody
          dataList={dataList}
          formList={formList}
          userInfoDZ={userInfoDZ}
          partsListAll={partsListAll}
        />
        <SummaryUsedPartsTableThead />
      </div>
    </form>
  );
};
