import { PermissionGroupModel } from "../models/PermissionGroupModel";
import { http } from "./Http";

// AM2101
export const GetPermissionGroupListAPI = (
    config: any
): Promise<PermissionGroupModel[]> => {
    const { affiliate_cd, baseURL, token } = config;
    return http({
        token: token,
        url: `${baseURL}/v1/permission_groups/${affiliate_cd}`
    });
};