import React, { useState, useEffect } from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td:last-child": {
        borderRight: "1px solid rgba(198, 209, 221)",
      },
    },
  })
);

export const ClaimEndUserSearchControlTableTbody = (props: any) => {
  const classes = useStyles();
  const { searchStatus } = props;

  const [selectedValue, setSelectedValue] = useState("");
  const [initFlgEndUserInfoList, setInitFlgEndUserInfoList] = useState(true);
  const [initFlgEndUserInfoDetail, setInitFlgEndUserInfoDetail] =
    useState(true);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedValue(event.target.value);
    if (props.endUserInfoFlg === 0) {
      setInitFlgEndUserInfoList(false);
    } else if (props.endUserInfoFlg === 1) {
      setInitFlgEndUserInfoDetail(false);
    }
  };

  const handleClick = (row: any) => {
    props.handleClick(row);
  };

  return (
    <TableBody>
      {props.endUserInfoList.map((row: any, index: number) => {
        return (
          <>
            {searchStatus === "list" && (
              <TableRow
                className={classes.tbody}
                hover
                onClick={() => handleClick(row)}
              >
                <TableCell>
                  {props.endUserInfoFlg === 0 && (
                    <Radio
                      checked={
                        initFlgEndUserInfoList
                          ? index === 0
                          : selectedValue === row.enduser_no
                      }
                      value={row.enduser_no}
                      onChange={(event: any) => handleChange(event, index)}
                    />
                  )}
                  {props.endUserInfoFlg === 1 && (
                    <Radio
                      checked={
                        initFlgEndUserInfoDetail
                          ? index === 0
                          : Number(selectedValue) === row.line_no
                      }
                      value={row.line_no}
                      onChange={(event: any) => handleChange(event, index)}
                    />
                  )}
                </TableCell>
                <TableCell>{row.customer_type}</TableCell>
                <TableCell>
                  {row.customer_type === "company"
                    ? row.company_name
                    : row.customer_type === "private"
                    ? row.last_name + " " + row.first_name
                    : ""}
                </TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.zipcode}</TableCell>
                <TableCell>{row.city}</TableCell>
                <TableCell>{row.phone_no}</TableCell>
                <TableCell>{row.email}</TableCell>
              </TableRow>
            )}

            {searchStatus === "detail" && (
              <TableRow
                className={classes.tbody}
                hover
                onClick={() => handleClick(row)}
              >
                <TableCell>
                  {props.endUserInfoFlg === 0 && (
                    <Radio
                      checked={
                        initFlgEndUserInfoList
                          ? index === 0
                          : selectedValue === row.enduser_no
                      }
                      value={row.enduser_no}
                      onChange={(event: any) => handleChange(event, index)}
                    />
                  )}
                  {props.endUserInfoFlg === 1 && (
                    <Radio
                      checked={
                        initFlgEndUserInfoDetail
                          ? index === 0
                          : Number(selectedValue) === row.line_no
                      }
                      value={row.line_no}
                      onChange={(event: any) => handleChange(event, index)}
                    />
                  )}
                </TableCell>
                <TableCell>{row.last_name + " " + row.first_name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone_no}</TableCell>
              </TableRow>
            )}
          </>
        );
      })}
    </TableBody>
  );
};
