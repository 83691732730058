import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/information.userStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

export const SummaryAscInformation = (props: any) => {
  const classes = useStyles();
  const { formList, loginUserInfo, claimType } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { getUserInfoDZ } = useCommonInfo();
  // init country
  let userInfoDZ = getUserInfoDZ() as any;

  return (
    <form noValidate autoComplete="off">
      <div>
        <div>
          <div className={classes.box}>
            {t("summary_title_asc_information")}
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_asc_name")}</span>
            <span className={classes.message}>{userInfoDZ.customer_name}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_account")}</span>
            <span className={classes.message}>{userInfoDZ.customer_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_phone_number")}</span>
            <span className={classes.message}>{userInfoDZ.tel_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_address")}</span>
            <span className={classes.message}>
              {userInfoDZ.address_1 && (
                <>
                  <span style={{ fontWeight: "normal" }}>{userInfoDZ.address_1}</span>
                  <br />
                </>
              )}
              {
                userInfoDZ.address_2 && (
                  <>
                    <span style={{ fontWeight: "normal" }}>{userInfoDZ.address_2}</span>
                    <br />
                  </>
                )
              }
              {
                userInfoDZ.address_3 && (
                  <>
                    <span style={{ fontWeight: "normal" }}>{userInfoDZ.address_3}</span>
                  </>
                )
              }
            </span >
          </div >
          <div className={classes.row}>
            <span>{t("summary_txt_post_code")}</span>
            <span className={classes.message}>{userInfoDZ.postal_cd}</span>
          </div>
        </div >
      </div >
    </form >
  );
};
