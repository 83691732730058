import { GlobalConfigurationModel } from "../models/GlobalConfigurationModel";
import { GetGlobalConfiguration } from "./GlobalConfiguration";
import { GlobalUserInformationModel } from "../models/GlobalUserInformationModel";
import { GetGlobalUserInformation } from "./GlobalUserInformation";

import {
  GetPartsModel,
  GetFileUrl,
  GetRegionsForModel,
  GetWebMSIConfiguration,
} from "./WebMSI";
import { GetPermissionGroupListAPI } from "./PermissionGroup";
import { WebMSIConfigurationModel } from "../models/WebMSIConfigurationModel";
import {
  GetCustomerDetailListAPI,
  GetReadCustomerMasterAPI,
  GetCustomerDetailListAPIforSearch,
} from "./Customer";
import {
  GetReadClaimListAPI,
  GetCreditNotesForSearch,
  GetReadClaimListForCSV,
  GetReadClaimDetailAPI,
  CreateClaimAPI,
  UpdateClaimAPI,
  DeleteClaimAPI,
  GetReadRepairHistoryListAPI,
} from "./ClaimHeader";
import {
  CreateClaimLaborAPI,
  GetReadClaimLaborAPI,
  UpdateClaimLaborAPI,
  GetReadClaimLaborListAPI,
} from "./ClaimLabor";
import {
  GetUploadSignedUrlByWco,
  GetDownloadSignedUrlByWco,
  GetUploadFile,
  GetDownloadFile,
  GetDownloadCreditNoteSignedUrlByWco,
} from "./ULDLDocumentWCO";
import {
  GetReadItemMasterAPI,
  GetItemDetailListAPI,
  GetItemDetailForJobsAPI,
  GetBaseItemListAPI,
  GetItemDetailForWcoAPI,
} from "./Item";
import { GetReadUserInfoAPIforDZ } from "./UserInfo";
import { GetReadPriceAPIforSimulation } from "./Price";
import { GetClaimPrintAPI, GetClaimPrintAPIDumi } from "./DocumentGeneratorWco";
import {
  GetEndusersOverview,
  GetEndusersDetail,
  GetTools,
  GetFileDZ,
} from "./Dz";
import { GetAlternateItemAPI } from "./AlternateItem";
type API = {
  // For Endpoint
  getGlobalConfiguration: (token: string) => Promise<GlobalConfigurationModel>;
  // For User Configuration - it is more general
  getGlobalUserInformation: (
    token: string,
    url: string
  ) => Promise<GlobalUserInformationModel>;
  // For User Permission
  getRegionsForModel: (model: any, config: any) => any;
  getPartsModel: (model: any, locale: any, region: any, config: any) => any;
  getPermissionGroupListAPI: (config: any) => any;
  getFileUrl: (path: any, config: any) => any;
  getWebMSIConfiguration: (
    token: string,
    url: string
  ) => Promise<WebMSIConfigurationModel>;
  getCustomerDetailListAPI: (config: any) => any;
  getReadCustomerMasterAPI: (customer_no: any, config: any) => any;
  getCustomerDetailListAPIforSearch: (
    query_parameters: any,
    config: any
  ) => any;
  getReadClaimListAPI: (query_parameters: any, config: any) => any;
  getReadClaimDetailAPI: (claim_no: any, config: any) => any;
  getReadClaimListForCSV: (query_parameters: any, config: any) => any;
  getCreditNotesForSearch: (query_parameters: any, config: any) => any;
  getReadClaimLaborListAPI: (config: any) => any;
  getUploadSignedUrlByWco: (media_name: any, config: any) => any;
  getDownloadSignedUrlByWco: (
    claims_no: any,
    sub_no: any,
    config: any,
    type?: any
  ) => any;
  getUploadFile: (url: any, data: any) => any;
  getDownloadFile: (url: any) => any;
  getItemDetailListAPI: (config: any) => any;
  getReadItemMasterAPI: (item_no: any, config: any) => any;
  getItemDetailForJobsAPI: (model_no: any, config: any) => any;
  getBaseItemListAPI: (config: any) => any;
  getReadUserInfoAPIforDZ: (config: any) => any;
  getDownloadCreditNoteSignedUrlByWco: (ref_no: any, config: any) => any;
  createClaimAPI: (config: any, data: any) => any;
  updateClaimAPI: (claim_no: any, config: any, data: any) => any;
  deleteClaimAPI: (config: any, claim_no: any, data: any) => any;
  createClaimLaborAPI: (data: any, config: any) => any;
  getReadClaimLaborAPI: (model_no: any, config: any) => any;
  updateClaimLaborAPI: (model_no: any, data: any, config: any) => any;
  getReadPriceAPIforSimulation: (query_parameters: any, config: any) => any;
  getClaimPrintAPI: (claim_no: any, config: any) => any;
  getReadRepairHistoryListAPI: (query_parameters: any, config: any) => any;
  getEndusersOverview: (config: any) => any;
  getEndusersDetail: (config: any, enduser_no: any) => any;
  getTools: (config: any, enduser_no: any) => any;
  getItemDetailForWcoAPI: (model_no: any, config: any) => any;
  getClaimPrintAPIDumi: (config: any) => any;
  getFileDZ: (config: any, url: any) => any;
  getAlternateItemAPI: (query_parameters: any, config: any) => any;
};

var api: API = {
  getGlobalConfiguration: GetGlobalConfiguration,
  getGlobalUserInformation: GetGlobalUserInformation,
  // For Dashboard Messages
  getPartsModel: GetPartsModel,
  getRegionsForModel: GetRegionsForModel,
  getPermissionGroupListAPI: GetPermissionGroupListAPI,
  getFileUrl: GetFileUrl,
  getWebMSIConfiguration: GetWebMSIConfiguration,
  getCustomerDetailListAPI: GetCustomerDetailListAPI,
  getReadCustomerMasterAPI: GetReadCustomerMasterAPI,
  getCustomerDetailListAPIforSearch: GetCustomerDetailListAPIforSearch,
  getReadClaimListAPI: GetReadClaimListAPI,
  getReadClaimDetailAPI: GetReadClaimDetailAPI,
  getReadClaimListForCSV: GetReadClaimListForCSV,

  getCreditNotesForSearch: GetCreditNotesForSearch,
  getReadClaimLaborListAPI: GetReadClaimLaborListAPI,
  getUploadSignedUrlByWco: GetUploadSignedUrlByWco,
  getDownloadSignedUrlByWco: GetDownloadSignedUrlByWco,
  getUploadFile: GetUploadFile,
  getDownloadFile: GetDownloadFile,
  getItemDetailListAPI: GetItemDetailListAPI,
  getReadItemMasterAPI: GetReadItemMasterAPI,
  getItemDetailForJobsAPI: GetItemDetailForJobsAPI,
  getBaseItemListAPI: GetBaseItemListAPI,
  getReadUserInfoAPIforDZ: GetReadUserInfoAPIforDZ,
  getDownloadCreditNoteSignedUrlByWco: GetDownloadCreditNoteSignedUrlByWco,
  createClaimAPI: CreateClaimAPI,
  updateClaimAPI: UpdateClaimAPI,
  deleteClaimAPI: DeleteClaimAPI,
  createClaimLaborAPI: CreateClaimLaborAPI,
  getReadClaimLaborAPI: GetReadClaimLaborAPI,
  updateClaimLaborAPI: UpdateClaimLaborAPI,
  getReadPriceAPIforSimulation: GetReadPriceAPIforSimulation,
  getClaimPrintAPI: GetClaimPrintAPI,
  getReadRepairHistoryListAPI: GetReadRepairHistoryListAPI,
  getEndusersOverview: GetEndusersOverview,
  getEndusersDetail: GetEndusersDetail,
  getTools: GetTools,
  getItemDetailForWcoAPI: GetItemDetailForWcoAPI,
  getClaimPrintAPIDumi: GetClaimPrintAPIDumi,
  getFileDZ: GetFileDZ,
  getAlternateItemAPI: GetAlternateItemAPI,
};

export default api;
