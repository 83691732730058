import React from "react";
import clsx from "clsx";
// hook
import { useStyles } from "../hooks/table.useStyles";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// root
import { useHistory } from "react-router-dom";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/CloudDownload";
// language
import { GetPrivateAppRoutePath, PathKey } from "../../AppRoutes";
import { getCodeNameByNumber } from "../../../modules/common.methods";
import { useLocale } from "../../../modules/hooks/UseLocale";
// methods
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

type Order = "asc" | "desc";

export const SearchResultTableTbody = (props: any) => {
  const {
    pageInfo,
    selected,
    order,
    orderBy,
    moreShow,
    moreShowCustomerPoNo,
    handleDownload,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const { com } = useLocale({ defaultPath: "common.constants" });

  const { getUserInfoDZ } = useCommonInfo();
  const userInfoDZ = getUserInfoDZ() as any;

  // sort
  const setSortData = (data: any, order: any, orderBys: any) => {
    if (orderBys) {
      data.sort((a: any, b: any) => {
        let aValues = orderBys.map((orderBysub: any) => a[orderBysub]);
        let bValues = orderBys.map((orderBysub: any) => b[orderBysub]);

        let sort = 0;
        if (orderBys.length > 1) {
          if (
            bValues.toString().toUpperCase() < aValues.toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            bValues.toString().toUpperCase() > aValues.toString().toUpperCase()
          ) {
            sort = 1;
          }
        } else {
          if (
            Object.prototype.toString.call(bValues[0]) === "[object Number]"
          ) {
            if (bValues[0] === null && aValues[0] !== null) {
              sort = -1;
            } else if (bValues[0] !== null && aValues[0] === null) {
              sort = 1;
            } else if (bValues[0] < aValues[0]) {
              sort = -1;
            } else if (bValues[0] > aValues[0]) {
              sort = 1;
            }
          } else {
            if (!bValues[0]) {
              bValues[0] = "";
            }
            if (!aValues[0]) {
              aValues[0] = "";
            }
            if (
              bValues[0].toString().toUpperCase() <
              aValues[0].toString().toUpperCase()
            ) {
              sort = -1;
            } else if (
              bValues[0].toString().toUpperCase() >
              aValues[0].toString().toUpperCase()
            ) {
              sort = 1;
            }
          }
        }

        // console.log("sort", aValues, bValues);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  const changeView = (path: string) => {
    history.push(path);
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          return (
            <TableRow className={clsx(classes.tableRow)} hover tabIndex={-1}>
              <TableCell className={classes.nowrap} align="center">
                {row.credit_note_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_po_no && row.customer_po_no.length > 0
                  ? row.customer_po_no.map((item: any, itemIndex: any) => {
                      if (itemIndex === 0) {
                        return item;
                      } else if (itemIndex < 10) {
                        return <>, {item}</>;
                      } else if (itemIndex === 10) {
                        return (
                          <span
                            className={classes.link}
                            onClick={() =>
                              moreShowCustomerPoNo(row.customer_po_no)
                            }
                          >
                            ...
                          </span>
                        );
                      }
                    })
                  : ""}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.contents && row.contents.length > 0
                  ? row.contents.map((item: any, itemIndex: any) => {
                      const path =
                        GetPrivateAppRoutePath(PathKey.SUMMARY) +
                        `?claimno=${item}`;
                      if (
                        row.contents.length < 10 &&
                        row.contents.length - 1 === itemIndex
                      ) {
                        return (
                          <span
                            className={(userInfoDZ.errorFlag && userInfoDZ.wco_claim) ? classes.link : classes.nowrap}
                            onClick={() => (userInfoDZ.errorFlag && userInfoDZ.wco_claim) ? changeView(path) : null}
                          >
                            {item}
                          </span>
                        );
                      } else if (row.contents.length > 9 && itemIndex === 10) {
                        return (
                          <span
                          className={(userInfoDZ.errorFlag && userInfoDZ.wco_claim) ? classes.link : classes.nowrap}
                          onClick={() => (userInfoDZ.errorFlag && userInfoDZ.wco_claim) ? moreShow(row.contents) : null}
                          >
                            ...
                          </span>
                        );
                      } else if (itemIndex < 10) {
                        return (
                          <>
                            <span
                            className={(userInfoDZ.errorFlag && userInfoDZ.wco_claim) ? classes.link : classes.nowrap}
                            onClick={() => (userInfoDZ.errorFlag && userInfoDZ.wco_claim) ? changeView(path) : null}
                            >
                              {item}
                            </span>
                            ,
                          </>
                        );
                      }
                    })
                  : ""}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.issue_date || row.issue_date === ""
                  ? ""
                  : date(new Date(row.issue_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {currency(Number(row.total_amount_without_tax), row.currency)}
              </TableCell>
              <TableCell className={classes.iconCell} align="center">
                {row.media_url && (
                  <IconButton
                    color="primary"
                    onClick={() => handleDownload(row)}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
                {row.media_name && (
                  <p className={classes.media_name}>{row.media_name}</p>
                )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
