import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const style1 = {
  stroke: "rgb(230, 0, 18)",
  fill: "rgba(0, 140, 162, 0.3)",
  cursor: "pointer",
};

const style2 = {
  stroke: "rgb(230, 0, 18)",
  cursor: "pointer",
};

const style3 = {
  fill: "rgba(0, 140, 162, 0.3)",
  cursor: "pointer",
};

const style4 = {
  fill: "rgba(255, 255, 255, 0.1)",
  cursor: "pointer",
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svgMessage: {
      "& .emphasize": style1,
      "& .selected": style1,
      "& path:hover": style2,
      "& ellipse:hover": style2,
      "& circle:hover": style2,
      "& polygon:hover": style2,
      "& rect:hover": style2,
      "& text": {
        cursor: "pointer",
      },
    },
    layer1: {
      zIndex: 10,
      position: "relative",
    },
    layer2: {
      position: "absolute",
      zIndex: 100,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      "& path": style4,
      "& ellipse": style4,
      "& circle": style4,
      "& polygon": style4,
      "& rect": style4,
      "& path:hover": style3,
      "& ellipse:hover": style3,
      "& circle:hover": style3,
      "& polygon:hover": style3,
      "& rect:hover": style3,
    },
    emphasize: {
      backgroundColor: "rgba(0, 140, 162, 0.3)",
    },
    nowrapCell: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      padding: "5px !important"
    },
    noteNo: {
      paddingLeft: "30px!important",
    },
    thead: {
      "& th": {
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
        borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& .MuiTableCell-root": {
        padding: " 5px 18px",
      },
      "& th:last-child": {
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      },
    },
    registerTbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& td:last-child": {
        borderRight: "2px solid rgba(198, 209, 221, 0.5)",
      },
      "& .MuiTableCell-root": {
        padding: "0",
      },
      "& .MuiTableRow-root.MuiTableRow-hover:hover": {
        backgroundColor: "rgba(0, 140, 162, 0.3)",
        cursor: "pointer",
      },
    },
    tbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& td:last-child": {
        borderRight: "2px solid rgba(198, 209, 221, 0.5)",
      },
      "& .MuiTableCell-root": {
        padding: "5px",
      },
      "& .MuiTableRow-root.MuiTableRow-hover:hover": {
        backgroundColor: "rgba(0, 140, 162, 0.3)",
        cursor: "pointer",
      },
    },
    textField: {
      width: "100%",
      "& .MuiOutlinedInput-input": {
        padding: "0 5px",
        height: "30px",
        lineHeight: "30px",
      },
    },
    registerTextField: {
      width: "100%",
      "& .MuiOutlinedInput-input": {
        padding: "0 5px",
        height: "30px",
        lineHeight: "30px",
        textAlign: "right",
      },
    },
    Qty: {
      padding: 0,
      width: "40px",
    },
    AllocatedQty: { width: "40px" },
    AllocatedQtyTd: { width: "40px", textAlign: "right" },
    BOQty: { width: "40px", textAlign: "right" },
    AltemativeItem: { width: "40px" },
    price: { width: "120px" },
    checkboxArea: {
      padding: "0 9px",
    },
    registerCheckbox: {
      textAlign: "center",
    },
    descriptionCell: {
      padding: "5px !important"
    },
    registerCellId: {
      width: "15px",
      padding: "5px !important"
    },
    currency: {
      width: "40px",
    },
    estimatedArrivalDate: {
      width: "70px",
    },
    amount: {
      width: "40px",
      textAlign: "right",
    },
    registerTotal: {
      textAlign: "right",
    },
    totalName: {
      textAlign: "right",
    },
    numberCell: {
      background: "#fff",
    },
    amountNumberCell: {
      textAlign: "right",
      background: "#fff",
    },
    technicalInformation_link: {
      "& span": {
        color: "rgb(42, 95, 111)",
        textDecoration: "underline",
      },
      "& span:hover": {
        textDecoration: "none",
      },
    },
  })
);
