import React from "react";
// components
import { ClaimDetailHistoryThead } from "./ClaimHistory.Thead";
import { ClaimDetailHistoryTbody } from "./ClaimHistory.Tbody";
// language
import { useLocale } from "../../hooks/UseLocale";
// hooks
import { useStyles } from "./ClaimHistory.styles";
// UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

export const ClaimDetailHistory = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath:
      "modules.components.jobDetail.general.group_repair_updated_history",
  });
  return (
    <>
      <TableContainer className={classes.groupContainer}>
        <Table stickyHeader>
          <ClaimDetailHistoryThead />
          <ClaimDetailHistoryTbody 
            dataList={props.dataList} 
            currency={props.currency}
            errorType={props.errorType}
          />
        </Table>
      </TableContainer>
    </>
  );
};
