import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "0px 20px 0 20px",
      alignItems: "flex-end",
      borderBottom: "1px solid #CFCFCF",
      //height: "110px",
      "& .MuiStepper-root": {
        padding: "0 24px",
      },
    },
    area: {
      display: "flex",
      paddingBottom: "10px",
      alignItems: "baseline",
    },
    item: {
      paddingRight: "10px",
    },
    stepper: {
      width: "500px",
      backgroundColor: "#F9F9F9",
    },
    button: {
      marginRight: "10px",
      color: "#FFFFFF !important",
    },
    buttonSubmit: {
      marginLeft: "10px",
    },
    title: {
      fontSize: "1.6rem",
      paddingRight: "10px",
    },
    stepperArea: {
      width: "495px",
    },
    buttonArea: {
      display: "flex",
    },
    buttonContainer: {},
    submitError: {
      color: "red",
      margin: "0",
      textAlign: "right",
    },
  })
);
