import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SUPPORT_LANGUAGES } from './constants/supportLanguages';

const LOCALE_KEY = 'i18nextLng';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    react: {
      useSuspense: false,
    },
    backend: HttpApi,
  });

export default i18n;

export const getLocale = (): string => {
  return window.localStorage.getItem(LOCALE_KEY) || getBrowserLocale();
};

export const getBrowserLocale = () => {
  var lang = navigator.language;

  if (SUPPORT_LANGUAGES.filter((f) => f.code === lang).length === 0) {
    // Set Default
    lang = SUPPORT_LANGUAGES[0].code;
  }

  return navigator.language;
};
