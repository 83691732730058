import React, { FC } from 'react';
import { LoginForm } from '../../modules/components/LoginForm';
import Container from '@material-ui/core/Container';
import logo from '../../resources/svg/logo.min.svg'
import Box from '@material-ui/core/Box';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocaleSelect } from '../../modules/components/LocaleSelect';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoStyle: {
            maxWidth: '250px',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        loginForm: {
            maxHeight: '400px',
            paddingTop: 'calc((100vh - 400px) / 2)'
        }
    }),
);

export const Page: FC = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="sm">
            <div className={classes.loginForm}>
                <Box>
                    <img className={classes.logoStyle} src={logo} alt="Login Logo" />
                </Box>
                <Box m={3}>
                    <Box m={3}>
                        <LoginForm />
                    </Box>
                    <Box m={3}>
                        <Grid container alignItems="center" justify="center">
                            <LocaleSelect />
                        </Grid>
                    </Box>
                </Box>
            </div>
        </Container>
    )
}