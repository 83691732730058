import React, { FC } from "react";
// components
import { Loading } from "../loading/Loading";

// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core";

// props
type Props = {
  contents: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
    },
  })
);

export const AppContainer: FC<Props> = ({ contents }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Loading />
      <main className={classes.content}>{contents}</main>
    </React.Fragment>
  );
};
