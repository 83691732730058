export const APIErrorMessage: any = {
  getDownloadJobRepairByS3singedUrlApi: {
    MA0012: {
      message_id: "MA0012_selected_download",
      key: "txtJobs",
    },
  },
  getReadPromotionListAPI: {
    MA0012: {
      message_id: "MA0012_selected_download",
      key: "txtPromotion",
    },
  },
  getDownloadPromotionAPI: {
    MA0012: {
      message_id: "MA0012_selected_download",
      key: "txtPromotion",
    },
  },
};
