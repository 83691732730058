import React, { FC, useEffect, useState, useCallback } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// component
import { StepperArea } from "../../modules/components/stepperArea/StepperArea";
import { CompleteCongratulation } from "./components/Congratulation";
// route
import { useHistory } from "react-router-dom";
import { CLAIM_NO_QUERY_STRING } from "../../modules/constants/claim.constants";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
import api from "../../modules/api/Api";
import { getApiResultStatus } from "../../modules/common.methods";
// language
import { useLocale } from "../../modules/hooks/UseLocale";

export const Page: FC = () => {
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({ defaultPath: "modules.components.ap" });

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const history = useHistory();
  // common
  const {
    getCommonConfig,
    getClaimDetailInfo,
    setClaimDetailInfo,
    setCommonInfo,
  } = useCommonInfo();
  let config = getCommonConfig() as any;
  let detailInfo = getClaimDetailInfo() as any;

  // loading
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  const getNewClaimNo = (): string => {
    return history.location.search.indexOf(CLAIM_NO_QUERY_STRING) >= 0
      ? history.location.search.replace(CLAIM_NO_QUERY_STRING, "")
      : "";
  };

  // page Type=> 0:create, 1:Edit
  const [claimType, setClaimType] = useState(0);
  const [claimStatus, setClaimStatus] = useState(0);
  const [downloadMessage, setDownloadMessage] = useState("");
  // start
  const [startFlg, setStartFlg] = useState(false);
  useEffect(() => {
    const _claim_no = getNewClaimNo();
    if (!startFlg && Object.keys(config).length > 0 && config.token) {
      setStartFlg(true);
      if (!_claim_no && Object.keys(detailInfo).length === 0) {
        handleToRetrun();
      } else {
        setClaimType(1);
        setClaimDetailInfo({});
        getClaimDetail(_claim_no);
      }
    }
  }, [startFlg, config]);

  const getClaimDetail = (_claim_no: any) => {
    apiLoading(true);
    api
      .getReadClaimDetailAPI(Number(_claim_no), config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        console.log("AT1702 response: ", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setClaimStatus(response.status);
        }
      });
  };

  // return button
  const handleToRetrun = () => {
    // change
    const path = GetPrivateAppRoutePath(PathKey.CLAIM);
    handleOnClick(path);
  };

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  const handleToPrint = () => {
    const _claim_no = getNewClaimNo();
    setDownloadMessage("");
    apiLoading(true);
    handleToPrintFunc(_claim_no, 0);
    //setTimeFunc(_claim_no, 0);
  };

  let timer: any;
  const setTimeFunc = (_claim_no: any, count: number) => {
    clearTimeout(timer);
    console.log("setTimeFunc", count);

    if (count > 2) {
      setDownloadMessage(com("messages.download_button_error"));
      apiLoading(false);
    } else {
      timer = setTimeout(() => {
        handleToPrintFunc(_claim_no, count + 1);
      }, 3000);
    }
  };

  const handleToPrintFunc = (_claim_no: any, count: number) => {
    console.log("setTimeFunc handleToPrintFunc", count);
    api
      .getDownloadSignedUrlByWco(_claim_no, "", config, "pdf")
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          if (code === "404" || code === 404) {
            setTimeFunc(_claim_no, count);
          } else {
            apiLoading(false);
            errorMessage(response);
          }
        } else {
          clearTimeout(timer);
          // const url = response.signed_url;
          const url = response.content;
          const fileName = `Warranty_Claim_${_claim_no}.pdf`;

          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              apiLoading(false);

              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
              }
            });
        }
      });
  };

  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    const message = com("messages." + response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  const getStepper = (status: any, type: any) => {
    console.log("getStepper", status, type);
    if (type === 0) {
      return 2;
    } else {
      if (status === "100") {
        return 2;
      } else if (status === "400") {
        return 1;
      } else {
        return 0;
      }
    }
  };

  const contents = (
    <div id="contents">
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <StepperArea
        claimType={claimType}
        claimStatus={claimStatus}
        activeStep={getStepper(claimStatus, claimType)}
      />
      <CompleteCongratulation
        handleToPrint={handleToPrint}
        handleToReturn={handleToRetrun}
        downloadMessage={downloadMessage}
      />
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
