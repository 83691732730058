import { AUTH0_AUDIENCES } from "../constants/api.audience.constants";
import { WebMSIConfigurationModel } from "../models/WebMSIConfigurationModel";
import { getWebMsi, http } from "./Http";

export const GetPartsModel = (
  model: any,
  locale: any,
  region: any,
  config: any
) => {
  const { token, webMSIURL } = config;
  const url = `${webMSIURL}/v1/models/${model}?locale=${locale}&region=${region}`;
  return http({
    token: token,
    url: url,
    config: config,
    apiAudience: AUTH0_AUDIENCES.WEBMSI,
  });
};

export const GetFileUrl = (path: any, config: any) => {
  const { token, webMSIURL } = config;
  const url = `${webMSIURL}/v1/documents/${path}`;

  return http({
    token: token,
    url: url,
    config: config,
    apiAudience: AUTH0_AUDIENCES.WEBMSI,
  });
};

export const GetRegionsForModel = (model: any, config: any) => {
  const { token, webMSIURL } = config;
  const url = `${webMSIURL}/v1/models/${model}/regions`;
  return http({
    token: token,
    url: url,
    config: config,
    apiAudience: AUTH0_AUDIENCES.WEBMSI,
  });
};

export const GetWebMSIConfiguration = (
  token: string,
  url: string
): Promise<WebMSIConfigurationModel> => {
  return http({
    token: token,
    url: `${url}/v1/configuration`,
  }).then(({ response }) => {
    return response as WebMSIConfigurationModel;
  });
};
