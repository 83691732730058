import React from "react";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { determineDataType } from "../../../modules/common.methods";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    technicalInformation: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop: "10px",
      "& p": {
        margin: 0,
      },
      "& ul": {
        listStyle: "none",
        margin: "0",
        padding: "0",
        display: "flex",
        flexWrap: "wrap",
      },
    },
    title: {
      paddingRight: "10px",
    },
    link: {
      paddingRight: "10px",
      paddingBottom: "10px",
      "& p": {
        margin: 0,
        color: "rgb(42, 95, 111)",
        textDecoration: "underline",
        cursor: "pointer",
      },
      "& p:hover": {
        textDecoration: "none",
      },
      "& a": {
        color: "rgb(42, 95, 111)",
      },
    },
  })
);

export const ClaimDetailPartsTechnicalInformation = (props: any) => {
  const classes = useStyles();
  const { moviesList, title, moviesClick } = props;

  return (
    <div className={classes.technicalInformation}>
      <p className={classes.title}>{title}: </p>
      <ul>
        {determineDataType(moviesList) &&
          moviesList.map((item: any) => (
            <li className={classes.link}>
              <a href={item.path} target="_blank">
                {item.name}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};
