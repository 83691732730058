import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

interface HeadCell {
  id: string;
  label: string;
}

export const headCells: HeadCell[] = [
  { id: "type", label: "table_enduser_search_type" },
  { id: "name_compay_name", label: "table_enduser_search_name_company" },
  { id: "street", label: "table_enduser_search_street" },
  { id: "post_code", label: "table_enduser_search_post_code" },
  { id: "city", label: "table_enduser_search_city" },
  { id: "tel_no", label: "table_enduser_search_phone" },
  { id: "email", label: "table_enduser_search_email" },
];

export const headCells2: HeadCell[] = [
  { id: "name", label: "table_enduser_search_name" },
  { id: "email", label: "table_enduser_search_email" },
  { id: "phone_no", label: "table_enduser_search_phone" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& th:first-child": {
        width: "20px",
      },
      "& th:last-child": {
        borderRight: "1px solid rgba(198, 209, 221)",
      },
    },
    theadCell: {
      background: "rgba(240, 242, 244)",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export const ClaimEndUserSearchControlTableThead = (props: any) => {
  const classes = useStyles();
  const { searchStatus } = props;
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell className={classes.theadCell}></TableCell>
        {searchStatus === "list" &&
          headCells.map((headCell) => (
            <TableCell className={classes.theadCell} key={headCell.id}>
              {t(headCell.label)}
            </TableCell>
          ))}
        {searchStatus === "detail" &&
          headCells2.map((headCell) => (
            <TableCell className={classes.theadCell} key={headCell.id}>
              {t(headCell.label)}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
