import React, { useState } from "react";
import Webcam from "react-webcam";
import clsx from "clsx";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { AlertMesssageBox } from "../../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CSearchModelContainer: {
      // width: "1300px",
      // height: "700px",
      // overflow: "auto",
    },
    title: {
      background: "rgba(240, 242, 244, 0.5)",
      textAlign: "right",
      padding: "10px",
    },
    cell: {
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "5px 10px",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "left",
    },
    buttonSetting: {
      marginLeft: "15px",
    },
    imgCss: {
      width: "100px",
      height: "100px",
    },
    screenShotButton: {
      cursor: "pointer",
      color: "#fff",
      backgroundColor: "#3D899F",
      padding: "6px 16px",
      fontSize: "0.875rem",
      minWidth: "64px",
      boxSizing: "border-box",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      lineHeight: "1.75",
      borderRadius: "4px",
      letterSpacing: "0.02857em",
      display: "inline-block",
    },
    screenShotButtonWrapper: {
      textAlign: "center",
      width: "720px",
      margin: "0 auto",
      position: "relative",
    },
    bigPhoto: {
      width: "669px",
      height: "500px",
      position: "absolute",
      top: "0px",
      left: "26px",
      opacity: "0",
      transition: "opacity 0.8s ease-in 0.3s",
    },
    showPhoto: {
      opacity: "1",
    },
    photoShowWrapper: {
      width: "800px",
      display: "flex",
      flexWrap: "wrap",
      margin: "0 auto",
    },
    photoShowItem: {
      width: "100px",
      paddingLeft: "4px",
      paddingTop: "4px",
      position: "relative",
      "& img": {
        width: "100%",
        height: "auto",
        cursor: "pointer",
        border: "3px solid #fff",
      },
      "& img:hover": {
        borderColor: "#3D899F",
      },
      "& .MuiIconButton-root": {
        background: "#000",
        padding: "0",
        borderRadius: "2px",
      },
      "& .MuiCheckbox-colorSecondary.Mui-disabled": {
        color: "rgba(255, 255, 255, 0.26)",
      },
      "& .MuiCheckbox-root": {
        color: "rgba(255, 255, 255)",
      },
      "& .Mui-checked": {
        color: "#3D899F",
      },
    },
    checkboxArea: {
      position: "absolute",
      right: "0",
      bottom: "0",
    },
  })
);

export const ClaimDetailTakePhotos = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { photoList, setPhotoList } = props;
  const [bigPhoto, setBigPhoto] = useState(
    process.env.PUBLIC_URL + "/transparent.png"
  );

  const handleClose = () => {
    props.handleWebCamClose(false);
  };

  const handleClickOk = () => {
    const newList =
      photoList.length > 0
        ? photoList.filter((_item: any) => _item.checkboxFlag)
        : [];
    if (newList.length > 0) {
      props.addPhotoByCam(newList, props.name);
    }
    props.handleWebCamClose(false);
  };

  const registerImageClick = (index: any, item: any) => {
    if (!itemDisabled(item)) {
      setPhotoList((prevState: any) => {
        let newList: any = JSON.parse(JSON.stringify(prevState));
        newList[index].checkboxFlag = !newList[index].checkboxFlag;
        return newList;
      });
    }
  };

  const itemDisabled = (item: any) => {
    let maxLength =
      10 - (props.uploadPhotoList.length + props.documentList.length);
    let newList =
      photoList.length > 0
        ? photoList.filter((_item: any) => _item.checkboxFlag)
        : [];
    if (newList.length > 0) {
      return !item.checkboxFlag ? newList.length >= maxLength : false;
    } else {
      return false;
    }
  };

  const showBigPhoto = (item: any) => {
    setBigPhoto(item.src);
  };

  const hideBigPhoto = () => {
    setBigPhoto(process.env.PUBLIC_URL + "/transparent.png");
  };

  const webCamModelWindow = (
    <>
      <div className={classes.CSearchModelContainer}>
        <div className={classes.screenShotButtonWrapper}>
          <Webcam
            audio={false}
            height={500}
            screenshotFormat="image/jpeg"
            width={720}
          >
            {({ getScreenshot }) => (
              <div
                className={classes.screenShotButton}
                onClick={() => {
                  const imageSrc = getScreenshot();
                  setPhotoList((prevState: any) => {
                    const newList: any = [
                      ...prevState,
                      { src: imageSrc, checkboxFlag: false },
                    ];
                    return newList;
                  });
                }}
              >
                {t("capture_photo")}
              </div>
            )}
          </Webcam>
          {
            <div
              className={clsx(classes.bigPhoto, bigPhoto && classes.showPhoto)}
            >
              <img src={bigPhoto} alt="" width="100%" height="500" />
            </div>
          }
        </div>
        <div className={classes.photoShowWrapper}>
          {photoList.length > 0 &&
            photoList.map((item: any, index: any) => (
              <div className={classes.photoShowItem}>
                <img
                  src={item.src}
                  onClick={() => registerImageClick(index, item)}
                  onMouseOver={() => showBigPhoto(item)}
                  onMouseOut={() => hideBigPhoto()}
                />
                <Checkbox
                  checked={item.checkboxFlag}
                  className={classes.checkboxArea}
                  onChange={() => registerImageClick(index, item)}
                  disabled={itemDisabled(item)}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );

  const webCamModelWindowButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleClickOk} color="primary">
        {t("prompt_button_OK")}
      </Button>
      <Button
        className={classes.buttonSetting}
        variant="contained"
        onClick={handleClose}
      >
        {t("prompt_button_Cancel")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="prompt_title_webCam"
      contents={webCamModelWindow}
      button={webCamModelWindowButton}
      openFlg={props.webCamOpen}
      handleCloseEvent={handleClose}
    />
  );
};
