import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { getLocalFolder } from "../../common.methods";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoverBtnComfirm: {
      color: "#979797",
      paddingLeft: "5px",
      "& .MuiTypography-body1": {
        display: "flex",
        alignItems: "center",
      },
    },
    popover: {
      pointerEvents: "none",
      marginLeft: "-40px",
    },
    popover2: {
      pointerEvents: "none",
      marginLeft: "40px",
    },
    paper: {
      padding: theme.spacing(1),
    },
    popCard: {
      width: "auto",
      maxWidth: "500px",
      lineHeight: "1",
      "& img": {
        maxWidth: "500px",
        height: "auto",
      },
    },
    popCardText: {
      lineHeight: "1.4",
      display: "block",
    },
  })
);

export const InformationIcon = (props: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const { t } = useLocale({
    defaultPath: "modules.components.info_text",
  });
  const getSrc = () => {
    const _file_name = props.imageFile ? props.imageFile : "register_enduser";
    return (
      process.env.PUBLIC_URL +
      `/iconInformation/${getLocalFolder()}/${_file_name}.png`
    );
  };
  return (
    <div className={classes.hoverBtnComfirm}>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoOutlinedIcon />
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={props.rightFlg ? classes.popover : classes.popover2}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={
          props.rightFlg
            ? {
                vertical: "top",
                horizontal: "right",
              }
            : {
                vertical: "top",
                horizontal: "left",
              }
        }
        transformOrigin={
          props.rightFlg
            ? {
                vertical: "top",
                horizontal: "right",
              }
            : {
                vertical: "top",
                horizontal: "left",
              }
        }
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.popCard}>
          {props.txtInner && props.txtInner.length > 0 ? (
            props.txtInner.map((item: any) => (
              <span className={classes.popCardText}>{t(item)}</span>
            ))
          ) : (
            <img src={getSrc()} alt="" width="100%"></img>
          )}
        </Typography>
      </Popover>
    </div>
  );
};
