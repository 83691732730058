import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStylesTop = makeStyles((theme: Theme) =>
  createStyles({
    containerFluid: {
      marginTop: "10px",
    },
    itemMemorandumContainer: {
      marginTop: "-20px",
    },
    tabArea: {
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    tabButton: {
      minWidth: "auto",
      background: "#fff",
      color: "#3D899F",
      border: "1px solid gray",
      borderBottom: "none",
      // textTransform: "inherit",
      "&:hover": {
        background: "gray",
        color: "#fff",
      },
      "&.Mui-selected": {
        background: "gray",
        color: "#fff",
      },
    },
    tabLayer: {
      border: "1px solid #000",
      position: "relative",
      overflow: "hidden",
    },
    layerContainer: {
      position: "relative",
      zIndex: 0,
    },
    groupContainer: {
      maxHeight: 370,
      border: "1px solid rgba(198, 209, 221, 0.5)",
    },
    zoomButtonContainer: {
      position: "absolute",
      bottom: 0,
      let: 0,
      zIndex: 1,
    },
    drawingContainer: {
      position: "relative",
    },
    registerMessageControl: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingTop: "10px",
    },
    MemoForEndUser: {
      width: "70%",
    },
    registerError: {
      color: "red",
    },
    registerErrorOrange: {
      color: "#ff9800",
    },
    registerTotal: {
      textAlign: "right",
    },
    svgArea: {
      paddingTop: "20px",
    },
    rightArea: {
      paddingTop: "92px!important",
    },
    register_error_message: {
      color: "red",
    },
    zoomIn: {
      width: "30px",
      height: "30px",
    },
    zoomOut: {
      width: "30px",
      height: "30px",
    },
  })
);
