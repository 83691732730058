import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/complete.useStyle";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const CompleteCongratulation = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { handleToPrint, handleToReturn, downloadMessage } = props;
  return (
    <form noValidate autoComplete="off">
      <div className={classes.outerLayer}>
        <div className={classes.titleBox}>
          <div className={classes.titleBody}>
            <div></div>
            <div className={classes.text}>{t("complete_congratulation")}</div>
          </div>
        </div>
        <div className={classes.titleLable}>
          <div>{t("complete_text1")}</div>
          <div>{t("complete_text2")}</div>
          <div>
            <DescriptionIcon className={classes.icon} />
          </div>
          <p className={classes.attetion}>{downloadMessage}</p>
          <div className={classes.ButtonBox}>
            <Button variant="contained" color="primary" onClick={handleToPrint}>
              {t("btn_print")}
            </Button>
            <Button variant="contained" onClick={handleToReturn}>
              {t("btn_return")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
