import React, { FC, useState, useEffect } from "react";
import clsx from "clsx";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { PersonalInformation } from "./components/Configuration.PersonalInformation";
import { Settings } from "./components/Configuration.Settings";

// UI
import { useStyles } from "./hooks/configuration.useStyles";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";

import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import SaveIcon from "@material-ui/icons/Save";

// language
import { useLocale } from "../../modules/hooks/UseLocale";

export const Page: FC = () => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.configuration" });
  const { com } = useLocale();

  const [partsTabActive, setPartsTabActive] = useState(false);

  const { setCommonInfo, getConfigurationErrorMessage } = useCommonInfo();

  // tab
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log("newValue", newValue);
    setValue(newValue);
  };

  // check result Genaral Tab
  const [genaralTabError, setGenaralTabError] = useState(false);
  // check result Genaral Tab
  const [custInfoTabError, setCustInfoTabError] = useState(false);

  const errorMessage = getConfigurationErrorMessage() as any;

  // alert
  const [alertShow, setAlertShow] = useState(errorMessage.alertShow);
  const [alertMessage, setAlertMessage] = useState(errorMessage.message);
  const [alertType, setAlertType] = useState(errorMessage.alertType);

  // loading
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  useEffect(() => {
    apiLoading(false);
  }, []);

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <div className={classes.root}>
        <AppBar className={classes.AppBar} position="static">
          <Tabs value={value} onChange={handleChange}>
            <Tab
              className={clsx(
                classes.tabButton,
                genaralTabError && classes.errorTabButton
              )}
              label={t("personal_information")}
            />
            <Tab
              className={clsx(
                classes.tabButton,
                custInfoTabError && classes.errorTabButton
              )}
              label={t("settings")}
            />
          </Tabs>
        </AppBar>
        {/* <Button variant="contained" color="primary" startIcon={<SaveIcon
        />}>
          {t("save")}
        </Button> */}
      </div>
      <Paper className={classes.paper}>
        <div hidden={value !== 0}>
          <PersonalInformation />
        </div>
        <div hidden={value !== 1}>
          <Settings />
        </div>
      </Paper>
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
