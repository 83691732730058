export const setLoborTotal = (
  response: any,
  dataList: any,
  transportMessage: any,
  partsList: any
) => {
  let laborValue: any = 0;
  let laborTotal: any = 0;
  laborValue = getErrorTypeLaborValue(response, dataList);
  laborTotal = getLaborTotal(laborValue, dataList, partsList.labor_list);
  let administrativerTotal: any = getAdministrativerTotal(
    dataList,
    partsList.administrative_cost_list
  );
  let freight = getFreight(transportMessage, dataList, partsList.freight_list);
  console.log(
    "total_amount0",
    response,
    dataList,
    laborValue,
    laborTotal,
    freight,
    administrativerTotal
  );
  const _charges: any = setTotalData(
    dataList,
    laborValue,
    laborTotal,
    freight,
    administrativerTotal,
    partsList.list_all
  );
  console.log("total_amount1", _charges);
  return _charges;
};

export const getErrorTypeLaborValue = (errorType: any, dataList: any) => {
  let errorTypeLaborValue = "";
  let errorTypeList: any = [];
  if (Array.isArray(errorType)) {
    errorTypeList = errorType;
  }
  if (dataList.error_type && dataList.error_type !== "") {
    for (let index in errorTypeList) {
      if (
        Number(errorTypeList[index].error_type) === Number(dataList.error_type)
      ) {
        errorTypeLaborValue = errorTypeList[index].labor_value;
        break;
      }
    }
  }
  return errorTypeLaborValue;
};

export const getLaborTotal = (code: any, dataList: any, labor_list: any) => {
  let _amount = 0;
  if (dataList.claim_line.length > 0) {
    let list = dataList.claim_line.filter((item: any) =>
      labor_list.includes(item.item_no)
    );
    if (list.length > 0) {
      list.forEach((item: any) => {
        if (!isNaN(code) && !isNaN(item.net_price)) {
          _amount += Number(code) * Number(item.net_price);
        }
      });
    }
  }
  return _amount;
};

export const getAdministrativerTotal = (
  dataList: any,
  administrative_cost_list: any
) => {
  let _amount = 0;
  if (dataList.claim_line.length > 0) {
    let list = dataList.claim_line.filter((item: any) =>
      administrative_cost_list.includes(item.item_no)
    );
    if (list.length > 0) {
      _amount = Number(list[0].net_price);
    }
  }
  return _amount;
};

export const getFreight = (
  transportMessage: any,
  dataList: any,
  freight_list: any
) => {
  console.log("getFreight", transportMessage, dataList);
  let _n = 0;
  if (
    transportMessage.transport_way_pick_up ||
    transportMessage.transport_way_return
  ) {
    _n = 1;
  }
  if (
    transportMessage.transport_way_pick_up &&
    transportMessage.transport_way_return
  ) {
    _n = 2;
  }
  // freight
  let _amount = 0;
  if (dataList.claim_line.length > 0) {
    let list = dataList.claim_line.filter((item: any) =>
      freight_list.includes(item.item_no)
    );
    if (list.length > 0) {
      list.forEach((item: any) => {
        if (!isNaN(item.net_price)) {
          _amount += _n * Number(item.net_price);
        }
      });
    }
  }
  console.log("getFreight _amount", _amount);
  return _amount;
};

export const setTotalData = (
  list: any,
  labor_value: any,
  laborTotal: any,
  freightTotal: any,
  administrativerTotal: any,
  partsList: any
) => {
  console.log("setTotalData", list, labor_value, laborTotal, freightTotal);
  let _charges = {
    qty_amount: 0,
    parts_amount: 0,
    total_amount: 0,
    labor_value: labor_value,
    labor_amount: laborTotal,
    administrativer_amount: administrativerTotal,
    freight_amount: freightTotal,
  };
  if (list.claim_line && list.claim_line.length > 0) {
    list.claim_line.forEach((item: any) => {
      if (!partsList.includes(item.item_no)) {
        // order qty amount
        if (!isNaN(item.order_qty)) {
          _charges.qty_amount += Number(item.order_qty);

          // parts amount
          if (!isNaN(item.net_price)) {
            _charges.parts_amount +=
              Number(item.order_qty) * Number(item.net_price);
          }
        }
        console.log("setTotalData -2", item.item_no, _charges.parts_amount);
      }
    });
    console.log(
      "setTotalData -1",
      _charges.parts_amount,
      _charges.total_amount
    );
    _charges.total_amount =
      _charges.parts_amount +
      Number(laborTotal) +
      Number(freightTotal) +
      Number(administrativerTotal);

    // xxx.0000002を防ぐのため
    _charges.parts_amount = Math.round(_charges.parts_amount * 100) / 100;
    _charges.total_amount = Math.round(_charges.total_amount * 100) / 100;

    console.log("total_amount", _charges);
    return _charges;
  }
};

export const fileDownloadFile = (row: any) => {
  const url = row.link;
  let w = window.open();
  if (w) {
    w.location.href = url;
  }
};
