import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

// UI
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ClaimDetailEndUserSearch } from "./ClaimDetail.EndUser.Search";
import { PhoneNumberInput } from "../../../modules/components/phoneInput/PhoneNumberInput";
import Link from "@material-ui/core/Link";
//import { PromotionSearchControlCSearch } from "../../promotion/components/Promotion.SearchControl.CSearch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { ReactComponent as PersonSearch } from "../../../assets/icons/PersonSearch.svg";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// hook
import { useStyles } from "../hooks/EndUser.style";
// api
import { ClaimDetailEndUserConditions } from "./ClaimDetail.EndUser.Conditions";
// compontent
import { InformationIcon } from "../../../modules/components/informationIcon/InformationIcon";
// common methods
import {
  getLocalFolder,
  getDomain,
  getPrivacyListInfo,
} from "../../../modules/common.methods";

export const ClaimDetailEndUser = (props: any) => {
  const classes = useStyles();
  const {
    formList,
    setFormListValue,
    formErrorList,
    setFormErrorListValue,
    valueValicationCheck,
    clearErrorCheck,
    mailValicationCheack,
    CSearchOpen,
    handleCSearch,
    CSearchClose,
    endUserInfoList,
    endUserInfoFlg,
    setEndUserInfoFlg,
    handleEndUserDetailSearch,
    handleSend,
    setEndUserInfoList,
    errorMessage,
    handleRefresh,
    handleChangeKey,
    searchStatus,
    privacyCheckFlgFunc,
    countriesList,
  } = props;
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { getUserInfoDZ, getCommonConfig } = useCommonInfo();
  // init country
  let userInfoDZ = getUserInfoDZ() as any;

  let config = getCommonConfig() as any;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [showItem, setShowItem] = useState(false);
  //hover button
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Allocate button click event
  const [getCondiions, setCondiions] = useState(false);
  const handleCondiions = () => {
    setCondiions(true);
  };

  const CondiionsClose = () => {
    setCondiions(false);
  };

  const changeRegisterEndUser = () => {
    window.open(
      getDomain(config.affiliate_cd) + "/add-new-account-type.html",
      "enduser"
    );
  };

  const getIconLink = () => {
    let { flag, path } = getPrivacyListInfo(config.affiliate_cd);
    if (flag) {
      return (
        <Link href={path} target="_blank">
          <InfoOutlinedIcon />
        </Link>
      );
    } else {
      return (
        <Link href={path} download>
          <InfoOutlinedIcon />
        </Link>
      );
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.tableUserInfo}>
        <tbody>
          <tr>
            <td colSpan={2} align="right">
              <div className={classes.importBox}>
                <div className={classes.importEnduserTitle}>
                  {t("enduser_my_contact")}
                </div>
                <Button
                  className={classes.buttonMyContacts}
                  variant="contained"
                  color="primary"
                  onClick={() => handleCSearch()}
                >
                  <PersonSearch />
                  {t("btn_my_contacts")}
                </Button>
              </div>
            </td>
            <th></th>
            <td></td>
            <th></th>
            <td align="right">
              <div className={classes.importBox}>
                <div className={classes.importEnduserTitle}></div>
                <div className={classes.register_end_user}>
                  <Button
                    className={classes.buttonRegister}
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAddIcon />}
                    onClick={changeRegisterEndUser}
                  >
                    {t("btn_register_end_user")}
                  </Button>
                  <InformationIcon txtInner={["register_enduser1"]} />
                </div>
              </div>
            </td>
            <th></th>
            <td></td>
          </tr>
          <tr></tr>
          <tr>
            <th>
              {t("txt_customer_type")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <FormControl
                variant="outlined"
                className={classes.selectContainerType}
                error={formErrorList.customer_type_error !== ""}
              >
                <Select
                  id="customer-type"
                  className={classes.select}
                  value={
                    formList.claim_address &&
                    formList.claim_address.customer_type
                  }
                  onChange={(e) =>
                    setFormListValue(
                      "claim_address",
                      e.target.value,
                      "customer_type"
                    )
                  }
                  onBlur={(e) =>
                    valueValicationCheck(
                      e.target.value,
                      "txt_customer_type",
                      "customer_type_error"
                    )
                  }
                >
                  {Array.isArray(com("customer_type")) ? (
                    com("customer_type").map((customerType: any) => (
                      <MenuItem
                        key={customerType.code}
                        value={customerType.code}
                      >
                        <ListItemText primary={customerType.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
                <FormHelperText>
                  {formErrorList.customer_type_error}
                </FormHelperText>
              </FormControl>
            </td>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </tr>
          <tr>
            <th>
              {t("txt_company_name")}
              {Number(
                formList.claim_address
                  ? formList.claim_address.customer_type
                  : ""
              ) === 1 && <span className={classes.mark}>*</span>}
            </th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textField}
                value={
                  formList.claim_address && formList.claim_address.company_name
                    ? formList.claim_address.company_name
                    : ""
                }
                error={formErrorList.company_name_error !== ""}
                helperText={formErrorList.company_name_error}
                onChange={(e) =>
                  setFormListValue(
                    "claim_address",
                    e.target.value,
                    "company_name"
                  )
                }
                onBlur={(e) => {
                  if (
                    Number(
                      formList.claim_address
                        ? formList.claim_address.customer_type
                        : ""
                    ) === 1
                  ) {
                    valueValicationCheck(
                      e.target.value,
                      "txt_company_name",
                      "company_name_error"
                    );
                    if (formErrorList.last_name_error !== "") {
                      clearErrorCheck("last_name_error");
                    }
                  } else {
                    if (formErrorList.company_name_error !== "") {
                      clearErrorCheck("company_name_error");
                    }
                  }
                }}
                inputProps={{ maxLength: 50 }}
              />
            </td>
            <th>
              {t("txt_last_name")}
              {Number(
                formList.claim_address
                  ? formList.claim_address.customer_type
                  : ""
              ) === 2 && <span className={classes.mark}>*</span>}
            </th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textFieldEnderuser}
                value={
                  formList.claim_address && formList.claim_address.last_name
                    ? formList.claim_address.last_name
                    : ""
                }
                error={formErrorList.last_name_error !== ""}
                helperText={formErrorList.last_name_error}
                onChange={(e) =>
                  setFormListValue("claim_address", e.target.value, "last_name")
                }
                onBlur={(e) => {
                  if (
                    Number(
                      formList.claim_address
                        ? formList.claim_address.customer_type
                        : ""
                    ) === 2
                  ) {
                    valueValicationCheck(
                      e.target.value,
                      "txt_last_name",
                      "last_name_error"
                    );
                    if (formErrorList.company_name_error !== "") {
                      clearErrorCheck("company_name_error");
                    }
                  } else {
                    if (formErrorList.last_name_error !== "") {
                      clearErrorCheck("last_name_error");
                    }
                  }
                }}
                inputProps={{ maxLength: 50 }}
              />
            </td>
            <th>{t("txt_first_name")}</th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textFieldEnderuserThree}
                value={
                  formList.claim_address && formList.claim_address.first_name
                    ? formList.claim_address.first_name
                    : ""
                }
                onChange={(e) =>
                  setFormListValue(
                    "claim_address",
                    e.target.value,
                    "first_name"
                  )
                }
                inputProps={{ maxLength: 50 }}
              />
            </td>
          </tr>
          <tr>
            <th>
              {t("txt_postal_code")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textField}
                value={
                  formList.claim_address && formList.claim_address.postal_code
                    ? formList.claim_address.postal_code
                    : ""
                }
                onChange={(e) =>
                  setFormListValue(
                    "claim_address",
                    e.target.value.substr(0, 8),
                    "postal_code"
                  )
                }
                onBlur={(e) => {
                  valueValicationCheck(
                    e.target.value,
                    "txt_postal_code",
                    "postal_code_error"
                  );
                }}
                error={formErrorList.postal_code_error !== ""}
                helperText={formErrorList.postal_code_error}
              />
            </td>
            <th>{t("txt_address_line_1")}</th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textFieldEnderuser}
                value={
                  formList.claim_address &&
                  formList.claim_address.address_line_1
                    ? formList.claim_address.address_line_1
                    : ""
                }
                onChange={(e) =>
                  setFormListValue(
                    "claim_address",
                    e.target.value,
                    "address_line_1"
                  )
                }
                inputProps={{ maxLength: 50 }}
              />
            </td>
            <th>{t("txt_address_line_2")}</th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textFieldEnderuserThree}
                value={
                  formList.claim_address &&
                  formList.claim_address.address_line_2
                    ? formList.claim_address.address_line_2
                    : ""
                }
                onChange={(e) =>
                  setFormListValue(
                    "claim_address",
                    e.target.value,
                    "address_line_2"
                  )
                }
                inputProps={{ maxLength: 50 }}
              />
            </td>
          </tr>
          <tr>
            <th>{t("txt_city")}</th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textField}
                value={
                  formList.claim_address && formList.claim_address.city
                    ? formList.claim_address.city
                    : ""
                }
                onChange={(e) =>
                  setFormListValue("claim_address", e.target.value, "city")
                }
                inputProps={{ maxLength: 50 }}
              />
            </td>
            <th>{t("txt_state")}</th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textFieldEnderuser}
                value={
                  formList.claim_address && formList.claim_address.state
                    ? formList.claim_address.state
                    : ""
                }
                onChange={(e) =>
                  setFormListValue("claim_address", e.target.value, "state")
                }
                inputProps={{ maxLength: 50 }}
              />
            </td>
            <th>{t("txt_country")}</th>
            <td>
              <FormControl
                variant="outlined"
                className={classes.selectContainer}
              >
                <Select
                  id="demo-simple-select-outlined"
                  className={classes.selectCountry}
                  value={
                    formList.claim_address &&
                    formList.claim_address.country_code
                      ? formList.claim_address.country_code.toLocaleUpperCase()
                      : userInfoDZ.country && userInfoDZ.country !== ""
                      ? userInfoDZ.country.toLocaleUpperCase()
                      : ""
                  }
                  onChange={(e) => {
                    const v: string = e.target.value as string;
                    setFormListValue(
                      "claim_address",
                      v ? v.toLocaleUpperCase() : v,
                      "country_code"
                    );
                  }}
                >
                  {countriesList.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.id.toLocaleUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>{t("txt_email")}</th>
            <td>
              <TextField
                variant="outlined"
                className={classes.textField}
                value={
                  formList.claim_address && formList.claim_address.mail_address
                    ? formList.claim_address.mail_address
                    : ""
                }
                type="email"
                onBlur={(e) => {
                  mailValicationCheack(
                    e.target.value,
                    "txt_email",
                    "mail_address_error"
                  );
                }}
                error={formErrorList.mail_address_error !== ""}
                helperText={formErrorList.mail_address_error}
                inputProps={{ maxLength: 254 }}
                onChange={(e) =>
                  setFormListValue(
                    "claim_address",
                    e.target.value,
                    "mail_address"
                  )
                }
              />
            </td>
            <th>{t("txt_tel_no")}</th>
            <td>
              {formList.claim_address && formList.claim_address.country_code ? (
                <>
                  <PhoneNumberInput
                    country={
                      formList.claim_address &&
                      formList.claim_address.country_code
                        ? formList.claim_address.country_code.toLowerCase()
                        : userInfoDZ.country.toLowerCase()
                    }
                    value={formList.claim_address.tel_no}
                    onChange={(value) =>
                      setFormListValue("claim_address", "+" + value, "tel_no")
                    }
                    className={classes.textWidth}
                  />
                </>
              ) : (
                <></>
              )}
              {!(
                formList.claim_address && formList.claim_address.country_code
              ) ? (
                <>
                  <PhoneNumberInput
                    country={
                      userInfoDZ.country && userInfoDZ.country.toLowerCase()
                    }
                    value={
                      formList.claim_address && formList.claim_address.tel_no
                    }
                    onChange={(value) =>
                      setFormListValue("claim_address", "+" + value, "tel_no")
                    }
                    className={classes.textWidth}
                  />
                </>
              ) : (
                <></>
              )}
            </td>
            <th>{t("txt_fax_no")}</th>
            <td className={classes.faxNo}>
              {formList.claim_address && formList.claim_address.country_code ? (
                <>
                  <PhoneNumberInput
                    country={
                      formList.claim_address &&
                      formList.claim_address.country_code
                        ? formList.claim_address.country_code.toLowerCase()
                        : userInfoDZ.country.toLowerCase()
                    }
                    value={formList.claim_address.fax_no}
                    onChange={(value) =>
                      setFormListValue("claim_address", "+" + value, "fax_no")
                    }
                    className={classes.textWidthFax}
                  />
                </>
              ) : (
                <></>
              )}
              {!(
                formList.claim_address && formList.claim_address.country_code
              ) ? (
                <>
                  <PhoneNumberInput
                    country={
                      userInfoDZ.country && userInfoDZ.country.toLowerCase()
                    }
                    value={
                      formList.claim_address && formList.claim_address.fax_no
                    }
                    onChange={(value) =>
                      setFormListValue("claim_address", "+" + value, "fax_no")
                    }
                    className={classes.textWidthFax}
                  />
                </>
              ) : (
                <></>
              )}
            </td>
          </tr>
          <tr>
            <th className={classes.thForToolDelivered}></th>
            <td className={classes.tdForToolDelivered} colSpan={4}>
              <div className={classes.privacyCheckArea}>
                <FormControl
                  error={formErrorList.privacy_check_flg_error != ""}
                  variant="standard"
                >
                  <FormControlLabel
                    className={classes.checkboxArea}
                    control={
                      <Checkbox
                        className={classes.checkboxColor}
                        checked={formList.privacy_check_flg}
                        onChange={(e) =>
                          setFormListValue(
                            "privacy_check_flg",
                            e.target.checked
                          )
                        }
                        name="gilad"
                        onBlur={privacyCheckFlgFunc}
                      />
                    }
                    label={t("privacy_check_flg")}
                  />
                  <FormHelperText>
                    {formErrorList.privacy_check_flg_error}
                  </FormHelperText>
                </FormControl>

                <div className={classes.hoverBtnComfirm}>{getIconLink()}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <ClaimDetailEndUserSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        endUserInfoList={endUserInfoList}
        endUserInfoFlg={endUserInfoFlg}
        setEndUserInfoFlg={setEndUserInfoFlg}
        handleEndUserDetailSearch={handleEndUserDetailSearch}
        handleSend={handleSend}
        setEndUserInfoList={setEndUserInfoList}
        errorMessage={errorMessage}
        handleRefresh={handleRefresh}
        handleChangeKey={handleChangeKey}
        searchStatus={searchStatus}
        formErrorList={formErrorList}
        setFormErrorListValue={setFormErrorListValue}
      />

      <ClaimDetailEndUserConditions
        CondiionsOpen={getCondiions}
        CondiionsClose={CondiionsClose}
      />
    </form>
  );
};
