import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
// UI
import { useStyles } from "../hooks/table.useStyles";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// root
import { Link } from "react-router-dom";
// hook
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";

type Order = "asc" | "desc";

export const ClaimResultTableTbody = (props: any) => {
  const classes = useStyles();
  const { com } = useLocale({ defaultPath: "common.constants" });
  const statusList = com("wco_status");
  const { pageInfo, selected, order, orderBy } = props;
  const isSelected = (name: string) => selected.indexOf(name) > -1;

  // sort
  const setSortData = (data: any, order: any, orderBys: any) => {
    if (orderBys) {
      data.sort((a: any, b: any) => {
        let aValues = orderBys.map((orderBysub: any) => a[orderBysub]);
        let bValues = orderBys.map((orderBysub: any) => b[orderBysub]);

        let sort = 0;
        if (orderBys.length > 1) {
          if (
            bValues.toString().toUpperCase() < aValues.toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            bValues.toString().toUpperCase() > aValues.toString().toUpperCase()
          ) {
            sort = 1;
          }
        } else {
          if (
            Object.prototype.toString.call(bValues[0]) === "[object Number]"
          ) {
            if (bValues[0] === null && aValues[0] !== null) {
              sort = -1;
            } else if (bValues[0] !== null && aValues[0] === null) {
              sort = 1;
            } else if (bValues[0] < aValues[0]) {
              sort = -1;
            } else if (bValues[0] > aValues[0]) {
              sort = 1;
            }
          } else {
            if (!bValues[0]) {
              bValues[0] = "";
            }
            if (!aValues[0]) {
              aValues[0] = "";
            }
            if (
              bValues[0].toString().toUpperCase() <
              aValues[0].toString().toUpperCase()
            ) {
              sort = -1;
            } else if (
              bValues[0].toString().toUpperCase() >
              aValues[0].toString().toUpperCase()
            ) {
              sort = 1;
            }
          }
        }

        // console.log("sort", aValues, bValues);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  // fetch the status name from constants
  const getStatusName = (code: string) => {
    let statusName = "";
    if (code && code !== "") {
      for (let index in statusList) {
        if (statusList[index].code === code) {
          statusName = statusList[index].name;
          break;
        }
      }
    }
    return statusName;
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          const isItemSelected = isSelected(row.claim_no);
          const path =
            GetPrivateAppRoutePath(
              Number(row.status) === 100 ? PathKey.CLAIMDETAIL : PathKey.SUMMARY
            ) + `?claimno=${row.claim_no}`;
          return (
            <TableRow
              className={clsx(
                classes.tableRow,
                row.status === "300" && classes.tableRowRed,
                (row.status === "400" || row.status === "500") &&
                  classes.tableRowGreen,
                row.status === "600" && classes.tableRowGray
              )}
              hover
              onClick={(event) => props.handleClick(event, row)}
              tabIndex={-1}
              key={row.claim_no}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox" className={classes.leftNowrap}>
                <Checkbox checked={isItemSelected} />
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                <Link to={path}>{row.claim_no}</Link>
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_name}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.model_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.serial_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_po_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.entry_date || row.entry_date === ""
                  ? ""
                  : date(new Date(row.entry_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.approve_date || row.approve_date === ""
                  ? ""
                  : date(new Date(row.approve_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {currency(
                  isNaN(row.total_amount) ? 0 : row.total_amount,
                  row.currency
                )}
              </TableCell>
              <TableCell className={classes.rightNowrap} align="center">
                {getStatusName(row.status)}
                {row.status === "210" && `(${row.re_entered_count})`}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
