import React, { useState } from "react";
// UI
import { useStyles } from "../hooks/ClaimDetail.Common.styles";
import TextField from "@material-ui/core/TextField";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { StepperArea } from "../../../modules/components/stepperArea/StepperArea";
export const ClaimDetailCommon = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { claimType, claimStatus, setFormListValue, formList } = props;

  const [claimPONoInputFlg, setClaimPONoInputFlg] = useState(true);
  
  return (
    <form noValidate autoComplete="off">
      <div className={classes.stepper}>
        <StepperArea
          claimStatus={claimStatus}
        />
      </div>
      <table className={classes.tableLayout}>
        <tbody>
          <tr className={classes.row}>
            <th className={classes.colTh}>{t("txt_claim_no")}</th>
            <td className={classes.searchmessageCell}>
              <div className={classes.row}>
                {claimType !== 0 && (
                  <TextField
                    className={classes.customerInputText}
                    variant="outlined"
                    disabled={true}
                    value={formList.claim_no}
                  />
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};
