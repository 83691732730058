import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/usedParts.userStyles";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// methods
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// import { PARTS_LIST } from "../../../modules/constants/claim.constants";

interface Data {
  part_number: string;
  qty: string;
  part_name: string;
  unit_price: string;
  net_total: string;
  vat: string;
  line_total: string;
}

export const SummaryUsedPartsTableTbody = (props: any) => {
  const classes = useStyles();
  const { dataList, formList, userInfoDZ, partsListAll } = props;
  return (
    <TableBody>
      {dataList.map(
        (row: any, index: any) =>
          !partsListAll.includes(row.item_no) && (
            <TableRow className={classes.tableRow} key={index}>
              <TableCell className={classes.nowrap} align="center">
                {row.item_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.order_qty}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.description}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.net_price
                  ? currency(row.net_price, userInfoDZ.currency)
                  : "-"}
              </TableCell>
              <TableCell className={classes.rightNowrap} align="center">
                {row.net_price && row.order_qty
                  ? currency(row.net_price * row.order_qty, userInfoDZ.currency)
                  : "-"}
              </TableCell>
            </TableRow>
          )
      )}
    </TableBody>
  );
};
