import { useState } from "react";
import api from "../api/Api";
import { WebMSIConfigurationModel } from "../models/WebMSIConfigurationModel";

const initialWebMSIConfigurationModel = {
  contents_url: undefined,
};

export const useWebMSIConfiguration = (): {
  getWebMSIConfiguration: (token: string, url: string) => Promise<WebMSIConfigurationModel>;
} => {
  const [userConfig, setUserConfig] = useState<WebMSIConfigurationModel>(
    initialWebMSIConfigurationModel
  );

  return {
    getWebMSIConfiguration: async (
      token: string,
      url: string
    ): Promise<WebMSIConfigurationModel> => {
      if (userConfig.contents_url === undefined) {
        // Extract from Server
        return api.getWebMSIConfiguration(token, url).then((config: any) => {
          setUserConfig({ contents_url: config.contentsUrl });
          return config;
        });
      } else {
        return userConfig;
      }
    },
  };
};
