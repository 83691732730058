import React from "react";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      padding: "20px 0",
      alignItems: "flex-start",
    },
    itemNo: {
      display: "flex",
    },
    title: {
      margin: 0,
      paddingRight: "10px",
    },
    item: {
      paddingRight: "10px",
    },
    inputTextField: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 5px",
      },
    },
    controlButton: {
      height: "30px",
      padding: "0 16px",
    },
  })
);

export const ClaimDetailPartsTableControl = (props: any) => {
  const classes = useStyles();
  const {
    itemNo,
    setItemNoValue,
    handleAddEvent,
    handleDeleteEvent,
    error,
    errorMessage,
    itemNoDisabled,
    addButtonDisabled,
    deleteButtonDisabled,
    itemNoName,
    AddButtonName,
    DeleteButtonName,
  } = props;
  return (
    <div className={classes.root}>
      <div className={classes.itemNo}>
        <p className={classes.title}>{itemNoName}</p>
        <div className={classes.item}>
          <TextField
            value={itemNo}
            error={error}
            helperText={errorMessage}
            variant="outlined"
            className={classes.inputTextField}
            disabled={itemNoDisabled}
            onChange={(e) => setItemNoValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddEvent();
              }
            }}
          />
        </div>
      </div>
      <div className={classes.item}>
        {addButtonDisabled && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddEvent}
            className={classes.controlButton}
            startIcon={<AddBoxIcon />}
          >
            {AddButtonName}
          </Button>
        )}
      </div>
      <div>
        {deleteButtonDisabled && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteEvent}
            className={classes.controlButton}
            startIcon={<DeleteIcon />}
          >
            {DeleteButtonName}
          </Button>
        )}
      </div>
    </div>
  );
};
