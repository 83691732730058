import { GlobalUserInformationModel } from "../models/GlobalUserInformationModel";
import { http } from "./Http";

export const GetGlobalUserInformation = (
  token: string,
  apiUrl: string
): Promise<GlobalUserInformationModel> => {
  return http({ token: token, url: `${apiUrl}` }).then(({ response }) => {
    return response as GlobalUserInformationModel;
  });
};
