import React from "react";
// hooks
import { useStyles } from "../hooks/Parts.styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// currency
import { priceOutputByCountryCode } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: "id",
    label: "txt_id",
  },
  {
    id: "Item",
    label: "txt_item",
  },
  {
    id: "Description",
    label: "txt_description",
  },
  {
    id: "Qty",
    label: "txt_order_qty",
  },
  {
    id: "Price",
    label: "txt_Price",
  },
  {
    id: "",
    label: "txt_total",
  },
];

export const ClaimDetailPartsRegisterThead = (props: any) => {
  const classes = useStyles();
  const { indeterminate, onSelectAllClick, checked } = props;
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });

  const getClassName = (id: string) => {
    if (id === "id") {
      return classes.registerCellId;
    } else if (id === "Qty") {
      return classes.Qty;
    } else if (id === "currency") {
      return classes.currency;
    }
  };
  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell className={classes.totalName} colSpan={6}>
          {props.totalName}
        </TableCell>
        <TableCell className={classes.amountNumberCell}>
          {currency(props.total, props.currency)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onSelectAllClick}
            checked={checked}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className={getClassName(headCell.id)}>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
