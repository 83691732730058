import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/messageToCustomer.userStyles";
import EventNoteIcon from "@material-ui/icons/EventNote";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// method
import { getCodeName } from "../../../modules/common.methods";

export const SummaryMessageToCustomer = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { list, status } = props;

  const getUser = (type: number, status: any = "") => {
    if (
      type === 20 ||
      type === 30 ||
      (type === 99 && (status === "200" || status === "210"))
    ) {
      return "summary_txt_message_from_makita";
    }
    if (type === 40 || (type === 99 && status === "300")) {
      return "summary_txt_message_from_asc";
    }
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.outerLayer}>
        <div className={classes.titleBox}>
          <div className={classes.titleBody}>
            <div>
              <EventNoteIcon className={classes.icon} />
            </div>
            <div className={classes.text}>{t("summary_txt_message_title")}</div>
          </div>
        </div>
        <div className={classes.messageDetail}>
          <ul className={classes.messageDetailList}>
            {list &&
              list.length > 0 &&
              list.map(
                (item: any, index: number) =>
                  (item.note_type === 99 ||
                    item.note_type === 20 ||
                    item.note_type === 30 ||
                    item.note_type === 40) &&
                  item.note !== "" && (
                    <li className={classes.item} key={index}>
                      <p className={classes.message}>{item.note}</p>
                      <div className={classes.bottomMessage}>
                        <p className={classes.date}>
                          {date(new Date(item.updated_date).getTime())}
                        </p>
                        <p className={classes.status}>
                          {t(getUser(item.note_type, status))}
                        </p>
                      </div>
                    </li>
                  )
              )}
          </ul>
        </div>
      </div>
    </form>
  );
};
