import React from "react";
// UI
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "60%",
      margin: "0 auto 20px",
    },
  })
);

export const AlertMesssageBox = (props: any) => {
  const classes = useStyles();
  const alertMessage = (
    <div className={classes.root}>
      {<Alert severity={props.type}>{props.message}</Alert>}
    </div>
  );
  return <>{props.show && alertMessage}</>;
};
