// UI
import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  groupContainer: {
    maxHeight: "130px",
    maxWidth: "100%",
    borderTop: "1px solid rgba(198, 209, 221, 0.5)",
    borderBottom: "1px solid rgba(198, 209, 221, 0.5)",
  },
  thead: {
    "& th": {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "0 10px",
    },
    "& th:last-child": {
      borderRight: "1px solid rgba(198, 209, 221, 0.5)",
    },
  },
  tbody: {
    "& td": {
      borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "0 10px",
    },
    "& td:last-child": {
      borderRight: "1px solid rgba(198, 209, 221, 0.5)",
    },
  },
  dateCell: {
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
  },
  DescriptionCell: {
    wordBreak: "break-all",
    whiteSpace: "break-spaces",
  },
}));
