export const SUPPORT_LANGUAGES = [
  // Use as Default
  {
    code: "da-DA",
    auth0_code: "da",
    text: "Dansk",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "de-DE",
    auth0_code: "de",
    text: "Deutsch",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "et-ET",
    auth0_code: "et",
    text: "Eesti keel",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "en-US",
    auth0_code: "en",
    text: "English",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "es-ES",
    auth0_code: "es",
    text: "Español",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "fr-FR",
    auth0_code: "fr",
    text: "Français",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "lv-LV",
    auth0_code: "lv",
    text: "Latviski",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "lt-LT",
    auth0_code: "lt",
    text: "Lietuvių",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "nl-NL",
    auth0_code: "nl",
    text: "Nederlands",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "no-NO",
    auth0_code: "nb",
    text: "Norsk",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },

  {
    code: "pl-PL",
    auth0_code: "pl",
    text: "Polski",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },

  {
    code: "fi-FI",
    auth0_code: "fi",
    text: "Suomi",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },
  {
    code: "sv-SV",
    auth0_code: "sv",
    text: "Svenska",
    special_list: [{ key: "parts_message", affiliate_cd: ["MD"] }],
  },

  //{
  //    code: 'ja-JP',
  //    auth0_code: 'ja',
  //    text: '日本語',
  //},
];
