import React, { useState } from "react";
// UI
import TextField from "@material-ui/core/TextField";
import { PhoneNumberInput } from "../../../modules/components/phoneInput/PhoneNumberInput";

// hook
import { useStyles } from "../hooks/control.useStyles";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { getCountry } from "../../../modules/common.methods";

export const PersonalInformation = (props: any) => {
  const classes = useStyles();
  const { getUserInfoDZ, getCommonConfig } = useCommonInfo();
  const config = getCommonConfig() as any;
  const userInfoRepair = getUserInfoDZ() as any;
  const { t } = useLocale({ defaultPath: "modules.components.configuration" });
  const getName = () => {
    return userInfoRepair.userInfo
      ? `${userInfoRepair.given_name ? userInfoRepair.given_name : ""} ${
          userInfoRepair.middle_name ? userInfoRepair.middle_name : ""
        } ${userInfoRepair.family_name ? userInfoRepair.family_name : ""}`
      : "";
  };
  const _country = getCountry(config.affiliate_cd);
  console.log("configuaton", userInfoRepair);
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <p className={classes.title}>{t("title")}</p>
      <div className={classes.groupArea}>
        <h3 className={classes.groupTitle}>{t("company_info")}</h3>
        <table className={`${classes.tableLayout}`}>
          <tbody>
            <tr>
              <th>{t("customer_no")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.customer_no
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("company_name")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.customer_name
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("country")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.country
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("address1")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.address_2
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("address2")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.address_3
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("state")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.state_cd
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("postal_code")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.postal_cd
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("contact_person")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfoRepair.customerInfo
                      ? userInfoRepair.customerInfo.attention_name
                      : ""
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.bottomArea}>
        <div className={classes.groupArea}>
          <h3 className={classes.groupTitle}>{t("user_info")}</h3>
          <table className={`${classes.tableLayout}`}>
            <tbody>
              <tr>
                <th>{t("name")}</th>
                <td>
                  <TextField
                    disabled={true}
                    variant="outlined"
                    value={getName()}
                  />
                </td>
              </tr>
              <tr>
                <th>{t("e_mail")}</th>
                <td>
                  <TextField
                    disabled={true}
                    variant="outlined"
                    value={
                      userInfoRepair.userInfo
                        ? userInfoRepair.userInfo.mail_address
                        : ""
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>{t("phone_no")}</th>
                <td>
                  <PhoneNumberInput
                    country={_country}
                    disabled={true}
                    onChange={() => false}
                    value={
                      userInfoRepair.userInfo
                        ? userInfoRepair.userInfo.tel_no
                        : ""
                    }
                    className={classes.textWidth}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p className={classes.explain}>
        {t("userInfo_message1")}
        <br />
        {t("userInfo_message2")}
      </p>
    </form>
  );
};
