import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, useContext, useEffect } from "react";
import { createContext } from "react";
import { useGlobalConfiguration } from "./GlobalConfiguration";
import { useGlobalUserInformation } from "./GlobalUserInformation";
import { useCommonInfo } from "./CommonInfoProvider";
import { APIAudience } from "../constants/api.audience.constants";
import { useWebMSIConfiguration } from "./WebMSIConfiguration";
import { AUTH0_AUDIENCES } from "../constants/api.audience.constants";

interface HttpContextInterface {}

const stub = (): never => {
  throw new Error("This method is not implemented");
};

const initialConfigurationContext = {};

const SecureHttpRequestContext = createContext<HttpContextInterface>(
  initialConfigurationContext
);
export const useSecureHttpRequest = () => useContext(SecureHttpRequestContext);

interface Props {
  children: any;
}

export const SecureHttpRequestProvider: FC<Props> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { getGlobalConfiguration } = useGlobalConfiguration();
  const { getGlobalUserInformation } = useGlobalUserInformation();
  const { setCommonConfig } = useCommonInfo();
  const { getWebMSIConfiguration } = useWebMSIConfiguration();

  // Wrapper for Auth0 Access Token
  const tokenHandler = (
    func: any,
    apiAudience: APIAudience,
    ...params: any[]
  ): Promise<any> => {
    console.log("website -7", func);

    if (isAuthenticated) {
      var audience =
        apiAudience === AUTH0_AUDIENCES.WEBMSI
          ? process.env.REACT_APP_AUTH0_WEBMSI_API_AUDIENCE!
          : process.env.REACT_APP_AUTH0_MAKMATE_API_AUDIENCE!;
      var scope =
        apiAudience === AUTH0_AUDIENCES.WEBMSI
          ? process.env.REACT_APP_AUTH0_WEBMSI_API_SCOPE!
          : process.env.REACT_APP_AUTH0_MAKMATE_API_SCOPE!;

      return getAccessToken(audience, scope).then((token) => {
        return func(token, ...params).then((res: any) => {
          return {
            response: res,
            token: token,
            getAccessTokenSilently: getAccessTokenSilently,
          };
        });
      });
    }
    throw new Error("Not Authenticated");
  };

  const getAccessToken = (
    audience: APIAudience,
    scope: string
  ): Promise<any> => {
    // @ts-ignore
    if (window.Cypress) {
      const itemKey = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`;
      const auth0 = JSON.parse(window.localStorage.getItem(itemKey)!);
      console.log(auth0);
      return Promise.resolve(auth0.body.access_token);
    } else {
      return getAccessTokenSilently({
        audience: audience,
        scope: scope,
      });
    }
  };

  const apiCallHandler = (
    attr = "web_console_endpoint",
    ...params: any[]
  ): Promise<any> => {
    console.log("website -10");
    // Make sure all configurations were loaded
    // Loading Global Config API
    return tokenHandler(getGlobalConfiguration, AUTH0_AUDIENCES.MAKMATE).then(
      ({ response }: { response: any }) => {
        console.log("website -3", response);

        // Loading Global User Information
        return tokenHandler(
          getGlobalUserInformation,
          AUTH0_AUDIENCES.MAKMATE,
          response.endpoint
        ).then(
          ({
            response,
            token,
            getAccessTokenSilently,
          }: {
            response: any;
            token: string;
            getAccessTokenSilently: any;
          }) => {
            console.log("website -4", response);

            let config = Object.assign(
              {},
              response,
              { token: token, getAccessTokenSilently: getAccessTokenSilently },
              {
                baseURL:
                  response.system_attribute.web_console_endpoint +
                  process.env.REACT_APP_REPAIR_PATH,
                webMSIURL: response.system_attribute.web_msi_endpoint,
                baseURLShop:
                  response.system_attribute.web_console_endpoint.replace(
                    "/repair",
                    ""
                  ) + process.env.REACT_APP_WEBSHOP_PATH,
                baseURLClaim:
                  response.system_attribute.web_console_endpoint.replace(
                    "/repair",
                    ""
                  ) + process.env.REACT_APP_CLAIM_PATH,
              }
            );
            return tokenHandler(
              getWebMSIConfiguration,
              AUTH0_AUDIENCES.WEBMSI,
              response.system_attribute.web_msi_endpoint
            ).then((_response: any) => {
              console.log("website -5", _response);
              // contents_url
              if (_response.response.contentsUrl) {
                config = Object.assign(config, {
                  webMSIContentsURL: _response.response.contentsUrl,
                });
              } else {
                config = Object.assign(config, {
                  webMSIContentsURL: "",
                });
              }
              setCommonConfig(config);
            });
          }
        );
      }
    );
  };

  // Actual Methods
  const httpRequestContext: HttpContextInterface = {};

  // Initialize
  useEffect(() => {
    console.log("website -1");
    apiCallHandler();
  }, []);

  return (
    <SecureHttpRequestContext.Provider value={{ ...httpRequestContext }}>
      {children}
    </SecureHttpRequestContext.Provider>
  );
};

const test = (_token: string) => {
  //    console.log(token);
  Promise.resolve(console.log("calling"));
};
