import React from "react";
import i18n from "./modules/i18n";
// components
import { ApplicationRouter } from "./modules/components/approuter/ApplicationRouter";
import { CommonInfoProvider } from "./modules/hooks/CommonInfoProvider";

function App() {
  // Use i18n
  i18n.init();

  return (
    <React.Fragment>
      <CommonInfoProvider>
        <ApplicationRouter />
      </CommonInfoProvider>
    </React.Fragment>
  );
}

export default App;
