import { Page as LoginPage } from "./login/LoginPage";
import { Page as ErrorPage } from "./error/ErrorPage";
import { Page as BlockedErrorPage } from "./error/BlockedErrorPage";
import { Page as Claim } from "./claim/Claim";
import { Page as ClaimDetail } from "./claimDetail/ClaimDetail";
import { Page as Summary } from "./summary/Summary";
import { Page as Complete } from "./complete/Complete";
import { Page as Ap } from "./ap/Ap";
import { Page as Configuration } from "./configuration/Configuration";
import { PrivateContents } from "../modules/components/PrivateContents";

export interface RouteModel {
  key: PathKey;
  path: string;
  component: any;
  isPublic?: boolean;
  title?: string;
}

export enum PathKey {
  LOGIN,
  LOGIN_ERROR,
  PRIVATE,
  CONFIGURATION,
  ERROR,
  CLAIM,
  CLAIMDETAIL,
  SUMMARY,
  COMPLETE,
  AP,
}

export const AppRoutes: RouteModel[] = [
  // For identify private route, set here
  {
    key: PathKey.PRIVATE,
    path: "/private",
    component: PrivateContents,
  },
  {
    key: PathKey.LOGIN,
    path: "/",
    component: LoginPage,
    isPublic: true,
  },
  {
    key: PathKey.LOGIN_ERROR,
    path: "/error",
    component: BlockedErrorPage,
    isPublic: true,
  },
  // Error path must be the end
  {
    key: PathKey.ERROR,
    path: "*",
    component: ErrorPage,
    isPublic: true,
  },
];

// withAuthenticationRequired does not allow passing additional parameters
export const PrivateAppRoutes: RouteModel[] = [
  {
    key: PathKey.CLAIM,
    path: "/private/claim",
    component: Claim,
    title: "claim_title",
  },
  {
    key: PathKey.CLAIMDETAIL,
    path: "/private/claimDetail",
    component: ClaimDetail,
    title: "claimDetail_title",
  },
  {
    key: PathKey.SUMMARY,
    path: "/private/summary",
    component: Summary,
    title: "summary_title",
  },
  {
    key: PathKey.COMPLETE,
    path: "/private/complete",
    component: Complete,
    title: "complete_title",
  },
  {
    key: PathKey.AP,
    path: "/private/creditNoteList",
    component: Ap,
    title: "ap_title",
  },
  {
    key: PathKey.CONFIGURATION,
    path: "/private/Configuration",
    component: Configuration,
    title: "configuration_title",
  },
  {
    key: PathKey.ERROR,
    path: "/private/*",
    component: ErrorPage,
  },
];

const getRoutePath = (routes: RouteModel[], key: PathKey) => {
  var route = routes.filter((f) => f.key === key);
  if (route.length > 0) {
    // Should be one record
    return route[0].path;
  } else {
    return routes[routes.length - 1].path;
  }
};

export const getAppRouteTitle = (path: string) => {
  var route = PrivateAppRoutes.filter((f) => f.path === path);
  if (route.length > 0) {
    // Should be one record
    return route[0].title;
  }
  return "";
};
export const getAppRouteKey = (path: string) => {
  var route = PrivateAppRoutes.filter((f) => f.path === path);
  if (route.length > 0) {
    // Should be one record
    return route[0].key;
  }
  return "";
};
export const GetAppRoutePath = (key: PathKey) => {
  return getRoutePath(AppRoutes, key);
};

export const GetPrivateAppRoutePath = (key: PathKey) => {
  return getRoutePath(PrivateAppRoutes, key);
};
