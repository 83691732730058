import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/table.useStyles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
// component
import { ClaimResultTableThead } from "./Claim.Result.Table.Thead";
import { ClaimResultTableTbody } from "./Claim.Result.Table.Tbody";
import TablePagination from "@material-ui/core/TablePagination";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

export const ClaimResultTable = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.claim" });
  const { getClaimsPageInfo, setClaimsPageInfo } = useCommonInfo();
  let pageMessage = getClaimsPageInfo() as any;

  const handleChangePage = (event: unknown, newPage: number) => {
    const obj = Object.assign({}, pageMessage, { page: newPage });
    setClaimsPageInfo(obj);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const number = parseInt(event.target.value, 10);
    const obj = Object.assign({}, pageMessage, { perPage: number, page: 0 });
    setClaimsPageInfo(obj);
  };

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.claim_no);
    // row selected claim_no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected claim_no list
      newSelected = newSelected.concat(props.selectedArray, row.claim_no);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected claim_no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected claim_no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected claim_no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data.slice(0);
      const newSelecteds = data.map((item: any) => item.claim_no);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg = list.length > 0 && list.length < props.data.length;
    props.setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === props.data.length;
    props.setAllChecked(allCheckedFlg);
  };

  // page
  const getPageInfo = () => {
    return {
      page: pageMessage.page,
      rowsPerPage: pageMessage.perPage,
    };
  };

  // order
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(["claim_no"]);

  useEffect(() => {
    // init search
    if (props.needResetOrderBy) {
      setOrderBy(["claim_no"]);
      setOrder("desc");
      props.setNeedResetOrderBy(false);
    }
  }, [props.needResetOrderBy]);
  
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy.indexOf(property) > -1 && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy([property]);
  };

  useEffect(() => {
    if (props.searchClickFlg) {
      const obj = Object.assign({}, pageMessage, { page: 0 });
      setClaimsPageInfo(obj);
      props.setSearchClickFlg(false);
    }
  }, [props.searchClickFlg]);

  return (
    <Paper className={classes.claimPaper}>
      <TableContainer className={classes.claimListContainer}>
        <ClaimResultTableThead
          indeterminate={props.indeterminate}
          allChecked={props.allChecked}
          onSelectAllClick={handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <ClaimResultTableTbody
          data={props.data}
          pageInfo={getPageInfo()}
          order={order}
          orderBy={orderBy}
          handleClick={(event: any, row: any) => handleClick(event, row)}
          selected={props.selectedArray}
        />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props.data.length}
        rowsPerPage={pageMessage.perPage}
        page={pageMessage.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
    </Paper>
  );
};
