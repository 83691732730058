import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/stepper.useStyles";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

// component
import { StepperArea } from "../../../modules/components/stepperArea/StepperArea";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const SummaryStepper = (props: any) => {
  const classes = useStyles();
  const {
    claimType,
    claimStatus,
    formList,
    handleToSubmmit,
    handleToEdit,
    handleToRetrunFunc,
    handleToSave,
    handleToDelete,
    handleToCancel,
    submitErrorFlag,
    handleToPrint,
  } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const getSepper = (status: any, type: any) => {
    if (type === 0) {
      return 1;
    } else {
      return status === "100" ||
        status === "400" ||
        status === "500" ||
        status === "600"
        ? 1
        : 0;
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.area}>
        <div className={classes.title}>
          {t("summary_title_claim_no")}
          {formList.claim_no}
        </div>
      </div>
      <div className={classes.area}>
        <div className={classes.stepperArea}>
          <StepperArea
            claimType={claimType}
            claimStatus={claimStatus}
            activeStep={getSepper(claimStatus, claimType)}
          />
        </div>
      </div>
      <div className={classes.area}>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonArea}>
            {(claimType === 0 ||
              Number(claimStatus) === 100 ||
              Number(claimStatus) === 300) && (
              <div className={classes.button}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleToEdit}
                  startIcon={<EditIcon />}
                >
                  {t("btn_edit")}
                </Button>
              </div>
            )}

            {(claimType === 0 ||
              Number(claimStatus) === 100 ||
              Number(claimStatus) === 300) && (
              <div className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToSave}
                  startIcon={<SaveIcon />}
                >
                  {t("btn_save")}
                </Button>
              </div>
            )}

            <div style={{ marginLeft: "1px" }}>
              <Button
                variant="contained"
                onClick={handleToRetrunFunc}
                startIcon={<ArrowBackIcon />}
              >
                {t("btn_return")}
              </Button>
            </div>

            {(Number(claimStatus) === 200 ||
              Number(claimStatus) === 210 ||
              Number(claimStatus) === 300) && (
              <div className={classes.buttonSubmit}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleToCancel}
                  startIcon={<CancelIcon />}
                >
                  {t("btn_cancel")}
                </Button>
              </div>
            )}

            {Number(claimStatus) === 100 && (
              <div className={classes.buttonSubmit}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleToDelete}
                  startIcon={<DeleteIcon />}
                >
                  {t("btn_delete")}
                </Button>
              </div>
            )}

            {(claimType === 0 ||
              Number(claimStatus) === 100 ||
              Number(claimStatus) === 300) && (
              <div className={classes.buttonSubmit}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToSubmmit}
                  startIcon={<CheckCircleOutlineIcon />}
                >
                  {t("btn_submmit")}
                </Button>
              </div>
            )}

            {Number(claimStatus) >= 200 && Number(claimStatus) < 600 && (
              <div className={classes.buttonSubmit}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToPrint}
                  startIcon={<PrintIcon />}
                >
                  {t("btn_print")}
                </Button>
              </div>
            )}
          </div>

          {submitErrorFlag && (
            <p className={classes.submitError}>
              {t("summary_diff_error_1")}
              <br />
              {t("summary_diff_error_2")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
