import React, { useState, useEffect } from "react";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    singleButtonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: "10px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    memoArea: {
      minWidth: "400px",
      margin: "0 auto",
    },
    title: {
      fontWeight: "bold",
      fontSize: "14px",
      margin: "0",
      padding: "0 20px 0 0",
      whiteSpace: "pre",
    },
    multilineTextField: {
      width: "100%",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px!important",
      },
    },
    error: {
      color: "red",
      margin: "0",
    },
  })
);

export const SummaryPopup = (props: any) => {
  const classes = useStyles();
  const {
    claim_no,
    completeWindowFlag,
    handleCloseCompleteChanges,
    handleCloseComplete,
    submitWindowFlag,
    handleCloseSubmitChanges,
    handleCloseSubmmit,
    deleteWindowFlag,
    handleCloseDeleteChanges,
    handleCloseDelete,
    deleteSuccessWindowFlag,
    handleCloseDeleteSuccessChanges,
    returnWindowFlag,
    handleCloseReturnChanges,
    handleCloseReturn,
    saveWindowFlag,
    handleCloseSaveChanges,
    handleCloseSave,
    cancelWindowFlag,
    handleCloseCancelChanges,
    cancelSuccessWindowFlag,
    handleCloseCancel,
    handleCloseCancelSuccessChanges,
    memoFlag,
    memoMessage,
    changeMemoMessage,
    handleCloseMemoChanges,
    handleCloseMemo,
    memoStatus,
    errorMessage,
  } = props;
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const { com } = useLocale({ defaultPath: "models.model_window" });
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  const attentionSaveChanges = (
    <p>
      {getName("messages.MS0098", {
        param1: t("txt_claim_no") + ":" + claim_no,
      })}
    </p>
  );

  const attentionSaveChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseSave}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // save message
  const attentionCompleteChanges = (
    <p>
      {getName("messages.MS0102")}
      <br />
      {getName("messages.MS0103")}
      <br />
      <span className={classes.fontBold}>{t("txt_claim_no")}</span>:{claim_no}
    </p>
  );
  const attentionCompleteChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseComplete}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // submit
  const attentionSubmitChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseSubmitChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseSubmmit}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );
  const attentionSubmitChanges = <p>{getName("messages.MS0101")}</p>;
  // delete message
  const attentionDeleteChanges = <p>{getName("messages.MS0105")}</p>;
  const attentionDeleteChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseDeleteChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseDelete}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // delete success message
  const attentionDeleteSuccessChanges = <p>{getName("messages.MS0106")}</p>;
  const attentionDeleteSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseDeleteSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );
  // return message
  const attentionReturnChanges = (
    <p>
      {getName("messages.MS0097")}
      <br />
      {getName("messages.MS0065")}
    </p>
  );
  const attentionReturnChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseReturnChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseReturn}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // cancel message
  const attentionCancelChanges = <p>{getName("messages.MS0105")}</p>;
  const attentionCancelChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseCancelChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseCancel}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // cancel success message
  const attentionCancelSuccessChanges = <p>{getName("messages.MS0107")}</p>;
  const attentionCancelSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseCancelSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // memo
  const attentionMemoChanges = (
    <div className={classes.memoArea}>
      <p className={classes.title}>{t("summary_memo")}</p>
      <TextField
        className={classes.multilineTextField}
        variant="outlined"
        multiline
        rows={10}
        value={memoMessage}
        onChange={(e: any) => changeMemoMessage(e.target.value)}
        inputProps={{ maxLength: 2048 }}
      />
      <p className={classes.error}>{errorMessage}</p>
    </div>
  );
  const attentionMemoChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" color="secondary" onClick={handleCloseMemo}>
        {com("prompt_button_Cancel")}
      </Button>
      <Button variant="contained" onClick={handleCloseMemoChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <>
      {completeWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionCompleteChanges}
          openFlg={completeWindowFlag}
          button={attentionCompleteChangesButton}
          handleCloseEvent={handleCloseCompleteChanges}
        />
      )}
      {submitWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionSubmitChanges}
          openFlg={submitWindowFlag}
          button={attentionSubmitChangesButton}
          handleCloseEvent={handleCloseSubmitChanges}
        />
      )}
      {deleteWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeleteChanges}
          openFlg={deleteWindowFlag}
          button={attentionDeleteChangesButton}
          handleCloseEvent={handleCloseDeleteChanges}
        />
      )}
      {deleteSuccessWindowFlag && (
        <ModelWindow
          title="prompt_title_success"
          contents={attentionDeleteSuccessChanges}
          openFlg={deleteSuccessWindowFlag}
          button={attentionDeleteSuccessChangesButton}
          handleCloseEvent={handleCloseDeleteSuccessChanges}
        />
      )}
      {returnWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionReturnChanges}
          openFlg={returnWindowFlag}
          button={attentionReturnChangesButton}
          handleCloseEvent={handleCloseReturnChanges}
        />
      )}
      {saveWindowFlag && (
        <ModelWindow
          title="prompt_title_success"
          contents={attentionSaveChanges}
          openFlg={saveWindowFlag}
          button={attentionSaveChangesButton}
          handleCloseEvent={handleCloseSaveChanges}
        />
      )}
      {cancelWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionCancelChanges}
          openFlg={cancelWindowFlag}
          button={attentionCancelChangesButton}
          handleCloseEvent={handleCloseCancelChanges}
        />
      )}
      {cancelSuccessWindowFlag && (
        <ModelWindow
          title="prompt_title_success"
          contents={attentionCancelSuccessChanges}
          openFlg={cancelSuccessWindowFlag}
          button={attentionCancelSuccessChangesButton}
          handleCloseEvent={handleCloseCancelSuccessChanges}
        />
      )}
      {memoFlag && (
        <ModelWindow
          title={"summary_txt_message_title"}
          contents={attentionMemoChanges}
          openFlg={memoFlag}
          button={attentionMemoChangesButton}
          handleCloseEvent={handleCloseMemo}
        />
      )}
    </>
  );
};
