//empty check
export const isEmptyCheck = (value: any) => {
  return !value || Object.keys(value).length === 0;
};

export const isEmptyStringOrNumber = (value: any) => {
  return value === undefined || value === null || value === "";
};

export const isEmptyNumber = (value: number) => {
  return value === undefined || value === null || isNaN(value);
};

export const isDateFormat = (value: any) => {
  return !(isNaN(value) && !isNaN(Date.parse(value)));
};

export const dateFormat = (fmt: any, date: any) => {
  var o: any = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12,
    "H+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    "S": date.getMilliseconds()
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
};

//email check
export const emailValidation = (value: any) => {
  let result = false;
  let validator = require('validator');

  if (value) {
    result = validator.isEmail(value, { allow_utf8_local_part: false });
  }
  return result;
};

// min length check
export const checkMinLength = (value: string, minLength: number) => {
  let result = true;
  if (value && value.length < minLength) {
    result = false;
  }
  return result;
};

// max length check
export const checkMaxLength = (value: string, maxLength: number) => {
  let result = true;
  if (value && value.length > maxLength) {
    result = false;
  }
  return result;
};

// digital number check
export const checkTypeNumber = (value: string) => {
  let result = true;
  if (value) {
    result = /^[0-9]+$/.test(value);
  }
  return result;
};

export const checkLength = (value: string, length: number) => {
  let result = true;
  const target = typeof value === "number" ? String(value) : value;
  if (target && target.length === length) {
    result = false;
  }
  return result;
};

const illegalChar: any = process.env.REACT_APP_ILLEGAL_CHAR;
export const checkIllegalChar = (value: string) => {
  let result = true;

  result =
    JSON.parse(illegalChar).filter((n: string) => value.indexOf(n) > -1)
      .length > 0;
  return result;
};

export const checkIllegalCharResult = (value: string) => {
  let result = true;

  result = JSON.parse(illegalChar).filter((n: string) => value.indexOf(n) > -1);
  return result;
};

const umlautsCharUnicode: any = process.env.REACT_APP_GERMAN_UMLAUTS_CHAR_UNICODE;
export const checkUmlautsCharUnicode = (value: string) => {
  let flag = true;
  if (value) {
    const res = new RegExp("[" + umlautsCharUnicode + "]+");
    const unicode_flag = value.match(res);
    flag = Boolean(unicode_flag);
  }
  return flag;
};
