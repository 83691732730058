import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      width: "800px",
      marginTop: "0px",
      // marginLeft: "-122px",
    },
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingLeft: "5px",
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
      },
    },
    searchmessageCell: {
      paddingRight: "20px",
    },
    customerInputText: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        width: "200px",
      },
      "& .PrivateNotchedOutline-root-47.MuiOutlinedInput-notchedOutline": {
        width: "100px",
      },
    },
    iconButton: {
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
      "& .MuiIconButton-label": {
        marginLeft: "-35px",
        marginTop: "2px",
      },
    },
    row: {
      display: "flex",
    },
    colTh: {
      padding: "4px 0px",
    },
  })
);
