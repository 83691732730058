import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM2801
export const CreateClaimLaborAPI = (data: any, config: any) => {
  const { baseURLClaim, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/labors/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AM2802
export const GetReadClaimLaborAPI = (model_no: any, config: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/labors/${affiliate_cd}/${model_no}`,
    config: config,
  };
  return http(param);
};

// AM2803
export const UpdateClaimLaborAPI = (model_no: any, data: any, config: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/labors/${affiliate_cd}/${model_no}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM2805
export const GetReadClaimLaborListAPI = (config: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/labors/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
