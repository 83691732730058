import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { GetPrivateAppRoutePath, PathKey } from "../../../../pages/AppRoutes";
import { useCommonInfo } from "../../../../modules/hooks/CommonInfoProvider";
import { ClaimIcon, ApIcon, configrationIcon } from "../../icons/Icons";

export interface NavigationDrawerItemGroup {
  menuItems: NavigationDrawerItem[];
}

export interface NavigationDrawerItem {
  textKey: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  path: string;
  showFlg: boolean;
}

export const GetNavigationDrawerItemGroups =
  (): NavigationDrawerItemGroup[] => {
    // loading
    const { getUserInfoDZ } = useCommonInfo();

    const userInfoDZ = getUserInfoDZ() as any;

    return [
      {
        menuItems: [
          {
            textKey: "txtClaim",
            icon: ClaimIcon,
            path: GetPrivateAppRoutePath(PathKey.CLAIM),
            showFlg:
              userInfoDZ.errorFlag &&
              userInfoDZ.userInfo &&
              userInfoDZ.userInfo.wco_claim &&
              userInfoDZ.customerInfo &&
              userInfoDZ.customerInfo.asc_flg,
          },
        ],
      },
      {
        menuItems: [
          {
            textKey: "txtAp",
            icon: ApIcon,
            path: GetPrivateAppRoutePath(PathKey.AP),
            showFlg:
              userInfoDZ.errorFlag &&
              userInfoDZ.userInfo &&
              userInfoDZ.userInfo.wco_credit_note &&
              userInfoDZ.customerInfo &&
              userInfoDZ.customerInfo.asc_flg,
          },
        ],
      },
      {
        menuItems: [
          {
            textKey: "txtConfiguration",
            icon: configrationIcon,
            path: GetPrivateAppRoutePath(PathKey.CONFIGURATION),
            showFlg: true,
          },
        ],
      },
    ];
  };
