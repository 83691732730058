import React, { useState } from "react";
// hook
import { makeStyles } from "@material-ui/core/styles";
// component
import { ClaimToolInformationSearchControlTableThead } from "./ClaimDetail.ToolInformation.Search.Table.Thead";
import { ClaimToolInformationSearchControlTableTbody } from "./ClaimDetail.ToolInformation.Search.Table.Tbody";
// UI
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

export const ClaimToolInformationSearchControlTable = (props: any) => {
  const { endUserInfoToolsList } = props;

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: "528px",
    },
  });
  const classes = useStyles();

  const handleClick = (value: any) => {
    props.setRadioSelectedValue(value);
    props.setAlertMessage("");
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <ClaimToolInformationSearchControlTableThead />
          <ClaimToolInformationSearchControlTableTbody
            endUserInfoToolsList={endUserInfoToolsList}
            handleClick={handleClick}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
};
