import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM0202
export const GetReadCustomerMasterAPI = (customer_no: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/customers/${affiliate_cd}/${customer_no}`,
    config: config,
  };
  return http(param);
};

// AM0205
export const GetCustomerDetailListAPI = (config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/customers/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};

// AM0206
export const GetCustomerDetailListAPIforSearch = (
  query_parameters: any,
  config: any
) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/customers/search/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
