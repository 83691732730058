import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/information.userStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const SummaryUserCompanyInformation = (props: any) => {
  const classes = useStyles();
  const { data } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  return (
    <form noValidate autoComplete="off">
      <div>
        <div>
          <div className={classes.box}>
            {t("summary_title_user_company_information")}
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_asc_name")}</span>
            <span>
              {data.customer_type === 1
                ? data.company_name
                : (data.last_name ? data.last_name : "") +
                  (data.first_name ? " " + data.first_name : "")}
            </span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_phone_number")}</span>
            <span>{data.tel_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_email_address")}</span>
            <span>{data.mail_address}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_address")}</span>
            <span>{data.address_line_1}</span>
          </div>
          <div className={classes.row}>
            <span>&nbsp;</span>
            <span>{data.address_line_2}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_post_code")}</span>
            <span>{data.postal_code}</span>
          </div>
        </div>
      </div>
    </form>
  );
};
