import React, { useState, useEffect } from "react";
import clsx from "clsx";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../hooks/summary.userStyle";
// methods
import { getNote } from "../../../modules/common.methods";

export const SummaryFreeNote = (props: any) => {
    const classes = useStyles();
    const {dataNote} = props;
    const { t } = useLocale({
        defaultPath: "modules.components.claim_detail",
      });

    console.log("SUMMARYFREENOTE "+JSON.stringify(dataNote));
    return(
        <div>
            <div className={classes.box}>{t("txt_free_note")}</div>
            <TextField
                
                  value={getNote(dataNote,15)}
                  className={classes.multilineTextField}
                  variant="outlined"
                  multiline
                  rows={3}
                />
        </div>
      );
}
