import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/usedParts.userStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

// component
interface HeadCell {
  id: string;
  label: string;
}
const headCells: HeadCell[] = [
  { id: "part_number", label: "summary_result_part_number" },
  { id: "qty", label: "summary_result_qty" },
  { id: "part_name", label: "summary_result_part_name" },
  { id: "unit_price", label: "summary_result_unit_price" },
  { id: "line_total", label: "summary_result_line_total" },
];
export const SummaryUsedPartsTableThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className={classes.titleHead}>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
