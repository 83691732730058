import React, { useState } from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { ClaimToolInformationSearchControlTable } from "./ClaimDetail.ToolInformation.Search.Table";
import { ClaimToolInformationSearchControl } from "./ClaimDetail.ToolInformation.Search.SearchControl";
import { AlertMesssageBox } from "../../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CSearchModelContainer: {
      width: "1300px",
      height: "700px",
      overflow: "auto",
    },
    title: {
      background: "rgba(240, 242, 244, 0.5)",
      textAlign: "right",
      padding: "10px",
    },
    cell: {
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "5px 10px",
    },
    rootEndUser: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& .MuiFormHelperText-contained": {
        color: "red",
        marginLeft: "0px",
      },
      "& .MuiButton-startIcon": {
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      "& .MuiButton-containedPrimary": {
        marginRight: "10px",
        marginLeft: "15px",
      },
    },
    buttonSetting: {
      marginLeft: "15px",
    },
    tableToolInfo: {
      width: "100%",
      margin: "0 auto",
      borderCollapse: "separate",
      borderSpacing: "10px",
      "& p": {
        whiteSpace: "nowrap",
      },
      "& th": {
        width: "200px",
        padding: "5px 0",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        //  paddingBottom: "10px",
        width: "13%",
      },
      "& .MuiIconButton-root": {
        padding: "0px",
      },
      "& .MuiIconButton-label": {
        marginTop: "2px",
      },
    },
    mark: {
      color: "red",
    },
    tdBox: {
      position: "relative",
    },
    modelArea: {
      position: "relative",
    },
  })
);

export const ClaimDetailToolInformationSearch = (props: any) => {
  const classes = useStyles();
  const { endUserInfoToolsList } = props;
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  // radio selected value
  const [radioSelectedValue, setRadioSelectedValue] = useState({});
  const [alertType, setAlertType] = useState("error");

  const handleClose = () => {
    props.CSearchClose(false);
  };

  const handleClickOk = () => {
    const data =
      radioSelectedValue && Object.keys(radioSelectedValue).length > 0
        ? radioSelectedValue
        : props.endUserInfoToolsList[0];
    console.log(
      "handleSendTool handleClickOk2",
      radioSelectedValue,
      props.endUserInfoToolsList
    );
    console.log("handleSendTool handleClickOk", data);
    if (data.enduser_no && data.enduser_no !== "" && 
        data.base_model_no && data.base_model_no !== "" && 
        data.serial_no && data.serial_no !== "") {       
      setRadioSelectedValue({});
      props.handleSend(data);
    } else {
      let message = "";
      if (!data.enduser_no || data.enduser_no === "" || data.enduser_no === undefined) {
        message = com("messages.MS0115", {
          param1: t("txt_ender_user_no"),
        });
      } else if (!data.base_model_no || data.base_model_no === "" || data.base_model_no === undefined) {
        message = com("messages.MS0115", {
          param1: t("txt_base_model_no"),
        });
      } else if (!data.serial_no || data.serial_no === "" || data.serial_no === undefined) {
        message = com("messages.MS0115", {
          param1: t("txt_serial_no"),
        });
      }
      props.setAlertMessage(message);
    } 
  };

  const CSearchModelWindow = (
    <div className={classes.CSearchModelContainer}>
      <AlertMesssageBox
        show={props.alertMessage && props.alertMessage !== ""}
        message={props.alertMessage}
        type={alertType}
      />
      <ClaimToolInformationSearchControl
        handleRefresh={props.handleRefresh}
        handleChangeKey={props.handleChangeKey}
      />
      <ClaimToolInformationSearchControlTable
        endUserInfoToolsList={endUserInfoToolsList}
        setRadioSelectedValue={setRadioSelectedValue}
        setAlertMessage={props.setAlertMessage}
      />
    </div>
  );

  const CSearchModelWindowButton = (
    <div className={classes.buttonControl}>
      <Button
        className={classes.buttonSetting}
        variant="contained"
        onClick={handleClose}
      >
        {t("prompt_button_Cancel")}
      </Button>
      <Button variant="contained" color="primary" onClick={handleClickOk}>
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="claim_detail_Toolsearch"
      contents={CSearchModelWindow}
      button={CSearchModelWindowButton}
      openFlg={props.CSearchOpen}
      handleCloseEvent={handleClose}
    />
  );
};
