// UI
import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "4px",
    "& .MuiAppBar-root": {
      width: "auto",
    },
    "& .MuiTabs-indicator": {
      background: "#3D899F",
    },
  },
  AppBar: {
    borderRadius: "10px 10px 0 0",
    overflow: "hidden",
    // background: "transparent",
  },
  title: {
    margin: "0",
    "& > span": {
      color: "#3D899F",
    },
  },
  buttonArea: {
    "& > button": {
      paddingTop: "5px",
      paddingBottom: "5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
  },
  componentHidden: {
    display: "none",
  },
  tabButton: {
    padding: "6px 10px",
    Width: "30px",
    background: "gray",
    // textTransform: "inherit",
    "&.Mui-selected": {
      background: "#ffffff",
      color: "#3D899F",
    },
    "&.Mui-disabled": {
      background: "rgba(169, 169, 169)!important",
      opacity: "1!important",
    },
  },
  errorTabButton: {
    padding: "6px 10px",
    Width: "30px",
    background: "#f44336",
    // textTransform: "inherit",
    "&.Mui-selected": {
      background: "#FFC7BA",
      // color: "#3D899F",
    },
  },
  paper: {
    padding: "20px 10px 10px 10px",
  },
  buttonControl: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  buttonSetting: {
    marginLeft: "50px",
  },
  confirmationContainer: {
    width: "400px",
    height: "150px",
    overflow: "auto",
    wordBreak: "break-word",
    whiteSpace: "pre-line",
  },
  textField: {
    width: "35%",
    height: "30px",
    "& .MuiInputBase-root": {
      lineHeight: "30px",
      height: "30px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px",
    },
  },
}));
