import { useState } from "react";
import api from "../api/Api";
import { GlobalConfigurationModel } from "../models/GlobalConfigurationModel";

const initialGlobalConfigurationModel = {
  endpoint: undefined,
};

export const useGlobalConfiguration = (): {
  getGlobalConfiguration: (token: string) => Promise<GlobalConfigurationModel>;
} => {
  const [userConfig, setUserConfig] = useState<GlobalConfigurationModel>(
    initialGlobalConfigurationModel
  );

  return {
    getGlobalConfiguration: async (
      token: string
    ): Promise<GlobalConfigurationModel> => {
      if (userConfig.endpoint === undefined) {
        // Extract from Server
        return api.getGlobalConfiguration(token).then((config: any) => {
          setUserConfig({ endpoint: config.endpoint });
          return config;
        });
      } else {
        console.log("in local config");
        return userConfig;
      }
    },
  };
};
