import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "0px 20px 0 20px",
      alignItems: "baseline",
      height: "110px",
    },
    area: {
      display: "flex",
      paddingBottom: "10px",
      alignItems: "baseline",
    },
    item: {
      paddingRight: "10px",
    },
    stepper: {
      width: "780px",
      marginLeft: "-48px",
      "& .MuiStepper-alternativeLabel": {
        backgroundColor: "#F9F9F9",
      },
    },
    button: {
      marginRight: "10px",
      color: "#FFFFFF !important",
    },
    buttonSubmit: {
      marginLeft: "10px",
    },
    title: {
      fontSize: "1.6rem",
      paddingRight: "10px",
    },
    outerLayer: {
      width: "35%",
      minHeight: "280px",
      margin: "0 auto",
      border: "1px solid #ccc",
      marginTop: "60px",
    },
    titleBox: {
      width: "100%",
      height: "20%",
      background: "#008CA2",
      position: "relative",
    },
    titleBody: {
      textAlign: "left",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      marginTop: "7px",
      width: "16%",
      height: "100%",
      color: "rgba(0, 0, 0, 0.54)",
    },
    text: {
      color: "#FFFFFF",
      padding: "10px",
      fontSize: "16px",
    },
    titleLable: {
      marginTop: "25px",
      textAlign: "center",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "15px",
      "& :nth-child(4)": {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "23px",
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    ButtonBox: {
      padding: "15px 25px",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "25px",
    },
    attetion: {
      fontSize: "20px",
      color: "red",
    },
  })
);
