/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// component
// import { AllocateTechnicianTableThead } from "./Jobs.SearchResult.Title.Allocate.Table.Thead";
// import { AllocateTechnicianTableTbody } from "./Jobs.SearchResult.Title.Allocate.Table.Tbody";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// UI
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AllocateModelContainer: {
      width: "400px",
      height: "520px",
      overflow: "auto",
      border: "1px solid rgba(198, 209, 221, 0.5)",
    },
    title: {
      background: "rgba(240, 242, 244, 0.5)",
      textAlign: "right",
      padding: "10px",
    },
    cell: {
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "5px 10px",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    root: {
      width: "100%",
    },
    container: {
      maxHeight: "517px",
    },
    buttonSetting: {
      marginLeft: "50px",
    },
    makitaImg: {
      width: "70%",
      float: "right"
    }
  })
);

export const ClaimDetailEndUserConditions = (props: any) => {
  const classes = useStyles();
  const { t, com } = useLocale();

  const handleClick = (value: any) => props.setSelectedValue(value);
  // Allocate Model window show / hidden
  const handleClose = () => {
    props.CondiionsClose(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? "desc" : "asc");
    props.setOrderBy(property);
  };

  const AllocateModelWindow = (
    <div className={classes.AllocateModelContainer}>
      <Paper className={classes.root}>
        <TableContainer className={classes.container} style={{ width: "auto" }}>

        </TableContainer>
      </Paper>
    </div>
  );

  const AllocateModelWindowButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleClose}>
        {t("models.model_window.prompt_button_Cancel")}
      </Button>
      <Button
        className={classes.buttonSetting}
        variant="contained"
        onClick={() => props.handleSend()}
        color="primary"
      >
        {t("models.model_window.prompt_button_Confirm")}
      </Button>
    </div>
  );

  return (
    <>
      <ModelWindow
        title="claim_detail_PrivacyNotice"
        contents={AllocateModelWindow}
        button={AllocateModelWindowButton}
        openFlg={props.CondiionsOpen}
        handleCloseEvent={handleClose}
      />
    </>
  );
};
