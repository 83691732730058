import React from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { GetPrivateAppRoutePath, PathKey } from "../../AppRoutes";
// root
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      margin: 0,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    buttonSetting: {
      marginLeft: "50px",
    },
    divWidth: {
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
    link: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "blue",
      paddingLeft: "5px",
      paddingTop: "5px",
      display: "inline-block",
    },
  })
);

export const ContentsMessageBox = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "models.model_window",
  });
  const { contentsListMessage } = props;
  const history = useHistory();

  const changeView = (path: string) => {
    history.push(path);
  };

  const contents = (
    <div className={classes.divWidth}>
      <p className={classes.message}>
        {contentsListMessage &&
          contentsListMessage.length > 0 &&
          contentsListMessage.map((item: any, index: number) => {
            const path =
              GetPrivateAppRoutePath(PathKey.SUMMARY) + `?claimno=${item}`;
            if (contentsListMessage.length - 1 === index) {
              return (
                <span
                  className={classes.link}
                  onClick={() => {
                    changeView(path);
                  }}
                >
                  {item}
                </span>
              );
            } else {
              return (
                <>
                  <span
                    className={classes.link}
                    onClick={() => {
                      changeView(path);
                    }}
                  >
                    {item}
                  </span>
                  ,
                </>
              );
            }
          })}
      </p>
    </div>
  );

  const windownButton = (
    <div className={classes.buttonControl}>
      <Button
        className={classes.buttonWidth}
        variant="contained"
        onClick={props.handleClose}
        color="primary"
      >
        {t("prompt_button_Close")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="ap_contents"
      contents={contents}
      button={windownButton}
      openFlg={props.open}
      handleCloseEvent={props.handleClose}
    />
  );
};
