import {
  Badge,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useEffect } from "react";
import { useLocale } from "../../../hooks/UseLocale";
import { GetNavigationDrawerItemGroups } from "./Navigation.Drawer.Menu.Items";
import { useHistory } from "react-router-dom";
import { UseDrawerStyles } from "./Navigation.Drawer.Style";

type Prop = {
  isDrawerOpen: boolean;
  onCloseEvent: () => void;
  onMouseOverDrawerEvent: () => void;
  onMouseLeaveDrawerEvent: () => void;
};

export const NavigationDrawerMenu: FC<Prop> = ({
  isDrawerOpen,
  onCloseEvent,
  onMouseOverDrawerEvent,
  onMouseLeaveDrawerEvent,
}) => {
  const { t } = useLocale({
    defaultPath: "modules.components.navigationDrawer",
  });
  const classes = UseDrawerStyles();
  const history = useHistory();
  const handleOnClick = useCallback(
    (path: string) => {
      onCloseEvent();
      history.push(path);
    },
    [history]
  );

  return (
    <Drawer
      variant="permanent"
      onMouseOver={onMouseOverDrawerEvent}
      onMouseLeave={onMouseLeaveDrawerEvent}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerMenuOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerMenuOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      {/* Margin for Toolbar */}
      <div className={classes.toolbar}></div>
      {GetNavigationDrawerItemGroups().map((m, i) => {
        return (
          <React.Fragment key={i}>
            {m.menuItems.map(
              (m2, i2) =>
                m2.showFlg && (
                  <List key={i}>
                    <ListItem
                      button
                      key={i2}
                      onClick={() => handleOnClick(m2.path)}
                    >
                      <ListItemIcon>
                        <m2.icon />
                      </ListItemIcon>
                      <ListItemText primary={t(m2.textKey)} />
                    </ListItem>
                  </List>
                )
            )}
            <Divider />
          </React.Fragment>
        );
      })}
    </Drawer>
  );
};
