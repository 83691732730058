import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeadCell {
  id: string;
  label: string;
}

export const headCells: HeadCell[] = [
  { id: "model_no", label: "txt_sales_model_no" },
  { id: "base_model_no", label: "txt_base_model_no" },
  { id: "serial_no", label: "txt_serial_no" },
  { id: "purchase_date", label: "txt_purchased_date" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& th:last-child": {
        borderRight: "1px solid rgba(198, 209, 221)",
      },
    },
    theadCell: {
      background: "rgba(240, 242, 244)",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export const ClaimToolInformationSearchControlTableThead = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell className={classes.theadCell}></TableCell>
        {headCells.map((headCell) => (
          <TableCell className={classes.theadCell} key={headCell.id}>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
