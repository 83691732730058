import { getLocale } from "../../i18n";

const languageToCurrency: any = {
  "en-US": { currency: "USD" },
  "de-DE": { currency: "EUR" },
  ja: { currency: "JPY" },
  "zh-CN": { currency: "CNY" },
  "sv-SE": { currency: "SEK" },
  "nb-NO": { currency: "NOK" },
  "da-DK": { currency: "DKK" },
  "pl-PL": { currency: "PLN" },
};

// TaskNo.11185
const localCurrencyToUnit: any = {
  USD: "$",
  GBP: "£",
  EUR: "€",
  JPY: "¥",
  CNY: "¥",
  SEK: "kr",
  NOK: "kr",
  DKK: "kr",
  PLN: "zł",
};

const currencyLocal: any = {
  USD: "en-US",
  EUR: "de-DE",
  JPY: "ja-JP",
  CNY: "zh-CN",
  SEK: "sv-SE",
  NOK: "nb-NO",
  DKK: "da-DK",
  PLN: "pl-PL",
};

const getCurrencyMessage = (currency: any) => {
  if (!currency) {
    currency = languageToCurrency[getLocale()]
      ? languageToCurrency[getLocale()].currency
      : "USD";
  }
  return currency;
};

export const currency = (input: number, currency = ""): string => {
  const _currency = getCurrencyMessage(currency);

  return new Intl.NumberFormat(currencyLocal[_currency], {
    style: "currency",
    currency: _currency,
  }).format(input);

  // return new Intl.NumberFormat(getLocale(), {
  //   style: "currency",
  //   currency: _currency,
  // }).format(input);
};

// TaskNo.11185
export const getOnlyCurrency = (currency = ""): string => {
  const _currency = getCurrencyMessage(currency);
  currency = localCurrencyToUnit[_currency];
  return currency;
};

// TaskNo.11185
export const getDelimiter = (input: number, currency = ""): string => {
  const _currency = getCurrencyMessage(currency);

  return new Intl.NumberFormat(currencyLocal[_currency], {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(input);
};

export const priceOutputByCountryCode = (
  input: number,
  locale: any = "",
  len: number = 2
) => {
  if (!locale) {
    locale = getLocale();
  }

  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: len,
  }).format(input);
};

export const priceOutputByCountryCodeAndCurrency = (
  input: number,
  locale: any = "",
  currency: any = "",
  len: number = 2
): string => {
  if (!locale) {
    locale = getLocale();
  }

  const _currency = getCurrencyMessage(currency);

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: _currency,
    minimumFractionDigits: len,
  }).format(input);
};

const getSign = () => {
  const number = 1.1;
  return new Intl.NumberFormat(getLocale()).format(number).replace(/1/g, "");
};

export const priceInputBySign = (input: string, len: number = 2) => {
  let sign = getSign();
  let _price = input.toString();
  if (sign !== ".") {
    _price = _price.replace(".", "*").replace(sign, ".");
  }
  let _priceList = _price.split(".");
  if (_priceList.length > 1 && _priceList[1].length > 2) {
    _price = Number(_price).toFixed(2);
  }
  return Number(_price);
};

export const priceOutputBySign = (input: string, len: number = 2) => {
  let sign = getSign();
  let _price = input.toString();
  if (sign !== ".") {
    _price = _price.replace(".", sign);
  }
  return _price;
};

export const decimalPointIsLenth = (input: any, len: number = 2) => {
  let sign = getSign();
  const list = input.toString().split(sign);
  if (list.length > 1) {
    return list[1].length <= len;
  }
  return true;
};
