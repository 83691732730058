import { http } from "./Http";

// AM2501
export const GetAlternateItemAPI  = (query_parameters: any, config: any) => {
  const { baseURLShop, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/alternate_items/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
