import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/messageToCustomer.userStyles";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
export const SummaryPhoto = (props: any) => {
  const classes = useStyles();
  const { data, fileDownload } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  return (
    <form noValidate autoComplete="off">
      <div className={classes.outerLayer}>
        <div className={classes.titleBox}>
          <div className={classes.titleBody}>
            <div>
              <InsertPhotoIcon className={classes.icon} />
            </div>
            <div className={classes.text}>{t("summary_title_photo")}</div>
          </div>
        </div>
        <div className={classes.media_list}>
          {data &&
            data.length > 0 &&
            data.map((item: any, index: number) => (
              <div key={index} className={classes.media_item}>
                <InsertPhotoIcon className={classes.iconSvgPhoto} />
                {item.updated_date ? (
                  <p
                    className={classes.link}
                    onClick={() => fileDownload(item)}
                  >
                    {item.media_name}
                  </p>
                ) : (
                  <p>{item.media_name}</p>
                )}
              </div>
            ))}
        </div>
      </div>
    </form>
  );
};
