import React, { useState } from "react";
import clsx from "clsx";

// UI
import TextField from "@material-ui/core/TextField";
import { SUPPORT_LANGUAGES } from "../../../modules/constants/supportLanguages";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

// hook
import { useStyles } from "../hooks/control.useStyles";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const defaultValue = {
  job_no: "",
  customer_no: "",
  customer_po_no: "",
  model_no: "",
  serial_no: "",
  tracking_no: "",
  postal_cd: "",
  service_center_id: [""],
  customer_name: "",
  address: "",
  address_1: "",
  mail_address: "",
  status: ["300", "400", "510"],
  technician_cd: "",
  order_no: "",
  received_date_from: null,
  received_date_to: null,
  job_no_error: "",
  customer_no_error: "",
  model_no_error: "",
  customer_name_error: "",
  mail_address_error: "",
  technician_cd_error: "",
  received_date_from_error: "",
  received_date_to_error: "",
};

export const Settings = (props: any) => {
  const classes = useStyles();
  const { formList } = props;
  const { getCommonInfo } = useCommonInfo();

  const commonInfo = getCommonInfo() as any;
  const { locale } = useLocale();

  const { t } = useLocale({ defaultPath: "modules.components.configuration" });

  const [searchValueList, setSearchValueList] = useState(defaultValue);

  const getOptionLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.row}>
        <div className={classes.cell}>
          <dl>
            <dt>{t("language")}</dt>
            <dd>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...locale}
                >
                  {SUPPORT_LANGUAGES.map((m) => (
                    <MenuItem key={m.code} value={m.code}>
                      {m.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </dd>
          </dl>
        </div>
      </div>
      {/* <div className={classes.row}>
        <div className={classes.cellNotification}>
          <dl>
            <dt>{t("notification")}</dt>
            <dd>
              <div className={classes.groupArea}>
                <table className={classes.tableUserInfo02}>
                  <tr>
                    <th></th>
                    <td>{t("e_mail")}</td>
                  </tr>
                  <tr>
                    <th>{t("message01")}</th>
                    <td>
                      <Checkbox
                        className={classes.checkboxLayout}
                        color="primary"
                        // checked={use_dz}
                        // onChange={(e) =>
                        //   changeData(
                        //     "use_dz",
                        //     e.target.checked
                        //   )
                        // }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{t("message02")}</th>
                    <td>
                      <Checkbox
                        //disabled={!use_dz}
                        className={classes.checkboxLayout}
                        color="primary"
                        //checked={userinfodz.sms_receive_notification}
                        // onChange={(e) => changeData(
                        //   "sms_receive_notification",
                        //   e.target.checked,
                        //   "userinfodz"
                        // )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{t("message03")}</th>
                    <td>
                      <Checkbox
                        //disabled={!use_dz}
                        className={classes.checkboxLayout}
                        color="primary"
                        //checked={userinfodz.mail_receive_notification}
                        // onChange={(e) => changeData(
                        //   "mail_receive_notification",
                        //   e.target.checked,
                        //   "userinfodz"
                        // )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{t("message04")}</th>
                    <td>
                      <Checkbox
                        //disabled={!use_dz}
                        className={classes.checkboxLayout}
                        color="primary"
                        //checked={userinfodz.mail_receive_notification}
                        // onChange={(e) => changeData(
                        //   "mail_receive_notification",
                        //   e.target.checked,
                        //   "userinfodz"
                        // )}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </dd>
          </dl>
        </div>
      </div> */}
    </form>
  );
};
