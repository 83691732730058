import { useState } from 'react';

export const useAnchorEl = (): { anchorEl: HTMLElement | null, setAnchorEl: (event: React.MouseEvent<HTMLElement>) => void, resetAnchorEl: () => void } => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    return {
        anchorEl: anchorEl,
        setAnchorEl: (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        resetAnchorEl: () => { setAnchorEl(null) }
    }
}