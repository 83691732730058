import React, { FC } from "react";
// UI
import {
  AppBar,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    leftMessage: {
      display: "flex",
      alignItems: "center",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contents: {
      padding: "24px",
      flexGrow: 1,
      marginTop: "64px",
      marginLeft: "80px",
    },
    buttonArea: {
      textAlign: "center",
    },
    redirectTo: {
        textDecoration: "none",
      },
  })
);
export const Page: FC = () => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.loginForm" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.leftMessage}>
              <Typography variant="h6">Takumi</Typography>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.contents}>
        <AlertMesssageBox
          show={true}
          message={com("messages.MS0119")}
          type={"error"}
        />
      </div>
      <div className={classes.buttonArea}>
        <a 
          className={classes.redirectTo}
          href={process.env.REACT_APP_AUTH0_REDIRECT_WCO_URI}>
            <Button 
              variant="contained" 
              color="primary"
              >
              {t("btn_return_to_login")}
            </Button> 
          </a>  
      </div>
    </>
  );
};
