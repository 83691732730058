import moment from "moment";
import { getLocale } from "./i18n";
import { semicolon } from "./constants/common.constants";
import { APIErrorMessage } from "./api/ApiErrorMessage";

export const getApiResultStatus = (code: any) => {
  return code < 400;
};

export const getAPIErrorMessage = (response: any, apiName: any) => {
  return response
    ? response.key + ":" + response.message_id
    : `${apiName} time out.`;
};

export const downloadToImage = (blob: any) => {
  let reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function () {
    const img: any = new Image();
    img.src = reader.result;
    const newWin: any = window.open("", "_blank");
    newWin.document.write(img.outerHTML);
    newWin.document.title = "Photo";
    newWin.document.close();
  };
};

export const IsBlank = (value: any) => {
  return value === undefined || value === null || value === "";
};

export const getCodeName = (code: any, list: any) => {
  let codeName = "";
  if (code && code !== "") {
    for (let index in list) {
      if (list[index].code === code) {
        codeName = list[index].name;
        break;
      }
    }
  }
  return codeName;
};

export const getCodeNameByNumber = (code: any, list: any) => {
  let codeName = "";
  if (code !== undefined && code !== null && code !== "") {
    for (let index in list) {
      if (list[index].code === code) {
        codeName = list[index].name;
        break;
      }
    }
  }
  return codeName;
};

export const getCodeNameByCodeNumber = (code: any, list: any) => {
  let codeName = "";
  if (code !== undefined && code !== null && code !== "") {
    for (let index in list) {
      if (Number(list[index].code) === Number(code)) {
        codeName = list[index].name;
        break;
      }
    }
  }
  return codeName;
};

export const getClaimDetailParams = (
  data: any,
  status: string,
  transportMessage: any = {},
  chargesValue: any = {}
) => {
  let _data = JSON.parse(JSON.stringify(data));
  _data.status = status;
  if (_data.claim_line.length > 0) {
    let newList: any = [];
    _data.claim_line.forEach((item: any) => {
      if (Number(item.suspend_exist) !== 1) {
        item.order_qty = getNumberDataType(item.order_qty);
        item.manually_update_flg =
          item.manually_update_flg === undefined ||
          item.manually_update_flg === null
            ? false
            : item.manually_update_flg;
        newList.push(item);
      }

      if (!item["webmsi_spec"]) {
        item["webmsi_spec"] = "Full";
      }
    });
    _data.claim_line = newList;
  }
  // transport
  _data.weight = transportMessage.weight;
  _data.shipping_method = transportMessage.shipping_method;
  _data.transport_way_pick_up = transportMessage.transport_way_pick_up;
  _data.transport_way_return = transportMessage.transport_way_return;
  _data.transport_cost_flg = transportMessage.transport_cost_flg;

  if (_data.device_runtime === "") {
    _data.device_runtime = 0;
  }

  // total_amount
  if (Object.keys(chargesValue).length > 0) {
    _data.total_amount = chargesValue.total_amount;
  } else {
    _data.total_amount = 0;
  }

  return _data;
};

export const getFormatByKey = (key: any, value: any, subKey: any) => {
  const numberList = [
    "claim_no",
    "condition_of_tool",
    "re_entered_count",
    "customer_no",
    "ref_no",
    "device_runtime",
    "guarantee_type",
    "delivery_distance",
    "order_qty",
    "net_price",
    "amount",
    "enduser_no",
    "customer_type",
    "freight_distance",
    "note_type",
    "media_type",
    "labor_value",
  ];
  // string => number
  if (
    (numberList.includes(key) || numberList.includes(subKey)) &&
    value !== ""
  ) {
    value = getNumberDataType(value);
  }
  // UTC Date => YYYY-MM-DD
  if (
    key === "purchased_date" ||
    key === "received_date" ||
    key === "repair_date"
  ) {
    value = moment(value).format("YYYY-MM-DD");
  }
  return value;
};

const DIFF_KEY = {
  tool_info: [
    "model_no",
    "serial_no",
    "condition_of_tool",
    "device_runtime",
    "purchased_date",
    "received_date",
    "repair_date",
    "manufactured_year",
    "manufactured_month",
    "error_type",
    "guarantee_type",
    "customer_po_no",
    "attention_check_flg",
    "privacy_check_flg",
  ],

  claim_note: ["claim_no", "sub_no", "note_type", "note"],

  claim_address: [
    "customer_type",
    "company_name",
    "first_name",
    "last_name",
    "postal_code",
    "address_line_1",
    "address_line_2",
    "city",
    "state",
    "country_code",
    "mail_address",
    "tel_no",
    "fax_no",
    "freight_distance",
    "enduser_no",
  ],
};

export const getNote = (value: any, type: number) => {
  const list =
    value && value.length > 0
      ? value.filter((item: any) => item.note_type === type)
      : [];
  const note = list.length > 0 ? list[0].note : "";
  return note;
};

// 仕様変更：ASCメッセージ追加→memoMessage
export const handleDiffChangedData = (
  formList: any,
  dataList: any,
  status: any,
  memoMessage: any,
  transportMessage: any = {},
  chargesValue: any = {},
  type = ""
) => {
  console.log(
    "handleDiffChangedData",
    status,
    formList,
    dataList,
    transportMessage,
    chargesValue
  );
  const postData: any = {};

  //claim_address
  const post_claim_address: any = {};
  DIFF_KEY.claim_address.forEach((item) => {
    console.log(
      "handleDiffChangedData post_claim_address",
      item,
      formList.claim_address[item],
      dataList.claim_address[item]
    );
    if (formList.claim_address[item] !== dataList.claim_address[item]) {
      post_claim_address[item] = formList.claim_address[item];
    }
  });

  if (Object.keys(post_claim_address).length > 0) {
    post_claim_address.update_flg = "1";
    post_claim_address.updated_date = dataList.claim_address.updated_date;
    postData.claim_address = post_claim_address;
  }

  //tool_info
  let tool_info_change_count = 0;
  DIFF_KEY.tool_info.forEach((item) => {
    console.log(
      "handleDiffChangedData tool_info",
      item,
      formList[item],
      dataList[item],
      formList[item] === dataList[item]
    );
    if (formList[item] !== dataList[item]) {
      if (item === "device_runtime") {
        const _oldData =
          dataList[item] === "" ||
          dataList[item] === undefined ||
          dataList[item] === null
            ? 0
            : dataList[item];
        const _newData =
          formList[item] === "" ||
          formList[item] === undefined ||
          formList[item] === null
            ? 0
            : formList[item];
        if (_oldData !== _newData) {
          postData[item] = formList[item] === "" ? 0 : formList[item];
        }
      } else {
        postData[item] = formList[item];
      }
      tool_info_change_count++;
    }
  });
  // status
  if (formList.status !== status) {
    postData.status = status;
    tool_info_change_count++;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count1",
    tool_info_change_count
  );

  const claim_note: any = {};
  const claim_note_array: any = [];
  const noteTypeList = [10,15];
//Check noteType 10 and 15
//10: Fault note
//15: Free note
  noteTypeList.forEach( type => {
    const old_claim_note = getNote(dataList.claim_note,type);
    const new_claim_note = getNote(formList.claim_note,type);

    const old_claim_note_record = dataList.claim_note.filter((record: any) => {
      return record.note_type === type;
    });

    const new_claim_note_record = formList.claim_note.filter((record: any) => {
      return record.note_type === type;
    });
    
  

    console.log(
      "handleDiffChangedData note",
      old_claim_note_record,
      new_claim_note_record,
      old_claim_note,
      new_claim_note,
      formList,
      dataList
    );

    if (old_claim_note !== new_claim_note) {
      if (old_claim_note_record.length !== 0) {
        claim_note.update_flg = "1";
        claim_note.sub_no = new_claim_note_record[0].sub_no;
        claim_note.note = new_claim_note;
        claim_note.updated_date = old_claim_note_record[0].updated_date;
      } else {
        claim_note.update_flg = "0";
        claim_note.note_type = type;
        claim_note.note = new_claim_note;
      }
      claim_note_array.push(claim_note);
    }
  });

  // ASC memo
  /** 仕様変更：ASCメッセージ追加→memoMessage
   *  memoMessage !== ブランク and submmitボタンをクリックすると、メッセージが送信する
   */
  const _note_list: any =
    dataList.claim_note.length > 0
      ? dataList.claim_note.filter((item: any) => item.note_type === 99)
      : [];
  if (memoMessage !== "" && type === "submmit") {
    claim_note_array.push({
      note_type: 40,
      note: memoMessage,
      update_flg: "0",
    });

    if (_note_list.length > 0) {
      let _clearMessage = {
        note_type: 99,
        note: "",
        update_flg: "1",
        claim_no: _note_list[0].claim_no,
        sub_no: _note_list[0].sub_no,
        updated_date: _note_list[0].updated_date,
      };
      claim_note_array.push(_clearMessage);
    }
  }
  // memoMessage保存の仕様追加
  if (type === "save") {
    const _memoMessage =
      _note_list.length > 0
        ? {
            note_type: 99,
            note: memoMessage,
            update_flg: "1",
            claim_no: _note_list[0].claim_no,
            sub_no: _note_list[0].sub_no,
            updated_date: _note_list[0].updated_date,
          }
        : {
            note_type: 99,
            note: memoMessage,
            update_flg: "0",
          };
    if (_memoMessage) {
      claim_note_array.push(_memoMessage);
    }
  }

  if (claim_note_array.length > 0) {
    postData.claim_note = claim_note_array;
  }

  // parts
  const claim_line: any = [];
  const claim_line_update_array: any = formList.claim_line.filter(
    (record: any) => {
      return dataList.claim_line.some((item: any) => {
        let updateNum = 0;
        let claim_line_record: any = {};
        if (item.line_no === record.line_no) {
          // item_no
          if (record.item_no !== item.item_no) {
            claim_line_record.item_no = record.item_no;
            updateNum++;
          }
          if (
            getNumberDataType(record.net_price) !==
            getNumberDataType(item.net_price)
          ) {
            claim_line_record.net_price = getNumberDataType(record.net_price);
            updateNum++;
          }
          if (
            getNumberDataType(record.order_qty) !==
            getNumberDataType(item.order_qty)
          ) {
            claim_line_record.order_qty = getNumberDataType(record.order_qty);
            updateNum++;
          }
          if (
            getNumberDataType(record.amount) !== getNumberDataType(item.amount)
          ) {
            claim_line_record.amount = getNumberDataType(record.amount);
            updateNum++;
          }
          // region spec
          if (record.webmsi_region !== item.webmsi_region) {
            claim_line_record.webmsi_region = record.webmsi_region;

            updateNum++;
          }
          if (record.webmsi_spec !== item.webmsi_spec) {
            claim_line_record.webmsi_spec = record.webmsi_spec;

            updateNum++;
          }
          if (updateNum > 0) {           
            claim_line_record.line_no = item.line_no;
            claim_line_record.updated_date = item.updated_date;
            claim_line_record.update_flg = "1";
            claim_line_record.amount = getNumberDataType(Number(record.net_price) * Number(record.order_qty));
            claim_line.push(claim_line_record);
          }
          return true;
        }
      });
    }
  );

  console.log("claim_line patch", claim_line, formList.claim_line);
  // create
  formList.claim_line.forEach((record: any) => {
    if (Number(record.suspend_exist) !== 1) {
      if (
        !claim_line_update_array.some((item: any) => {
          return item.line_no === record.line_no;
        })
      ) {
        let claim_line_record: any = {};
        claim_line_record.item_no = record.item_no;
        claim_line_record.net_price = getNumberDataType(record.net_price);
        claim_line_record.order_qty = getNumberDataType(record.order_qty);
        claim_line_record.amount = getNumberDataType(record.amount);
        claim_line_record.line_no = record.line_no;
        claim_line_record.manually_update_flg = Boolean(
          record.manually_update_flg
        );

        // region spec
        claim_line_record.webmsi_region = record.webmsi_region;
        claim_line_record.webmsi_spec = record.webmsi_spec;

        claim_line_record.update_flg = "0";
        claim_line.push(claim_line_record);
      }
    }
  });

  // delete
  dataList.claim_line.forEach((record: any) => {
    if (
      !claim_line_update_array.some((item: any) => {
        return item.line_no === record.line_no;
      })
    ) {
      let claim_line_record: any = {};
      claim_line_record.line_no = record.line_no;
      claim_line_record.update_flg = "2";
      claim_line_record.updated_date = record.updated_date;
      claim_line.push(claim_line_record);
    }
  });

  if (claim_line.length > 0) {
    postData.claim_line = claim_line;
  }
  //upload
  const claim_media: any = {};
  const claim_media_invoice: any = [];
  const claim_media_picture: any = [];

  const claim_media_invoice_same_array: any =
    formList.claim_media.claim_media_invoice.filter((record: any) => {
      return dataList.claim_media.claim_media_invoice.some((item: any) => {
        return record.sub_no === item.sub_no;
      });
    });

  formList.claim_media.claim_media_invoice.forEach((record: any) => {
    if (
      !claim_media_invoice_same_array.some((item: any) => {
        return item.sub_no === record.sub_no;
      })
    ) {
      let claim_media_invoice_record: any = {};
      claim_media_invoice_record.media_type = record.media_type;
      claim_media_invoice_record.media_name = record.media_name;
      claim_media_invoice_record.media_url = record.media_url;
      claim_media_invoice_record.update_flg = "0";
      claim_media_invoice.push(claim_media_invoice_record);
    }

    // delete
    if (record.update_flg === 2) {
      let claim_media_picture_record: any = {};
      claim_media_picture_record.sub_no = record.sub_no;
      claim_media_picture_record.update_flg = "2";
      claim_media_picture_record.updated_date = record.updated_date;
      claim_media_picture.push(claim_media_picture_record);
    }
  });

  // delete
  // dataList.claim_media.claim_media_invoice.forEach((record: any) => {
  //   if (
  //     !claim_media_invoice_same_array.some((item: any) => {
  //       return item.sub_no === record.sub_no;
  //     })
  //   ) {
  //     let claim_media_invoice_record: any = {};
  //     claim_media_invoice_same_array.line_no = record.sub_no;
  //     claim_media_invoice_record.update_flg = "2";
  //     claim_media_invoice.push(claim_media_invoice_record);
  //   }
  // });

  const claim_media_picture_same_array: any =
    formList.claim_media.claim_media_picture.filter((record: any) => {
      return dataList.claim_media.claim_media_picture.some((item: any) => {
        return record.sub_no === item.sub_no;
      });
    });

  formList.claim_media.claim_media_picture.forEach((record: any) => {
    if (
      !claim_media_picture_same_array.some((item: any) => {
        return item.sub_no === record.sub_no;
      })
    ) {
      let claim_media_picture_record: any = {};
      claim_media_picture_record.media_type = record.media_type;
      claim_media_picture_record.media_name = record.media_name;
      claim_media_picture_record.media_url = record.media_url;
      claim_media_picture_record.update_flg = "0";
      claim_media_picture.push(claim_media_picture_record);
    }

    // delete
    if (record.update_flg === 2) {
      let claim_media_picture_record: any = {};
      claim_media_picture_record.sub_no = record.sub_no;
      claim_media_picture_record.update_flg = "2";
      claim_media_picture_record.updated_date = record.updated_date;
      claim_media_picture.push(claim_media_picture_record);
    }
  });

  // delete
  // dataList.claim_media.claim_media_picture.forEach((record: any) => {
  //   if (
  //     !claim_media_picture_same_array.some((item: any) => {
  //       return item.sub_no === record.sub_no;
  //     })
  //   ) {
  //     let claim_media_picture_record: any = {};
  //     claim_media_picture_record.line_no = record.sub_no;
  //     claim_media_picture_record.update_flg = "2";
  //     claim_media_picture.push(claim_media_picture_record);
  //   }
  // });

  if (claim_media_invoice.length > 0) {
    claim_media.claim_media_invoice = claim_media_invoice;
  }
  if (claim_media_picture.length > 0) {
    claim_media.claim_media_picture = claim_media_picture;
  }
  if (claim_media_invoice.length > 0 || claim_media_picture.length > 0) {
    postData.claim_media = claim_media;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count7",
    tool_info_change_count
  );

  // transport
  if (transportMessage.transport_cost_flg !== dataList.transport_cost_flg) {
    postData.transport_cost_flg = transportMessage.transport_cost_flg;
    tool_info_change_count++;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count transport_cost_flg",
    tool_info_change_count,
    transportMessage.transport_cost_flg,
    dataList.transport_cost_flg
  );
  if (transportMessage.weight !== dataList.weight) {
    postData.weight = transportMessage.weight;
    tool_info_change_count++;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count2",
    tool_info_change_count
  );
  if (transportMessage.shipping_method !== dataList.shipping_method) {
    postData.shipping_method = transportMessage.shipping_method;
    tool_info_change_count++;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count3",
    tool_info_change_count
  );
  if (
    transportMessage.transport_way_pick_up !== dataList.transport_way_pick_up
  ) {
    postData.transport_way_pick_up = transportMessage.transport_way_pick_up;
    tool_info_change_count++;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count4",
    tool_info_change_count
  );
  if (transportMessage.transport_way_return !== dataList.transport_way_return) {
    postData.transport_way_return = transportMessage.transport_way_return;
    tool_info_change_count++;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count5",
    tool_info_change_count
  );
  // total_amount
  let _total_amount = 0;
  if (Object.keys(chargesValue).length > 0) {
    _total_amount = chargesValue.total_amount;
  }

  if (_total_amount !== dataList.total_amount) {
    postData.total_amount = getNumberDataType(_total_amount);
    tool_info_change_count++;
  }
  console.log(
    "handleDiffChangedData tool_info_change_count2",
    tool_info_change_count
  );
  if (tool_info_change_count > 0) {
    postData.update_flg = "1";
    postData.updated_date = dataList.updated_date;
  }
  if (Object.keys(postData).length > 0) {
    // postData.updated_date = dataList.updated_date;
  }
  if (type === "save") {
    postData.save_flg = true;
  } else {
    postData.save_flg = false;
  }

  postData.update_flg = "1";
  postData.updated_date = dataList.updated_date;
  console.log("handleDiffChangedData total_amount", postData);
  return postData;
};

// ASC メッセージ追加 → memoMessage
export const diffContents = (
  _claim_no: string,
  formList: any,
  dataList: any,
  memoMessage: any,
  transportMessage: any = {},
  chargesValue: any = {}
) => {
  // common
  if (dataList.customer_po_no !== formList.customer_po_no) {
    return false;
  }
  console.log("diffContents", formList, dataList);
  // endUser
  if (!diffEnduserTab(formList, dataList)) {
    return false;
  }
  console.log("diffContents1");

  // tool information
  if (!toolInformationTab(formList, dataList)) {
    return false;
  }
  console.log("diffContents2");

  // parts
  if (!partsTab(formList, dataList)) {
    return false;
  }
  console.log("diffContents3");

  // upload
  if (!uploadTab(formList, dataList)) {
    return false;
  }
  console.log("diffContents4");

  if (!transportMessageInfo(transportMessage, dataList)) {
    return false;
  }
  console.log("diffContents5");

  if (!chargesMessageInfo(chargesValue, dataList)) {
    return false;
  }
  console.log("diffContents6");

  // ASC Memo Message
  // memoMessage保存仕様追加
  if (!memoMessageFunc(formList, dataList, 99, memoMessage)) {
    return false;
  }

  return true;
};

const diffEnduserTab = (formList: any, dataList: any) => {
  console.log("diffContents1 customer_type");

  if (
    !diffMessage(
      dataList.claim_address.customer_type,
      formList.claim_address.customer_type
    )
  ) {
    return false;
  }
  console.log("diffContents1 company_name");

  if (
    !diffMessage(
      dataList.claim_address.company_name,
      formList.claim_address.company_name
    )
  ) {
    return false;
  }
  console.log("diffContents1 last_name");

  if (
    !diffMessage(
      dataList.claim_address.last_name,
      formList.claim_address.last_name
    )
  ) {
    return false;
  }
  console.log("diffContents1 first_name");

  if (
    !diffMessage(
      dataList.claim_address.first_name,
      formList.claim_address.first_name
    )
  ) {
    return false;
  }
  console.log(
    "diffContents1 postal_code",
    dataList.claim_address.postal_code,
    formList.claim_address.postal_code
  );

  if (
    !diffMessage(
      dataList.claim_address.postal_code,
      formList.claim_address.postal_code
    )
  ) {
    return false;
  }
  console.log("diffContents1 address_line_1");

  if (
    !diffMessage(
      dataList.claim_address.address_line_1,
      formList.claim_address.address_line_1
    )
  ) {
    return false;
  }
  console.log("diffContents1 address_line_2");

  if (
    !diffMessage(
      dataList.claim_address.address_line_2,
      formList.claim_address.address_line_2
    )
  ) {
    return false;
  }
  console.log("diffContents1 city");

  if (!diffMessage(dataList.claim_address.city, formList.claim_address.city)) {
    return false;
  }
  console.log("diffContents1 state");

  if (
    !diffMessage(dataList.claim_address.state, formList.claim_address.state)
  ) {
    return false;
  }
  console.log("diffContents1 country_code");

  if (
    !diffMessage(
      dataList.claim_address.country_code,
      formList.claim_address.country_code
    )
  ) {
    return false;
  }
  console.log(
    "diffContents1 mail_address",
    dataList.claim_address.mail_address,
    formList.claim_address.mail_address,
    dataList.claim_address.mail_address === formList.claim_address.mail_address,
    !diffMessage(
      dataList.claim_address.mail_address,
      formList.claim_address.mail_address
    )
  );

  if (
    !diffMessage(
      dataList.claim_address.mail_address,
      formList.claim_address.mail_address
    )
  ) {
    return false;
  }
  console.log(
    "diffContents1 tel_no",
    dataList.claim_address.tel_no,
    formList.claim_address.tel_no,
    dataList.claim_address.tel_no === formList.claim_address.tel_no
  );
  if (
    !diffMessage(dataList.claim_address.tel_no, formList.claim_address.tel_no)
  ) {
    return false;
  }
  console.log(
    "diffContents1 fax_no",
    dataList.claim_address.fax_no,
    formList.claim_address.fax_no,
    dataList.claim_address.fax_no === formList.claim_address.fax_no
  );
  if (
    !diffMessage(dataList.claim_address.fax_no, formList.claim_address.fax_no)
  ) {
    return false;
  }
  console.log(
    "diffContents1 privacy_check_flg",
    dataList.privacy_check_flg,
    formList.privacy_check_flg,
    dataList.privacy_check_flg === formList.privacy_check_flg
  );
  if (!diffMessage(dataList.privacy_check_flg, formList.privacy_check_flg)) {
    return false;
  }

  // enduser_no
  if (
    !diffMessage(
      dataList.claim_address.enduser_no,
      formList.claim_address.enduser_no
    )
  ) {
    return false;
  }

  return true;
};

const toolInformationTab = (formList: any, dataList: any) => {
  console.log("toolInformationTab", formList, dataList);
  console.log("diffContents2 model_no");
  // model no
  if (!diffMessage(dataList.model_no, formList.model_no)) {
    return false;
  }
  console.log("diffContents2 serial_no");

  // serial no
  if (!diffMessage(dataList.serial_no, formList.serial_no)) {
    return false;
  }
  console.log("diffContents2 condition_of_tool");

  if (!diffMessage(dataList.condition_of_tool, formList.condition_of_tool)) {
    return false;
  }
  console.log("diffContents2 device_runtime");

  if (!diffMessage(dataList.device_runtime, formList.device_runtime)) {
    return false;
  }
  console.log(
    "diffContents2 purchased_date",
    dataList.purchased_date,
    formList.purchased_date
  );

  if (!diffMessage(dataList.purchased_date, formList.purchased_date)) {
    return false;
  }
  console.log(
    "diffContents2 received_date",
    dataList.received_date,
    formList.received_date
  );

  if (!diffMessage(dataList.received_date, formList.received_date)) {
    return false;
  }
  console.log("diffContents2 repair_date");

  if (!diffMessage(dataList.repair_date, formList.repair_date)) {
    return false;
  }
  console.log("diffContents2 manufactured_year");

  if (!diffMessage(dataList.manufactured_year, formList.manufactured_year)) {
    return false;
  }
  console.log("diffContents2 manufactured_month");

  if (!diffMessage(dataList.manufactured_month, formList.manufactured_month)) {
    return false;
  }
  console.log("diffContents2 error_type");

  if (!diffMessage(dataList.error_type, formList.error_type)) {
    return false;
  }
  console.log(
    "diffContents2 guarantee_type",
    dataList.guarantee_type,
    formList.guarantee_type,
    dataList.guarantee_type === formList.guarantee_type
  );

  if (!diffMessage(dataList.guarantee_type, formList.guarantee_type)) {
    return false;
  }

  if (!memoMessageFunc(formList, dataList, 10)) {
    return false;
  }
  if (!memoMessageFunc(formList, dataList, 15)) {
    return false;
  }
  return true;
};

const memoMessageFunc = (
  formList: any,
  dataList: any,
  type: any = "",
  memoMessage: any = undefined
) => {
  // note
  const old_note_list =
    dataList.claim_note && dataList.claim_note.length > 0
      ? dataList.claim_note.filter((item: any) => item.note_type === type)
      : [];
  const old_note = old_note_list.length > 0 ? old_note_list[0].note : "";
  let new_note;
  if (memoMessage === undefined) {
    const new_note_list =
      formList.claim_note && formList.claim_note.length > 0
        ? formList.claim_note.filter((item: any) => item.note_type === type)
        : [];

    new_note = new_note_list.length > 0 ? new_note_list[0].note : "";
  } else {
    new_note = memoMessage;
  }
  return diffMessage(old_note, new_note);
};

const partsTab = (formList: any, dataList: any) => {
  let _flg = true;
  console.log(
    "diffContents3 create & update",
    formList.claim_line,
    dataList.claim_line
  );
  if (formList.claim_line.length > 0) {
    for (let i = 0; i < formList.claim_line.length; i++) {
      const item = formList.claim_line[i];
      // edit data
      if (item.line_no !== "" && item.line_no !== undefined) {
        if (dataList.claim_line.length > 0) {
          // response data
          const _response = dataList.claim_line.filter(
            (subItem: any) => subItem.line_no === item.line_no
          );
          console.log("diffContents3 update3", _response, item);
          if (_response.length > 0) {
            const subItem = _response[0];
            if (!diffMessage(item.order_qty, subItem.order_qty, "number")) {
              _flg = false;
            }
            if (!diffMessage(item.net_price, subItem.net_price, "number")) {
              _flg = false;
            }
            if (!diffMessage(item.item_no, subItem.item_no)) {
              _flg = false;
            }
          } else {
            console.log("diffContents3 update2", _flg);
            // error
            _flg = false;
          }
        } else {
          console.log("diffContents3 update1", _flg);
          // error
          _flg = false;
        }
      } else {
        console.log("diffContents3 create", _flg);
        // create data
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  if (!_flg) {
    return _flg;
  }
  console.log("diffContents3 delete");
  // delete

  if (dataList.claim_line.length > 0) {
    for (let i = 0; i < dataList.claim_line.length; i++) {
      const item = dataList.claim_line[i];
      if (formList.claim_line.length > 0) {
        // input data
        const _input = formList.claim_line.filter(
          (subItem: any) => subItem.line_no === item.line_no
        );
        if (_input.length === 0) {
          _flg = false;
        }
      } else {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }

  if (!_flg) {
    return _flg;
  }

  if (
    !diffMessage(
      dataList.claim_address.freight_distance,
      formList.claim_address.freight_distance
    )
  ) {
    _flg = false;
  }

  if (!_flg) {
    return _flg;
  }

  console.log("diffContents3 attention_check_flg");

  if (
    !diffMessage(dataList.attention_check_flg, formList.attention_check_flg)
  ) {
    _flg = false;
  }

  return _flg;
};

const uploadTab = (formList: any, dataList: any) => {
  console.log("diffContents4 claim_media_invoice create");
  let _flg = true;
  if (formList.claim_media.claim_media_invoice.length > 0) {
    for (let i = 0; i < formList.claim_media.claim_media_invoice.length; i++) {
      const item = formList.claim_media.claim_media_invoice[i];
      // create data
      if (item.sub_no === "" || item.sub_no === undefined) {
        _flg = false;
      }
      if (item.update_flg === 2) {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  if (!_flg) {
    return false;
  }
  console.log("diffContents4 claim_media_invoice delete");
  // if (dataList.claim_media.claim_media_invoice.length > 0) {
  //   for (let i = 0; i < dataList.claim_media.claim_media_invoice.length; i++) {
  //     const item = dataList.claim_media.claim_media_invoice[i];
  //     if (formList.claim_media.claim_media_invoice.length > 0) {
  //       // input data
  //       const _input = formList.claim_media.claim_media_invoice.filter(
  //         (subItem: any) => subItem.sub_no === item.sub_no
  //       );
  //       if (_input.length === 0) {
  //         _flg = false;
  //       }
  //     } else {
  //       _flg = false;
  //     }
  //     if (!_flg) {
  //       break;
  //     }
  //   }
  // }
  // if (!_flg) {
  //   return false;
  // }
  console.log("diffContents4 claim_media_picture create");

  if (formList.claim_media.claim_media_picture.length > 0) {
    for (let i = 0; i < formList.claim_media.claim_media_picture.length; i++) {
      const item = formList.claim_media.claim_media_picture[i];
      // create data
      if (item.sub_no === "" || item.sub_no === undefined) {
        _flg = false;
      }
      if (item.update_flg === 2) {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  if (!_flg) {
    return false;
  }
  console.log("diffContents4 claim_media_picture delete", _flg);

  // if (dataList.claim_media.claim_media_picture.length > 0) {
  //   for (let i = 0; i < dataList.claim_media.claim_media_picture.length; i++) {
  //     const item = dataList.claim_media.claim_media_picture[i];
  //     if (formList.claim_media.claim_media_picture.length > 0) {
  //       // input data
  //       const _input = formList.claim_media.claim_media_picture.filter(
  //         (subItem: any) => subItem.sub_no === item.sub_no
  //       );
  //       if (_input.length === 0) {
  //         _flg = false;
  //       }
  //     } else {
  //       _flg = false;
  //     }
  //     if (!_flg) {
  //       break;
  //     }
  //   }
  // }
  return _flg;
};

const diffMessage = (data: any, response: any, type: string = "") => {
  if (type === "") {
    return getStringDataType(data) === getStringDataType(response);
  } else if (type === "number") {
    return getNumberDataType(data) === getNumberDataType(response);
  }
};

const transportMessageInfo = (formList: any, dataList: any) => {
  let _flg = true;
  if (!diffMessage(dataList.transport_cost_flg, formList.transport_cost_flg)) {
    return false;
  }

  if (!diffMessage(dataList.weight, formList.weight)) {
    return false;
  }

  if (
    !diffMessage(dataList.transport_way_pick_up, formList.transport_way_pick_up)
  ) {
    return false;
  }

  if (
    !diffMessage(dataList.transport_way_return, formList.transport_way_return)
  ) {
    return false;
  }

  if (!diffMessage(dataList.shipping_method, formList.shipping_method)) {
    return false;
  }

  return _flg;
};

const chargesMessageInfo = (formList: any, dataList: any) => {
  console.log(
    "diffcontents5 chargesMessageInfo total_amount",
    dataList.total_amount,
    formList.total_amount
  );
  let _flg = true;
  if (!diffMessage(dataList.total_amount, formList.total_amount, "number")) {
    return false;
  }

  return _flg;
};

export const getNumberDataType = (value: any) => {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    if (value !== "") {
      return Number(value);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getStringDataType = (value: any) => {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "number" || typeof value === "boolean") {
    return String(value);
  } else {
    return "";
  }
};

export const getPrivacyListInfo = (affiliate_cd: any) => {
  const Locale = getLocale();
  let _flag = false;
  let _lan: any = "en-US";
  let _path: any = "";
  const _path_affiliate_cd = `REACT_APP_PRIVACY_${affiliate_cd}`;
  const _path_affiliate_cd_lan = `REACT_APP_PRIVACY_${affiliate_cd}_${Locale}`;
  const _path_affiliate_cd_pdf = `REACT_APP_PRIVACY_PDF_${affiliate_cd}`;
  const _path_affiliate_cd_pdf_lan = `REACT_APP_PRIVACY_PDF_${affiliate_cd}_${Locale}`;
  // set link
  _path = process.env[_path_affiliate_cd_lan];
  if (_path) {
    _flag = true;
  } else {
    _path = process.env[_path_affiliate_cd];
    if (_path) {
      _flag = true;
    }
  }

  // set pdf
  if (!_flag) {
    if (process.env[_path_affiliate_cd_pdf_lan]) {
      _lan = process.env[_path_affiliate_cd_pdf_lan];
    } else {
      if (process.env[_path_affiliate_cd_pdf]) {
        _lan = process.env[_path_affiliate_cd_pdf];
      }
    }
    _path =
      process.env.PUBLIC_URL + `/pdf/${_lan}/privacy notice_to enduser.pdf`;
  }

  return {
    flag: _flag,
    path: _path,
  };
};

export const getLocalFolder = (type: any = "") => {
  const Locale = getLocale();
  if (type === "pdf") {
    let list: any = process.env.REACT_APP_REGION_PDF
      ? process.env.REACT_APP_REGION_PDF.split(",")
      : [];
    if (list.length > 0) {
      if (list.includes(Locale)) {
        return Locale;
      }
    }
  } else {
    let list: any = process.env.REACT_APP_REGION_ICON_INFORMATION
      ? process.env.REACT_APP_REGION_ICON_INFORMATION.split(",")
      : [];
    if (list.length > 0) {
      if (list.includes(Locale)) {
        return Locale;
      }
    }
  }
  return "en-US";
};

/**
 * roll out
 */
// // csv semicolon
// const semicolon = [{ affiliate_cd: "MD", sign: ";" }];
// export const getSignByCSV = (affiliate_cd: any) => {
//   let defaultSign = ",";
//   let currentSign = semicolon.filter(
//     (item: any) => item.affiliate_cd === affiliate_cd
//   );
//   return currentSign.length > 0 ? currentSign[0].sign : defaultSign;
// };
export const getSignByCSV = (affiliate_cd: any) => {
  let defaultSign = ";";
  let currentSign = semicolon.filter(
    (item: any) => item.affiliate_cd === affiliate_cd
  );
  return currentSign.length > 0 ? currentSign[0].sign : defaultSign;
};

// export const get dz's Domain
export const getDomain = (affiliate_cd: any) => {
  if (process.env.REACT_APP_AFFILIATE_CD_DZ_LINK) {
    const countryList: any = (
      process.env.REACT_APP_AFFILIATE_CD as string
    ).split(",");

    const linkList: any = (
      process.env.REACT_APP_AFFILIATE_CD_DZ_LINK as string
    ).split(",");
    const _index: any = countryList.indexOf(affiliate_cd);
    if (_index >= 0) {
      return linkList[_index];
    }
  }
  return process.env.REACT_APP_AFFILIATE_CD_DZ_LINK;
};

// getPartsList
export const getPartsList = (affiliate_cd: any) => {
  const countryList: any = (process.env.REACT_APP_AFFILIATE_CD as string).split(
    ","
  );
  const _index: any = countryList.indexOf(affiliate_cd);
  let _list_all: any = [];
  let _labor_list: any = [];
  let _administrative_cost_list: any = [];
  let _freight_list: any = [];
  if (_index >= 0) {
    // REACT_APP_LABOR_ITEM
    if (process.env[`REACT_APP_LABOR_ITEM_${affiliate_cd}`]) {
      _labor_list = (
        process.env[`REACT_APP_LABOR_ITEM_${affiliate_cd}`] as string
      ).split(",");
    }

    // REACT_APP_AC_ITEM
    if (process.env[`REACT_APP_AC_ITEM_${affiliate_cd}`]) {
      _administrative_cost_list = (
        process.env[`REACT_APP_AC_ITEM_${affiliate_cd}`] as string
      ).split(",");
    }

    // REACT_APP_FREIGHT_ITEM
    if (process.env[`REACT_APP_FREIGHT_ITEM_${affiliate_cd}`]) {
      _freight_list = (
        process.env[`REACT_APP_FREIGHT_ITEM_${affiliate_cd}`] as string
      ).split(",");
    }
    _list_all = _list_all.concat(
      _labor_list,
      _administrative_cost_list,
      _freight_list
    );
  }
  return {
    list_all: _list_all,
    labor_list: _labor_list,
    administrative_cost_list: _administrative_cost_list,
    freight_list: _freight_list,
  };
};

// get weight
export const getWeightByAffilicateCd = (affiliate_cd: any) => {
  if (process.env[`REACT_APP_ITEM_WEIGHT_${affiliate_cd}`]) {
    return Number(process.env[`REACT_APP_ITEM_WEIGHT_${affiliate_cd}`]);
  }
  return 30;
};

export const getRegionPullDownDefault = (
  _needCountrys: any,
  _region: any,
  _country: any,
  _region_priority: any
) => {
  let _index = -1;
  const _region_priority_list: any =
    _region_priority && _region_priority.split(",");
  if (_region_priority_list.length > 0) {
    _region_priority_list.forEach((item: any) => {
      if (_index === -1) {
        _index = _needCountrys.indexOf(item);
      }
    });
  }
  console.log(
    "_needCountrys1",
    _needCountrys[_index],
    _region,
    _needCountrys.indexOf(_country),
    _needCountrys,
    _index
  );
  /**
   * AffiliateごとのデフォルトRegionがない場合は、
   * 次の優先はEU。
   * EUもない場合は、現状のアルファベットソートの最上位
   */

  // regionがない場合は
  if (!_region) {
    if (_needCountrys.indexOf(_country) !== -1) {
      _region = _country;
    } else if (_index !== -1) {
      _region = _needCountrys[_index];
    } else {
      // Region listの第一目を設定
      if (_needCountrys.length > 0) {
        _region = _needCountrys[0];
      } else {
        _region = _country;
      }
    }
  }

  return _region;
};

export const getRegionFunc = (affiliate_cd: any, country: any) => {
  if (process.env[`REACT_APP_REGION_${affiliate_cd}`]) {
    return process.env[`REACT_APP_REGION_${affiliate_cd}`];
  }
  return country;
};

// get country
export const getCountry = (affiliate_cd: any) => {
  if (process.env.REACT_APP_AFFILIATE_CD_COUNTRY) {
    const countryList: any = (
      process.env.REACT_APP_AFFILIATE_CD as string
    ).split(",");

    const List: any = (
      process.env.REACT_APP_AFFILIATE_CD_COUNTRY as string
    ).split(",");
    const _index: any = countryList.indexOf(affiliate_cd);
    if (_index >= 0) {
      return List[_index];
    }
  }
  return process.env.REACT_APP_AFFILIATE_CD_COUNTRY;
};

export const getTransportCostShow = (affiliate_cd: any) => {
  const _list: any = process.env.REACT_APP_TRANSPORT_COST_HIDE
    ? process.env.REACT_APP_TRANSPORT_COST_HIDE.split(",")
    : [];
  return !_list.includes(affiliate_cd);
};

export const determineDataType = (
  param: any,
  type: any = "array",
  returnValueFalg: boolean = false
) => {
  if (type === "array") {
    if (returnValueFalg) {
      return Array.isArray(param) ? param : [];
    } else {
      return Array.isArray(param) && param.length > 0;
    }
  }
};

export const getAPIErrorInfo = (response: any, apiName: any = "") => {
  if (response && apiName) {
    let _api: any = APIErrorMessage[apiName];
    if (_api && response.message_id) {
      let _message_id = _api[response.message_id];
      if (_message_id) {
        return Object.assign({}, response, _message_id);
      }
    }
  }
  return response;
};

export const changeNumberByBlank = (value: any) => {
  if (value === "") {
    return 0;
  } else {
    return value;
  }
};

export const paramChangePost = (params: any, type = "") => {
  const claim_header = [
    "claim_no",
    "status",
    "re_entered_count",
    "customer_no",
    "ref_no",
    "model_no",
    "serial_no",
    "condition_of_tool",
    "device_runtime",
    "purchased_date",
    "received_date",
    "repair_date",
    "delay_reason",
    "approve_date",
    "error_type",
    "guarantee_type",
    "delivery_distance",
    "total_amount",
    "currency",
    "entry_date",
    "service_center_id",
    "customer_po_no",
    "manufactured_year",
    "manufactured_month",
    "labor_value",
    "privacy_check_flg",
    "Attention_check_flg",
    "transport_cost_flg",
    "weight",
    "shipping_method",
    "transport_way_pick_up",
    "pre_approve_flg",
    "transport_way_return",
    "unique_key",
    "save_flg",
    "delete_flg",
    "created_date",
    "created_by",
    "updated_date",
    "updated_by",
  ];
  const claim_line = [
    "claim_no",
    "line_no",
    "item_no",
    "order_qty",
    "net_price",
    "amount",
    "webmsi_region",
    "webmsi_spec",
    "manually_update_flg",
    "delete_flg",
    "created_date",
    "created_by",
    "updated_date",
    "updated_by",
  ];
  const claim_address = [
    "claim_no",
    "enduser_no",
    "customer_type",
    "company_name",
    "first_name",
    "last_name",
    "city",
    "State",
    "address_line_1",
    "address_line_2",
    "postal_code",
    "country_code",
    "tel_no",
    "fax_no",
    "mail_address",
    "freight_distance",
    "delete_flg",
    "created_date",
    "created_by",
    "updated_date",
    "updated_by",
  ];
  const claim_note = [
    "claim_no",
    "sub_no",
    "note_type",
    "note",
    "delete_flg",
    "created_date",
    "created_by",
    "updated_date",
    "updated_by",
  ];
  if (type === "claim") {
    // claim header
    claim_header.forEach((e: any) => {
      if (params[e] === "") {
        params[e] = undefined;
      }
    });
    // claim line
    if (params.claim_line.length > 0) {
      params.claim_line.forEach((element: any) => {
        claim_line.forEach((e: any) => {
          if (element[e] === "") {
            element[e] = undefined;
          }
        });
      });
    }
    // claim address
    if (Object.keys(params.claim_address).length > 0) {
      claim_address.forEach((e: any) => {
        if (params.claim_address[e] === "") {
          params.claim_address[e] = undefined;
        }
      });
    }
    if (params.claim_note.length > 0) {
      params.claim_note.forEach((element: any) => {
        claim_note.forEach((e: any) => {
          if (element[e] === "") {
            element[e] = undefined;
          }
        });
      });
    }
  }
  return params;
};
