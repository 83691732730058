import React, { FC, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import jwt_decode from "jwt-decode";
// api
import api from "../api/Api";
import { AlertMesssageBox } from "../components/alert/AlertMesssageBox";
// language
import { useLocale } from "../hooks/UseLocale";

const initialCommonInfoContext = {
  getCommonInfo: () => {},
  setCommonInfo: (property: string, value: any) => {},
  setCommonInfoFull: (value: any) => {},
  setCommonConfig: (value: any) => {},
  getCommonConfig: () => {},
  getHotKey: () => {},
  setHotKey: (property: string, value: any) => {},
  getHistoryRouter: () => {},
  setHistoryRouter: (value: any) => {},
  getCreditNoteListPageMessage: () => {},
  setCreditNoteListPageMessage: (value: any) => {},
  getClaimsPageMessage: () => {},
  setClaimsPageMessage: (value: any) => {},
  getClaimDetailInfo: () => {},
  setClaimDetailInfo: (value: any) => {},
  getUserInfoDZ: () => {},
  setUserInfoDZ: (value: any) => {},
  getClaimsPageInfo: () => {},
  setClaimsPageInfo: (value: any) => {},
  getClaimsSearchMessage: () => {},
  setClaimsSearchMessage: (value: any) => {},
  getConfigurationErrorMessage: () => {},
  setConfigurationErrorMessage: (value: any) => {},
};

const CommonInfoContext = createContext(initialCommonInfoContext);
export const useCommonInfo = () => useContext(CommonInfoContext);

interface Props {
  children: any;
}

interface initialCommonInfoMessage {
  loadingFlg: boolean;
  itemList: any[];
  itemBaseList: any[];
  customerList: any[];
  technicianList: any[];
  serviceCenterList: any[];
  token: string;
  configFlg: boolean;
}

const initialCommonInfoMessage = {
  loadingFlg: false,
  itemList: [],
  itemBaseList: [],
  customerList: [],
  technicianList: [],
  serviceCenterList: [],
  token: "",
  configFlg: false,
};

interface initialHotKeyMessage {
  handleF2Search: boolean;
  handleF8Save: boolean;
  handleF9Refresh: boolean;
  handlePageDown: boolean;
  handlePageUp: boolean;
}

const initialHotKeyMessage = {
  handleF2Search: false,
  handleF8Save: false,
  handleF9Refresh: false,
  handlePageDown: false,
  handlePageUp: false,
};

interface initialHistoryRouter {
  from: string;
  to: string;
}

const initialHistoryRouter = {
  from: "",
  to: "",
};

const initalCommonConfig = {
  token: "",
};
const initalUserInfoRepair = {};
const initalUserPermissions = {
  permission_group: undefined,
  permission: undefined,
};

const initalConfigurationErrorMessage: any = {
  alertShow: false,
  message: "",
  alertType: "",
};

export const CommonInfoProvider: FC<Props> = ({ children }) => {
  const { com } = useLocale();
  const [commonInfo, setCommonInfo] = useState<initialCommonInfoMessage>(
    initialCommonInfoMessage
  );
  const [userInfoDZ, setUserInfoDZ] = useState({});
  const [commonConfig, setCommonConfig] = useState(initalCommonConfig);
  const [userInfoRepair, setUserInfoRepair] = useState(initalUserInfoRepair);
  const [creditNoteList, setCreditNoteList] = useState({});
  const [claimsPage, setClaimsPage] = useState({
    page: 0,
    perPage: 10,
  });
  const [claimsSearch, setClaimsSearch] = useState({});
  const [configurationErrMsg, setConfigurationErrMsg] = useState(initalConfigurationErrorMessage);
  const [claimDetailInfomation, setClaimDetailInfomation] = useState({});
  const [hotKey, setHotKey] =
    useState<initialHotKeyMessage>(initialHotKeyMessage);
  const [historyRouter, setHistoryRouter] =
    useState<initialHistoryRouter>(initialHistoryRouter);

  const [startFlag, setStartFlag] = useState(false);

  useEffect(() => {
    if (!startFlag && commonConfig.token) {
      setStartFlag(true);
      commonAPI();
    }
  }, [commonConfig, startFlag]);

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Message");
  const [alertType, setAlertType] = useState("error");
  const commonAPI = async () => {
    let flag = true;
    const _token: any = jwt_decode(commonConfig.token);
    let customer_no: any = "";
    let userInfo: any;
    if (_token.sub) {
      await api
        .getReadUserInfoAPIforDZ(commonConfig)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("getReadUserInfoAPIforDZ", response);
          if (code !== 200) {
            flag = false;
            setAlertShow(true);
            const _message = com("messages." + response.message_id, {
              param1: com("messages_key_name." + response.key),
            });
            setAlertMessage(_message);
          } else {
            customer_no = response.customer_no;
            if (response.user_block) {
              flag = false;
            }
            userInfo = response;
          }
        });
      if (customer_no) {
        await api
          .getReadCustomerMasterAPI(customer_no, commonConfig)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("getReadCustomerMasterAPI", response);
            if (code !== 200) {
              setAlertShow(true);
              const _message = com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              });
              setUserInfoDZ(
                Object.assign(
                  {},
                  { userInfo: userInfo },
                  { errorFlag: flag },
                  userInfo
                )
              );
              // setUserInfoDZ({ errorFlag: false });
              setAlertMessage(_message);
            } else {
              setUserInfoDZ(
                Object.assign(
                  {},
                  { userInfo: userInfo },
                  { customerInfo: response },
                  userInfo,
                  response,
                  {
                    errorFlag: flag,
                  }
                )
              );
              // setUserInfoDZ(Object.assign({}, response, { errorFlag: flag }));
            }
          });
      } else {
        setUserInfoDZ({ customer: 0 });
      }
    }
  };

  const commonInfoContext = {
    getCommonInfo: () => {
      return commonInfo;
    },
    setCommonInfo: (property: string, value: any) => {
      setCommonInfo((prevState: any) => {
        const list = Object.assign({}, prevState, { [property]: value });
        return list;
      });
    },
    setCommonInfoFull: (value: any) => {
      const list = Object.assign({}, commonInfo, { ...value });
      setCommonInfo(list);
    },
    setCommonConfig: (value: any) => {
      const list = Object.assign({}, value);
      setCommonConfig(list);
    },
    getCommonConfig: () => {
      return commonConfig;
    },
    getHotKey: () => {
      return hotKey;
    },
    setHotKey: (property: string, value: any) => {
      const list = Object.assign({}, hotKey, { [property]: value });
      setHotKey(list);
    },
    getHistoryRouter: () => {
      return historyRouter;
    },
    setHistoryRouter: (value: any) => {
      const list = Object.assign({}, { from: historyRouter.to, to: value });
      setHistoryRouter(list);
    },
    getCreditNoteListPageMessage: () => {
      return creditNoteList;
    },
    setCreditNoteListPageMessage: (value: any) => {
      const list = Object.assign({}, { from: historyRouter.to, to: value });
      setCreditNoteList(list);
    },
    getClaimsPageMessage: () => {
      return claimsPage;
    },
    setClaimsPageMessage: (value: any) => {
      const list = Object.assign({}, value);
      setClaimsPage(list);
    },
    getClaimDetailInfo: () => {
      return claimDetailInfomation;
    },
    setClaimDetailInfo: (value: any) => {
      const list = Object.assign({}, value);
      setClaimDetailInfomation(list);
    },
    getUserInfoDZ: () => {
      return userInfoDZ;
    },
    setUserInfoDZ: (value: any) => {
      const list = Object.assign({}, value);
      setUserInfoDZ(list);
    },
    getClaimsPageInfo: () => {
      return claimsPage;
    },
    setClaimsPageInfo: (value: any) => {
      const list = Object.assign({}, value);
      setClaimsPage(list);
    },
    getClaimsSearchMessage: () => {
      return claimsSearch;
    },
    setClaimsSearchMessage: (value: any) => {
      const list = Object.assign({}, value);
      setClaimsSearch(list);
    },
    getConfigurationErrorMessage: () => {
      return configurationErrMsg;
    },
    setConfigurationErrorMessage: (value: any) => {
      const list = Object.assign({}, value);
      setConfigurationErrMsg(list);
    },
  };
  return (
    <CommonInfoContext.Provider value={{ ...commonInfoContext }}>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      {children}
    </CommonInfoContext.Provider>
  );
};
