import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useState, useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateAppRoutes, RouteModel } from "../../pages/AppRoutes";
import { useAuth0Redirect } from "../hooks/UseAuth0Redirect";
import { useDrawer } from "../hooks/UseDrawer";
import { NavigationBar } from "./navigation/Navigation.Bar";
import { NavigationDrawerMenu } from "./navigation/drawers/Navigation.Drawer.Menu";
import { SecureHttpRequestProvider } from "../hooks/SecureHttpRequestProvider";
import { useHistory } from "react-router-dom";
import { getAppRouteKey, PathKey } from "../../pages/AppRoutes";
import { useCommonInfo } from "../hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 2,
      position: "relative",
    },
    background: {
      zIndex: 1,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: theme.spacing(8),
      marginLeft: theme.spacing(10),
      [theme.breakpoints.only("xs")]: {
        marginLeft: theme.spacing(7),
      },
    },
  })
);

export const PrivateContents: FC = () => {
  const classes = useStyles();

  const { resetRedirect } = useAuth0Redirect();
  const menuDrawer = useDrawer(false);
  const notificationDrawer = useDrawer(false);
  const { setHotKey, setHistoryRouter } = useCommonInfo();

  // From next time, redirect to Auth0 page.
  resetRedirect();

  // Webhook operation

  const history = useHistory() as any;

  useEffect(() => {
    const path =
      history.location.pathname.indexOf("?") !== -1
        ? history.location.pathname.split("?")[0]
        : history.location.pathname;
    const key = String(getAppRouteKey(history.location.pathname));
    setHistoryRouter(key);
  }, [history.location]);

  const keyPress = (event: any) => {
    const key = getAppRouteKey(history.location.pathname);
  };

  return (
    <SecureHttpRequestProvider>
      <div onKeyDown={(e) => keyPress(e)} tabIndex={0}>
        <div className={classes.background}></div>
        <div className={classes.root}>
          <NavigationBar onToggleMenuDrawerEvent={menuDrawer.onToggleDrawer} />
          <NavigationDrawerMenu
            isDrawerOpen={menuDrawer.isDrawerOpen}
            onCloseEvent={menuDrawer.onCloseDrawer}
            onMouseOverDrawerEvent={menuDrawer.onMouseOverDrawer}
            onMouseLeaveDrawerEvent={menuDrawer.onMouseLeaveDrawer}
          />
          <Switch>
            {PrivateAppRoutes.map((m: any, i: any) => {
              return (
                <Route
                  key={i}
                  path={m.path}
                  exact
                  render={(props) => (
                    <main className={classes.content}>
                      <m.component<RouteModel> {...props} routes={m.routes} />
                    </main>
                  )}
                />
              );
            })}
          </Switch>
        </div>
      </div>
    </SecureHttpRequestProvider>
  );
};
