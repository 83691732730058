import {
  APIAudience,
  AUTH0_AUDIENCES,
} from "../constants/api.audience.constants";
import { methods, lastModified } from "../constants/common.constants";

export async function http({
  token,
  url,
  method = methods.get,
  data,
  flg,
  config,
  claimFlag,
  apiAudience,
}: {
  token: string;
  url: string;
  method?: string;
  data?: any;
  flg?: boolean;
  config?: any;
  claimFlag?: any;
  apiAudience?: APIAudience;
}): Promise<any> {
  var init: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  console.log("api param data", url, method, JSON.stringify(data));
  if (method === methods.delete) {
    const headers: any = init.headers;
    headers[lastModified] = data.updated_date;
    data = undefined;
  }
  if (claimFlag) {
    const headers: any = init.headers;
    headers["X-Called-By"] = "dz";
  }

  if (data !== undefined) init.body = JSON.stringify(data);

  // Return as json
  var json = () => {
    let code: any;
    return fetch(url!, init)
      .then((response) => {
        code = response.status;

        // AT0101 post's response
        if (flg) {
          return response;
        } else {
          return method !== methods.get && code < 400 ? null : response;
        }
      })
      .then((response) => (response ? response.json() : null))
      .then((data) => {
        return {
          code: code,
          response: data,
        };
      })
      .catch((error) => {
        return {
          code: 999,
          response: {
            key: "",
            message_id: "MS0037",
          },
        };
      });
  };

  if (config) {
    token = await getToken(url, config, apiAudience);
    const headers: any = init.headers;
    headers.Authorization = `Bearer ${token}`;
  }
  return json();
}

async function getToken(
  url: any,
  config: any = "",
  apiAudience: APIAudience = AUTH0_AUDIENCES.MAKMATE
) {
  var audience =
    apiAudience === AUTH0_AUDIENCES.WEBMSI
      ? process.env.REACT_APP_AUTH0_WEBMSI_API_AUDIENCE!
      : process.env.REACT_APP_AUTH0_MAKMATE_API_AUDIENCE!;
  var scope =
    apiAudience === AUTH0_AUDIENCES.WEBMSI
      ? process.env.REACT_APP_AUTH0_WEBMSI_API_SCOPE!
      : process.env.REACT_APP_AUTH0_MAKMATE_API_SCOPE!;

  // @ts-ignore
  if (window.Cypress) {
    const itemKey = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`;
    const auth0 = JSON.parse(window.localStorage.getItem(itemKey)!);
    console.log(auth0);
    return Promise.resolve(auth0.body.access_token);
  } else {
    return config.getAccessTokenSilently({
      audience: audience,
      scope: scope,
    });
  }
}

export const upload = (url: any, data: any) => {
  var init: RequestInit = {
    method: "PUT",
    body: data,
    headers: {
      "upload-file": "",
    },
  };

  // Return as json
  var json = () => {
    return fetch(url!, init).then((response) => {
      return response.status;
    });
  };

  return json();
};

export const download = (url: string, method = methods.get): Promise<any> => {
  var init: RequestInit = {
    method: method,
  };

  // Return as json
  var json = () => {
    let code: any;
    return fetch(url!, init)
      .then((response) => {
        code = response.status;
        console.log("download", response);
        return response;
      })
      .then((response: any) => (response ? response.blob() : null))
      .then((data) => {
        return {
          code: code,
          response: data,
        };
      });
  };

  return json();
};

export const download_CSV_file = ({
  token,
  url,
  method = methods.get,
  data,
  flg,
  config,
  apiAudience,
}: {
  token: string;
  url: string;
  method?: string;
  data?: any;
  flg?: boolean;
  config?: any;
  apiAudience?: APIAudience;
}): Promise<any> => {
  var init: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // Return as json
  var json = () => {
    let code: any;
    return fetch(url!, init)
      .then((response) => {
        code = response.status;
        console.log("download", response);
        return response;
      })
      .then((response: any) => (response ? response.blob() : null))
      .then((data) => {
        return {
          code: code,
          response: data,
        };
      })
      .catch((error) => {
        return {
          code: 999,
          response: {
            key: "",
            message_id: "MS0037",
          },
        };
      });
  };

  return json();
};

export const getWebMsi = (url: string, method = methods.get): Promise<any> => {
  var init: RequestInit = {
    method: method,
  };

  // Return as json
  var json = () => {
    let code: any;
    return fetch(url!, init)
      .then((response) => {
        code = response.status;
        return response;
      })
      .then((response: any) => (response ? response.text() : null))
      .then((data) => {
        return {
          code: code,
          response: data,
        };
      })
      .catch((error) => {
        console.log("error", error);
        return {
          code: 999,
          response: {
            key: "",
            message_id: "MS0037",
          },
        };
      });
  };

  return json();
};

export const downloadDZ = (param: any, method = methods.get): Promise<any> => {
  console.log("downloadDZ", param);
  var init: any = {
    headers: { Authorization: `Bearer ${param.token}` },
  };
  console.log("downloadDZ", init);
  // Return as json
  var json = () => {
    let code: any;
    return fetch(param.url!, init)
      .then((response) => {
        code = response.status;
        console.log("download", response);
        return response;
      })
      .then((response: any) => (response ? response.blob() : null))
      .then((data) => {
        return {
          code: code,
          response: data,
        };
      })
      .catch((error) => {
        return {
          code: 999,
          response: {
            key: "",
            message_id: "MS0037",
          },
        };
      });
  };

  return json();
};
