import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/ClaimDetail.Upload.styles";
import DescriptionIcon from "@material-ui/icons/Description";
import ImageIcon from "@material-ui/icons/Image";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { InformationIcon } from "../../../modules/components/informationIcon/InformationIcon";
// components
import { ClaimDetailTakePhotos } from "./ClaimDetail.Upload.TakePhotos";

// language
import { useLocale } from "../../../modules/hooks/UseLocale";

// component
export const ClaimDetailUpload = (props: any) => {
  const classes = useStyles();
  const {
    claimMedia,
    handleUpload,
    photoDelete,
    fileDownload,
    fileDownloadFile,
    formErrorList,
    documentList,
  } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  // button click event of take photos
  const [webCamOpenPicture, setWebCamOpenPicture] = useState(false);
  const [webCamOpenInvoice, setWebCamOpenInvoice] = useState(false);
  const [photoList, setPhotoList] = useState([]);

  const handleWebCamPicture = () => {
    setWebCamOpenPicture(true);
    setPhotoList([]);
  };

  const handleWebCamClosePictrue = () => {
    setWebCamOpenPicture(false);
    setPhotoList([]);
  };

  const handleWebCamInvoice = () => {
    setWebCamOpenInvoice(true);
    setPhotoList([]);
  };

  const handleWebCamCloseInvoice = () => {
    setWebCamOpenInvoice(false);
    setPhotoList([]);
  };

  // const goButtonDisabledFlg = (listName: string) => {
  //   return claimMedia[listName] ? claimMedia[listName].length >= 10 : false;
  // };
  const goButtonDisabledFlgFile = (listName: string) => {
    const _flg = false;
    if (claimMedia[listName]) {
      let _newList = claimMedia[listName].filter(
        (item: any) => Number(item.update_flg) !== 2
      );
      return listName === "claim_media_picture"
        ? _newList.length >= 10
        : _newList.length >= 10 - documentList.length;
    }
    // return claimMedia[listName]
    //   ? claimMedia[listName].length >= 10 - documentList.length
    //   : false;
    return _flg;
  };

  const handleFileSelected = (file: any, name: string) => {
    handleUpload(file, name);
  };

  const addPhotoByCam = (list: any, name: any) => {
    if (list.length > 0) {
      props.handleUploadByCam(list, name);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div>
          <div className={classes.message}>
            <div className={classes.image}>
              <DescriptionIcon color="primary" />
            </div>
            {/* <p className={classes.text}>
        <span className={classes.type}>
          {t("upload_invoice_description_type")}
        </span>
        {t("upload_invoice_description")}
      </p> */}
          </div>
          <div className={classes.explainArea}>
            <p className={classes.title}>{t("upload_invoice_title")}</p>
            <InformationIcon txtInner={["upload_type", "upload_size"]} />
          </div>
          <div className={classes.button}>
            <input
              accept="image/*,.pdf"
              id="contained-button-file"
              type="file"
              className={classes.uploadInput}
              disabled={goButtonDisabledFlgFile("claim_media_invoice")}
              onChange={(e: any) =>
                handleFileSelected(e.target.files, "claim_media_invoice")
              }
              onClick={(e: any) => {
                e.target.value = null;
              }}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={goButtonDisabledFlgFile("claim_media_invoice")}
                className={classes.uploadButton}
              >
                {t("btn_upload")}
              </Button>
            </label>
          </div>
          <div className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<PhotoCameraIcon />}
              disabled={goButtonDisabledFlgFile("claim_media_invoice")}
              className={classes.uploadButton}
              onClick={handleWebCamInvoice}
            >
              {t("btn_take_photos")}
            </Button>
          </div>
          {formErrorList.upload_claim_media_invoice_error !== "" && (
            <p className={classes.error}>
              {formErrorList.upload_claim_media_invoice_error}
            </p>
          )}
          <ul className={classes.list}>
            {documentList &&
              documentList.length > 0 &&
              documentList.map((item: any, index: number) => (
                <li key={"document" + index}>
                  <p
                    className={classes.link}
                    onClick={() => fileDownloadFile(item)}
                  >
                    {item.filename}
                  </p>
                </li>
              ))}
            {claimMedia &&
            claimMedia.claim_media_invoice &&
            claimMedia.claim_media_invoice.length > 0
              ? claimMedia.claim_media_invoice.map((item: any, index: number) =>
                  item.update_flg !== 2 ? (
                    <li key={"claim_media_invoice" + index}>
                      {item.updated_date ? (
                        <p
                          className={classes.link}
                          onClick={() => fileDownload(item)}
                        >
                          {item.media_name}
                        </p>
                      ) : (
                        <p>{item.media_name}</p>
                      )}
                      <div>
                        <IconButton
                          onClick={() =>
                            photoDelete(index, "claim_media_invoice")
                          }
                        >
                          <Close />
                        </IconButton>
                      </div>
                    </li>
                  ) : null
                )
              : null}
          </ul>
        </div>
        <div className={classes.item_area_right}>
          <div className={classes.message}>
            <div className={classes.image}>
              <ImageIcon color="primary" />
            </div>
          </div>
          <div className={classes.explainArea}>
            <p className={classes.title}>{t("upload_image_title")}</p>
            <InformationIcon txtInner={["upload_type", "upload_size"]} />
          </div>
          <div className={classes.button}>
            <input
              accept="image/*,.pdf"
              id="contained-button-file2"
              type="file"
              className={classes.uploadInput}
              disabled={goButtonDisabledFlgFile("claim_media_picture")}
              onChange={(e: any) =>
                handleFileSelected(e.target.files, "claim_media_picture")
              }
              onClick={(e: any) => {
                e.target.value = null;
              }}
            />
            <label htmlFor="contained-button-file2">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={goButtonDisabledFlgFile("claim_media_picture")}
                className={classes.uploadButton}
              >
                {t("btn_upload")}
              </Button>
            </label>
          </div>
          <div className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<PhotoCameraIcon />}
              disabled={goButtonDisabledFlgFile("claim_media_picture")}
              className={classes.uploadButton}
              onClick={handleWebCamPicture}
            >
              {t("btn_take_photos")}
            </Button>
          </div>
          {formErrorList.upload_error !== "" && (
            <p className={classes.error}>{formErrorList.upload_error}</p>
          )}
          <ul className={classes.list}>
            {claimMedia &&
            claimMedia.claim_media_picture &&
            claimMedia.claim_media_picture.length > 0
              ? claimMedia.claim_media_picture.map((item: any, index: number) =>
                  item.update_flg !== 2 ? (
                    <li key={"claim_media_picture" + index}>
                      {item.updated_date ? (
                        <p
                          className={classes.link}
                          onClick={() => fileDownload(item)}
                        >
                          {item.media_name}
                        </p>
                      ) : (
                        <p>{item.media_name}</p>
                      )}
                      <div>
                        <IconButton
                          onClick={() =>
                            photoDelete(index, "claim_media_picture")
                          }
                        >
                          <Close />
                        </IconButton>
                      </div>
                    </li>
                  ) : null
                )
              : null}
          </ul>
        </div>
      </div>
      <ClaimDetailTakePhotos
        webCamOpen={webCamOpenPicture}
        handleWebCamClose={handleWebCamClosePictrue}
        uploadPhotoList={claimMedia["claim_media_picture"]}
        documentList={[]}
        addPhotoByCam={addPhotoByCam}
        photoList={photoList}
        setPhotoList={setPhotoList}
        name="claim_media_picture"
      />
      <ClaimDetailTakePhotos
        webCamOpen={webCamOpenInvoice}
        handleWebCamClose={handleWebCamCloseInvoice}
        uploadPhotoList={claimMedia["claim_media_invoice"]}
        documentList={documentList}
        addPhotoByCam={addPhotoByCam}
        photoList={photoList}
        setPhotoList={setPhotoList}
        name="claim_media_invoice"
      />
    </>
  );
};
