import React, { FC, useEffect, useState, useCallback } from "react";
// UI
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import FileSaver from "file-saver";
import { getCSVContents, getCSVFileName } from "./methods/Claim.Common.Methods";
// api
import api from "../../modules/api/Api";
// common
import { getApiResultStatus, getAPIErrorInfo } from "../../modules/common.methods";

// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { ClaimSearchControl } from "./components/Claim.SearchControl";
import { ClaimResult } from "./components/Claim.Result";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// route
import { useHistory } from "react-router-dom";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";

const defaultValue = {
  claim_no: "",
  model_no: "",
  serial_no: "",
  parts_used: "",
  customer_po_no: "",
  select_status: [],
  entry_date_from: null,
  entry_date_to: null,
  approved_date_from: null,
  approved_date_to: null,
  user_company_name: "",
  postal_code: "",
  claim_no_error: "",
  model_no_error: "",
  serial_no_error: "",
  parts_used_error: "",
  customer_po_no_error: "",
  entry_date_from_error: "",
  entry_date_to_error: "",
  approved_date_from_error: "",
  approved_date_to_error: "",
  user_company_name_error: "",
  postal_code_error: "",
};

export const Page: FC = () => {
  const {
    setCommonInfo,
    getCommonInfo,
    setCommonInfoFull,
    getCommonConfig,
    getUserInfoDZ,
    getHistoryRouter,
    setClaimsPageInfo,
    getClaimsPageInfo,
    setClaimsSearchMessage,
    getClaimsSearchMessage,
    setConfigurationErrorMessage,
  } = useCommonInfo();
  const history = useHistory();

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  let config = getCommonConfig() as any;
  let historyRouter = getHistoryRouter() as any;
  let claimsSearchMessage = getClaimsSearchMessage() as any;
  let pageMessage = getClaimsPageInfo() as any;
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({
    defaultPath: "modules.components.claim",
  });
  const commonInfo = getCommonInfo() as any;
  const userInfo = getUserInfoDZ() as any;
  const [initFlg, setInitFlg] = useState(true);
  const [searchFlg, setSearchFlg] = useState(false);
  const [isloadingCompleted, setIsloadingCompleted] = useState(false);
  const [alertMessageList, setAlertMessageList] = useState([]);
  // search value
  const [searchValueList, setSearchValueList] = useState(defaultValue);
  // search result
  const [resultList, setResultList] = useState<any[]>([]);
  const [selectedArray, setSelectedArray] = useState<string[]>([]);
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [searchClickFlg, setSearchClickFlg] = useState(false);

  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    const message = com("messages." + response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  const handleRedirect = () => {
    if (
      userInfo.errorFlag &&
      userInfo.userInfo &&
      userInfo.userInfo.wco_claim &&
      userInfo.customerInfo &&
      userInfo.customerInfo.asc_flg
    ) {
      return true;
    } else if (
      userInfo.errorFlag &&
      userInfo.userInfo &&
      userInfo.userInfo.wco_credit_note &&
      userInfo.customerInfo &&
      userInfo.customerInfo.asc_flg
    ) {
      const path = GetPrivateAppRoutePath(PathKey.AP);
      handleOnClick(path);
      return false;
    } else {
      const obj: any = {
        alertShow: true,
        message: com("messages.MS0116"),
        alertType: "error",
      };
      setConfigurationErrorMessage(obj);
      const path = GetPrivateAppRoutePath(PathKey.CONFIGURATION);
      handleOnClick(path);
      return false;
    }
  };

  useEffect(() => {
    // init search
    if (initFlg && (!config.token || Object.keys(userInfo).length === 0)) {
      apiLoading(true);
    }
    if (initFlg && config.token && Object.keys(userInfo).length > 0) {
      console.log("userInfo1", userInfo);
      setInitFlg(false);

      if (!handleRedirect()) {
        return;
      }
      let masterItemList: any = [];
      let masterItemBaseList: any = [];

      let itemListApi;
      let itemBaseListApi;
      let userInfoRepairApi;
      // item master AM0305
      if (!commonInfo.itemList || commonInfo.itemList.length === 0) {
        itemListApi = api
          .getItemDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0305 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterItemList = response;
            }
          });
      } else {
        masterItemList = commonInfo.itemList;
      }

      // get base item master list
      if (!commonInfo.itemBaseList || commonInfo.itemBaseList.length === 0) {
        itemBaseListApi = api
          .getBaseItemListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("base item response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterItemBaseList = response;
            }
          });
      } else {
        masterItemBaseList = commonInfo.itemBaseList;
      }

      Promise.all([itemListApi, itemBaseListApi]).then(() => {
        setCommonInfoFull({
          itemList: masterItemList,
          itemBaseList: masterItemBaseList,
          loadingFlg: false,
        });
        setIsloadingCompleted(true);
      });
    }
  });

  useEffect(() => {
    let data: any = {};
    if (isloadingCompleted) {
      if (historyRouter.to === String(PathKey.CLAIM)) {
        if (
          historyRouter.from === String(PathKey.CLAIMDETAIL) ||
          historyRouter.from === String(PathKey.SUMMARY)
        ) {
          data = Object.assign({}, defaultValue, claimsSearchMessage);
        } else {
          data = Object.assign({}, defaultValue);
          const obj = Object.assign({}, pageMessage, {
            page: 0,
          });
          setClaimsPageInfo(obj);
        }
        setSearchValueList(data);
        let query_parameters = conditionSearch(data);
        search(data, query_parameters, false);
      }
    }
  }, [historyRouter, isloadingCompleted]);

  // common search method
  const search = (data: any, query_parameters: string, appendErr = false) => {
    setResultList([]);
    // Read Claim List AT1705
    api
      .getReadClaimListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT1705 response: ", response);
        const searchMessage = Object.assign({}, data);
        setClaimsSearchMessage(searchMessage);
        if (code !== 200) {
          const obj: any = {
            no: alertMessageList.length,
            message: com("messages." + response.message_id, {
              param1: com("messages_key_name_wco." + response.key),
            }),
            alertType: "error",
          };
          let alertMessageListNew: any = [obj];
          setAlertMessageList(alertMessageListNew);
          apiLoading(false);
        } else {
          const obj: any = {
            no: alertMessageList.length,
            message: com("messages.MS0049"),
            alertType: "warning",
          };
          if (response.total_num && response.total_num >= 2000) {
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          } else {
            setAlertMessageList([]);
          }
          let newList = response.claims_list.slice(0);
          if (newList.length > 0) {
            newList.forEach((item: any) => {
              if (!item.currency) {
                item.currency = config.currency;
              }
              if (!item.customer_type) {
                item.customer_name = "";
              } else {
                item.customer_name = "";
                if (Number(item.customer_type) === 1) {
                  item.customer_name = item.company_name
                    ? item.company_name
                    : "";
                } else {
                  item.customer_name =
                    (item.last_name ? item.last_name : "") +
                    " " +
                    (item.first_name ? item.first_name : "");
                }
              }
            });
          }
          setResultList(newList);
          apiLoading(false);
        }
      });
  };

  // set search value
  const setSearchValue = (property: any, value: any) => {
    setSearchValueList((preSearchValueList) => {
      return Object.assign({}, preSearchValueList, { [property]: value });
    });
  };

  // search button click event
  const handleSearchEvent = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedArray([]);
    setAlertMessageList([]);
    if (isAllErrMsgmpty()) {
      //props.setSearchClickFlg(true);
      let query_parameters = conditionSearch(searchValueList, false);
      search(searchValueList, query_parameters, false);
    }
  };

  const conditionSearch = (data: any, appendErr = false) => {
    console.log("userInfo", userInfo);
    let param_select_status = "";
    let param_entryDateFrom = "";
    let param_entryDateTo = "";
    if (data.select_status.length > 0) {
      for (let index in data.select_status) {
        param_select_status =
          param_select_status + "&status=" + data.select_status[index];
      }
    } else {
      param_select_status = "&status=";
    }
    let entry_date_from = data.entry_date_from;
    if (entry_date_from !== null) {
      param_entryDateFrom = new Date(entry_date_from).toISOString();
    } else {
      param_entryDateFrom = "";
    }

    let entry_date_to = data.entry_date_to;
    if (entry_date_to !== null) {
      param_entryDateTo = new Date(entry_date_to).toISOString();
    } else {
      param_entryDateTo = "";
    }

    let param_approvedDateFrom, param_approvedDateTo;
    let approved_date_from = data.approved_date_from;
    if (approved_date_from !== null) {
      param_approvedDateFrom = new Date(approved_date_from).toISOString();
    } else {
      param_approvedDateFrom = "";
    }

    let approved_date_to = data.approved_date_to;
    if (approved_date_to !== null) {
      param_approvedDateTo = new Date(approved_date_to).toISOString();
    } else {
      param_approvedDateTo = "";
    }

    let query_parameters =
      "claim_no=" +
      encodeURIComponent(data.claim_no) +
      "&company_name=" +
      encodeURIComponent(data.user_company_name) +
      "&postal_code=" +
      encodeURIComponent(data.postal_code) +
      "&model_no=" +
      encodeURIComponent(data.model_no) +
      "&serial_no=" +
      encodeURIComponent(data.serial_no) +
      "&item_no=" +
      encodeURIComponent(data.parts_used) +
      "&customer_po_no=" +
      encodeURIComponent(data.customer_po_no) +
      param_select_status +
      "&entry_date_from=" +
      encodeURIComponent(param_entryDateFrom) +
      "&entry_date_to=" +
      encodeURIComponent(param_entryDateTo) +
      "&approve_date_from=" +
      encodeURIComponent(param_approvedDateFrom) +
      "&approve_date_to=" +
      encodeURIComponent(param_approvedDateTo) +
      "&customer_no=" +
      userInfo.customer_no;

    console.log("query_parameters", query_parameters);
    return query_parameters;
  };

  // clear button click event
  const handleClearEvent = () => {
    setSelectedArray([]);
    setAlertMessageList([]);
    const valueList = Object.assign({}, defaultValue, {
      select_status: [],
    });
    setSearchValueList(valueList);
  };

  const downloadCSV = () => {
    let query_parameters = conditionSearch(searchValueList, false);
    // AT1707
    api
      .getReadClaimListForCSV(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        if (!getApiResultStatus(code)) {
          apiLoading(false);
          response = getAPIErrorInfo(
            response,
            "getDownloadClaimsByS3singedUrlApi"
          );
          const obj: any = {
            no: alertMessageList.length,
            message: com("messages." + response.message_id, {
              param1: com("messages_key_name_wco." + response.key),
            }),
            alertType: "error",
          };

          let alertMessageListNew: any = [obj];
          setAlertMessageList(alertMessageListNew);
        } else {
          console.log("AT1707", code, response);
          const url = response.content;
          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              if (!getApiResultStatus(code)) {
                const obj: any = {
                  no: alertMessageList.length,
                  message: com("messages." + response.message_id, {
                    param1: com("messages_key_name." + response.key),
                  }),
                  alertType: "error",
                };
                apiLoading(false);
                let alertMessageListNew: any = [obj];
                setAlertMessageList(alertMessageListNew);
              } else {
                let reader: any = new FileReader();
                reader.readAsText(response, "UTF-8");
                reader.onload = function () {
                  let data = this.result;
                  setCSV(JSON.parse(data));
                  apiLoading(false);
                };
              }
            });
        }
      });
  };

  const setCSV = (data: any) => {
    const str = getCSVContents(com, t, data, config);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "claimList" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const isAllEmpty = () => {
    let result = false;
    if (
      searchValueList.claim_no === "" &&
      searchValueList.model_no === "" &&
      searchValueList.serial_no === "" &&
      searchValueList.parts_used === "" &&
      searchValueList.customer_po_no === "" &&
      searchValueList.select_status.length === 0 &&
      searchValueList.entry_date_from === null &&
      searchValueList.entry_date_to === null
    ) {
      result = true;
    }
    return result;
  };

  const isAllErrMsgmpty = () => {
    let result = false;
    if (
      searchValueList.claim_no_error === "" &&
      searchValueList.model_no_error === "" &&
      searchValueList.serial_no_error === "" &&
      searchValueList.parts_used_error === "" &&
      searchValueList.customer_po_no_error === "" &&
      searchValueList.entry_date_from_error === "" &&
      searchValueList.approved_date_from_error === ""
    ) {
      result = true;
    }
    return result;
  };

  const handleCreate = () => {
    const path = GetPrivateAppRoutePath(PathKey.CLAIMDETAIL);
    handleOnClick(path);
  };

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // alert
  // const [alertMessage, setAlertMessage] = useState("");
  // const [alertType, setAlertType] = useState("error");

  const contents = (
    <div id="contents">
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      {alertMessageList.length > 0 &&
        alertMessageList.map((item: any) => (
          <AlertMesssageBox
            key={item.no}
            show={item.message}
            message={item.message}
            type={item.alertType}
          />
        ))}
      <ClaimSearchControl
        setAlertMessageList={setAlertMessageList}
        handleSearchEvent={handleSearchEvent}
        handleClearEvent={handleClearEvent}
        setSearchValueList={setSearchValueList}
        searchValueList={searchValueList}
        setSearchValue={(property: any, value: any) =>
          setSearchValue(property, value)
        }
        conditionSearch={conditionSearch}
        isAllErrMsgmpty={isAllErrMsgmpty}
      />
      <ClaimResult
        data={resultList}
        handleCreate={handleCreate}
        downloadCSV={downloadCSV}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        isloadingCompleted={isloadingCompleted}
        setSearchClickFlg={setSearchClickFlg}
        searchClickFlg={searchClickFlg}
        searchFlg={searchFlg}
        setSearchFlg={setSearchFlg}
        setClaimsSearchMessage={setClaimsSearchMessage}
      />
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
