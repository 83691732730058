import React from "react";
// component
import { TextInput } from "../../../modules/components/textInput/TextInput";
// hook
import { useStyles } from "../hooks/Parts.styles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// methods
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { determineDataType } from "../../../modules/common.methods";
export const ClaimDetailPartsRegisterTbody = (props: any) => {
  const classes = useStyles();
  const {
    data,
    selected,
    setRegisterDataProperty,
    registerRowClick,
    orderQtyAuthDisable,
    setRegisterProjectBlur,
    dataList,
    partsListAll,
  } = props;
  const isSelected = (name: number) => selected.indexOf(name) !== -1;

  return (
    <TableBody>
      {determineDataType(data) &&
        data.map((row: any, index: number) => {
          const isItemSelected = isSelected(Number(index));
          return (
            <TableRow
              className={classes.registerTbody}
              hover
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
            >
              <TableCell
                padding="checkbox"
                className={classes.registerCheckbox}
              >
                <Checkbox
                  checked={isItemSelected}
                  className={classes.checkboxArea}
                  onChange={() => registerRowClick(index)}
                  disabled={partsListAll.includes(row.item_no)}
                />
              </TableCell>
              <TableCell className={classes.registerCellId}>
                {index + 1}
              </TableCell>
              <TableCell className={classes.nowrapCell}>
                {row.item_no}
              </TableCell>
              <TableCell className={classes.descriptionCell}>
                {row.description}
              </TableCell>
              <TableCell className={classes.Qty}>
                <TextInput
                  value={row.order_qty ? row.order_qty : ""}
                  className={classes.registerTextField}
                  disabled={
                    orderQtyAuthDisable || partsListAll.includes(row.item_no)
                  }
                  onChange={(e: any) => {
                    let value = e.target.value;
                    setRegisterDataProperty("order_qty", value, index);
                  }}
                  onBlur={(e: any) => {
                    setRegisterProjectBlur(row, index);
                  }}
                />
              </TableCell>
              <TableCell className={classes.price} align="right">
                {row.net_price
                  ? currency(row.net_price, dataList.currency)
                  : "-"}
              </TableCell>
              <TableCell className={classes.price} align="right">
                {!isNaN(row.net_price) && !isNaN(row.order_qty)
                  ? currency(
                      Number(row.net_price) * Number(row.order_qty),
                      dataList.currency
                    )
                  : "-"}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
