import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "10px",
      maxWidth: "600px",
      "& .MuiFormHelperText-root": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& dl": {
        paddingLeft: "10px",
        marginBottom: "0px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiOutlinedInput-input": {
        minHeight: "30px",
        padding: "0",
        paddingLeft: "5px",
        width: "100%",
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
      "& .react-tel-input .form-control": {
        width: "100%",
        height: "30px",
      },
    },
    row: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      padding: "10px 20px 20px 10px",
    },
    cell: {
      width: "300px",
      marginRight: "20px",
    },
    cellNotification: {
      width: "900px",
      marginRight: "20px",
    },
    advancedButton: {
      "& button": {
        width: "140px",
        padding: "5px 0",
      },
    },
    searchControlSelect: {
      width: "140px",
      "& .MuiInputBase-root": {
        height: "30px",
      },
    },
    buttonArea: {
      padding: "37px 0 0 10px",
    },
    buttonCondition: {
      minWidth: "35px",
      width: "35px",
      height: "30px",
      "& .MuiButton-startIcon": {
        marginLeft: "0",
        marginRight: "0",
      },
    },

    datePickerContainer: {
      position: "relative",
      display: "flex",
      marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePickerArea: {
      width: "400px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePicker: {
      width: "47%",
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    datePickerInterval: {
      position: "absolute",
      top: "20px",
      left: "50%",
      marginTop: "4px",
      marginLeft: "-4px",
      borderTop: "1px solid #000",
      width: "5px",
      display: "block",
    },
    advancedHideFlg: {
      display: "none",
    },
    configurationDd: {
      width: "245px",
    },
    technicianCodeDd: {
      width: "250px",
    },
    modelNoDd: {
      width: "150px",
    },
    customerNmDd: {
      width: "150px",
    },
    emailDd: {
      width: "200px",
    },
    technicianDd: {
      width: "150px",
    },
    autoComplete: {
      padding: "0px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    customerArea: {
      display: "flex",
    },
    groupArea: {
      marginTop: "20px",
      border: "1px solid #767676",
      padding: "15px 10px 5px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px",
      },
      "& textarea": {
        width: "100%",
      },
    },
    groupTitle: {
      position: "absolute",
      top: "-20px",
      left: "5px",
      fontSize: "15px",
      background: "#fff",
      fontWeight: "normal",
      padding: "0 5px",
      marginTop: "9px",
    },
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        width: "20%",
        minWidth: "100px",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
        color: "#686868",
      },
    },
    tableUserInfo02: {
      width: "100%",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        fontWeight: "normal",
        wordBreak: "keep-all",
        textAlign: "left",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        width: "50px",
        verticalAlign: "Top",
      },
    },
    checkboxLayout: {
      padding: 0,
    },
    formControl: {
      margin: theme.spacing(1),
      maxWidth: 300,
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
    title: { fontSize: "24px", fontWeight: "bold", marginTop: "0" },
    textWidth: {
      width: "100% !important",
    },
    bottomArea: {
      paddingTop: "30px",
    },
    explain: {
      fontSize: "15px",
    },
  })
);
