// Example
// const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

import { getLocale } from "../../i18n";

export const date = (input: number | string): string => {
  var date = getDateInput(input);
  return date.toLocaleDateString(getLocale());
};

export const time = (input: number | string): string => {
  var date = getDateInput(input);
  return `${doubleDigit(date.getHours())}:${doubleDigit(
    date.getMinutes()
  )}:${doubleDigit(date.getSeconds())}`;
};

export const timeWithoutSecond = (input: number | string): string => {
  var date = getDateInput(input);
  return `${doubleDigit(date.getHours())}:${doubleDigit(date.getMinutes())}`;
};

const getDateInput = (input: number | string): Date => {
  var dateInput: number;
  switch (typeof input) {
    case "number": {
      dateInput = input;
      break;
    }
    case "string": {
      dateInput = +input;
      break;
    }
  }
  return new Date(dateInput);
};

const doubleDigit = (input: Number): string => {
  return ("0" + input).slice(-2);
};
