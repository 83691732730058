import React from "react";
// hooks
import { useStyles } from "./ClaimHistory.styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// language
import { useLocale } from "../../hooks/UseLocale";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: "DateTime",
    label: "created_date",
  },
  {
    id: "UpdatedBy",
    label: "created_by",
  },
  {
    id: "Description",
    label: "description",
  },
];

export const ClaimDetailHistoryThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
