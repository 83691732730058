export const getFileName = (files: any, claimMedia: any) => {
  const file = files[0];
  let _fileName = file.name;
  let fileNameList = _fileName.split(".");
  if (claimMedia.length > 0) {
    for (let i = 0; i < claimMedia.length; i++) {
      let flg = false;
      for (let j = 0; j < claimMedia.length; j++) {
        if (_fileName === claimMedia[j].media_name) {
          flg = true;
          break;
        }
      }
      if (!flg) {
        break;
      } else {
        let _name = "";
        if (fileNameList.length > 2) {
          for (let a = 0; a < fileNameList.length; i++) {
            if (a < fileNameList.length) {
              _name += fileNameList[a];
            }
          }
        } else {
          _name = fileNameList[0];
        }
        _fileName =
          _name +
          "(" +
          (i + 1) +
          ")" +
          "." +
          fileNameList[fileNameList.length - 1];
      }
    }
  }
  return _fileName;
};

export const dataURLtoBlob = (baseurl: any) => {
  let _arr = baseurl.split(";");
  let arr = _arr[1].split(",");
  let _getMime = baseurl.split(",");
  let mime = _getMime[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;

  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let blob = new Blob([u8arr], {
    type: mime,
  });
  return new File([blob], "camera_" + getPhotoFileName(), {
    type: mime,
  });
};

// get photo file name
const getPhotoFileName = () => {
  const date = new Date();
  let y = date.getFullYear().toString();
  let m: any = date2length(date.getMonth() + 1, 2);
  let d: any = date2length(date.getDate(), 2);
  let hh = date2length(date.getHours(), 2);
  let mm = date2length(date.getMinutes(), 2);
  let ss = date2length(date.getSeconds(), 2);

  return y + m + d + hh + mm + ss;
};

// date length
const date2length = (v: any, len: number) => {
  let _t: string = typeof v === "number" ? v.toString() : v;
  for (let i = 0; i < len; i++) {
    if (typeof _t === "string" && _t.length <= len - 1) {
      _t = "0" + _t;
    }
  }
  return _t;
};
