import { methods } from "../constants/common.constants";
import { http } from "./Http";

// AM0101
export const CreateUserInfoAPI = (data: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0102
export const GetReadUserInfoAPI = (sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/${sub}`,
    config: config,
  };
  return http(param);
};

// AM0103
export const GetReadUserInfoAPIforDZ = (config: any) => {
  const { baseURL, token, sub } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/dz/${sub}`,
    config: config,
  };
  return http(param);
};

// AM0104
export const UpdateUserInfoAPIforDZ = (data: any, sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/dz/${sub}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0105
export const UpdateUserInfoAPI = (data: any, sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/${sub}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0106
export const DeleteUserInfoAPI = (data: any, sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/${sub}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0107
export const GetUserinfoDetailListAPI = (config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users?biztype=1`,
    config: config,
  };
  return http(param);
};

// AM0107
export const GetUserinfoDetailListAPIByDz = (config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users?biztype=0`,
    config: config,
  };
  return http(param);
};
