import React, { FC } from 'react';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useLocale } from '../hooks/UseLocale';
import { SUPPORT_LANGUAGES } from '../constants/supportLanguages';
import { useAuth0Redirect } from '../hooks/UseAuth0Redirect';

export const LoginForm: FC = () => {
    const { t, locale } = useLocale({ defaultPath: 'modules.components.loginForm' });
    const {isRedirected, setRedirect} = useAuth0Redirect();

    const { loginWithRedirect } = useAuth0();
    const options: RedirectLoginOptions = {};

    if(SUPPORT_LANGUAGES.filter(f=> f.code === locale.value).length > 0){
        options.ui_locales = SUPPORT_LANGUAGES.filter(f=> f.code === locale.value)[0].auth0_code;
    }

    // document.referrer does not work. So use LocalStorage
    // Redirect to Auth0 page if the current state is redirected
    if(!isRedirected){
        setRedirect();
        loginWithRedirect(options);
    }

    return (
        <form>
            <Box display='flex' justifyContent='center'>
                <Box>
                    <Button onClick={() => loginWithRedirect(options)} variant='contained' color='primary'>
                        {t('btnLogin')}
                    </Button>
                </Box>
            </Box>
        </form>
    )
}
