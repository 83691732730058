import { useState } from "react";
import api from "../api/Api";
import { GlobalUserInformationModel } from "../models/GlobalUserInformationModel";

const initialGlobalUserInformation = {
  given_name: undefined,
  family_name: undefined,
  middle_name: undefined,
  affiliate_cd: undefined,
  tel_no: undefined,
  system_attribute: undefined,
};

export const useGlobalUserInformation = (): {
  getGlobalUserInformation: (
    token: string,
    url: string
  ) => Promise<GlobalUserInformationModel>;
} => {
  const [
    globalUserInformation,
    setGlobalUserInformation,
  ] = useState<GlobalUserInformationModel>(initialGlobalUserInformation);

  return {
    getGlobalUserInformation: async (
      token: string,
      url: string
    ): Promise<GlobalUserInformationModel> => {
      if (globalUserInformation.affiliate_cd === undefined) {
        return api.getGlobalUserInformation(token, url).then((config: any) => {
          setGlobalUserInformation(config);
          return config;
        });
      } else {
        return globalUserInformation;
      }
    },
  };
};
