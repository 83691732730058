export const methods = {
  get: "GET",
  post: "POST",
  patch: "PATCH",
  delete: "DELETE",
};

export const lastModified = "Last-Modified";

export const responseStatusCodes = {
  success: 200,
  noContent: 204,
};

export const IsSuccessResponse = (c: number): boolean => ((c / 100) | 0) === 2;
export const IsNothingData = (c: number): boolean => c === 404;

export const Sum = (...n: number[]): number =>
  n.filter((f) => f !== undefined).reduce((a, v) => a + v, 0);

export const semicolon = [
  { affiliate_cd: "MD", sign: ";" },
  { affiliate_cd: "MFN", sign: ";" },
  { affiliate_cd: "MOS", sign: ";" },
  { affiliate_cd: "MSW", sign: ";" },
  { affiliate_cd: "MNW", sign: ";" },
  { affiliate_cd: "MES", sign: ";" },
  { affiliate_cd: "MDK", sign: ";" },
  { affiliate_cd: "MUK", sign: "," },
];

export const affiliateCDLan = {
  hidden: ["MUK", "ME"],
  iconMarkShow: ["MD"],
};
