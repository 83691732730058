import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      fontSize: "20px",
      marginTop: "20px",
      borderLeft: "3px solid #8DA1B9",
      borderBottom: "3px solid #8DA1B9",
      paddingLeft: "5px"
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid #D4B4B4",
        borderBottom: "1px solid #D4B4B4",
      },
      "& th:nth-child(1)": {
        width: "10%"
      },
      "& th:nth-child(2)": {
        width: "5%"
      },
      "& th:nth-child(3)": {
        width: "30%"
      },
      "& th:nth-child(4)": {
        width: "5%"
      },
      "& th:nth-child(5)": {
        width: "5%"
      },
      "& th:nth-child(6)": {
        width: "5%"
      },
      "& th:last-child": {
        borderRight: "1px solid #D4B4B4",
        width: "5%"
      },
    },
    tbody: {
      "& td:not(:first-child)": {
        borderLeft: "1px solid #D4B4B4)",
      },
    },
    tableRow: {
      "& td:not(:first-child)": {
        borderLeft: "1px solid #D4B4B4",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderBottom: "1px solid #D4B4B4",
      },
    },
    tableRowRed: {
      backgroundColor: "rgba(230, 0, 18, 0.6);",
    },
    tableRowYellow: {
      backgroundColor: "rgba(246, 172, 25, 0.6);",
    },
    tableRowHighlight: {
      backgroundColor: "#F9FF85",
    },
    tableRowHeader: {
      "& .MuiTableCell-head": {
        // width: "40px",
        width: "20%",
      },
    },
    cellCheckbox: {
      width: "40px !important",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    nowrapFirstRow: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      borderLeft: "1px solid #D4B4B4",
      "& :first-child": {
        borderTop: "3px solid #8DA1B9"
      }

    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      borderLeft: "1px solid #D4B4B4",
      "& :first-child": {
        borderTop: "3px solid #8DA1B9"
      }
    },
    leftNowrap: {
      borderLeft: "1px solid #D4B4B4",
      width: "40px"
    },
    rightNowrap: {
      borderRight: "1px solid #D4B4B4",
    },
    nowrapTechnician: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      width: "40px",
    },
    groupContainer: {
      maxHeight: 650,
    },
    titleHead: {
      fontWeight: "bold",
      fontSize: "16px"
    },
    tfoot: {
      "& td": {
        //background: "rgba(240, 242, 244)",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        borderTop: "1px solid #D4B4B4",
      },
      "& td:not(:first-child)": {
        borderLeft: "1px solid #D4B4B4",
      },
    },
    claimListContainer: {
      maxHeight: 560,
    },
    claimPaper: {
      width: "100%"
    }
  })
);