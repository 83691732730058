export const getSubmmitParamFlag = (_params: any, formList: any) => {
  let _data = JSON.parse(JSON.stringify(_params));
  let _dataList = Object.keys(_params);
  if (_dataList.length > 0) {
    let _flg = false;
    _dataList.forEach((item: any) => {
      if (
        item !== "updated_date" &&
        item !== "status" &&
        item !== "update_flg"
      ) {
        _flg = true;
      }
      if (item === "status") {
        if (
          !(
            (formList.status === "200" || formList.status === "210") &&
            _data.status === "210"
          )
        ) {
          _flg = true;
        }
      }
    });
    return _flg;
  } else {
    return false;
  }
};
