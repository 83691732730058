import React, { FC, useEffect, useState, useCallback } from "react";
import clsx from "clsx";
// route
import { useHistory, useParams } from "react-router-dom";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { ClaimDetailEndUser } from "./components/ClaimDetail.EndUser";
import { ClaimDetailToolInformation } from "./components/ClaimDetail.ToolInformation";
import { ClaimDetailParts } from "./components/ClaimDetail.Parts";
import { ClaimDetailUpload } from "./components/ClaimDetail.Upload";
import { ClaimDetailCommon } from "./components/ClaimDetail.Common";
import { ClaimDetailMemo } from "./components/ClaimDetail.Memo";
import { ClaimDetailPopup } from "./components/ClaimDetail.Popup";
import { ClaimDetailHistory } from "../../modules/components/claimHistory/ClaimHistory";
import { headCells as EndUserHeader } from "./components/ClaimDetail.EndUser.Search.Table.Thead";
import { headCells2 as EndUserHeaderDetail } from "./components/ClaimDetail.EndUser.Search.Table.Thead";
import { headCells as ToolInforHeaderDetail } from "./components/ClaimDetail.ToolInformation.Search.Table.Thead";

// UI
import { useStyles } from "./hooks/ClaimDetail.styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// methods
import {
  getApiResultStatus,
  getClaimDetailParams,
  getFormatByKey,
  handleDiffChangedData,
  diffContents,
  getNote,
  getPartsList,
} from "../../modules/common.methods";
import {
  getFileName,
  dataURLtoBlob,
} from "./methods/ClaimDetail.Upload.Methods";
// api
import api from "../../modules/api/Api";
import {
  defaultErrorValueBlank,
  TRANSPORT_MESSAGE,
} from "./constants/claimDetail.constants";
// check
import {
  checkMaxLength,
  isEmptyCheck,
  isEmptyStringOrNumber,
  emailValidation,
  checkIllegalChar,
  checkIllegalCharResult,
  isDateFormat,
  dateFormat,
  checkUmlautsCharUnicode,
} from "../../modules/hooks/UseValication";
import {
  DEFAULT_VALUE_BLANK,
  CLAIM_NO_QUERY_STRING,
} from "../../modules/constants/claim.constants";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
import { setLoborTotal } from "../../modules/methods/claim.methods";
import {
  getPriceParam,
  setPriceAPIResult,
} from "./methods/ClaimDetail.Parts.Methods";
// country List
import countries from "i18n-iso-countries";

const stateNewDefaultValue = ["0", "0", "0", "1"];
const stateEditDefaultValue = ["1", "1", "1", "1"];
const tabNewCtrollDefaultValue = [false, false, true, true];
const tabEditCtrollDefaultValue = [false, false, false, false];

export const Page: FC = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { com } = useLocale();
  const history = useHistory();
  // alert
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [startFlg, setStartFlg] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  // tab
  const [value, setValue] = useState(0);
  //
  const [claimNo, setClaimNo] = useState(
    history.location.search.indexOf(CLAIM_NO_QUERY_STRING) >= 0
      ? history.location.search.replace(CLAIM_NO_QUERY_STRING, "")
      : ""
  );
  // form page value
  const [formList, setFormList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );
  const [formErrorList, setFormErrorList] = useState(
    defaultErrorValueBlank
  ) as any;
  // data DB value
  const [dataList, setDataList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );
  const [repairHistoryList, setRepairHistoryList] = useState([]);

  // page Type=> 0:create, 1:Edit
  const [claimType, setClaimType] = useState(0);
  const [claimStatus, setClaimStatus] = useState("0");
  const illegalChar: any = process.env.REACT_APP_ILLEGAL_CHAR;

  // parts
  const [partsInitFlag, setPartsInitFlag] = useState(false);

  const [stateData, setStateData] = useState([]) as any;
  const [tabCtroll, setTabCtroll] = useState([]) as any;

  const [tempModelNo, setTempModelNo] = useState("");
  const [tempSerialNo, setTempSerialNo] = useState("");
  const [errorType, setErrorType] = useState([]) as any;
  const [clearPartsFlag, setClearPartsFlag] = useState(false);
  const [faultDescriptionFlg, setFaultDescriptionFlg] = useState(false);
  //end user infomation
  const [endUserInfoList, setEndUserInfoList] = useState([]);
  //end user infomation search basics
  const [endUserInfoListBasics, setEndUserInfoListBasics] = useState([]);
  // end user infomation flag: 0: list; 1: detail
  const [endUserInfoFlg, setEndUserInfoFlg] = useState(0);
  //end user infomation toolS
  const [endUserInfoToolsList, setEndUserInfoToolsList] = useState([]) as any;
  const [endUserInfoToolsBaseList, setEndUserInfoToolsBaseList] = useState(
    []
  ) as any;

  const [saveDraftFlag, setSaveDraftFlag] = useState(false);

  // common
  const {
    getCommonInfo,
    getCommonConfig,
    setCommonInfo,
    setCommonInfoFull,
    getClaimDetailInfo,
    setClaimDetailInfo,
    getUserInfoDZ,
  } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  let config = getCommonConfig() as any;
  let userInfoDZ = getUserInfoDZ() as any;
  let detailInfo = getClaimDetailInfo() as any;
  // loading
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  const getNewClaimNo = (): string => {
    return history.location.search.indexOf(CLAIM_NO_QUERY_STRING) >= 0
      ? history.location.search.replace(CLAIM_NO_QUERY_STRING, "")
      : "";
  };

  const getCountriesListArray = (list: any) => {
    let newList: any = [];
    const objList: any = Object.keys(list);
    for (let i = 0; i < objList.length; i++) {
      const obj = {
        id: objList[i],
        name: list[objList[i]],
      };
      newList.push(obj);
    }
    return newList;
  };

  const [modelNoChangeFlag, setModelNoChangeFlag] = useState(false);
  const [tabChangeFlag, setTabChangeFlag] = useState(false);

  const [partsDefaultList, setPartsDefaultList] = useState({
    list_all: [],
    labor_list: [],
    administrative_cost_list: [],
    freight_list: [],
  });

  // page Init
  useEffect(() => {
    const _claim_no = getNewClaimNo();
    console.log(
      "claim detail start",
      _claim_no,
      getNewClaimNo(),
      history.location.search
    );
    if (_claim_no) {
      setClaimNo(_claim_no);
      // edit tab is p&d,create tab is enduser
      setClaimType(1);
    } else {
      setClaimType(0);
    }
    if (!config.token && !userInfoDZ.customer_no) {
      apiLoading(true);
    }
    const edit_flag = sessionStorage.getItem("edit_flag");
    sessionStorage.removeItem("edit_flag");

    if (!startFlg && config.token && userInfoDZ.customer_no) {
      const _parts_list = getPartsList(config.affiliate_cd);
      setPartsDefaultList(_parts_list);

      console.log("claimDetail start", detailInfo, userInfoDZ);
      if (!edit_flag || Object.keys(detailInfo).length === 0) {
        initialize(_claim_no);
      } else {
        detailInfo.dataList.getModelMessage = true;
        setClaimDetailInfoFunc(
          detailInfo.formList,
          detailInfo.dataList,
          detailInfo.claimStatus
        );

        // 配送料の設定
        setTransportMessage(detailInfo.transportMessage);
        setEndUserInfoToolsList(detailInfo.endUserInfoToolsList);
        setEndUserInfoToolsBaseList(detailInfo.endUserInfoToolsList);
        // clear claimDetail
        setClaimDetailInfo({});
        getModelNoErrorTypeMessage(detailInfo.formList);
        // set memo message
        // ASC メッセージ保存 → memoMessage
        setMemoMessage(detailInfo.memoMessage);
      }
      setStartFlg(true);
    }
    // country pull down
    const countriesListObj: any = countries.getAlpha2Codes();
    const countriesListArray: any = getCountriesListArray(countriesListObj);
    setCountriesList(countriesListArray);
  }, [startFlg, config, userInfoDZ]);

  useEffect(() => {
    if (!claimNo || claimStatus === "100") {
      handleTabCtrol();
    }
  }, [stateData]);

  //   const changeStatusFunc = (paramClaimNo: any, paramClaimStatus: any) => {
  //     if (paramClaimNo && paramClaimStatus !== "100") {
  //       setStateData(stateEditDefaultValue);
  //       setTabCtroll(tabEditCtrollDefaultValue);
  //     } else {
  //       setStateData(stateNewDefaultValue);
  //       setTabCtroll(tabNewCtrollDefaultValue);
  //     }
  // };

  useEffect(() => {
    if (!saveDraftFlag) {
      if (claimNo && claimStatus !== "100") {
        setStateData(stateEditDefaultValue);
        setTabCtroll(tabEditCtrollDefaultValue);
      } else {
        setStateData(stateNewDefaultValue);
        setTabCtroll(tabNewCtrollDefaultValue);
      }
    } else {
      setSaveDraftFlag(false);
    }
  }, [claimStatus]);

  useEffect(() => {
    if (formList.error_type !== undefined && formList.error_type !== "") {
      for (let i = 0; i < errorType.length; i++) {
        if (formList.error_type === errorType[i].error_type) {
          if (errorType[i].fault_description) {
            setFaultDescriptionFlg(true);
            break;
          } else {
            setFaultDescriptionFlg(false);
            setFormErrorListValue("fault_description_error", "");
            break;
          }
        }
      }
    }
  }, [formList.error_type]);

  useEffect(() => {
    console.log("dataList default", dataList);
    if (!dataList.getModelMessage) {
      getModelNoErrorTypeMessage(dataList);
    }
  }, [dataList]);

  const getModelNoErrorTypeMessage = (data: any) => {
    if (
      data.serial_no !== "" &&
      data.serial_no !== "*" &&
      data.model_no !== ""
    ) {
      setTempModelNo(data.model_no);
      setTempSerialNo(data.serial_no);
      let query_parameters =
        "model_no=" +
        encodeURIComponent(data.model_no) +
        "&serial_no=" +
        encodeURIComponent(data.serial_no) +
        "&claim_no=" +
        encodeURIComponent(dataList.claim_no ? dataList.claim_no : 0) +
        "&job_no=" +
        encodeURIComponent(0);
      getRepairHistory(query_parameters);
    }
    if (data.model_no !== "") {
      getReadModelErrorTypeList(data.model_no);
    }
  };

  const getReadModelErrorTypeList = async (model_no: any) => {
    console.log("getReadModelErrorTypeList");
    apiLoading(true);
    await api
      .getReadClaimLaborAPI(encodeURIComponent(model_no), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("get Model Error Type List", code, response);
        if (getApiResultStatus(code)) {
          if (response.length > 0) {
            if (Array.isArray(response)) {
              response.map((item: any, index: number) => {
                item.index = index;
              });
            }
          }
          setErrorType(response);
          const _charges = setLoborTotal(
            response,
            dataList,
            transportMessage,
            partsDefaultList
          );
          console.log("error type list", _charges);
          setChargesValue(_charges);
        } else {
          if (code === 404) {
            setErrorType([]);
            let message = com("common.constants.messages.MS0100", {
              param1: t("txt_model_no"),
            });
            setFormErrorListValue("model_no_error", message);
          } else {
            errorMessage(response);
          }
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  const [chargesValue, setChargesValue] = useState({
    qty_amount: 0,
    parts_amount: 0,
    total_amount: 0,
  });

  const handleTabCtrol = () => {
    let arrayStateData = [...stateData];

    let arrayTabCtroll = [...tabCtroll];

    if (arrayStateData[0] === "1" || arrayStateData[0] === "2") {
      arrayTabCtroll[2] = false;
    } else {
      arrayTabCtroll[2] = true;
    }

    if (arrayStateData[1] === "1" || arrayStateData[1] === "2") {
      arrayTabCtroll[3] = false;
    } else {
      arrayTabCtroll[3] = true;
    }

    setTabCtroll(arrayTabCtroll);
  };

  // initialize
  const initialize = async (param_claimNo: string) => {
    // init
    apiLoading(true);
    let apiList = [];
    let localUserPermissions = {};
    let masterModelList: any = [];
    let masterItemList: any = [];

    //  get base item master list
    if (!commonInfo.itemBaseList || commonInfo.itemBaseList.length === 0) {
      let api1 = api
        .getBaseItemListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0305 response: ", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            masterItemList = response;
          }
        });
      apiList.push(api1);
    } else {
      masterItemList = commonInfo.itemBaseList;
    }

    // item master AM0305
    if (commonInfo.itemList.length === 0) {
      const api3 = api
        .getItemDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0305 response: ", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            masterModelList = response;
          }
        });
      apiList.push(api3);
    } else {
      masterModelList = commonInfo.itemList;
    }

    // fetch claim detail AT1702
    if (param_claimNo) {
      const api4 = getReadClaimDetailInfo(param_claimNo);
      apiList.push(api4);
    } else {
      // create default value
      setFormList((prevState: any) => ({
        ...prevState,
        ...{
          customer_no: userInfoDZ.customer_no,
          currency: userInfoDZ.currency,
        },
        ["claim_address"]: {
          ...prevState["claim_address"],
          ["country_code"]: userInfoDZ.country,
        },
      }));
    }

    Promise.all(apiList).then(async () => {
      setCommonInfoFull({
        itemList: masterModelList,
        itemBaseList: masterItemList,
        loadingFlg: false,
      });
    });
  };

  const [claimErrorFlag, setClaimErrorFlag] = useState(false);

  // fetch the detail info AT1702
  const getReadClaimDetailInfo = (claimNo: string) => {
    return api
      .getReadClaimDetailAPI(Number(claimNo), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AT1702 response: ", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
          setSaveDraftFlag(false);
          setClaimErrorFlag(true);
        } else {
          setClaimErrorFlag(false);
          // 詳細情報の取得
          const _response = Object.assign(
            JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)),
            response
          );
          // transportMessage情報の初期化
          setTransportMessage({
            weight: response.weight,
            shipping_method: response.shipping_method,
            transport_way_pick_up: response.transport_way_pick_up,
            transport_way_return: response.transport_way_return,
            transport_cost_flg: response.transport_cost_flg,
          });

          setClaimDetailInfoFunc(_response, _response, _response.status);
          getToolsInformation(_response.claim_address);
          // 権限設定
          if (
            Number(response.status) !== 100 &&
            Number(response.status) !== 300
          ) {
            const path = response.claim_no
              ? GetPrivateAppRoutePath(PathKey.SUMMARY) +
              "?claimno=" +
              response.claim_no
              : GetPrivateAppRoutePath(PathKey.SUMMARY);
            handleOnClick(path);
          }

          // memoMessage初期化
          if (response.claim_note.length > 0) {
            const _list: any = response.claim_note.filter(
              (item: any) => item.note_type === 99
            );
            const _message: any = _list.length > 0 ? _list[0].note : "";
            setMemoMessage(_message);
          }
        }
      });
  };

  const getToolsInformation = (data: any) => {
    if (data && data.enduser_no) {
      apiLoading(true);
      api
        .getTools(config, data.enduser_no)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          console.log("get end user detail tools", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setEndUserInfoToolsList(response);
            setEndUserInfoToolsBaseList(response);
          }
        });
    }
  };

  const setClaimDetailInfoFunc = (
    _formList: any,
    _dataList: any,
    _status: any
  ) => {
    // formList
    if (!_formList.claim_line) {
      _formList.claim_line = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_line)
      );
    }
    if (!_formList.claim_address) {
      _formList.claim_address = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_address)
      );
    }
    // dataList
    if (!_dataList.claim_line) {
      _dataList.claim_line = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_line)
      );
    }
    if (!_dataList.claim_address) {
      _dataList.claim_address = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_address)
      );
    }

    // set status
    if (_status === claimStatus) {
      setSaveDraftFlag(false);
    }
    setClaimStatus(_status);
    // set response data

    setDataList(JSON.parse(JSON.stringify(_dataList)));
    // set page data
    setFormList(JSON.parse(JSON.stringify(_formList)));
  };

  // error message
  const errorMessage = (response: any) => {
    apiLoading(false);
    let message = "";
    if (response.message_id) {
      message = com("common.constants.messages." + response.message_id, {
        param1: response.key
          ? com("common.constants.messages_key_name." + response.key)
          : "",
      });
    } else if (response.message) {
      if (response.status && response.status === 403) {
        message = com("common.constants.messages.MA0007");
      } else {
        message = response.message;
      }
    }
    if (message) {
      setAlertMessage(message);
      setAlertType("error");
    }
  };

  // success message
  const successMessage = (message: any) => {
    apiLoading(false);
    setAlertMessage(message);
    setAlertType("success");
  };

  // tab change
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // clear error
    clearErrorText();
    console.log("parts handleChange", newValue);
    let checkResult = true;
    let arrayStateData: any[] = [...stateData];
    if (value === 0) {
      checkResult = endUserCheck();
    } else if (value === 1) {
      checkResult = ToolInfoCheck();
    } else if (value === 2) {
      checkResult = partsCheck();
    } else if (value === 3) {
      checkResult = uploadCheck();
    }
    if (checkResult) {
      arrayStateData[value] = "1";
    } else {
      if (arrayStateData[value] === "1" || arrayStateData[value] === "2") {
        arrayStateData[value] = "2";
      } else if (newValue < value) {
        arrayStateData[value] = "2";
      }
    }

    setStateData(arrayStateData);

    if (!claimNo || claimStatus === "100") {
      if (newValue > value) {
        for (let index = value; index < newValue; index++) {
          if (arrayStateData[index] === "0" || arrayStateData[index] === "2") {
            return;
          }
        }
      }
    }

    setValue(newValue);
  };

  useEffect(() => {
    console.log("setPartsInitFlag", value, partsInitFlag);
    if (value === 2) {
      setPartsInitFlag(true);
    } else {
      setPartsInitFlag(false);
    }
  }, [value]);

  const handleUploadByCam = async (files: any, name: string) => {
    for (let i = 0; i < files.length; i++) {
      let item = dataURLtoBlob(files[i].src);
      await handleUpload([item], name);
    }
  };

  // media file upload event
  const handleUpload = (files: any, name: string) => {
    // loading
    if (files && files.length > 0) {
      const _mediaList = formList.claim_media ? formList.claim_media[name] : [];
      const fileName = getFileName(files, _mediaList);
      const file = files[0];
      apiLoading(true);
      // Get Upload signed url AT1801
      return api
        .getUploadSignedUrlByWco(encodeURIComponent(fileName), config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("AT0401 response", response);

          if (getApiResultStatus(code)) {
            const data = response;
            return api
              .getUploadFile(data.content, file)
              .then((response: any) => {
                apiLoading(false);
                // uid
                if (getApiResultStatus(response)) {
                  console.log("getUploadFile", file);
                  const params: any = {
                    media_url: data.uid,
                    media_name: fileName,
                    media_type: name === "claim_media_invoice" ? 1 : 2,
                    update_flg: "0", // 0:create,1:update,2:delete
                  };
                  // let _data: any = JSON.parse(
                  //   JSON.stringify(formList.claim_media)
                  // );
                  // _data[name].push(params);
                  setFormListValue("claim_media", params, name);
                } else {
                  errorMessage(response);
                }
              });
          } else {
            errorMessage(response);
          }
        });
    }
  };
  useEffect(() => {
    let _data = formList.claim_media;
    if (
      _data.claim_media_picture.filter((item: any) => item.update_flg !== 2)
        .length > 0 &&
      userInfoDZ.wco_picture
    ) {
      clearErrorCheck("upload_error");
    }
    if (
      _data.claim_media_invoice.filter((item: any) => item.update_flg !== 2)
        .length > 0
    ) {
      clearErrorCheck("upload_claim_media_invoice_error");
    }
  }, [formList.claim_media]);

  const photoDelete = (index: number, name: string) => {
    let list = JSON.parse(JSON.stringify(formList.claim_media));
    let item: any = list[name][index];
    if (item.sub_no) {
      item.update_flg = 2;
    } else {
      list[name].splice(index, 1);
    }
    setFormListValue("claim_media", list);
    if (
      list.claim_media_picture.filter((item: any) => item.update_flg !== 2)
        .length === 0 &&
      userInfoDZ.wco_picture
    ) {
      setFormErrorListValue(
        "upload_error",
        com("common.constants.messages.upload_error")
      );
    }

    if (
      list.claim_media_invoice.filter((item: any) => item.update_flg !== 2)
        .length === 0 &&
      documentList &&
      documentList.length === 0
    ) {
      setFormErrorListValue(
        "upload_claim_media_invoice_error",
        com("common.constants.messages.upload_error")
      );
    }
  };

  const changeNote = (note: string, note_type: number) => {
    console.log("changeNote", note, note_type);
    let list: any = JSON.parse(JSON.stringify(formList.claim_note));
    let _new_note_list: any = list.filter((item: any) => item.note_type === note_type);
    if (_new_note_list.length > 0) {
      _new_note_list[0].note = note;
      if (_new_note_list[0].update_flg !== "0") {
        _new_note_list[0].update_flg = "1";
      }
    } else {
      const item: any = {
        note_type: note_type,
        note: note,
        update_flg: "0",
      };
      list.push(item);
    }
    setFormListValue("claim_note", list);
  };

  // set data
  const setFormListValue = (key: string, value: any, subKey: string = "") => {
    // clear error
    clearErrorText();

    value = getFormatByKey(key, value, subKey);
    if (subKey !== "") {
      if (
        subKey === "claim_media_picture" ||
        subKey === "claim_media_invoice"
      ) {
        setFormList((prevState: any) => {
          let _data: any = JSON.parse(JSON.stringify(prevState));
          if (_data.claim_media && _data.claim_media[subKey]) {
            _data.claim_media[subKey].push(value);
          }
          return _data;
        });
      } else {
        setFormList((prevState: any) => ({
          ...prevState,
          [key]: { ...prevState[key], [subKey]: value },
        }));
      }
    } else {
      // error type
      if (key === "error_type") {
        const _labor_value = errorType.filter(
          (item: any) => item.error_type === value
        );
        const error_type = {
          error_type: value,
          labor_value:
            _labor_value.length > 0 ? Number(_labor_value[0].labor_value) : 1,
        };
        setFormList((prevState: any) => ({
          ...prevState,
          ...error_type,
        }));
      } else {
        setFormList((prevState: any) => ({ ...prevState, [key]: value }));
      }
    }
  };

  const [transportMessage, setTransportMessage] = useState(TRANSPORT_MESSAGE);
  const setTransportListValue = (
    key: string,
    value: any,
    subKey: string = ""
  ) => {
    value = getFormatByKey(key, value, subKey);
    if (subKey !== "") {
      setTransportMessage((prevState: any) => ({
        ...prevState,
        [key]: { ...prevState[key], [subKey]: value },
      }));
    } else {
      setTransportMessage((prevState: any) => ({ ...prevState, [key]: value }));
    }
  };

  // set error value
  const setFormErrorListValue = (key: string, value: any) => {
    setFormErrorList((prevState: any) => ({ ...prevState, [key]: value }));
  };

  // common search method
  const getRepairHistory = (query_parameters: string) => {
    setRepairHistoryList([]);
    // Read Repair Job List AT1708
    return api
      .getReadRepairHistoryListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT1708 response: ", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response.history_list && response.history_list.length > 0) {
            response.history_list.map((item: any) => {
              if (item.history_no) {
                let obj: any = {
                  history_no: item.history_no,
                  entry_date: item.entry_date,
                  customer_no: item.customer_no,
                  type_kind: item.type_kind,
                };
                newList.push(obj);
              }
            });
          }
          setRepairHistoryList(newList);
        }
      });
  };

  // Model No. check
  const onBlurOfModelNo = () => {
    console.log("onBlurOfModelNo", formList.model_no);
    let _model_no: string = formList.model_no;
    let noError = true;
    if (isEmptyCheck(_model_no)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_model_no"),
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
      console.log("noError1");
    } else if (!checkMaxLength(_model_no, 15)) {
      let message = com("common.constants.messages.MS0002", {
        param1: t("txt_model_no"),
        param2: 15,
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
      console.log("noError2");
    } else if (!masterExitOfModelNo(_model_no)) {
      let message = com("common.constants.messages.MS0004", {
        param1: t("txt_model_no"),
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
      console.log("noError3");
    }
    console.log("noError", noError);
    if (noError) {
      setFormErrorListValue("model_no_error", "");
      setFormErrorListValue("serial_no_error", "");
    }
    return noError;
  };

  // Serial No. check
  const onBlurOfSerialNo = () => {
    let noError = true;
    if (isEmptyCheck(formList.serial_no)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_serial_no"),
      });
      setFormErrorListValue("serial_no_error", message);
      noError = false;
    } else if (!checkMaxLength(formList.serial_no, 15)) {
      let message = com("common.constants.messages.MS0002", {
        param1: t("txt_serial_no"),
        param2: 15,
      });
      setFormErrorListValue("serial_no_error", message);
      noError = false;
    }
    if (noError) {
      setFormErrorListValue("serial_no_error", "");
    }
    return noError;
  };

  const masterExitOfModelNo = (value: string) => {
    let isExisted = false;
    let items = commonInfo.itemList;

    if (value && value !== "") {
      if (items.length > 0) {
        for (let index in items) {
          if (
            items[index].model_no.toString().toUpperCase() ===
            value.toString().toUpperCase()
          ) {
            isExisted = true;
            let modelNoByDB = items[index].model_no.toString();
            setFormListValue("model_no", modelNoByDB);
            break;
          }
        }
      } else {
        // TODO call api to check whether existed
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  const onBlurOfpurchasedDate = () => {
    let noError = true;
    setFormErrorListValue("purchased_date_error", "");
    if (isDateFormat(formList.purchased_date)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_purchased_date"),
      });
      setFormErrorListValue("purchased_date_error", message);
      noError = false;
    } else if (formList.purchased_date > dateFormat("yyyy-MM-dd", new Date())) {
      let message = com("common.constants.messages.MS0005", {
        startDate: t("txt_purchased_date"),
        endDate: t("txt_today"),
      });
      setFormErrorListValue("purchased_date_error", message);
      noError = false;
    } else {
      noError = dateCheck();
    }
    return noError;
  };

  const onBlurOfReceivedDate = () => {
    let noError = true;
    setFormErrorListValue("received_date_error", "");
    if (isDateFormat(formList.received_date)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_received_date"),
      });
      setFormErrorListValue("received_date_error", message);
      noError = false;
    } else if (formList.received_date > dateFormat("yyyy-MM-dd", new Date())) {
      let message = com("common.constants.messages.MS0005", {
        startDate: t("txt_received_date"),
        endDate: t("txt_today"),
      });
      setFormErrorListValue("received_date_error", message);
      noError = false;
    } else {
      noError = dateCheck();
    }
    return noError;
  };

  const onBlurOfRepairDate = () => {
    let noError = true;
    setFormErrorListValue("repair_date_error", "");
    if (isDateFormat(formList.repair_date)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_repair_date"),
      });
      setFormErrorListValue("repair_date_error", message);
      noError = false;
    } else if (formList.repair_date > dateFormat("yyyy-MM-dd", new Date())) {
      let message = com("common.constants.messages.MS0005", {
        startDate: t("txt_repair_date"),
        endDate: t("txt_today"),
      });
      setFormErrorListValue("repair_date_error", message);
      noError = false;
    } else {
      noError = dateCheck();
    }
    return noError;
  };

  const dateCheck = () => {
    let noError = true;
    setFormErrorListValue("purchased_date_error", "");
    setFormErrorListValue("received_date_error", "");
    setFormErrorListValue("repair_date_error", "");
    if (
      !(
        isDateFormat(formList.purchased_date) &&
        isDateFormat(formList.received_date) &&
        isDateFormat(formList.repair_date)
      )
    ) {
      if (formList.purchased_date > formList.received_date) {
        let message = com("common.constants.messages.MS0005", {
          startDate: t("txt_purchased_date"),
          endDate: t("txt_received_date"),
        });
        setFormErrorListValue("purchased_date_error", message);
        noError = false;
      } else if (formList.purchased_date > formList.repair_date) {
        let message = com("common.constants.messages.MS0005", {
          startDate: t("txt_purchased_date"),
          endDate: t("txt_repair_date"),
        });
        setFormErrorListValue("purchased_date_error", message);
        noError = false;
      } else if (formList.received_date > formList.repair_date) {
        let message = com("common.constants.messages.MS0005", {
          startDate: t("txt_received_date"),
          endDate: t("txt_repair_date"),
        });
        setFormErrorListValue("received_date_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurFaultDescription = () => {
    let noError = true;
    setFormErrorListValue("fault_description_error", "");
    if (faultDescriptionFlg) {
      if (isEmptyStringOrNumber(getNote(formList.claim_note,10))) {
        let message = com("common.constants.messages.MS0001", {
          param1: t("txt_fault_description"),
        });
        setFormErrorListValue("fault_description_error", message);
        noError = false;
      }
    } else {
      noError = true;
    }
    return noError;
  };

  const [partsRefreshFlag, setPartsRefreshFlag] = useState(false);
  const [toolRefreshFlag, setToolRefreshFlag] = useState(false);

  const handleRefresh = () => {
    setFormErrorList(defaultErrorValueBlank);
    setFormList(DEFAULT_VALUE_BLANK);
    initialize(claimNo);

    setPartsRefreshFlag(true);
    setToolRefreshFlag(true);

    setSaveDraftFlag(false);
    if (value === 2) {
      setPartsInitFlag(true);
    }

    setValue(0);

    // setPartsRefreshFlag(true);
    // setValue(0);
    // if (claimType === 0) {
    //   setPartsInitFlag(false);
    //   setFormList(JSON.parse(JSON.stringify(dataList)));
    // } else {
    //   setPartsInitFlag(true);
    //   getReadClaimDetailInfo(claimNo);
    // }

    // // parts
    // setClearPartsFlag(true);

    // ASC メッセージ追加
    setMemoMessage("");
  };

  // save button
  const [saveWindowFlag, setSaveWindowFlag] = useState(false);
  const [saveClaimNo, setSaveClaimNo] = useState("");
  const [errorMessageText, setErrorMessageText] = useState("");
  const handleToSave = () => {
    console.log("handleToSave start");
    const _flg1 = diffMethods();
    let arrayStateData: any[] = [...stateData];
    const _errorList: any = Object.keys(formErrorList);
    const _errorMessageList =
      _errorList.length > 0
        ? _errorList.filter((item: string) => formErrorList[item] !== "")
        : [];
    const _flg2 =
      _errorMessageList.length > 0 ||
      (errorRegisterList.length > 0
        ? errorRegisterList.filter(
          (item: any) => item.type !== "6" && item.type !== "8"
        ).length
        : 0) ||
      (itemNoErrorList.length > 0
        ? itemNoErrorList.filter(
          (item: any) => item.type !== "6" && item.type !== "8"
        ).length
        : 0);
    console.log("handleToSave", _flg1, _flg2, formErrorList, _errorList);

    if (_flg1) {
      const _message = com("common.constants.messages.MS0117");
      setErrorMessageText(_message);
    } else if (_flg2) {
      const _message = com("common.constants.messages.save_error");
      setErrorMessageText(_message);
    } else {
      setSaveDraftFlag(true);
      arrayStateData[3] = "1";
      setStateData(arrayStateData);
    }
  };

  useEffect(() => {
    if (saveDraftFlag) {
      setErrorMessageText("");
      handleToSaveFunc();
    }
  }, [saveDraftFlag]);

  const handleToSaveFunc = () => {
    console.log("handleToSaveFunc start", memoMessage);
    apiLoading(true);
    const _chargesValue = setLoborTotal(
      errorType,
      formList,
      transportMessage,
      partsDefaultList
    );

    if (formList.claim_no) {
      // patch
      // 一時保存：Staus変更なし
      // ASC メッセージ追加 → memoMessage
      const _params = handleDiffChangedData(
        JSON.parse(JSON.stringify(formList)),
        dataList,
        dataList.status,
        memoMessage,
        transportMessage,
        _chargesValue,
        "save"
      );
      console.log("save patch params", _params);
      api
        .updateClaimAPI(formList.claim_no, config, _params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            setSaveDraftFlag(false);
            errorMessage(response);
          } else {
            successMessage(com("common.constants.messages.MS0058"));
            setItemNoErrorList([]);
            getReadClaimDetailInfo(formList.claim_no);
          }
        });
    } else {
      // post
      // Claim自体が保存されたことがなければDraftになります。
      const _params = getClaimDetailParams(
        formList,
        "100",
        transportMessage,
        _chargesValue
      );
      console.log("save post params", _params);
      api
        .createClaimAPI(config, _params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
            setSaveDraftFlag(false);
          } else {
            console.log("handleToSubmmit", response);
            setSaveWindowFlag(true);
            setSaveClaimNo(response.claim_no);
            getReadClaimDetailInfo(response.claim_no);
            setItemNoErrorList([]);
          }
        });
    }
  };

  const handleCloseSaveChanges = () => {
    setSaveWindowFlag(false);
    handleCloseSave();
  };
  const handleCloseSave = () => {
    setSaveWindowFlag(false);
    // change
    const path =
      GetPrivateAppRoutePath(PathKey.CLAIMDETAIL) + "?claimno=" + saveClaimNo;
    handleOnClick(path);
    setClaimType(1);
    setClaimNo(saveClaimNo);
    getReadClaimDetailInfo(saveClaimNo);
  };

  // summary button
  const handleToSummary = async () => {
    console.log("handleToSummary");
    let checkResult = false;
    let arrayStateData: any[] = [...stateData];

    checkResult = endUserCheck();
    if (!checkResult) {
      arrayStateData[0] = "2";
      setValue(0);
      setStateData(arrayStateData);
      return;
    } else {
      arrayStateData[0] = "1";
    }

    checkResult = ToolInfoCheck();
    if (!checkResult) {
      arrayStateData[1] = "2";
      setValue(1);
      setStateData(arrayStateData);
      return;
    } else {
      arrayStateData[1] = "1";
    }

    checkResult = partsCheck();
    if (!checkResult) {
      arrayStateData[2] = "2";
      setValue(2);
      setStateData(arrayStateData);
      return;
    } else {
      arrayStateData[2] = "1";
    }

    checkResult = uploadCheck();
    if (!checkResult) {
      arrayStateData[3] = "2";
      setValue(3);
      setStateData(arrayStateData);
      return;
    } else {
      arrayStateData[3] = "1";
    }

    checkResult = memoCheck();
    if (!checkResult) {
      return;
    }

    setStateData(arrayStateData);
    let { _price_flag, newFormList } = await handleGetPrice();
    // get parts net price
    if (!_price_flag) {
      arrayStateData[2] = "2";
      setValue(2);
      setStateData(arrayStateData);
      return;
    }

    console.log("handleGetPrice func complete", formList, newFormList);

    const _newClaimLineList = Object.assign({}, formList, newFormList);
    console.log("handleGetPrice newClaimLineList", _newClaimLineList);
    // ASC メッセージ追加 → memoMessage
    const _param = {
      formList: JSON.parse(JSON.stringify(_newClaimLineList)),
      claimType: claimType,
      claimStatus: claimStatus,
      dataList: JSON.parse(JSON.stringify(dataList)),
      transportMessage: transportMessage,
      endUserInfoToolsList: endUserInfoToolsList,
      memoMessage: memoMessage,
    };
    setClaimDetailInfo(_param);
    const path = formList.claim_no
      ? GetPrivateAppRoutePath(PathKey.SUMMARY) +
      "?claimno=" +
      formList.claim_no
      : GetPrivateAppRoutePath(PathKey.SUMMARY);
    handleOnClick(path);
  };
  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // end user Tab check
  const endUserCheck = () => {
    // customer Type require
    let _flg1: boolean = valueValicationCheck(
      formList.claim_address.customer_type,
      "txt_customer_type",
      "customer_type_error"
    );

    let _flg2: boolean = false;
    if (Number(formList.claim_address.customer_type) === 1) {
      /**
       * Customer Type = Commercial
       * company Name require
       */
      _flg2 = valueValicationCheck(
        formList.claim_address.company_name,
        "txt_company_name",
        "company_name_error"
      );
      clearErrorCheck("last_name_error");
    } else if (Number(formList.claim_address.customer_type) === 2) {
      /**
       * Customer Type = Private
       * Last Name require
       */
      _flg2 = valueValicationCheck(
        formList.claim_address.last_name,
        "txt_last_name",
        "last_name_error"
      );
      clearErrorCheck("company_name_error");
    }

    // mail address check
    let _flg3: boolean = formList.claim_address.mail_address
      ? mailValicationCheack(
        formList.claim_address.mail_address,
        "txt_email",
        "mail_address_error"
      )
      : true;

    // postal code check
    let _flg4: boolean = valueValicationCheck(
      formList.claim_address.postal_code,
      "txt_postal_code",
      "postal_code_error"
    );

    let _flg5 = formList.privacy_check_flg;
    privacyCheckFlgFunc();

    const _flg = _flg1 && _flg2 && _flg3 && _flg4 && _flg5;
    return _flg;
  };

  // Parts drawing cost tab check
  const [errorRegisterList, setErrorRegisterList] = useState([]);
  const [itemNoErrorList, setItemNoErrorList] = useState([]);

  const partsCheck = () => {
    console.log("partsCheck");
    let _flg1: boolean = formList.attention_check_flg;
    if (_flg1) {
      clearErrorCheck("attention_check_flg_error");
    } else {
      const message: any = com("common.constants.messages.MS0121", {
        param1: t("attention_check_flg"),
      });
      setFormErrorListValue("attention_check_flg_error", message);
    }

    let _flg2: boolean = true;
    let _flg3: boolean = true;
    if (formList.claim_line.length > 0) {
      formList.claim_line.forEach((item: any) => {
        // transport way の checkboxがチェックしてない場合は、
        const _list: any = partsDefaultList.list_all;
        if (_list.includes(item.item_no) && !item.order_qty) {
          _flg3 = false;
        }
      });
    } else {
      // _flg2 = false;
    }

    if (_flg2) {
      clearErrorCheck("claim_line_error");
    } else {
      const message: any = com("common.constants.messages.MS0001", {
        param1: t("claim_lines"),
      });
      setFormErrorListValue("claim_line_error", message);
    }

    if (_flg3) {
      clearErrorCheck("transport_message_error");
    } else {
      setFormErrorListValue(
        "transport_message_error",
        com("common.constants.messages.transport_way_error")
      );
    }

    let _flg4: boolean = true;
    const _freightLists: any = partsDefaultList.freight_list;
    const _freightList = formList.claim_line.filter((item: any) =>
      _freightLists.includes(item.item_no)
    );

    if (
      (transportMessage.transport_cost_flg && _freightList.length === 0) ||
      (!transportMessage.transport_cost_flg && _freightList.length > 0)
    ) {
      _flg4 = false;
    }
    if (_flg4) {
      clearErrorCheck("transport_message_error");
    } else {
      setFormErrorListValue(
        "transport_message_error",
        com("common.constants.messages.transport_cost_error")
      );
    }

    let _flg5: boolean = true;
    if (
      transportMessage.transport_cost_flg &&
      !(
        transportMessage.transport_way_pick_up ||
        transportMessage.transport_way_return
      )
    ) {
      _flg5 = false;
    }

    if (_flg5) {
      clearErrorCheck("transport_message_error");
    } else {
      setFormErrorListValue(
        "transport_message_error",
        com("common.constants.messages.transport_way_error")
      );
    }

    // RegisterやItem No.入力チェック
    let _flg6: boolean = true;
    const _itemNoErrorListNumber =
      itemNoErrorList.length > 0
        ? itemNoErrorList.filter(
          (item: any) => item.type !== "6" && item.type !== "8"
        ).length
        : 0;
    const _errorRegisterListNumber =
      errorRegisterList.length > 0
        ? errorRegisterList.filter(
          (item: any) => item.type !== "6" && item.type !== "8"
        ).length
        : 0;
    _flg6 = _itemNoErrorListNumber === 0 && _errorRegisterListNumber === 0;
    console.log(
      "P&D check",
      _flg1,
      ",",
      _flg2,
      ",",
      _flg3,
      ",",
      _flg4,
      ",",
      _flg6
    );
    return _flg1 && _flg2 && _flg3 && _flg4 && _flg5 && _flg6;
  };

  const uploadCheck = () => {
    let _flg1: boolean = true;
    let _flg1_claim_media_invoice: boolean = true;
    if (userInfoDZ.wco_picture) {
      _flg1 =
        formList.claim_media.claim_media_picture.filter(
          (item: any) => item.update_flg !== 2
        ).length > 0;
    }

    if (_flg1) {
      clearErrorCheck("upload_error");
    } else {
      setFormErrorListValue(
        "upload_error",
        com("common.constants.messages.upload_error")
      );
    }

    _flg1_claim_media_invoice =
      formList.claim_media.claim_media_invoice.filter(
        (item: any) => item.update_flg !== 2
      ).length > 0 ||
      (documentList && documentList.length > 0);

    if (_flg1_claim_media_invoice) {
      clearErrorCheck("upload_claim_media_invoice_error");
    } else {
      setFormErrorListValue(
        "upload_claim_media_invoice_error",
        com("common.constants.messages.upload_error")
      );
    }
    return _flg1 && _flg1_claim_media_invoice;
  };

  const [memoErrorMessage, setMemoErrorMessage] = useState("");
  const memoCheck = () => {
    let _flg = true;
    if (Number(claimStatus) > 100) {
      _flg = memoMessage !== "";
      if (_flg) {
        setMemoErrorMessage("");
      } else {
        setMemoErrorMessage(
          com("common.constants.messages.MS0001", {
            param1: t("detail_memo"),
          })
        );
      }
    }
    return _flg;
  };

  const ToolInfoCheck = () => {
    // Model No check
    let _flg1: boolean = onBlurOfModelNo();
    // Serial No check
    let _flg2: boolean = onBlurOfSerialNo();
    // Condition Of Tool check
    let _flg3: boolean = valueValicationCheck(
      formList.condition_of_tool,
      "txt_condition_of_tool",
      "condition_of_tool_error"
    );
    // purchased Date check
    let _flg4: boolean = onBlurOfpurchasedDate();
    // Received Date check
    let _flg5: boolean = onBlurOfReceivedDate();
    // Repair Date check
    let _flg6: boolean = onBlurOfRepairDate();
    // Error Type check
    let _flg7: boolean = valueValicationCheck(
      formList.error_type,
      "txt_error_type",
      "error_type_error"
    );
    // Guarantee Type check
    let _flg8: boolean = valueValicationCheck(
      formList.guarantee_type,
      "txt_guarantee_type",
      "guarantee_type_error"
    );
    // Fault Description check
    let _flg9: boolean = onBlurFaultDescription();

    const _flg =
      _flg1 &&
      _flg2 &&
      _flg3 &&
      _flg4 &&
      _flg5 &&
      _flg6 &&
      _flg7 &&
      _flg8 &&
      _flg9;
    return _flg;
  };

  const endUserNoValicationCheck = (value: any = "") => {
    return true;
  };

  const valueValicationCheck = (
    value: any = "",
    name: any = "",
    error_name: any = ""
  ) => {
    console.log("valueValicationCheck", value);
    if (isEmptyStringOrNumber(value)) {
      const message: any = com("common.constants.messages.MS0001", {
        param1: t(name),
      });
      setFormErrorListValue(error_name, message);
      return false;
    } else {
      clearErrorCheck(error_name);
      return true;
    }
  };

  const mailValicationCheack = (
    value: any,
    name: any = "",
    error_name: any = ""
  ) => {
    let _flg = true;

    if (value) {
      if (checkUmlautsCharUnicode(value)) {
        let message = com("common.constants.messages.MS0122");
        setFormErrorListValue(error_name, message);
        _flg = false;
      } else if (!emailValidation(value)) {
        let message = com("common.constants.messages.MS0040");
        setFormErrorListValue(error_name, message);
        _flg = false;
      }
      if (checkIllegalChar(value)) {
        let message = com("common.constants.messages.MS0093", {
          param1: checkIllegalCharResult(value),
        });
        setFormErrorListValue(error_name, message);
        _flg = false;
      }
    }

    if (_flg) {
      clearErrorCheck(error_name);
    }

    return _flg;
  };

  const clearErrorCheck = (error_name: any) => {
    setFormErrorListValue(error_name, "");
  };

  const handleNext = async () => {
    // clear error
    clearErrorText();
    let checkResult: any = false;
    let arrayStateData: any[] = [...stateData];

    if (value === 0) {
      checkResult = endUserCheck();
      // checkResult = true;
    } else if (value === 1) {
      checkResult = ToolInfoCheck();
    } else if (value === 2) {
      checkResult = partsCheck();
      // get parts net price
      if (checkResult) {
        const { _price_flag } = await handleGetPrice();
        checkResult = _price_flag;
      }
    } else if (value === 3) {
      checkResult = uploadCheck();
      if (checkResult) {
        if (Number(claimStatus) > 100) {
        } else {
          handleToSummary();
          return;
        }
      }
    } else if (value === 4) {
      handleToSummary();
      return;
    }

    if (checkResult) {
      arrayStateData[value] = "1";
      setValue(value + 1);
    } else {
      if (arrayStateData[value] === "1") {
        arrayStateData[value] = "2";
      }
    }
    setStateData(arrayStateData);
  };

  const handlePrev = () => {
    // clear error
    clearErrorText();
    let checkResult = false;
    let arrayStateData: any[] = [...stateData];

    if (value === 0) {
      checkResult = true;
    } else if (value === 1) {
      checkResult = ToolInfoCheck();
    } else if (value === 2) {
      checkResult = partsCheck();
    } else if (value === 3) {
      checkResult = uploadCheck();
    } else if (value === 4) {
      checkResult = true;
    }

    if (checkResult) {
      arrayStateData[value] = "1";
    } else {
      arrayStateData[value] = "2";
    }
    setStateData(arrayStateData);
    setValue(value - 1);
  };

  const setRegisterData = (list: any) => {
    setFormListValue("claim_line", list);
  };

  const fileDownload = (row: any) => {
    apiLoading(true);
    // Get Download signed url AT0402
    api
      .getDownloadSignedUrlByWco(formList.claim_no, row.sub_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          apiLoading(false);
          const url = response.content;
          let w = window.open();
          if (w) {
            w.location.href = url;
          }
        }
      });
  };

  const setForListFull = (newList: any) => {
    console.log("handleGetPrice setForListFull", newList);
    setFormList((prevState: any) => ({
      ...prevState,
      ...newList,
    }));
  };
  // return button
  const [returnWindowFlag, setReturnWindowFlag] = useState(false);
  const handleToRetrun = () => {
    let _flg = diffMethods();
    if (_flg) {
      handleCloseReturn();
    } else {
      // popup
      setReturnWindowFlag(true);
    }
  };
  const handleCloseReturnChanges = () => {
    setReturnWindowFlag(false);
  };
  const handleCloseReturn = () => {
    // change
    const path = GetPrivateAppRoutePath(PathKey.CLAIM);
    handleOnClick(path);
  };

  // delete button
  const [deleteWindowFlag, setDeleteWindowFlag] = useState(false);
  const [deleteSuccessWindowFlag, setDeleteSuccessWindowFlag] = useState(false);
  const handleToDelete = () => {
    setDeleteWindowFlag(true);
  };
  const handleCloseDeleteChanges = () => {
    setDeleteWindowFlag(false);
  };
  const handleCloseDelete = () => {
    if (formList.claim_no) {
      const params = {
        updated_date: formList.updated_date,
      };
      apiLoading(true);
      api
        .deleteClaimAPI(config, formList.claim_no, params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          setDeleteWindowFlag(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setDeleteSuccessWindowFlag(true);
          }
        });
    }
  };
  const handleCloseDeleteSuccessChanges = () => {
    setDeleteSuccessWindowFlag(false);
    handleCloseReturn();
  };

  const diffMethods = () => {
    const _claim_no = getNewClaimNo();
    const _chargesValue = setLoborTotal(
      errorType,
      formList,
      transportMessage,
      partsDefaultList
    );

    // memoMessage保存の仕様追加
    return diffContents(
      _claim_no,
      formList,
      dataList,
      memoMessage,
      transportMessage,
      _chargesValue
    );
  };

  // button click event of end user search
  const [CSearchOpen, setCSearchOpen] = useState(false);
  const [searchStatus, setSearchStatus] = useState("list");
  const handleCSearch = () => {
    console.log("handleEndUserDetailSearch handleCSearch start");
    apiLoading(true);
    api
      .getEndusersOverview(config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("get end user info", response);
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setCSearchOpen(true);
          setEndUserInfoList(response);
          setEndUserInfoListBasics(response);
          setSearchStatus("list");
        }
      });
  };

  const handleEndUserDetailSearch = (enduser_no: any) => {
    console.log(
      "handleEndUserDetailSearch api detail and tool start",
      enduser_no
    );
    let endUserInfoList: any = [];
    let endUserdetailValue: any = [];

    let getEndusersDetailApi;
    let getToolsApi;
    apiLoading(true);
    getEndusersDetailApi = api
      .getEndusersDetail(config, enduser_no)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        console.log("get end user detail", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setEndUserInfoFlg(1);
          if (response.contacts.length > 0) {
            endUserdetailValue = JSON.parse(JSON.stringify(response));
            for (let key in endUserdetailValue.contacts) {
              let item = endUserdetailValue.contacts[key];
              item.enduser_no = endUserdetailValue.enduser_no;
              item.customer_type = endUserdetailValue.customer_type;
              item.company_name = endUserdetailValue.company_name;
              item.zipcode = endUserdetailValue.zipcode;
              item.address = endUserdetailValue.address;
              item.city = endUserdetailValue.city;
              item.country = endUserdetailValue.country;
              endUserInfoList.push(item);
            }
          } else {
            let item = JSON.parse(JSON.stringify(response));
            endUserInfoList.push(item);
          }
          setSearchStatus("detail");
          setEndUserInfoList(endUserInfoList);
          setEndUserInfoListBasics(endUserInfoList);
          if (response.contacts.length === 1) {
            handleSend(endUserInfoList[0]);
          }
        }
      });

    getToolsApi = api
      .getTools(config, enduser_no)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("get end user detail tools", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setEndUserInfoToolsList(response);
          setEndUserInfoToolsBaseList(response);
        }
      });

    Promise.all([getEndusersDetailApi, getToolsApi]).then(() => {
      apiLoading(false);
    });
  };

  const handleSend = (row: any) => {
    setEndUserInfoFlg(0);
    setCSearchOpen(false);

    setFormList((prevState: any) => ({
      ...prevState,
      claim_address: {
        ...prevState.claim_address,
        customer_type:
          row.customer_type === "company"
            ? 1
            : row.customer_type === "private"
              ? 2
              : "",
        enduser_no: row.enduser_no ? row.enduser_no : "",
        company_name: row.company_name ? row.company_name : "",
        first_name: row.first_name ? row.first_name : "",
        last_name: row.last_name ? row.last_name : "",
        address_line_1: row.address ? row.address : "",
        city: row.city ? row.city : "",
        mail_address: row.email ? row.email : "",
        tel_no: row.phone_no ? row.phone_no : "",
        postal_code: row.zipcode ? row.zipcode : "",
        country_code: row.country
          ? row.country.toLowerCase()
          : userInfoDZ.country && userInfoDZ.country !== ""
            ? userInfoDZ.country.toLowerCase()
            : "",
      },
    }));
  };

  const handleRefreshCSearch = () => {
    setEndUserInfoFlg(0);
    handleCSearch();
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
    setEndUserInfoFlg(0);
  };

  const handleChangeKey = (value: any) => {
    searchResult(value);
  };

  const searchResult = (key: any) => {
    let list = endUserInfoListBasics;
    let headers;
    if (searchStatus === "list") {
      headers = EndUserHeader;
    } else if (searchStatus === "detail") {
      headers = EndUserHeaderDetail;
    }
    const newList: any = SearchResultMethods(key, list, headers);
    setEndUserInfoList(newList);
  };

  const SearchResultMethods = (key: string, list: any[], header: any = []) => {
    let text: any;
    if (key && list && list.length > 0) {
      let newList = JSON.parse(JSON.stringify(list));
      key = key.toString().toUpperCase();
      newList = newList.filter((item: any, index: number) => {
        let flg = false;
        for (let i = 0; i < header.length; i++) {
          const searchItem = header[i];
          if (
            item[searchItem.id] !== undefined &&
            item[searchItem.id] !== null
          ) {
            text = item[searchItem.id].toString().toUpperCase();
          }
          if (text !== undefined && text.indexOf(key) !== -1) {
            if (!flg) {
              flg = true;
            }
          }
        }
        return flg;
      });
      return newList;
    } else {
      if (list && list.length > 0) {
        return JSON.parse(JSON.stringify(list));
      } else {
        return [];
      }
    }
  };

  // cancel button
  const [cancelWindowFlag, setCancelWindowFlag] = useState(false);
  const [cancelSuccessWindowFlag, setCancelSuccessWindowFlag] = useState(false);
  const handleToCancel = () => {
    setCancelWindowFlag(true);
  };
  const handleCloseCancelChanges = () => {
    setCancelWindowFlag(false);
  };
  const handleCloseCancel = () => {
    if (formList.claim_no) {
      const params = {
        status: "600",
        update_flg: "1",
        updated_date: formList.updated_date,
      };
      apiLoading(true);
      api
        .updateClaimAPI(formList.claim_no, config, params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          setCancelWindowFlag(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setCancelSuccessWindowFlag(true);
          }
        });
    }
  };
  const handleCloseCancelSuccessChanges = () => {
    setCancelSuccessWindowFlag(false);
    handleCloseReturn();
  };

  // return to summary
  const [returnSummaryWindowFlag, setReturnSummaryWindowFlag] = useState(false);
  const handleToRetrunSummary = () => {
    let _flg = diffMethods();
    if (_flg) {
      handleCloseReturnSummary();
    } else {
      // popup
      setReturnSummaryWindowFlag(true);
    }
  };
  const handleCloseReturnSummaryChanges = () => {
    setReturnSummaryWindowFlag(false);
  };
  const handleCloseReturnSummary = () => {
    // change
    const path =
      GetPrivateAppRoutePath(PathKey.SUMMARY) + "?claimno=" + formList.claim_no;
    handleOnClick(path);
  };

  const [CSearchOpenTool, setCSearchOpenTool] = useState(false);
  const [toolInfoHistory, setToolInfoHistory] = useState(false);
  const handleSendTool = (row: any) => {
    setCSearchOpenTool(false);
    console.log("handleSendTool", row);
    let _tool = {
      model_no: row.base_model_no,
      serial_no: row.serial_no,
      purchased_date: row.purchase_date,
      error_type: null,
      unique_key: row.unique_key,
      guarantee_type: row.guarantee_type ? 2 : 1,
    };
    setFormList((prevState: any) => ({
      ...prevState,
      ..._tool,
    }));

    if (
      row &&
      row.serial_no !== "" &&
      row.serial_no !== "*" &&
      row.base_model_no !== ""
    ) {
      setTempModelNo(row.base_model_no);
      setTempSerialNo(row.serial_no);
      let query_parameters =
        "model_no=" +
        encodeURIComponent(row.base_model_no) +
        "&serial_no=" +
        encodeURIComponent(row.serial_no) +
        "&claim_no=" +
        encodeURIComponent(dataList.claim_no ? dataList.claim_no : 0) +
        "&job_no=" +
        encodeURIComponent(0);
      getRepairHistory(query_parameters);
    }
    if (row && row.base_model_no !== "") {
      getReadModelErrorTypeList(row.base_model_no);
    }

    setToolInfoHistory(true);
    setModelNoChangeFlag(true);
  };

  // my models pupup search
  const handleChangeKeyTool = (key: any) => {
    let list = endUserInfoToolsBaseList;
    let headers = ToolInforHeaderDetail;
    const newList: any = SearchResultMethods(key, list, headers);
    setEndUserInfoToolsList(newList);
  };

  const handleRefreshTool = () => {
    console.log("handleRefreshTool", formList.claim_address);
    getToolsInformation(formList.claim_address);
  };

  // message box
  const [total, setTotal] = useState("");
  const handleGetPrice = async () => {
    console.log("handleGetPrice");
    setItemNoErrorList([]);
    let _newList: any = [];
    let _flg1 = true;
    let _success_flag = true;
    const registerData: any = formList.claim_line;
    registerData.forEach((item: any) => {
      const _itemList = _newList.filter(
        (subItem: any) => item.item_no === subItem.item_no
      );
      if (_itemList.length > 0) {
        const qty = _itemList.reduce((qty: number, subItem: any) => {
          qty += subItem.order_qty;
          return qty;
        }, 0);
        _itemList[0].order_qty = qty;
      } else {
        _newList.push(item);
      }
      // transport way の checkboxがチェックしてない場合は、
      const _list: any = partsDefaultList.list_all;
      if (_list.includes(item.item_no) && !item.order_qty) {
        _flg1 = false;
      }
    });
    console.log("handleGetPrice _flg1", _flg1);

    let _newFormList: any = {};
    if (_flg1) {
      apiLoading(true);
      clearErrorCheck("transport_message_error2");
      const { params, lineNoList } = getPriceParam(
        dataList.claim_line,
        registerData,
        userInfoDZ.customer_no
      );
      console.log("handleGetPrice _params", params, lineNoList);
      // AM2201
      await api
        .getReadPriceAPIforSimulation(params, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("handleGetPrice api success");
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            let _total: any = 0;
            // setFormListValue("partsQtyChangeFlag", false);
            clearErrorCheck("claim_line_error");
            if (response && response.lines && response.lines.length > 0) {
              const { _total, newList, resultList } = setPriceAPIResult(
                response,
                lineNoList,
                registerData
              );

              // 値の設定
              setTotal(String(_total));
              console.log("handleGetPrice api success", newList);
              _newFormList = {
                claim_line: resultList,
                currency: resultList[0].currency,
              };
              setForListFull(_newFormList);
            }
          }
        });
    } else {
      _success_flag = false;
      setFormErrorListValue(
        "transport_message_error2",
        com("common.constants.messages.transport_way_error")
      );
    }
    console.log("handleGetPrice _success_flag", _success_flag, _newFormList);

    return {
      _price_flag: _success_flag,
      newFormList: _newFormList,
    };
  };

  const privacyCheckFlgFunc = () => {
    if (formList.privacy_check_flg) {
      clearErrorCheck("privacy_check_flg_error");
    } else {
      const message: any = com(
        "common.constants.messages.privacy_notice_error"
      );
      setFormErrorListValue("privacy_check_flg_error", message);
    }
  };

  const setLaborItemInfo = (_error_type: any) => {
    let _labor_value = "";

    // get labor value
    if (
      errorType.length > 0 &&
      _error_type !== undefined &&
      _error_type !== null &&
      _error_type !== ""
    ) {
      let _new_error_type_list = errorType.filter(
        (item: any) => item.error_type === _error_type
      );
      if (_new_error_type_list.length > 0) {
        _labor_value = _new_error_type_list[0].labor_value;
      }
    }

    // set labor item
    if (formList.claim_line.length > 0) {
      let newList = JSON.parse(JSON.stringify(formList.claim_line));
      const _labor_list: any = partsDefaultList.labor_list;
      let _newList = newList.filter((item: any) =>
        _labor_list.includes(item.item_no)
      );
      if (_newList.length > 0) {
        _newList[0].order_qty = _labor_value ? _labor_value : 1;
        setFormListValue("claim_line", newList);
      }
    }
  };

  const clearErrorText = () => {
    // save error
    if (errorMessageText !== "") {
      setErrorMessageText("");
    }
  };

  const fileDownloadFile = (message: any) => {
    console.log("claim detail file", message);
    api
      .getFileDZ(config, message.link)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("claim detail file response", response);
        if (!getApiResultStatus(code)) {
          errorMessage({ message_id: "MA0003" });
        } else {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          a.href = url;
          a.download = message.filename;
          a.click();
        }
      });
  };

  // memo
  // ASC メッセージ追加 → memoMessage
  const [memoMessage, setMemoMessage] = useState("");
  const changeMemoMessage = (newData: any) => {
    setMemoMessage(newData);
  };

  // uploadのDoucument
  const [documentList, setDocumentList] = useState([{ filename: "test1" }]);
  console.log("test1234", documentList);
  useEffect(() => {
    let _list = [];
    if (endUserInfoToolsList.length > 0) {
      const _modelNoList = endUserInfoToolsList.filter(
        (item: any) => item.unique_key === formList.unique_key
      );

      if (
        _modelNoList.length > 0 &&
        _modelNoList[0].invoices &&
        _modelNoList[0].invoices.length > 0
      ) {
        _list = _modelNoList[0].invoices;
      }
    }
    setDocumentList(_list);
  }, [endUserInfoToolsList, formList.unique_key]);

  const contents = (
    <div id="contents">
      <AlertMesssageBox
        show={alertMessage && alertMessage !== ""}
        message={alertMessage}
        type={alertType}
      />
      {!claimErrorFlag && (
        <div>
          <div className={classes.claim_header}>
            <ClaimDetailCommon
              claimType={claimType}
              claimStatus={claimStatus}
              setFormListValue={setFormListValue}
              formList={formList}
            />
            {claimType !== 0 && (
              <div className={classes.history_area}>
                <ClaimDetailHistory
                  dataList={formList.claim_updated_history}
                  currency={userInfoDZ.currency}
                  errorType={errorType}
                />
              </div>
            )}
          </div>
          <div className={classes.container}>
            <div className={classes.buttonControl}>
              {value !== 0 && (
                <div className={classes.buttonPrevArea}>
                  <Button
                    className={classes.buttonMessage}
                    variant="contained"
                    onClick={handlePrev}
                  >
                    {t("btn_previous")}
                  </Button>
                </div>
              )}
              <div className={classes.buttonNextArea}>
                <Button
                  className={classes.buttonMessageNext}
                  variant="contained"
                  onClick={handleNext}
                >
                  {t("btn_next")}
                </Button>
              </div>
            </div>
            <div className={classes.root}>
              <AppBar className={classes.AppBar} position="static">
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    className={clsx(
                      classes.tabButton,
                      stateData[0] === "2" && classes.errorTabButton
                    )}
                    disabled={tabCtroll[0]}
                    label={t("tab_endUser")}
                  />
                  <Tab
                    className={clsx(
                      classes.tabButton,
                      stateData[1] === "2" && classes.errorTabButton
                    )}
                    disabled={tabCtroll[1]}
                    label={t("tab_toolinformation")}
                  />
                  <Tab
                    className={clsx(
                      classes.tabButton,
                      stateData[2] === "2" && classes.errorTabButton
                    )}
                    disabled={tabCtroll[2]}
                    label={t("tab_parts_drawings")}
                  />
                  <Tab
                    className={clsx(
                      classes.tabButton,
                      stateData[3] === "2" && classes.errorTabButton
                    )}
                    disabled={tabCtroll[3]}
                    label={t("tab_upload")}
                  />
                  {Number(claimStatus) > 100 && (
                    <Tab
                      className={clsx(
                        classes.tabButton,
                        memoErrorMessage !== "" && classes.errorTabButton
                      )}
                      label={t("tab_memo")}
                    />
                  )}
                </Tabs>
              </AppBar>
              <div>
                {(claimStatus === "100" || claimStatus === "300") && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.searchButton}
                    startIcon={<RefreshIcon />}
                    onClick={handleRefresh}
                  >
                    {t("btn_refresh")}
                  </Button>
                )}

                {(claimType === 0 ||
                  Number(claimStatus) === 100 ||
                  Number(claimStatus) === 300) && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.searchButton}
                      onClick={handleToSave}
                      startIcon={<SaveIcon />}
                    >
                      {t("btn_save")}
                    </Button>
                  )}

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.searchButton}
                  startIcon={<CheckCircleOutlineIcon />}
                  onClick={handleToSummary}
                >
                  {t("btn_summary")}
                </Button>
                {(claimType === 0 || Number(claimStatus) === 100) && (
                  <Button
                    variant="contained"
                    className={classes.searchButton}
                    startIcon={<ArrowBackIcon />}
                    onClick={handleToRetrun}
                  >
                    {t("btn_return")}
                  </Button>
                )}
                {claimType !== 0 && Number(claimStatus) > 100 && (
                  <Button
                    variant="contained"
                    className={classes.searchButton}
                    onClick={handleToRetrunSummary}
                    startIcon={<ArrowBackIcon />}
                  >
                    {t("btn_return_summary")}
                  </Button>
                )}
                {Number(claimStatus) === 100 && (
                  <Button
                    variant="contained"
                    className={classes.searchButton}
                    color="secondary"
                    onClick={handleToDelete}
                    startIcon={<DeleteIcon />}
                  >
                    {t("btn_delete")}
                  </Button>
                )}
                {(Number(claimStatus) === 200 ||
                  Number(claimStatus) === 210 ||
                  Number(claimStatus) === 300) && (
                    <Button
                      variant="contained"
                      className={classes.searchButton}
                      color="secondary"
                      onClick={handleToCancel}
                    >
                      {t("btn_cancel")}
                    </Button>
                  )}
              </div>
            </div>
            <Paper className={classes.paper}>
              <p className={classes.errorTab}>{errorMessageText}</p>
              <div hidden={value !== 0}>
                <ClaimDetailEndUser
                  formList={formList}
                  formErrorList={formErrorList}
                  setFormErrorListValue={setFormErrorListValue}
                  endUserNoValicationCheck={endUserNoValicationCheck}
                  valueValicationCheck={valueValicationCheck}
                  setFormListValue={setFormListValue}
                  clearErrorCheck={clearErrorCheck}
                  mailValicationCheack={mailValicationCheack}
                  CSearchOpen={CSearchOpen}
                  handleCSearch={handleCSearch}
                  CSearchClose={CSearchClose}
                  endUserInfoList={endUserInfoList}
                  endUserInfoFlg={endUserInfoFlg}
                  setEndUserInfoFlg={setEndUserInfoFlg}
                  handleEndUserDetailSearch={handleEndUserDetailSearch}
                  handleSend={handleSend}
                  errorMessage={errorMessage}
                  handleRefresh={handleRefreshCSearch}
                  handleChangeKey={handleChangeKey}
                  searchStatus={searchStatus}
                  privacyCheckFlgFunc={privacyCheckFlgFunc}
                  countriesList={countriesList}
                />
              </div>
              <div hidden={value !== 1}>
                <ClaimDetailToolInformation
                  formList={formList}
                  setFormList={setFormList}
                  dataList={dataList}
                  setDataList={setDataList}
                  data={repairHistoryList}
                  setFormListValue={setFormListValue}
                  getRepairHistory={getRepairHistory}
                  onBlurOfModelNo={onBlurOfModelNo}
                  onBlurOfSerialNo={onBlurOfSerialNo}
                  setRepairHistoryList={setRepairHistoryList}
                  tempModelNo={tempModelNo}
                  setTempModelNo={setTempModelNo}
                  tempSerialNo={tempSerialNo}
                  setTempSerialNo={setTempSerialNo}
                  errorType={errorType}
                  setErrorType={setErrorType}
                  formErrorList={formErrorList}
                  setFormErrorList={setFormErrorList}
                  setFormErrorListValue={setFormErrorListValue}
                  getReadModelErrorTypeList={getReadModelErrorTypeList}
                  onBlurOfpurchasedDate={onBlurOfpurchasedDate}
                  onBlurOfReceivedDate={onBlurOfReceivedDate}
                  onBlurOfRepairDate={onBlurOfRepairDate}
                  getNote={getNote}
                  changeNote={changeNote}
                  valueValicationCheck={valueValicationCheck}
                  endUserInfoToolsList={endUserInfoToolsList}
                  userInfoDZ={userInfoDZ}
                  faultDescriptionFlg={faultDescriptionFlg}
                  onBlurFaultDescription={onBlurFaultDescription}
                  handleSendTool={handleSendTool}
                  handleRefresh={handleRefreshTool}
                  handleChangeKey={handleChangeKeyTool}
                  CSearchOpen={CSearchOpenTool}
                  setCSearchOpen={setCSearchOpenTool}
                  toolInfoHistory={toolInfoHistory}
                  setToolInfoHistory={setToolInfoHistory}
                  setModelNoChangeFlag={setModelNoChangeFlag}
                  setLaborItemInfo={setLaborItemInfo}
                  toolRefreshFlag={toolRefreshFlag}
                  setToolRefreshFlag={setToolRefreshFlag}
                  setTabChangeFlag={setTabChangeFlag}
                  setRegisterData={setRegisterData}
                />
              </div>
              <div hidden={value !== 2}>
                <ClaimDetailParts
                  dataList={formList}
                  partsInitFlag={partsInitFlag}
                  errorMessage={errorMessage}
                  registerData={formList.claim_line}
                  setRegisterData={setRegisterData}
                  setFormListValue={setFormListValue}
                  formErrorList={formErrorList}
                  setForListFull={setForListFull}
                  clearPartsFlag={clearPartsFlag}
                  setClearPartsFlag={setClearPartsFlag}
                  partsRefreshFlag={partsRefreshFlag}
                  setPartsRefreshFlag={setPartsRefreshFlag}
                  transportMessage={transportMessage}
                  setTransportMessage={setTransportMessage}
                  setTransportListValue={setTransportListValue}
                  responseList={dataList}
                  setFormErrorListValue={setFormErrorListValue}
                  clearErrorCheck={clearErrorCheck}
                  handleGetPrice={handleGetPrice}
                  modelNoChangeFlag={modelNoChangeFlag}
                  setModelNoChangeFlag={setModelNoChangeFlag}
                  errorRegisterList={errorRegisterList}
                  setErrorRegisterList={setErrorRegisterList}
                  itemNoErrorList={itemNoErrorList}
                  setItemNoErrorList={setItemNoErrorList}
                  setLaborItemInfo={setLaborItemInfo}
                  total={total}
                  setTotal={setTotal}
                  tabChangeFlag={tabChangeFlag}
                  setTabChangeFlag={setTabChangeFlag}
                  partsDefaultList={partsDefaultList}
                />
              </div>
              <div hidden={value !== 3}>
                <ClaimDetailUpload
                  claimMedia={formList.claim_media}
                  handleUpload={handleUpload}
                  photoDelete={photoDelete}
                  fileDownload={fileDownload}
                  fileDownloadFile={fileDownloadFile}
                  formErrorList={formErrorList}
                  handleUploadByCam={handleUploadByCam}
                  documentList={documentList}
                />
              </div>
              <div hidden={value !== 4}>
                {Number(claimStatus) > 100 && (
                  <ClaimDetailMemo
                    list={formList.claim_note}
                    memoMessage={memoMessage}
                    changeMemoMessage={changeMemoMessage}
                    memoErrorMessage={memoErrorMessage}
                  />
                )}
              </div>
            </Paper>
          </div>
          <ClaimDetailPopup
            returnWindowFlag={returnWindowFlag}
            handleCloseReturnChanges={handleCloseReturnChanges}
            handleCloseReturn={handleCloseReturn}
            deleteWindowFlag={deleteWindowFlag}
            handleCloseDeleteChanges={handleCloseDeleteChanges}
            handleCloseDelete={handleCloseDelete}
            deleteSuccessWindowFlag={deleteSuccessWindowFlag}
            handleCloseDeleteSuccessChanges={handleCloseDeleteSuccessChanges}
            claim_no={saveClaimNo}
            saveWindowFlag={saveWindowFlag}
            handleCloseSaveChanges={handleCloseSaveChanges}
            handleCloseSave={handleCloseSave}
            cancelWindowFlag={cancelWindowFlag}
            handleCloseCancelChanges={handleCloseCancelChanges}
            handleCloseCancel={handleCloseCancel}
            cancelSuccessWindowFlag={cancelSuccessWindowFlag}
            handleCloseCancelSuccessChanges={handleCloseCancelSuccessChanges}
            returnSummaryWindowFlag={returnSummaryWindowFlag}
            handleCloseReturnSummaryChanges={handleCloseReturnSummaryChanges}
            handleCloseReturnSummary={handleCloseReturnSummary}
          />
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
