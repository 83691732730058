import { useState } from "react";

export const useDrawer = (
  open: boolean
): {
  isDrawerOpen: boolean;
  onToggleDrawer: () => void;
  onMouseOverDrawer: () => void;
  onMouseLeaveDrawer: () => void;
  onCloseDrawer: () => void;
} => {
  const [isOpen, setOpen] = useState(open);

  // For Toggle Drawer when clicking hamburger menu
  const [isToggle, setToggle] = useState(open);

  return {
    isDrawerOpen: isOpen,
    onMouseOverDrawer: () => {
      if (!isToggle) {
        setOpen(true);
      }
    },
    onMouseLeaveDrawer: () => {
      if (!isToggle) {
        setOpen(false);
      }
    },
    onToggleDrawer: () => {
      setToggle(!isToggle);
      setOpen(!isOpen);
    },
    onCloseDrawer: () => {
      setOpen(false);
    },
  };
};
