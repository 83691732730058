export const DEFAULT_VALUE_BLANK: { [key: string]: any } = {
  claim_no: undefined,
  Status: undefined,
  re_entered_count: undefined,
  customer_no: null,
  credit_note_no: null,
  model_no: "",
  serial_no: "",
  condition_of_tool: 1,
  device_runtime: undefined,
  purchased_date: undefined,
  received_date: undefined,
  repair_date: undefined,
  approve_date: undefined,
  error_type: null,
  guarantee_type: null,
  delivery_distance: undefined,
  total_amount: 0,
  currency: undefined,
  entry_date: undefined,
  service_center_id: undefined,
  customer_po_no: "",
  manufactured_year: (new Date().getFullYear()).toString(),
  manufactured_month: "01",
  // delete_flg: undefined,

  update_flg: undefined,
  attention_check_flg: false,
  privacy_check_flg: false,
  // 画面用
  // partsQtyChangeFlag: false,
  transport_way_pick_up: false,
  transport_way_return: false,
  transport_cost_flg: false,
  weight: undefined,
  shipping_method: undefined,
  labor_value: 1,
  claim_line: [],
  claim_address: {
    claim_no: undefined,
    enduser_no: undefined,
    customer_type: null,
    company_name: undefined,
    first_name: undefined,
    last_name: undefined,
    address_1: undefined,
    address_2: undefined,
    address_3: undefined,
    postal_code: undefined,
    country_code: undefined,
    tel_no: undefined,
    fax_no: undefined,
    mail_address: undefined,
    // freight_distance: undefined,
    // delete_flg: undefined,
    created_date: undefined,
    created_by: undefined,
    updated_date: undefined,
    updated_by: undefined,
  },
  claim_media: {
    claim_media_invoice: [],
    claim_media_picture: [],
  },
  claim_note: [],
};

export const STEP_LIST = [
  {
    status: "0", //New
    label: ["entered", "approved", "credit_issued"],
    activeStep: -1,
  },
  {
    status: "100", //Drafted
    label: ["entered", "approved", "credit_issued"],
    activeStep: -1,
  },
  {
    status: "200", //Entered
    label: ["entered", "approved", "credit_issued"],
    activeStep: 0,
  },
  {
    status: "210", //Re-Entered
    label: ["entered", "approved", "credit_issued"],
    activeStep: 0,
  },
  {
    status: "300", //Declined
    label: ["entered", "declined", "credit_issued"],
    activeStep: 1,
  },
  {
    status: "400", //Approved
    label: ["entered", "approved", "credit_issued"],
    activeStep: 1,
  },
  {
    status: "500", //Credit Issued
    label: ["entered", "approved", "credit_issued"],
    activeStep: 2,
  },
  {
    status: "600", //Cancelled
    label: ["cancelled", "approved", "credit_issued"],
    activeStep: 0,
  },
];

export const CLAIM_NO_QUERY_STRING = "?claimno=";

// export const PARTS_LIST = ["17", "18", "SWTRANS", "SWSPEDI"];
