import { http } from "./Http";
import { methods } from "../constants/common.constants";

// 1701
export const CreateClaimAPI = (config: any, data: any) => {
  const { baseURLClaim, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/${affiliate_cd}`,
    config: config,
    method: methods.post,
    data: data,
    flg: true,
    claimFlag: true,
  };
  return http(param);
};

// AT1702
export const GetReadClaimDetailAPI = (claim_no: any, config: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/${affiliate_cd}/${claim_no}`,
    config: config,
    claimFlag: true,
  };
  return http(param);
};

// AT1703
export const UpdateClaimAPI = (claim_no: any, config: any, data: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/${affiliate_cd}/${claim_no}`,
    config: config,
    method: methods.patch,
    data: data,
    claimFlag: true,
  };
  return http(param);
};

// AT1704
export const DeleteClaimAPI = (config: any, claim_no: any, data: any) => {
  const { baseURLClaim, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/${affiliate_cd}/${claim_no}`,
    config: config,
    data: data,
    method: methods.delete,
    claimFlag: true,
  };
  return http(param);
};

// AT1705
export const GetReadClaimListAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/${affiliate_cd}?${query_parameters}`,
    config: config,
    claimFlag: true,
  };
  return http(param);
};

// AT1706
export const GetCreditNotesForSearch = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/credit_notes/${affiliate_cd}?${query_parameters}`,
    config: config,
    claimFlag: true,
  };
  return http(param);
};

// AT1707
export const GetReadClaimListForCSV = (query_parameters: any, config: any) => {
  const { baseURLClaim, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/dls3url/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};


// AT1708
export const GetReadRepairHistoryListAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURLClaim, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/claims/history/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
