import React, { FC, useCallback } from "react";
import {
  Badge,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from "@material-ui/core";
import { useLocale } from "../../hooks/UseLocale";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useAnchorEl } from "../../hooks/UseAnchorEl";
import MoreIcon from "@material-ui/icons/MoreVert";
import { useAuth0 } from "@auth0/auth0-react";
import {
  GetAppRoutePath,
  GetPrivateAppRoutePath,
  PathKey,
} from "../../../pages/AppRoutes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

const mobileMenuId = "navigation-mobile-menu";
const profileMenuId = "navigation-profile-menu";

type Props = {
  // Move to provider?
};

export const NavigationMenu: FC<Props> = () => {
  const { t } = useLocale({ defaultPath: "modules.components.navigationMenu" });
  const { isAuthenticated, logout } = useAuth0();
  const classes = useStyles();
  const profileAE = useAnchorEl();

  const isProfileMenuOpen = Boolean(profileAE.anchorEl);

  const mobileAE = useAnchorEl();
  const isMobileMenuOpen = Boolean(mobileAE.anchorEl);

  // For Profile
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    profileAE.setAnchorEl(event);
  };
  const handleProfileMenuClose = () => {
    profileAE.resetAnchorEl();
    handleMobileMenuClose();
  };
  //configuration
  const history = useHistory();
  const handleClickConfiguration = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // For Mobile Menu
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    mobileAE.setAnchorEl(event);
  };
  const handleMobileMenuClose = () => {
    mobileAE.resetAnchorEl();
  };

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin + GetAppRoutePath(PathKey.LOGIN),
    });
  };

  // For Profile Menu
  const renderProfileMenu = (
    <>
      <IconButton
        aria-controls={profileMenuId}
        aria-haspopup="true"
        edge="end"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircleIcon />
      </IconButton>
      <p className={classes.sectionMobile}>{t("txtConfiguration")}</p>
    </>
  );

  const renderProfileMenuItem = (
    <Menu
      anchorEl={profileAE.anchorEl}
      id={profileMenuId}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleClickConfiguration(
            GetPrivateAppRoutePath(PathKey.CONFIGURATION)
          );
          handleProfileMenuClose();
        }}
      >
        {t("txtConfiguration")}
      </MenuItem>
      {isAuthenticated && (
        <MenuItem onClick={handleLogout}>{t("txtLogout")}</MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <div className={classes.sectionDesktop}>{renderProfileMenu}</div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
        <Menu
          anchorEl={mobileAE.anchorEl}
          id={mobileMenuId}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={handleProfileMenuOpen}>
            {renderProfileMenu}
          </MenuItem>
        </Menu>
      </div>
      {renderProfileMenuItem}
    </>
  );
};
