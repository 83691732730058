const AUTH0_REDIRECT_KEY = 'auth0Redirected';

export const useAuth0Redirect = (): { isRedirected: boolean, setRedirect: () => void, resetRedirect: () => void } => {
    var state = getCurrentState();
    // If state is null or false, DO redirect
    if (state === null || state === 'false') {
        state = 'true';
        setCurrentState(true);
    }

    return {
        isRedirected: JSON.parse(state),
        setRedirect: () => {
            setCurrentState(true);
        },
        resetRedirect: () => {
            setCurrentState(false);
        }
    }
}

const getCurrentState = (): string | null => {
    return window.localStorage.getItem(AUTH0_REDIRECT_KEY);
}

const setCurrentState = (input: boolean): void => {
    return window.localStorage.setItem(AUTH0_REDIRECT_KEY, String(input));
}
