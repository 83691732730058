import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TextField from "@material-ui/core/TextField";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "600px",
      margin: "0 auto",
    },
    textarea: {
      display: "flex",
    },
    title: {
      fontWeight: "bold",
      fontSize: "14px",
      margin: "0",
      padding: "0 20px 0 0",
      whiteSpace: "pre",
    },
    multilineTextField: {
      width: "100%",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px!important",
      },
    },
    messageDetailList: {
      margin: "0",
      listStyle: "none",
      padding: "0",
      paddingBottom: "30px",
      "& li+li": {
        paddingTop: "5px",
      },
    },
    item: { paddingBottom: "5px", borderBottom: "1px solid gray" },
    message: { margin: "0", paddingBottom: "5px", whiteSpace: "break-spaces" },
    bottomMessage: {
      borderTop: "1px dashed gray",
      paddingTop: "5px",
      display: "flex",
      justifyContent: "space-between",
    },
    date: {
      margin: "0",
    },
    status: {
      margin: "0",
    },
    error: {
      margin: "0",
      color: "red",
    },
    memo_area: {
      flex: "1",
    },
  })
);

export const ClaimDetailMemo = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { list, changeMemoMessage, memoMessage, memoErrorMessage } = props;

  const getUser = (type: number) => {
    if (type === 20 || type === 30) {
      return "summary_txt_message_from_makita";
    }
    return "summary_txt_message_from_asc";
  };

  return (
    <div className={classes.container}>
      <ul className={classes.messageDetailList}>
        {list &&
          list.length > 0 &&
          list.map(
            (item: any, index: number) =>
              (item.note_type === 20 ||
                item.note_type === 30 ||
                item.note_type === 40) &&
              item.note !== "" && (
                <li className={classes.item} key={index}>
                  <p className={classes.message}>{item.note}</p>
                  <div className={classes.bottomMessage}>
                    <p className={classes.date}>
                      {date(new Date(item.updated_date).getTime())}
                    </p>
                    <p className={classes.status}>
                      {t(getUser(item.note_type))}
                    </p>
                  </div>
                </li>
              )
          )}
      </ul>
      <div className={classes.textarea}>
        <p className={classes.title}>{t("detail_memo")}</p>
        <div className={classes.memo_area}>
          <TextField
            className={classes.multilineTextField}
            variant="outlined"
            multiline
            rows={5}
            value={memoMessage}
            onChange={(e: any) => changeMemoMessage(e.target.value)}
            inputProps={{ maxLength: 2048 }}
          />
          <p className={classes.error}>{memoErrorMessage}</p>
        </div>
      </div>
    </div>
  );
};
