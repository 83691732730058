import { ChangeEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { getLocale } from "../i18n";
import { SUPPORT_LANGUAGES } from "../constants/supportLanguages";

// defaultPath should be match with translation.json path
export const useLocale = (props?: {
  namespace?: string;
  defaultPath?: string;
}): { t: any; locale: any; com: any; getName: any; getAffiliateCDLan: any } => {
  const { t, i18n } = useTranslation(props?.namespace);

  const setHtmlProps = (locale: string) => {
    document.title = t("title");
    document.documentElement.lang = locale;
  };

  // Change html lang
  setHtmlProps(getLocale());

  return {
    t: (key: string, ignoreDefaultPath: boolean = false) => {
      var path = ignoreDefaultPath
        ? key
        : props?.defaultPath === undefined || props?.defaultPath === null
        ? `${key}`
        : `${props?.defaultPath}.${key}`;
      return t(path);
    },
    com: (key: string, param: any = {}, ignoreDefaultPath: boolean = false) => {
      var path = ignoreDefaultPath
        ? key
        : props?.defaultPath === undefined || props?.defaultPath === null
        ? `${key}`
        : `${props?.defaultPath}.${key}`;
      return t(path, { returnObjects: true, param: param });
    },
    getName: (
      key: string,
      param: any = {},
      ignoreDefaultPath: boolean = false
    ) => {
      var path = ignoreDefaultPath
        ? key
        : props?.defaultPath === undefined || props?.defaultPath === null
        ? `${key}`
        : `${props?.defaultPath}.${key}`;
      return t(path, { returnObjects: true, param: param });
    },
    getAffiliateCDLan: (key: string, affiliate_cd: string) => {
      const _lan = getLocale();
      let affilicate_list: any = [];
      let _key: any = "";
      if (SUPPORT_LANGUAGES && SUPPORT_LANGUAGES.length > 0) {
        SUPPORT_LANGUAGES.forEach((item: any) => {
          if (
            item.code === _lan &&
            item.special_list &&
            item.special_list.length > 0
          ) {
            item.special_list.forEach((subItem: any) => {
              if (subItem.key === key) {
                affilicate_list = subItem.affiliate_cd;
              }
            });
          }
        });
      }
      let _flag = false;
      if (affilicate_list.length > 0) {
        if (
          !i18n.exists(`${props?.defaultPath}.${key}_${affiliate_cd}`) &&
          !(props?.defaultPath === undefined || props?.defaultPath === null)
        ) {
          _flag = false;
        } else {
          _flag = true;
        }
      }
      _key = _flag ? `${key}_${affiliate_cd}` : `${key}`;

      // console.log("test1234", _key, affilicate_list);
      var path =
        props?.defaultPath === undefined || props?.defaultPath === null
          ? `${_key}`
          : `${props?.defaultPath}.${_key}`;
      return t(path);
    },
    locale: {
      onChange: (
        event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: ReactNode
      ) => {
        var locale = event.target.value as string;
        setHtmlProps(locale);
        i18n.changeLanguage(locale);
      },
      value: getLocale(),
    },
  };
};
