import React from "react";
//UI
import TextField from "@material-ui/core/TextField";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    disabledRoot: {
      borderRadius: "4px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      fontSize: "1rem",
      "& .MuiInputBase-input": {
        boxSizing: "border-box",
      },
    },
  })
);
export const TextInput = (props: any) => {
  const classes = useStyles();
  const {
    value,
    onChange,
    disabled,
    onBlur,
    className,
    disableClassName,
  } = props;

  const inputArea = (
    <TextField
      value={value}
      variant="outlined"
      onChange={(e: any) => onChange(e)}
      onBlur={(e: any) => onBlur(e)}
      className={`${classes.root} ${className}`}
    />
  );
  const InputDisabledArea = (
    <div className={`${classes.disabledRoot} ${className} ${disableClassName}`}>
      <div className="MuiInputBase-input MuiOutlinedInput-input">{value}</div>
    </div>
  );
  return <>{disabled ? InputDisabledArea : inputArea}</>;
};
