import React, { useState, useCallback } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid #CFCFCF",
    },
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingLeft: "5px",
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
      },
    },
    searchTitleCell: {
      width: "100px",
    },
    searchTitleCell2: {
      width: "120px",
    },
    searchmessageCell: {
      width: "130px",
      paddingRight: "20px",
    },
    customerInputText: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        width: "200px",
      },
      "& .PrivateNotchedOutline-root-47.MuiOutlinedInput-notchedOutline": {
        width: "100px",
      },
    },
    searchButton: {
      height: "30px",
      padding: "0 16px",
    },
    iconButton: {
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
      "& .MuiIconButton-label": {
        marginLeft: "-35px",
        marginTop: "2px",
      }
    }
  })
);

export const ClaimToolInformationSearchControl = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.tableLayout}>
        <tbody>
          <tr style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <td className={classes.searchmessageCell}>
                <div style={{ display: "flex" }}>
                  <TextField
                    className={classes.customerInputText}
                    variant="outlined"
                    onChange={(e) => {
                      //e.preventDefault();
                      props.handleChangeKey(e.target.value);
                    }}
                  />
                  <IconButton
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </td>
            </div>

            <div>
              <td>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.searchButton}
                  startIcon={<RefreshIcon />}
                  onClick={props.handleRefresh}
                >
                  {t("btn_refresh")}
                </Button>
              </td>
            </div>

          </tr>
        </tbody>
      </table>
    </form>
  );
};
