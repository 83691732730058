import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/control.useStyles";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

export const SearchControl = (props: any) => {
  const classes = useStyles();
  const {
    searchValueList,
    setSearchValue,
    checkApFrom,
    onBlurOfApFrom,
    checkApTo,
    onBlurOfApTo,
    handleSearchEvent,
    handleClearEvent,
  } = props;
  const { t } = useLocale({ defaultPath: "modules.components.ap" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const statusList = com("wco_ap_status");

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.row}>
        <dl>
          <dt>{t("credit_note_no")}</dt>
          <dd>
            <TextField
              value={searchValueList.credit_note_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value.substr(0, 30);
                setSearchValue("credit_note_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchEvent();
                }
              }}
              error={searchValueList.credit_note_no_error !== ""}
              helperText={searchValueList.credit_note_no_error}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("customer_po_no")}</dt>
          <dd>
            <TextField
              value={searchValueList.customer_po_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value.substr(0, 30);
                setSearchValue("customer_po_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchEvent();
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("claim_no")}</dt>
          <dd>
            <TextField
              value={searchValueList.claim_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value
                  .replace(/[^\d]/g, "")
                  .substr(0, 10);
                setSearchValue("claim_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchEvent();
                }
              }}
            />
          </dd>
        </dl>
        <dl className={classes.datePickerArea}>
          <dt>
            <span> {t("ap_from")}</span>
            <span className={classes.datePickerTitle}>{t("ap_to")}</span>
          </dt>
          <dd className={classes.datePickerContainer}>
            <DatePicker
              value={searchValueList.ap_from}
              onChange={(date: any) => setSearchValue("ap_from", date)}
              onAccept={(date: any) =>
                checkApFrom(new Date(date).setHours(0, 0, 0, 0))
              }
              className={classes.datePicker}
              error={searchValueList.ap_from_error !== ""}
              helperText={searchValueList.ap_from_error}
              onBlur={(e: any) => onBlurOfApFrom(e)}
              onError={(error: any, value: any) => {
                if (error !== searchValueList.ap_from_error && error !== "") {
                  setSearchValue("ap_from_error", error);
                }
              }}
            />
            <span className={classes.datePickerInterval}></span>
            <DatePicker
              value={searchValueList.ap_to}
              onChange={(date: any) => {
                if (date && date !== "") {
                  setSearchValue(
                    "ap_to",
                    new Date(date).setHours(23, 59, 59, 999)
                  );
                } else {
                  setSearchValue("ap_to", date);
                }
              }}
              onAccept={(date: any) =>
                checkApTo(new Date(date).setHours(23, 59, 59, 999))
              }
              className={classes.datePicker}
              error={searchValueList.ap_to_error !== ""}
              helperText={searchValueList.ap_to_error}
              onBlur={(e: any) => onBlurOfApTo(e)}
              onError={(error: any, value: any) => {
                if (error !== searchValueList.ap_to_error && error !== "") {
                  setSearchValue("ap_to_error", error);
                }
              }}
            />
          </dd>
        </dl>
        <div className={classes.buttonArea}>
          <Button
            className={classes.buttonCondition}
            variant="contained"
            color="primary"
            onClick={handleSearchEvent}
            startIcon={<SearchIcon />}
          >
            {t("btn_ap_search")}
          </Button>
        </div>
        <div className={classes.buttonArea}>
          <Button
            className={classes.buttonCondition}
            variant="contained"
            onClick={handleClearEvent}
            startIcon={<RefreshIcon />}
          >
            {t("btn_ap_clear")}
          </Button>
        </div>
      </div>
    </form>
  );
};
