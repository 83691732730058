import { http } from "./Http";

// 2101
export const GetClaimPrintAPI = (claim_no: any, config: any) => {
  const { baseURLClaim, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/docugen/claim/${affiliate_cd}/${claim_no}`,
    config: config,
  };
  return http(param);
};

export const GetClaimPrintAPIDumi = (config: any) => {
  const { baseURLClaim, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/docugen/init/${affiliate_cd}/1`,
    config: config,
  };
  return http(param);
};
