import React, { useState, useEffect } from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td:last-child": {
        borderRight: "1px solid rgba(198, 209, 221)",
      },
      "& td:first-child": {
        width: "30px",
      },
    },
  })
);

export const ClaimToolInformationSearchControlTableTbody = (props: any) => {
  const classes = useStyles();
  const { endUserInfoToolsList } = props;

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    setSelectedValue(index);
  };

  return (
    <TableBody>
      {endUserInfoToolsList &&
        endUserInfoToolsList.length > 0 &&
        endUserInfoToolsList.map((row: any, index: any) => (
          <TableRow
            className={classes.tbody}
            hover
            key={index}
            onClick={() => props.handleClick(row)}
          >
            <TableCell>
              <Radio
                name="customerSelection"
                checked={Number(selectedValue) === index}
                onChange={(event: any) => handleChange(event, index)}
              />
            </TableCell>
            <TableCell>{row.model_no}</TableCell>
            <TableCell>{row.base_model_no}</TableCell>
            <TableCell>{row.serial_no}</TableCell>
            <TableCell>{row.purchase_date}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};
