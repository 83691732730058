import React from "react";
// hooks
import { useStyles } from "../hooks/common.styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    technicalInformation_link: {
      width: "200px",
      padding: "0",
      paddingLeft: "10px",
    },
    Qty: {
      width: "40px",
    },
  })
);
interface HeadCell {
  id: string;
  label: string;
  class?: string;
}

const headCells: HeadCell[] = [
  {
    id: "Position",
    label: "thead_position",
  },
  {
    id: "PartsNo",
    label: "thead_PartsNo",
  },
  {
    id: "Description",
    label: "thead_Description",
  },
  {
    id: "Qty",
    label: "thead_Qty",
  },
  {
    id: "TechnicalInformation_open",
    label: "TechnicalInformation_open",
  },
  {
    id: "TechnicalInformation_close",
    label: "TechnicalInformation_close",
  },
];
export const ClaimDetailPartsThead = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });

  const getClass = (label: any) => {
    if (
      label === "TechnicalInformation_open" ||
      label === "TechnicalInformation_close"
    ) {
      return myClasses.technicalInformation_link;
    } else if (label === "thead_Qty") {
      return myClasses.Qty;
    }
  };

  return (
    <TableHead id="registerHead" className={classes.thead2}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell className={getClass(headCell.label)} key={headCell.id}>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
