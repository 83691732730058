import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      fontSize: "20px",
      marginTop: "20px",
      borderLeft: "3px solid #8DA1B9",
      borderBottom: "3px solid #8DA1B9",
      paddingLeft: "5px"
    },
    root: {
      flexGrow: 1,
      "& .makeStyles-paper-22": {
        padding: "0px 16px",
        marginTop: "16px",
      },
    },
    paper: {
      padding: theme.spacing(2),
      backgroundColor: "#F9F9F9",
      boxShadow: "none",
    },
    history_area: {
      width: "100%",
      maxWidth: "1000px",
      padding: "40px 20px 0 20px",
    },
    multilineTextField: {
      readOnly: true,
      borderLeft: "1px solid #D4B4B4",
      borderRight: "1px solid #D4B4B4",
      borderBottom: "1px solid #D4B4B4",
      width: "100%",
      "& fieldset": { border: 'none'},
      '& .MuiOutlinedInput-multiline': { padding: '5px 14px'}
    },

  })
);
