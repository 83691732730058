import React, { FC } from "react";
// language
import { getLocale } from "../../i18n";
// input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ar from "react-phone-input-2/lang/ar.json";
import cn from "react-phone-input-2/lang/cn.json";
import de from "react-phone-input-2/lang/de.json";
import es from "react-phone-input-2/lang/es.json";
import fr from "react-phone-input-2/lang/fr.json";
import id from "react-phone-input-2/lang/id.json";
import ir from "react-phone-input-2/lang/ir.json";
import it from "react-phone-input-2/lang/it.json";
import jp from "react-phone-input-2/lang/jp.json";
import pt from "react-phone-input-2/lang/pt.json";
import ru from "react-phone-input-2/lang/ru.json";
import tr from "react-phone-input-2/lang/tr.json";

// props
type Props = {
  value: any;
  country?: string;
  className?: any;
  onChange: (date: any) => void;
  placeholder?: string;
  autoFormat?: boolean;
  onBlur?: (date: any) => void;
  disabled?: boolean;
};

export const PhoneNumberInput: FC<Props> = ({
  value,
  country = "de",
  className,
  onChange,
  placeholder = "",
  autoFormat = false,
  onBlur,
  disabled,
}) => {
  // set locale
  const localeStr = getLocale();
  const localeArray = localeStr.split("-");
  let localString = "";
  if (localeArray && localeArray.length > 1) {
    localString = localeArray[1].toLowerCase();
  }

  let localObject = getLocaleObject(localString);
  return (
    <>
      <PhoneInput
        localization={localObject}
        country={country}
        value={value}
        onChange={onChange}
        placeholder=""
        autoFormat={false}
        onBlur={onBlur}
        disabled={disabled}
      />
    </>
  );
};

const getLocaleObject = (local: string) => {
  let localObject = {};
  switch (local) {
    case "ar":
      localObject = ar;
      break;
    case "cn":
      localObject = cn;
      break;
    case "de":
      localObject = de;
      break;
    case "es":
      localObject = es;
      break;
    case "fr":
      localObject = fr;
      break;
    case "id":
      localObject = id;
      break;
    case "ir":
      localObject = ir;
      break;
    case "it":
      localObject = it;
      break;
    case "jp":
      localObject = jp;
      break;
    case "pt":
      localObject = pt;
      break;
    case "ru":
      localObject = ru;
      break;
    case "tr":
      localObject = tr;
      break;
    case "us":
      localObject = {};
      break;
    default:
      localObject = de;
  }
  return localObject;
};
