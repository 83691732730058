import React, { FC, useEffect } from "react";
// UI
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// common
import { useCommonInfo } from "../../hooks/CommonInfoProvider";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const Loading: FC = (props: any) => {
  const classes = useStyles();
  const { getCommonInfo } = useCommonInfo();
  const loading = getCommonInfo() as any;
  return (
    <Backdrop className={classes.backdrop} open={loading.loadingFlg}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
