import { GlobalConfigurationModel } from "../models/GlobalConfigurationModel";
import { http } from "./Http";

export const GetGlobalConfiguration = (
  token: string
): Promise<GlobalConfigurationModel> => {
  return http({
    token: token,
    url: process.env.REACT_APP_GLOBAL_CONFIGURATION_URI!,
  }).then(({ response }) => {
    return response as GlobalConfigurationModel;
  });
};
