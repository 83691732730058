import React, { FC } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { SUPPORT_LANGUAGES } from '../constants/supportLanguages';
import { useLocale } from '../hooks/UseLocale';

export const LocaleSelect: FC = () => {
    const {locale} = useLocale();

    return (
        <FormControl>
            <Select
                {...locale}>
                {
                    SUPPORT_LANGUAGES.map((m) => <MenuItem key={m.code} value={m.code}>{m.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}