import { createStyles, makeStyles, Theme } from '@material-ui/core';

const drawerMenuWidth = 240;
const drawerNotificationWidth = 320;

export const UseDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    blank: {  
      margin: 'auto',
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerMenu: {
      width: drawerMenuWidth,
    },
    drawerMenuOpen: {
      width: drawerMenuWidth,
    },
    drawerNotification: {
      width: drawerNotificationWidth,
    },
    drawerNotificationOpen: {
      width: drawerNotificationWidth,
    },
    drawerNotificationClose: {
      width: 0,
    },
    toolbar: {
      padding: theme.spacing(0, 1),
      // ...theme.mixins.toolbar,
      // Edge - previous version - does not support '...theme.mixins.toolbar' syntax
      // So implement code directory
      // https://github.com/mui-org/material-ui/blob/v4.11.0/packages/material-ui/src/styles/createMixins.js
      minHeight: 56,
      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        minHeight: 48,
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: 64,
      },
    },
  })
);
