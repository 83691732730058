import React, { useState, useEffect } from "react";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    singleButtonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: "10px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
  })
);

export const ClaimDetailPopup = (props: any) => {
  const classes = useStyles();
  const {
    returnWindowFlag,
    handleCloseReturnChanges,
    handleCloseReturn,
    returnSummaryWindowFlag,
    handleCloseReturnSummaryChanges,
    handleCloseReturnSummary,
    deleteWindowFlag,
    handleCloseDeleteChanges,
    handleCloseDelete,
    deleteSuccessWindowFlag,
    handleCloseDeleteSuccessChanges,
    claim_no,
    saveWindowFlag,
    handleCloseSaveChanges,
    handleCloseSave,
    cancelWindowFlag,
    handleCloseCancelChanges,
    cancelSuccessWindowFlag,
    handleCloseCancel,
    handleCloseCancelSuccessChanges,
  } = props;
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const { com } = useLocale({ defaultPath: "models.model_window" });
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  // return message
  const attentionReturnChanges = (
    <p>
      {getName("messages.MS0097")}
      <br />
      {getName("messages.MS0065")}
    </p>
  );
  const attentionReturnChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseReturnChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseReturn}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // delete message
  const attentionDeleteChanges = <p>{getName("messages.MS0105")}</p>;
  const attentionDeleteChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseDeleteChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseDelete}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // delete success message
  const attentionDeleteSuccessChanges = <p>{getName("messages.MS0106")}</p>;
  const attentionDeleteSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseDeleteSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // cancel message
  const attentionCancelChanges = <p>{getName("messages.MS0105")}</p>;
  const attentionCancelChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseCancelChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseCancel}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // cancel success message
  const attentionCancelSuccessChanges = <p>{getName("messages.MS0107")}</p>;
  const attentionCancelSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseCancelSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  const attentionSaveChanges = (
    <p>
      {getName("messages.MS0098",{
        param1: t("txt_claim_no") + ":" + claim_no,
      })
    }
    </p>
  );

  const attentionSaveChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseSave}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // return summary message
  const attentionReturnSummaryChanges = (
    <p>
      {getName("messages.MS0097")}
      <br />
      {getName("messages.MS0065")}
    </p>
  );
  const attentionReturnSummaryChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseReturnSummaryChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseReturnSummary}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  return (
    <>
      {returnWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionReturnChanges}
          openFlg={returnWindowFlag}
          button={attentionReturnChangesButton}
          handleCloseEvent={handleCloseReturnChanges}
        />
      )}

      {returnSummaryWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionReturnSummaryChanges}
          openFlg={returnSummaryWindowFlag}
          button={attentionReturnSummaryChangesButton}
          handleCloseEvent={handleCloseReturnSummaryChanges}
        />
      )}

      {saveWindowFlag && (
        <ModelWindow
          title="prompt_title_success"
          contents={attentionSaveChanges}
          openFlg={saveWindowFlag}
          button={attentionSaveChangesButton}
          handleCloseEvent={handleCloseSaveChanges}
        />
      )}
      {deleteWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeleteChanges}
          openFlg={deleteWindowFlag}
          button={attentionDeleteChangesButton}
          handleCloseEvent={handleCloseDeleteChanges}
        />
      )}
      {deleteSuccessWindowFlag && (
        <ModelWindow
          title="prompt_title_success"
          contents={attentionDeleteSuccessChanges}
          openFlg={deleteSuccessWindowFlag}
          button={attentionDeleteSuccessChangesButton}
          handleCloseEvent={handleCloseDeleteSuccessChanges}
        />
      )}
      {cancelWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionCancelChanges}
          openFlg={cancelWindowFlag}
          button={attentionCancelChangesButton}
          handleCloseEvent={handleCloseCancelChanges}
        />
      )}
      {cancelSuccessWindowFlag && (
        <ModelWindow
          title="prompt_title_success"
          contents={attentionCancelSuccessChanges}
          openFlg={cancelSuccessWindowFlag}
          button={attentionCancelSuccessChangesButton}
          handleCloseEvent={handleCloseCancelSuccessChanges}
        />
      )}
    </>
  );
};
