import React, { FC, useEffect, useState, useCallback } from "react";
import { useStyles } from "./hooks/summary.userStyle";
// route
import { useHistory, useParams } from "react-router-dom";
// components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { SummaryStepper } from "./components/Summary.Stepper";
import { SummaryAscInformation } from "./components/Summary.AscInformation";
import { SummaryUserCompanyInformation } from "./components/Summary.UserCompanyInformation";
import { SummaryRepairInformation } from "./components/Summary.RepairInformation";
import { SummaryWarrantyCharges } from "./components/Summary.WarrantyCharges";
import { SummaryMessageToCustomer } from "./components/Summary.MessageToCustomer";
import { SummaryDocuments } from "./components/Summary.Documents";
import { SummaryPhoto } from "./components/Summary.Photo";
import { SummaryUsedPartdTable } from "./components/Summary.UsedParts.Table";
import { ClaimDetailHistory } from "../../modules/components/claimHistory/ClaimHistory";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// api
import api from "../../modules/api/Api";
// methods
import {
  getApiResultStatus,
  getClaimDetailParams,
  handleDiffChangedData,
  diffContents,
  getPartsList,
} from "../../modules/common.methods";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
import {
  DEFAULT_VALUE_BLANK,
  CLAIM_NO_QUERY_STRING,
} from "../../modules/constants/claim.constants";
import { SummaryPopup } from "./components/Summary.Popup";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
import { getSubmmitParamFlag } from "./methods/summary.methods";
import { setLoborTotal } from "../../modules/methods/claim.methods";
import { SummaryFreeNote } from "./components/Summary.FreeNote";

export const Page: FC = () => {
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const classes = useStyles();
  const history = useHistory();
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { com } = useLocale();

  const [startFlg, setStartFlg] = useState(false);
  // form DB value
  const [formList, setFormList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );
  const [dataList, setDataList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );

  // page Type=> 0:create, 1:Edit
  const [claimType, setClaimType] = useState(0);
  const [claimStatus, setClaimStatus] = useState(0);
  const [loginUserInfo, setLoginUserInfo] = useState({});
  const [errorType, setErrorType] = useState([]);
  const [repairHistoryList, setRepairHistoryList] = useState([]);
  const [showHistoryFlg, setShowHistoryFlg] = useState(false);
  const [endUserInfoToolsList, setEndUserInfoToolsList] = useState([]) as any;

  // common
  const {
    getCommonInfo,
    getCommonConfig,
    setCommonInfo,
    getClaimDetailInfo,
    setClaimDetailInfo,
    getUserInfoDZ,
  } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  let config = getCommonConfig() as any;
  let detailInfo = getClaimDetailInfo() as any;
  let userInfoDZ = getUserInfoDZ() as any;

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  const getNewClaimNo = (): string => {
    return history.location.search.indexOf(CLAIM_NO_QUERY_STRING) >= 0
      ? history.location.search.replace(CLAIM_NO_QUERY_STRING, "")
      : "";
  };

  const [transportMessage, setTransportMessage] = useState({
    transport_way_pick_up: false,
    transport_way_return: false,
  });

  const [partsDefaultList, setPartsDefaultList] = useState({
    list_all: [],
    labor_list: [],
    administrative_cost_list: [],
    freight_list: [],
  });

  // page Init
  useEffect(() => {
    const _claim_no = getNewClaimNo();
    if (!startFlg && config.token) {
      setStartFlg(true);

      const _parts_list = getPartsList(config.affiliate_cd);
      setPartsDefaultList(_parts_list);

      if (_claim_no) {
        setClaimType(1);
      } else {
        setClaimType(0);
      }
      api.getClaimPrintAPIDumi(config);
      console.log("summary detailInfo", detailInfo);
      // ClaimDetailからSummary画面に遷移する
      if (Object.keys(detailInfo).length > 0) {
        console.log("endUserInfoToolsList", detailInfo);
        setFormList(detailInfo.formList);
        setDataList(detailInfo.dataList);
        // status
        setClaimStatus(detailInfo.claimStatus);
        // transportMessage
        setTransportMessage(detailInfo.transportMessage);
        // tools list
        setEndUserInfoToolsList(detailInfo.endUserInfoToolsList);
        // setClaimDetailInfo({});
        // memo message
        setMemoMessage(detailInfo.memoMessage);
      } else {
        // ClaimDetail以外ところからSummary画面に遷移する
        getClaimDetail(_claim_no);
      }
    }
  }, [startFlg, config]);

  const [claimErrorFlag, setClaimErrorFlag] = useState(false);

  const getClaimDetail = (_claim_no: any) => {
    apiLoading(true);
    // fetch the detail info AT1702
    console.log("summary getClaimDetail", _claim_no, config);
    api
      .getReadClaimDetailAPI(Number(_claim_no), config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        console.log("AT1702 response: ", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
          apiLoading(false);
          setClaimErrorFlag(true);
        } else {
          setClaimErrorFlag(false);
          // status ≠ 100：Draft
          if (Number(response.status) > 100) {
            setClaimDetailMessage(response);
          } else {
            // Draftの場合は、Summaryが非活性になります。
            handleToRetrun();
          }
        }
      });
  };

  const setClaimDetailMessage = (response: any) => {
    if (!response.claim_line) {
      response.claim_line = DEFAULT_VALUE_BLANK.claim_line;
    }
    if (!response.claim_address) {
      response.claim_address = DEFAULT_VALUE_BLANK.claim_address;
    }
    response.customer_no = userInfoDZ.customer_no;
    response.currency = userInfoDZ.currency;
    // set status
    setClaimStatus(response.status);
    // transport message
    let _transportMessage: any = {
      weight: response.weight,
      shipping_method: response.shipping_method,
      transport_way_pick_up: response.transport_way_pick_up,
      transport_way_return: response.transport_way_return,
      transport_cost_flg: response.transport_cost_flg,
    };
    setTransportMessage(_transportMessage);
    // set response data
    setDataList(JSON.parse(JSON.stringify(response)));
    // set page data
    setFormList(JSON.parse(JSON.stringify(response)));
    // tool information
    getToolsInformation(response.claim_address);

    // memoMessage初期化
    if (response.claim_note.length > 0) {
      const _list: any = response.claim_note.filter(
        (item: any) => item.note_type === 99
      );
      const _message: any = _list.length > 0 ? _list[0].note : "";
      setMemoMessage(_message);
    }
  };

  const getToolsInformation = (data: any) => {
    console.log("getToolsInformation", data);
    if (data && data.enduser_no) {
      apiLoading(true);
      api
        .getTools(config, data.enduser_no)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          console.log("get end user detail tools", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setEndUserInfoToolsList(response);
          }
        });
    }
  };

  const [chargesValue, setChargesValue] = useState({
    qty_amount: 0,
    parts_amount: 0,
    total_amount: 0,
    labor_value: 0,
    labor_amount: 0,
    freight_amount: 0,
    administrativer_amount: 0,
  });

  useEffect(() => {
    console.log("getReadModelErrorTypeList", formList);
    if (
      formList.serial_no !== "" &&
      formList.serial_no !== "*" &&
      formList.model_no !== ""
    ) {
      let query_parameters =
        "model_no=" +
        encodeURIComponent(formList.model_no) +
        "&serial_no=" +
        encodeURIComponent(formList.serial_no) +
        "&claim_no=" +
        encodeURIComponent(formList.claim_no ? formList.claim_no : 0) +
        "&job_no=" +
        encodeURIComponent(0);
      getRepairHistory(query_parameters);
    }
    if (formList.model_no !== "") {
      getReadModelErrorTypeList(formList.model_no);
    }
  }, [formList]);

  // common search method
  const getRepairHistory = (query_parameters: string) => {
    setRepairHistoryList([]);
    // Read Repair Job List AT1708
    return api
      .getReadRepairHistoryListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT1708 response: ", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response.history_list && response.history_list.length > 0) {
            response.history_list.map((item: any) => {
              if (item.history_no) {
                let obj: any = {
                  history_no: item.history_no,
                  entry_date: item.entry_date,
                  customer_no: item.customer_no,
                  type_kind: item.type_kind,
                };
                newList.push(obj);
              }
            });
          }
          setRepairHistoryList(newList);
        }
      });
  };

  const getReadModelErrorTypeList = async (model_no: any) => {
    console.log("getReadModelErrorTypeList api start");
    apiLoading(true);
    await api
      .getReadClaimLaborAPI(encodeURIComponent(model_no), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("get Model Error Type List", code, response);
        apiLoading(false);
        if (getApiResultStatus(code)) {
          if (response.length > 0) {
            if (Array.isArray(response)) {
              response.map((item: any, index: number) => {
                item.index = index;
              });
            }
          }
          setErrorType(response);
          const _charges = setLoborTotal(
            response,
            formList,
            transportMessage,
            partsDefaultList
          );
          console.log("getReadModelErrorTypeList", _charges);
          setChargesValue(_charges);
        } else {
          if (code === 404) {
            setErrorType([]);
            let message = com("common.constants.messages.MS0100", {
              param1: t("txt_model_no"),
            });
            //setFormErrorListValue("model_no_error", message);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  const openHistory = () => {
    setShowHistoryFlg(true);
  };

  const closeHistory = () => {
    setShowHistoryFlg(false);
  };

  // error message
  const errorMessage = (response: any) => {
    let message = "";
    if (response.message_id) {
      message = getName("messages." + response.message_id, {
        param1: t(response.key),
      });
    } else if (response.message) {
      if (response.status && response.status === 403) {
        message = getName("messages.MA0007");
      } else {
        message = response.message;
      }
    }
    if (message) {
      setAlertShow(true);
      setAlertMessage(message);
      setAlertType("error");
    }
  };

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // submmit button
  const [submitWindowFlag, setSubmitWindowFlag] = useState(false);
  const [submitErrorFlag, setSubmitErrorFlag] = useState(false);

  const handleToSubmmit = () => {
    console.log("handleToSubmmit");
    if (formList.save_flg) {
      setSubmitWindowFlag(true);
      setAlertMessage("");
      setAlertType("");
    } else {
      let _flg = diffMethods();
      if (formList.status === "300" && _flg) {
        const _message = com("common.constants.messages.MS0117");
        setAlertMessage(_message);
        setAlertType("error");
      } else {
        setSubmitWindowFlag(true);
        setAlertMessage("");
        setAlertType("");
      }
    }
  };

  const handleCloseSubmitChanges = () => {
    setSubmitWindowFlag(false);
  };
  const handleCloseSubmmit = () => {
    setSubmitWindowFlag(false);
    // setSubmitErrorFlag(false);
    // let _flg = diffMethods();
    // console.log("handleCloseSubmmit", _flg, formList, dataList);
    // if (_flg && formList.status && Number(dataList.status) > 100) {
    //   setSubmitErrorFlag(true);
    // } else {
    // }
    // memo check
    // handleToSubmmitFunc("200");
    handleMemoCheck();
  };

  const handleToSubmmitFunc = (status: any) => {
    console.log("handleToSubmmitFunc", status, memoMessage);
    apiLoading(true);
    const _chargesValue = setLoborTotal(
      errorType,
      formList,
      transportMessage,
      partsDefaultList
    );
    if (formList.claim_no) {
      if (
        (formList.status === "200" ||
          formList.status === "210" ||
          formList.status === "300") &&
        status === "200"
      ) {
        status = "210";
      }
      console.log("handleToSubmmitFunc2", status);

      // patch
      // 仕様変更：ASCメッセージ追加→memoMessage
      const _params = handleDiffChangedData(
        JSON.parse(JSON.stringify(formList)),
        dataList,
        status,
        memoMessage,
        transportMessage,
        _chargesValue,
        "submmit"
      );
      console.log("handleToSubmmitFunc patch params", _params, status);
      if (getSubmmitParamFlag(_params, formList)) {
        api
          .updateClaimAPI(formList.claim_no, config, _params)
          .then(({ code, response }: { code: any; response: any }) => {
            apiLoading(false);
            if (!getApiResultStatus(code)) {
              errorMessage(response);
            } else {
              console.log("handleToSubmmit2 patch", formList.claim_no);
              handleCloseComplete(formList.claim_no);
            }
          });
      } else {
        apiLoading(false);
        handleToRetrun();
      }
    } else {
      // post
      const _params = getClaimDetailParams(
        formList,
        status,
        transportMessage,
        _chargesValue
      );
      console.log("handleToSubmmitFunc post params", _params, _chargesValue);
      api
        .createClaimAPI(config, _params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            console.log("handleToSubmmit2 post", response);
            createSuccess(response);
          }
        });
    }
  };

  // memo
  const [memoMessage, setMemoMessage] = useState("");
  const [memoErrorMessage, setMemoErrorMessage] = useState("");
  const handleCloseMemoChanges = () => {
    setMemoErrorMessage("");
    if (memoMessage === "") {
      setMemoErrorMessage(
        com("common.constants.messages.MS0001", {
          param1: t("summary_txt_message_title"),
        })
      );
    } else {
      handleToSubmmitFunc("200");
    }
  };
  const changeMemoMessage = (newData: any) => {
    setMemoMessage(newData);
  };

  // memo Check
  const [memoFlag, setMemoFlag] = useState(false);
  const handleMemoCheck = () => {
    let _flg: any = true;
    if (formList.status === "300") {
      _flg = memoMessage !== "";
    }
    if (!_flg) {
      setMemoFlag(true);
    } else {
      handleToSubmmitFunc("200");
    }
  };

  const handleCloseMemo = () => {
    setMemoFlag(false);
  };

  // complete
  const [completeWindowFlag, setCompleteWindowFlag] = useState(false);
  const [completeClaimNo, setCompleteClaimNo] = useState("");
  const createSuccess = (data: any) => {
    console.log("createSuccess", data);
    setCompleteWindowFlag(true);
    setCompleteClaimNo(data.claim_no);
  };
  const handleCloseCompleteChanges = () => {
    console.log("handleCloseCompleteChanges");
    setCompleteWindowFlag(false);
    handleCloseComplete("");
  };
  const handleCloseComplete = (_claim_no: any = "") => {
    console.log("handleCloseComplete", completeClaimNo, _claim_no);
    const path =
      GetPrivateAppRoutePath(PathKey.COMPLETE) +
      "?claimno=" +
      (typeof _claim_no === "string" || typeof _claim_no === "number"
        ? _claim_no
        : completeClaimNo);
    console.log("handleCloseComplete", path);
    handleOnClick(path);
  };

  // edit button
  const handleToEdit = () => {
    sessionStorage.setItem("edit_flag", "true");
    const path = formList.claim_no
      ? GetPrivateAppRoutePath(PathKey.CLAIMDETAIL) +
        "?claimno=" +
        formList.claim_no
      : GetPrivateAppRoutePath(PathKey.CLAIMDETAIL);
    handleOnClick(path);
  };

  const diffMethods = () => {
    const _claim_no = getNewClaimNo();
    const _chargesValue = setLoborTotal(
      errorType,
      formList,
      transportMessage,
      partsDefaultList
    );
    return diffContents(
      _claim_no,
      formList,
      dataList,
      transportMessage,
      _chargesValue,
      memoMessage
    );
  };

  // return button
  const [returnWindowFlag, setReturnWindowFlag] = useState(false);
  const handleToRetrunFunc = () => {
    handleToRetrun();
  };
  const handleCloseReturnChanges = () => {
    setReturnWindowFlag(false);
  };
  const handleToRetrun = () => {
    // change
    const path = GetPrivateAppRoutePath(PathKey.CLAIM);
    handleOnClick(path);
  };

  // delete button
  const [deleteWindowFlag, setDeleteWindowFlag] = useState(false);
  const [deleteSuccessWindowFlag, setDeleteSuccessWindowFlag] = useState(false);
  const handleToDelete = () => {
    setDeleteWindowFlag(true);
  };
  const handleCloseDeleteChanges = () => {
    setDeleteWindowFlag(false);
  };
  const handleCloseDelete = () => {
    if (formList.claim_no) {
      const params = {
        updated_date: formList.updated_date,
      };
      apiLoading(true);
      api
        .deleteClaimAPI(config, formList.claim_no, params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          setDeleteWindowFlag(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setDeleteSuccessWindowFlag(true);
          }
        });
    }
  };
  const handleCloseDeleteSuccessChanges = () => {
    setDeleteSuccessWindowFlag(false);
    handleToRetrun();
  };

  // cancel button
  const [cancelWindowFlag, setCancelWindowFlag] = useState(false);
  const [cancelSuccessWindowFlag, setCancelSuccessWindowFlag] = useState(false);
  const handleToCancel = () => {
    setCancelWindowFlag(true);
  };
  const handleCloseCancelChanges = () => {
    setCancelWindowFlag(false);
  };
  const handleCloseCancel = () => {
    if (formList.claim_no) {
      const params = {
        status: "600",
        update_flg: "1",
        updated_date: formList.updated_date,
      };
      apiLoading(true);
      api
        .updateClaimAPI(formList.claim_no, config, params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          setCancelWindowFlag(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setCancelSuccessWindowFlag(true);
          }
        });
    }
  };
  const handleCloseCancelSuccessChanges = () => {
    setCancelSuccessWindowFlag(false);
    handleToRetrun();
  };

  // success message
  const successMessage = (message: any) => {
    apiLoading(false);
    setAlertMessage(message);
    setAlertType("success");
  };

  // fetch the detail info AT1702
  const getReadClaimDetailInfo = (claimNo: string) => {
    return api
      .getReadClaimDetailAPI(Number(claimNo), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AT1702 response: ", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
          setClaimErrorFlag(true);
        } else {
          setClaimErrorFlag(false);
          const _response = Object.assign(
            JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)),
            response
          );
          setClaimDetailInfoFunc(_response, _response, _response.status);
        }
      });
  };

  const setClaimDetailInfoFunc = (
    _formList: any,
    _dataList: any,
    _status: any
  ) => {
    // formList
    if (!_formList.claim_line) {
      _formList.claim_line = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_line)
      );
    }
    if (!_formList.claim_address) {
      _formList.claim_address = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_address)
      );
    }
    // dataList
    if (!_dataList.claim_line) {
      _dataList.claim_line = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_line)
      );
    }
    if (!_dataList.claim_address) {
      _dataList.claim_address = JSON.parse(
        JSON.stringify(DEFAULT_VALUE_BLANK.claim_address)
      );
    }
    // set status
    setClaimStatus(_status);
    // set response data
    setDataList(JSON.parse(JSON.stringify(_dataList)));
    // set page data
    setFormList(JSON.parse(JSON.stringify(_formList)));
    setClaimDetailReturnInfo(_dataList, _formList);
  };

  const setClaimDetailReturnInfo = (_dataList: any, _formList: any) => {
    if (Object.keys(detailInfo).length > 0) {
      const _param = {
        formList: JSON.parse(JSON.stringify(_formList)),
        claimType: 1,
        claimStatus: _dataList.status,
        dataList: JSON.parse(JSON.stringify(_dataList)),
        transportMessage: detailInfo.transportMessage,
        endUserInfoToolsList: detailInfo.endUserInfoToolsList,
      };
      console.log("setClaimDetailReturnInfo", _param);
      setClaimDetailInfo(_param);
    }
  };

  // save button
  const [saveWindowFlag, setSaveWindowFlag] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");

  const handleToSave = () => {
    console.log("handleToSave", formList, dataList);
    let _flg = diffMethods();
    if (_flg) {
      const _message = com("common.constants.messages.MS0117");
      setAlertMessage(_message);
      setAlertType("error");
    } else {
      handleToSaveFunc();
      setAlertMessage("");
      setAlertType("");
    }
  };

  const handleToSaveFunc = () => {
    apiLoading(true);
    console.log("handleToSaveFunc", formList);
    const _chargesValue = setLoborTotal(
      errorType,
      formList,
      transportMessage,
      partsDefaultList
    );
    if (formList.claim_no) {
      // patch
      const _params = handleDiffChangedData(
        JSON.parse(JSON.stringify(formList)),
        dataList,
        dataList.status,
        memoMessage,
        transportMessage,
        _chargesValue,
        "save"
      );
      console.log("save patch params", _params);
      api
        .updateClaimAPI(formList.claim_no, config, _params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            successMessage(com("common.constants.messages.MS0058"));
            getReadClaimDetailInfo(formList.claim_no);
          }
        });
    } else {
      // post
      const _params = getClaimDetailParams(
        formList,
        "100",
        transportMessage,
        _chargesValue
      );
      console.log("save post params", _params, _chargesValue);
      api
        .createClaimAPI(config, _params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            console.log("handleToSubmmit", response);
            setSaveWindowFlag(true);
            setCompleteClaimNo(response.claim_no);
            getReadClaimDetailInfo(response.claim_no);
          }
        });
    }
  };

  const handleCloseSaveChanges = () => {
    setSaveWindowFlag(false);
    handleCloseSave();
  };
  const handleCloseSave = () => {
    setSaveWindowFlag(false);
    // change
    const path =
      GetPrivateAppRoutePath(PathKey.SUMMARY) + "?claimno=" + completeClaimNo;
    handleOnClick(path);
    setClaimType(1);
    console.log("formList.claim_no", completeClaimNo);
    setCompleteClaimNo(completeClaimNo);
    getReadClaimDetailInfo(completeClaimNo);
  };

  // fileDownlad
  const fileDownload = (row: any) => {
    apiLoading(true);
    // Get Download signed url AT0402
    api
      .getDownloadSignedUrlByWco(formList.claim_no, row.sub_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          apiLoading(false);
          const url = response.content;
          let w = window.open();
          if (w) {
            w.location.href = url;
          }
        }
      });
  };

  const [downloadMessage, setDownloadMessage] = useState("");
  const handleToPrint = () => {
    const _claim_no = getNewClaimNo();
    setDownloadMessage("");
    apiLoading(true);
    // setTimeFunc(_claim_no, 0);
    handleToPrintFunc(_claim_no, 0);
  };

  let timer: any;
  const setTimeFunc = (_claim_no: any, count: number) => {
    clearTimeout(timer);
    console.log("setTimeFunc", count);

    if (count > 2) {
      setDownloadMessage(
        com("common.constants.messages.download_button_error")
      );
      apiLoading(false);
    } else {
      timer = setTimeout(() => {
        handleToPrintFunc(_claim_no, count + 1);
      }, 3000);
    }
  };

  const handleToPrintFunc = (_claim_no: any, count: number) => {
    console.log("setTimeFunc handleToPrintFunc", count);
    api
      .getDownloadSignedUrlByWco(_claim_no, "", config, "pdf")
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          if (code === "404" || code === 404) {
            setTimeFunc(_claim_no, count);
          } else {
            apiLoading(false);
            errorMessage(response);
          }
        } else {
          clearTimeout(timer);
          // const url = response.signed_url;
          const url = response.content;
          const fileName = `Warranty_Claim_${_claim_no}.pdf`;

          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              apiLoading(false);

              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
              }
            });
        }
      });
  };

  const changeView = (path: any) => {
    clearData();
    handleOnClick(path);
  };

  // 画面リロードのため、データクリア必要
  const clearData = () => {
    setAlertShow(false);
    setAlertMessage("");
    setAlertType("error");
    setFormList(JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)));
    setDataList(JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)));
    setClaimType(0);
    setClaimStatus(0);
    setLoginUserInfo({});
    setErrorType([]);
    setRepairHistoryList([]);
    setShowHistoryFlg(false);
    setEndUserInfoToolsList([]);
    // transport
    setTransportMessage({
      transport_way_pick_up: false,
      transport_way_return: false,
    });
    // charges
    setChargesValue({
      qty_amount: 0,
      parts_amount: 0,
      total_amount: 0,
      labor_value: 0,
      labor_amount: 0,
      freight_amount: 0,
      administrativer_amount: 0,
    });
    // submit
    setSubmitWindowFlag(false);
    setSubmitErrorFlag(false);
    // complete
    setCompleteWindowFlag(false);
    setCompleteClaimNo("");
    // return
    setReturnWindowFlag(false);
    // delte
    setDeleteWindowFlag(false);
    setDeleteSuccessWindowFlag(false);
    // cancel
    setCancelWindowFlag(false);
    setCancelSuccessWindowFlag(false);
    // save
    setSaveWindowFlag(false);
    setErrorMessageText("");
    // download
    setDownloadMessage("");
    setStartFlg(false);
  };

  const fileDownloadFile = (message: any) => {
    console.log("claim detail file", message);
    api
      .getFileDZ(config, message.link)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("claim detail file response", response);
        if (!getApiResultStatus(code)) {
          errorMessage({ message_id: "MA0003" });
        } else {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          a.href = url;
          a.download = message.filename;
          a.click();
        }
      });
  };

  const contents = (
    <div id="contents">
      <AlertMesssageBox
        show={alertMessage && alertMessage !== ""}
        message={alertMessage}
        type={alertType}
      />
      {!claimErrorFlag && (
        <div>
          {downloadMessage !== "" && (
            <AlertMesssageBox
              show={downloadMessage && downloadMessage !== ""}
              message={downloadMessage}
              type={"warning"}
            />
          )}
          <SummaryStepper
            claimType={claimType}
            handleToSubmmit={handleToSubmmit}
            formList={formList}
            claimStatus={claimStatus}
            handleToEdit={handleToEdit}
            handleToRetrunFunc={handleToRetrunFunc}
            handleToSave={handleToSave}
            handleToDelete={handleToDelete}
            handleToCancel={handleToCancel}
            submitErrorFlag={submitErrorFlag}
            handleToPrint={handleToPrint}
          />

          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                {(formList.status === "210" ||
                  formList.status === "300" ||
                  formList.status === "400") && (
                  <Paper className={classes.paper}>
                    <SummaryMessageToCustomer
                      list={formList.claim_note}
                      status={formList.status}
                    />
                  </Paper>
                )}
                <Paper className={classes.paper}>
                  <SummaryDocuments
                    data={formList.claim_media.claim_media_invoice}
                    fileDownload={fileDownload}
                    formList={formList}
                    endUserInfoToolsList={endUserInfoToolsList}
                    fileDownloadFile={fileDownloadFile}
                  />
                </Paper>
                <Paper className={classes.paper}>
                  <SummaryPhoto
                    data={formList.claim_media.claim_media_picture}
                    fileDownload={fileDownload}
                  />
                </Paper>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Paper className={classes.paper}>
                          <SummaryAscInformation
                            formList={formList}
                            loginUserInfo={loginUserInfo}
                            claimType={claimType}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper className={classes.paper}>
                          <SummaryUserCompanyInformation
                            data={formList.claim_address}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <SummaryWarrantyCharges
                            formList={formList}
                            chargesValue={chargesValue}
                            errorType={errorType}
                            transportMessage={transportMessage}
                            userInfoDZ={userInfoDZ}
                            partsListAdministrativeCost={
                              partsDefaultList.administrative_cost_list
                            }
                            config={config}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper className={classes.paper}>
                      <SummaryRepairInformation
                        data={formList}
                        errorType={errorType}
                        repairHistoryList={repairHistoryList}
                        openHistory={openHistory}
                        closeHistory={closeHistory}
                        showHistoryFlg={showHistoryFlg}
                        userInfoDZ={userInfoDZ}
                        changeView={changeView}
                      />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <SummaryUsedPartdTable
                        dataList={formList.claim_line}
                        formList={formList}
                        userInfoDZ={userInfoDZ}
                        partsListAll={partsDefaultList.list_all}
                      />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <SummaryFreeNote
                        dataNote={formList.claim_note}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {claimType !== 0 && (
            <div className={classes.history_area}>
              <ClaimDetailHistory
                dataList={formList.claim_updated_history}
                currency={userInfoDZ.currency}
                errorType={errorType}
              />
            </div>
          )}
          <SummaryPopup
            claim_no={completeClaimNo}
            completeWindowFlag={completeWindowFlag}
            handleCloseCompleteChanges={handleCloseCompleteChanges}
            handleCloseComplete={handleCloseComplete}
            submitWindowFlag={submitWindowFlag}
            handleCloseSubmitChanges={handleCloseSubmitChanges}
            handleCloseSubmmit={handleCloseSubmmit}
            deleteWindowFlag={deleteWindowFlag}
            handleCloseDeleteChanges={handleCloseDeleteChanges}
            handleCloseDelete={handleCloseDelete}
            deleteSuccessWindowFlag={deleteSuccessWindowFlag}
            handleCloseDeleteSuccessChanges={handleCloseDeleteSuccessChanges}
            returnWindowFlag={returnWindowFlag}
            handleCloseReturnChanges={handleCloseReturnChanges}
            handleCloseReturn={handleToRetrun}
            saveWindowFlag={saveWindowFlag}
            handleCloseSaveChanges={handleCloseSaveChanges}
            handleCloseSave={handleCloseSave}
            cancelWindowFlag={cancelWindowFlag}
            handleCloseCancelChanges={handleCloseCancelChanges}
            handleCloseCancel={handleCloseCancel}
            cancelSuccessWindowFlag={cancelSuccessWindowFlag}
            handleCloseCancelSuccessChanges={handleCloseCancelSuccessChanges}
            memoMessage={memoMessage}
            memoFlag={memoFlag}
            changeMemoMessage={changeMemoMessage}
            handleCloseMemoChanges={handleCloseMemoChanges}
            handleCloseMemo={handleCloseMemo}
            errorMessage={memoErrorMessage}
          />
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
