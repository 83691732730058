import React, { FC } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// props
type Props = {
  title: any;
  contents: any;
  button: any;
  openFlg: boolean;
  handleCloseEvent: () => void;
  notTitle?: any;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const ModelWindow: FC<Props> = ({
  title = "",
  contents,
  button = "",
  openFlg = false,
  handleCloseEvent,
  notTitle = false,
}) => {
  const { t } = useLocale({ defaultPath: "models.model_window" });
  // close Event
  const handleClose = () => {
    handleCloseEvent();
  };

  return (
    <React.Fragment>
      <div className="modelContainer">
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openFlg}
          maxWidth={false}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title && (notTitle ?title:t(title))}
          </DialogTitle>
          <DialogContent dividers>{contents}</DialogContent>
          <DialogActions>{button}</DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
};
