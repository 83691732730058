import React, { useState, useCallback, useEffect } from "react";
// hook
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// UI
import { useStyles } from "../hooks/information.userStyles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link as LinkTo } from "react-router-dom";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";
import { getNote } from "../../../modules/common.methods";
import { getDomain } from "../../../modules/common.methods";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

export const SummaryRepairInformation = (props: any) => {
  const classes = useStyles();
  const {
    data,
    errorType,
    repairHistoryList,
    openHistory,
    closeHistory,
    showHistoryFlg,
    userInfoDZ,
    changeView,
  } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { getCommonConfig } = useCommonInfo();
  let config = getCommonConfig() as any;

  // fetch the error type name from constants
  const getErrorTypeName = (code: string) => {
    let errorTypeName = "";
    let errorTypeList: any = [];
    if (Array.isArray(errorType)) {
      errorTypeList = errorType;
    }
    if (code && code !== "") {
      for (let index in errorTypeList) {
        if (Number(errorTypeList[index].error_type) === Number(code)) {
          errorTypeName = errorTypeList[index].error_description;
          break;
        }
      }
    }
    return errorTypeName;
  };

  // fetch the condition of tool name from constants
  const getConditionOfToolName = (code: string) => {
    let conditionOfToolName = "";
    let conditionOfToolList: any = [];
    if (Array.isArray(com("condition_of_tool"))) {
      conditionOfToolList = com("condition_of_tool");
    }
    if (code && code !== "") {
      for (let index in conditionOfToolList) {
        if (Number(conditionOfToolList[index].code) === Number(code)) {
          conditionOfToolName = conditionOfToolList[index].name;
          break;
        }
      }
    }
    return conditionOfToolName;
  };

  const getName = (code: string, listName: string) => {
    let name = "";
    let list: any = [];
    if (Array.isArray(com(listName))) {
      list = com(listName);
    }
    if (code && code !== "") {
      for (let index in list) {
        if (Number(list[index].code) === Number(code)) {
          name = list[index].name;
          break;
        }
      }
    }
    return name;
  };

  return (
    <form noValidate autoComplete="off">
      <div>
        <div>
          <div className={classes.box}>
            {t("summary_title_repair_information")}
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_claim_number")}</span>
            <span>{data.claim_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("txt_customer_type")}</span>
            <span>
              {data.claim_address &&
                getName(data.claim_address.customer_type, "customer_type")}
            </span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_customer_po_no")}</span>
            <span>{data.customer_po_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_model_number")}</span>
            <span>{data.model_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_serial_number")}</span>
            {repairHistoryList.length > 0 && data.serial_no !== "*" && (
              <span
                className={classes.generalModelLink}
                onClick={() => {
                  openHistory();
                }}
              >
                {data.serial_no}
              </span>
            )}
            {(repairHistoryList.length === 0 || data.serial_no === "*") && (
              <span>{data.serial_no}</span>
            )}
          </div>
          {showHistoryFlg && (
            <div style={{ position: "absolute", zIndex: 10000 }}>
              <Table stickyHeader className={classes.root02}>
                <TableHead className={classes.thead}>
                  <TableRow>
                    <TableCell>
                      <div className={classes.tableHeadTitle}>
                        {t("txt_repair_history")}
                      </div>

                      <IconButton
                        aria-label="close"
                        className={classes.closeBtn}
                        onClick={() => {
                          closeHistory();
                        }}
                      >
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                  <TableContainer className={classes.groupContainer}>
                    {repairHistoryList.map((row: any, index: number) => {
                      let path: any;
                      if (row.type_kind === 1) {
                        return (
                          <TableRow>
                            <TableCell>
                              <span>
                                {!row.entry_date || row.entry_date === ""
                                  ? ""
                                  : date(new Date(row.entry_date).getTime())}
                              </span>
                              {row.customer_no === userInfoDZ.customer_no && (
                                <Link
                                  href={
                                    getDomain(config.affiliate_cd) +
                                    "/repair-job/" +
                                    row.history_no
                                  }
                                  target="_blank"
                                  rel="noopener"
                                  className={classes.generalModelLink02}
                                >
                                  {row.history_no}
                                </Link>
                              )}
                              {row.customer_no !== userInfoDZ.customer_no && (
                                <span
                                  className={classes.generalModelLinkBlackFont}
                                >
                                  {row.history_no}
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        path =
                          GetPrivateAppRoutePath(PathKey.SUMMARY) +
                          `?claimno=${row.history_no}`;
                        return (
                          <TableRow>
                            <TableCell>
                              <span>
                                {!row.entry_date || row.entry_date === ""
                                  ? ""
                                  : date(new Date(row.entry_date).getTime())}
                              </span>
                              {row.customer_no === userInfoDZ.customer_no && (
                                <span
                                  onClick={() => changeView(path)}
                                  className={classes.generalModelLink}
                                >
                                  {row.history_no}
                                </span>
                              )}
                              {row.customer_no !== userInfoDZ.customer_no && (
                                <span
                                  className={classes.generalModelLinkBlackFont}
                                >
                                  {row.history_no}
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableContainer>
                </TableBody>
              </Table>
            </div>
          )}
          <div className={classes.row}>
            <span>{t("summary_txt_purchased_date")}</span>
            <span>
              {!data.purchased_date || data.purchased_date === ""
                ? ""
                : date(new Date(data.purchased_date).getTime())}
            </span>
          </div>
          <div className={classes.row}>
            <span>{t("txt_received_date")}</span>
            <span>
              {!data.received_date || data.received_date === ""
                ? ""
                : date(new Date(data.received_date).getTime())}
            </span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_repair_date")}</span>
            <span>
              {!data.repair_date || data.repair_date === ""
                ? ""
                : date(new Date(data.repair_date).getTime())}
            </span>
          </div>

          <div className={classes.row}>
            <span>{t("summary_txt_problem")}</span>
            <span>{getErrorTypeName(data.error_type)}</span>
          </div>
          <div className={classes.row}>
            <span>{t("txt_guarantee_type")}</span>
            <span>{getName(data.guarantee_type, "guarantee_type")}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_condition_of_tool")}</span>
            <span>{getConditionOfToolName(data.condition_of_tool)}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_special_remark")}</span>
            <span className={classes.spacialRemark}>
              {getNote(data.claim_note,10)}
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};
