import React, { useState } from "react";
// hook
import { makeStyles } from "@material-ui/core/styles";
// component
import { ClaimEndUserSearchControlTableThead } from "./ClaimDetail.EndUser.Search.Table.Thead";
import { ClaimEndUserSearchControlTableTbody } from "./ClaimDetail.EndUser.Search.Table.Tbody";
// UI
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

export const ClaimEndUserSearchControlTable = (props: any) => {
  const {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    endUserInfoList,
    endUserInfoFlg,
    setEndUserInfoFlg,
    searchStatus,
  } = props;
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: "528px",
    },
  });
  const classes = useStyles();

  const handleClick = (value: any) => props.setRadioSelectedValue(value);
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <ClaimEndUserSearchControlTableThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            searchStatus={searchStatus}
          />
          <ClaimEndUserSearchControlTableTbody
            data={props.data}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            handleClick={handleClick}
            researchFlg={props.researchFlg}
            setResearchFlg={props.setResearchFlg}
            endUserInfoList={endUserInfoList}
            endUserInfoFlg={endUserInfoFlg}
            setEndUserInfoFlg={setEndUserInfoFlg}
            searchStatus={searchStatus}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
};
