import React from "react";
import { SvgIcon } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Receipt from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";

export const ClaimIcon = () => (
  <SvgIcon>
    <AssignmentIcon />
  </SvgIcon>
);

export const ApIcon = () => (
  <SvgIcon>
    <Receipt />
  </SvgIcon>
);

export const configrationIcon = () => (
  <SvgIcon>
    <SettingsIcon />
  </SvgIcon>
);
