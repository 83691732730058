import {
  layerList,
  SELECTED_CLASS,
} from "../constants/ClaimDetail.Parts.constants";
import { getNumberDataType } from "../../../modules/common.methods";
import { priceOutputByCountryCode } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { determineDataType } from "../../../modules/common.methods";
export const init = (self_original: any, _region: any, _models: any) => {
  let self_pages = [] as any;
  let self_drawings = [] as any;
  let self_specificationModels = [] as any;
  let self_parts = [] as any;

  let fullParts = [] as any;
  let dataFlg = false;

  let self_technicalInformation = self_original.technicalInformation;
  let technicalInformation_movies = self_technicalInformation
    ? self_technicalInformation.movies
    : [];
  let technicalInformation_documents = self_technicalInformation
    ? self_technicalInformation.documents
    : [];
  let technicalInformation_specificationModels =
    self_original.specificationModels ? self_original.specificationModels : [];

  if (determineDataType(self_original["specificationModels"])) {
    const pages = determineDataType(self_original["drawings"])
      ? new Set(self_original["drawings"].map((m: any) => m["page"]))
      : [];

    (Array.from(pages) as number[])
      .sort((a, b) => a - b)
      .forEach((e: any) => {
        self_pages.push(e);
        const item = {
          layer1: self_original["drawings"].filter(
            (f: any) => f.page === e && f.page && f.role === "Layer1"
          ),
          layer2: self_original["drawings"].filter(
            (f: any) => f.page === e && f.page && f.role === "Layer2"
          ),
        };
        self_drawings.push(item);
      });

    self_original["specificationModels"].forEach((e: any) => {
      const specificationCode = e.specificationCode;
      e["parts"].forEach((item: any, index: number) => {
        item.sid = index;
        item.uniqueID =
          String(item.partNo) +
          ":" +
          String(item.drawingNo) +
          ":" +
          String(item.generation);
        item.item_no = item.partNo;
        item.order_qty = item.qty;
        item.description = item.name;

        if (item.setParts && item.setParts.length > 0) {
          item.setParts.forEach((subItem: any) => {
            subItem.uniqueID =
              String(subItem.partNo) +
              ":" +
              String(item.drawingNo) +
              ":" +
              String(item.partNo);
            subItem.item_no = subItem.partNo;
            subItem.order_qty = subItem.qty;
            subItem.description = subItem.name;
          });
        }

        if (technicalInformation_specificationModels.length > 0) {
          technicalInformation_specificationModels.forEach((subList: any) => {
            const sub_specificationCode = subList.specificationCode;
            if (specificationCode === sub_specificationCode) {
              if (subList.parts.length > 0) {
                let partsList = subList.parts.filter(
                  (subItem: any) =>
                    subItem.partNo === item.partNo &&
                    subItem.generation === item.generation
                );
                if (partsList.length > 0) {
                  if (partsList[0].engineeringChange) {
                    item.open = partsList[0].engineeringChange.open;
                    item.close = partsList[0].engineeringChange.close;
                  }
                }
              }
            }
          });
        }

        const list = fullParts.filter(
          (fullItem: any) => fullItem.uniqueID === item.uniqueID
        );
        if (list.length === 0) {
          fullParts.push(item);
        }
      });
      self_specificationModels.push(e);
    });

    self_specificationModels.unshift({
      specificationCode: "Full",
      parts: fullParts,
    });
    let _selectedModelFlag = false;
    let _selectedModelList: any = [];
    if (_models) {
      _selectedModelList = self_specificationModels.filter(
        (item: any) => item.specificationCode === _models
      );
      _selectedModelFlag = _selectedModelList.length > 0;
    }
    if (_selectedModelFlag) {
      self_parts = _selectedModelList[0].parts;
    } else {
      self_parts = fullParts;
    }

    self_parts.sort(function (a: any, b: any) {
      if (a.displayId < b.displayId) {
        return -1;
      } else if (a.displayId > b.displayId) {
        return 1;
      } else {
        if (a.optionalNo < b.optionalNo) {
          return -1;
        } else if (a.optionalNo > b.optionalNo) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  } else {
    dataFlg = true;
  }

  return {
    self_original,
    self_pages,
    self_specificationModels,
    self_parts,
    self_drawings,
    technicalInformation_movies,
    technicalInformation_documents,
    dataFlg,
  };
};

export const getSvgPath = (data: any) => {
  let list: any[] = [];
  if (determineDataType(data)) {
    data.map((item: any) => {
      let obj: any = {};
      if (determineDataType(layerList)) {
        layerList.map((layer: string) => {
          const path = item[layer].filter((f: any) =>
            f.path.endsWith(".svg")
          )[0].path;
          obj[layer] = path;
        });
        list.push(obj);
      }
    });
  }
  return list;
};

export const getSvg = (url: string, path: string) => {
  return fetch(url + path, { method: "GET" })
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      const doc = new DOMParser().parseFromString(result, "image/svg+xml");
      return doc;
    });
};

// myself
export const setMyself = (id: any, flg: boolean) => {
  // For myself
  const element: any = document.getElementById("tabLayerDrawing");
  const selectedPath = element.querySelectorAll(`[data-id="${id}"`);
  if (selectedPath) {
    for (let i = 0; i < selectedPath.length; i++) {
      const selectedPathItem = selectedPath[i];
      if (flg) {
        selectedPathItem.classList.remove(SELECTED_CLASS);
      } else {
        selectedPathItem.classList.add(SELECTED_CLASS);
      }
    }
  }
};

export const handleTableMouseEvent = (id: number, type: string = "") => {
  const element: any = document.getElementById("tabLayerDrawing");
  const selectedObjList = element.querySelectorAll(`[data-id="${id}"`);
  if (selectedObjList) {
    for (let i = 0; i < selectedObjList.length; i++) {
      const selectedObjItem = selectedObjList[i];
      if (type === "mouseover") {
        selectedObjItem.classList.add("emphasize");
      } else {
        selectedObjItem.classList.remove("emphasize");
      }
    }
  }
};

export const zoom = (scale: any) => {
  const drawings = document.querySelectorAll("#tabLayerDrawing .drawing");
  drawings.forEach((f: any) => {
    if (f.style) {
      f.style.transform = `scale(${scale}, ${scale})`;
    }
  });
};

export const setTableSelectedListFun = (
  item: any,
  tableSelectedList: any,
  registerData: any
) => {
  const id = item.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);
  if (index < 0) {
    newTableData.push(id);
  } else {
    let flg = false;
    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        flg = true;
      }
    }
    if (!flg) {
      newTableData.splice(index, 1);
    }
  }

  return newTableData;
};

export const setSvgSelectedListFun = (
  item: any,
  svgSelectedList: any,
  fullParts: any,
  tableSelectedList: any,
  registerData: any
) => {
  const drawingNo = String(item.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);
  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
    setMyself(item.drawingNo, false);
  } else {
    let flg = false;
    let registerFlg = false;
    // table
    const partsList: any = getPartInfomationByDrawingNo(fullParts, item);

    if (partsList.length > 0) {
      for (let i = 0; i < partsList.length; i++) {
        const subItem = partsList[i];
        const index = tableSelectedList.indexOf(subItem.uniqueID);
        if (index >= 0 && item.uniqueID !== subItem.uniqueID) {
          flg = true;
          break;
        }
      }
    }

    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        registerFlg = true;
      }
    }

    if (!flg && !registerFlg) {
      newSvgData.splice(drawingIndex, 1);
      setMyself(item.drawingNo, true);
    }
  }
  return newSvgData;
};

export const getItemNoMessage = () => {
  return { item_no: "", description: "" };
};

export const getReisterStatus = (option: any, status: any) => {
  if (
    (option === 3 || option === 4 || option === 5) &&
    (status === "300" || status === "500")
  ) {
    return "quote";
  } else if (
    option === 1 ||
    option === 2 ||
    ((option === 3 || option === 4 || option === 5) &&
      (status === "400" || status === "410" || status === "510"))
  ) {
    return "order";
  }
  return "";
};

export const getRegisterInsertMessage = (list: any) => {
  let insertList: any = [];
  let errorList: any = [];
  list.forEach((item: any, index: any) => {
    if (!item.order_qty) {
      const error = {
        row: index + 1,
        type: "1",
      };
      errorList.push(error);
    }
    const message = setIsertResponse(item);
    insertList.push(message);
  });
  return {
    insertList: insertList,
    errorList: errorList,
  };
};

const setIsertResponse = (item: any) => {
  return {
    item_no: item.item_no,
    order_qty: getNumberDataType(item.order_qty),
    net_price: getNumberDataType(item.net_price),
    webmsi_region: item.webmsi_region,
    webmsi_spec: item.webmsi_spec,
  };
};

export const getRegisterOrderMessage = (responseList: any, list: any) => {
  let insertList: any = [];
  let errorList: any = [];
  let updateList: any = [];
  let deleteList: any = [];
  for (let i = 0; i < responseList.length; i++) {
    const item = responseList[i];
    let DeleteFlg = false;
    for (let i = 0; i < list.length; i++) {
      const subItem = list[i];
      if (item.item_no === subItem.item_no && item.sub_no === subItem.sub_no) {
        DeleteFlg = true;
        break;
      }
    }
    if (!DeleteFlg) {
      const message = {
        sub_no: item.sub_no,
        updated_date: item.updated_date,
      };
      deleteList.push(message);
    }
  }

  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    if (!item.order_qty) {
      const error = {
        row: i + 1,
        type: "1",
      };
      errorList.push(error);
    } else if (isNaN(getNumberDataType(item.order_qty) as number)) {
      const error = {
        row: i + 1,
        type: "2",
      };
      errorList.push(error);
    }

    let InsertFlg = false;
    for (let i = 0; i < responseList.length; i++) {
      const subItem = responseList[i];
      if (item.item_no === subItem.item_no && item.sub_no === subItem.sub_no) {
        let UpdateFlg = false;
        let message: any = {};
        if (
          getNumberDataType(item.order_qty) !==
          getNumberDataType(subItem.order_qty)
        ) {
          UpdateFlg = true;
          message.order_qty = getNumberDataType(item.order_qty);
        }
        if (
          getNumberDataType(item.net_price) !==
          getNumberDataType(subItem.net_price)
        ) {
          UpdateFlg = true;
          message.net_price = getNumberDataType(item.net_price);
        }
        if (item.webmsi_region !== subItem.webmsi_region) {
          UpdateFlg = true;
          message.webmsi_region = item.webmsi_region;
        }
        if (item.webmsi_spec !== subItem.webmsi_spec) {
          UpdateFlg = true;
          message.webmsi_spec = item.webmsi_spec;
        }
        if (UpdateFlg) {
          message.sub_no = subItem.sub_no;
          message.item_no = item.item_no;
          message.updated_date = subItem.updated_date;
          updateList.push(message);
        }
        InsertFlg = true;
        break;
      }
    }
    if (!InsertFlg) {
      const message = setIsertResponse(item);
      insertList.push(message);
    }
  }

  return {
    insertList: insertList,
    errorList: errorList,
    updateList: updateList,
    deleteList: deleteList,
  };
};

// get selected message information
export const getSelectedCurrectMessage = (id: any, fullParts: any) => {
  let currentMessage: any = { id: "", message: null };
  if (fullParts.length > 0) {
    const partList: any = fullParts.filter(
      (item: any) => String(item.drawingNo) === String(id)
    );
    if (partList.length > 0) {
      partList.forEach((item: any) => {
        if (currentMessage.id === "") {
          currentMessage.id = item.generation;
          currentMessage.message = JSON.parse(JSON.stringify(item));
        }
      });
    }
  }
  return currentMessage;
};

export const handleTableDoubleClickEvent = (
  message: any,
  registerData: any,
  svgSelectedList: any,
  tableSelectedList: any,
  fullParts: any
) => {
  let newReigsterData: any = [];

  const drawingNo = String(message.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);

  const id = message.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);
  console.log("index", drawingIndex, index);

  newReigsterData = registerData.slice(0);
  const messageForNewRegisterData = JSON.parse(JSON.stringify((message)));
  // 登録時はqtyを1とする。
  messageForNewRegisterData.order_qty = 1;
  newReigsterData.push(messageForNewRegisterData);

  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
  }

  if (index < 0) {
    newTableData.push(id);
  }
  setMyself(message.drawingNo, false);

  return {
    newReigsterData: newReigsterData,
    newSvgData: newSvgData,
    newTableData: newTableData,
  };
};

export const handleSvgDoubleClickEvent = (
  message: any,
  registerData: any,
  svgSelectedList: any,
  tableSelectedList: any,
  fullParts: any
) => {
  let newReigsterData: any = [];
  // const registerList = registerData.filter(
  //   (item: any) => item.uniqueID == message.uniqueID
  // );

  const drawingNo = String(message.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);

  const id = message.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);

  // if (registerList.length == 0) {
  newReigsterData = registerData.slice(0);
  const messageForNewRegisterData = JSON.parse(JSON.stringify((message)));
  // 登録時はqtyを1とする。
  messageForNewRegisterData.order_qty = 1;
  newReigsterData.push(messageForNewRegisterData);

  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
  }

  if (index < 0) {
    newTableData.push(id);
  }
  setMyself(message.drawingNo, false);

  return {
    newReigsterData: newReigsterData,
    newSvgData: newSvgData,
    newTableData: newTableData,
  };
};

export const addReigsterMessage = (
  ItemNoError: any,
  itemNoMessage: any,
  fullParts: any,
  tableSelectedList: any,
  svgSelectedList: any,
  registerData: any
) => {
  let list: any = [];
  let newTableData = tableSelectedList.slice(0);
  let newSvgData = svgSelectedList.slice(0);
  let newRegisterData: any = registerData.slice(0);
  let item: {
    item_no: "";
    description: "";
    order_qty: Number;
    suspend_flg: Boolean;
  };

  // get item no
  if (!itemNoMessage.itemList || itemNoMessage.itemList.length <= 0) {
    // itemNo
    const data = Object.assign({}, itemNoMessage);
    list.push(data);
    const partsList: any = getPartInfomationByItemNo(fullParts, data);

    if (partsList.length > 0) {
      partsList.forEach((item: any) => {
        const index = tableSelectedList.indexOf(item.uniqueID);
        if (index < 0) {
          newTableData.push(item.uniqueID);
        }

        const drawingNo = String(item.drawingNo);
        const drawingIndex = svgSelectedList.indexOf(String());
        if (drawingIndex < 0) {
          newSvgData.push(drawingNo);
          setMyself(item.drawingNo, false);
        }
      });
    }
    newRegisterData.push(itemNoMessage);
  } else {
    for (let i = 0; i < itemNoMessage.itemList.length; i++) {
      item = itemNoMessage.itemList[i];
      const data = Object.assign({}, itemNoMessage.itemList[i]);
      const partsList: any = getPartInfomationByItemNo(fullParts, data);

      if (partsList.length > 0) {
        partsList.forEach((item: any) => {
          const index = tableSelectedList.indexOf(item.uniqueID);
          if (index < 0) {
            newTableData.push(item.uniqueID);
          }

          const drawingNo = String(item.drawingNo);
          const drawingIndex = svgSelectedList.indexOf(String());
          if (drawingIndex < 0) {
            newSvgData.push(drawingNo);
            setMyself(item.drawingNo, false);
          }
        });
      }
      newRegisterData.push(item);
    }
  }

  return {
    newRegisterData: newRegisterData,
    newSvgData: newSvgData,
    newTableData: newTableData,
  };
};

export const deleteReigsterMessage = (
  registerData: any,
  selected: any,
  fullParts: any,
  svgSelectedList: any,
  tableSelectedList: any
) => {
  let newReigsterList: any = [];
  // register data
  registerData.forEach((item: any, index: number) => {
    const name: string =
      item.uniqueID !== undefined
        ? item.uniqueID
        : item.item_no + ":" + (item.sub_no !== undefined ? item.sub_no : 0);
    const selectedIndex = selected.indexOf(index);
    if (selectedIndex === -1) {
      newReigsterList.push(item);
    }
  });

  // selected Data Information
  let newTableData: any = [];
  let newSvgData: any = [];
  if (tableSelectedList.length > 0 && newReigsterList.length > 0) {
    tableSelectedList.forEach((tableItem: any) => {
      const _item_no = tableItem.split(":")[0];
      console.log("deleteReigsterMessage _item_no", _item_no);
      newReigsterList.forEach((subItem: any) => {
        if (_item_no === subItem.item_no) {
          newTableData.push(tableItem);
        }
      });
    });
  }

  if (svgSelectedList.length > 0) {
    svgSelectedList.forEach((svgItem: any) => {
      let flag = false;
      const _itemList = getPartInfomationByDrawingNo(fullParts, {
        drawingNo: svgItem,
      });
      if (_itemList.length > 0 && newReigsterList.length > 0) {
        _itemList.forEach((_item: any) => {
          newReigsterList.forEach((_newItem: any) => {
            if (_newItem.item_no === _item.item_no) {
              flag = true;
            }
          });
        });
      }
      if (flag) {
        setMyself(svgItem, false);
        newSvgData.push(svgItem);
      } else {
        setMyself(svgItem, true);
      }
    });
  }

  return {
    newTableData: newTableData,
    newSvgData: newSvgData,
    newReigsterList: newReigsterList,
  };
};

export const handleClickEventBySvgFunc = (
  item: any,
  tableSelectedList: any,
  registerData: any,
  svgSelectedList: any
) => {
  const id = item.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);
  if (index < 0) {
    newTableData.push(id);
  } else {
    let flg = false;
    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        flg = true;
      }
    }
    if (!flg) {
      newTableData.splice(index, 1);
    }
  }

  const drawingNo = String(item.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);
  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
    setMyself(item.drawingNo, false);
  } else {
    let flg = false;
    let registerFlg = false;

    // svg
    if (index < 0) {
      flg = true;
    }

    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        registerFlg = true;
      }
    }

    if (!flg && !registerFlg) {
      newSvgData.splice(drawingIndex, 1);
      setMyself(item.drawingNo, true);
    }
  }
  return {
    newTableData: newTableData,
    newSvgData: newSvgData,
  };
};

export const setDefaultSVGandPartsFunc = (list: any, partsList: any) => {
  let newTableData: any = [];
  let newSvgData: any = [];
  list.forEach((item: any) => {
    partsList.forEach((subItem: any) => {
      if (item.item_no === subItem.item_no) {
        const id = subItem.uniqueID;
        const index = newTableData.indexOf(id);
        if (index < 0) {
          newTableData.push(subItem.uniqueID);
        }

        const drawingNo = String(subItem.drawingNo);
        const drawingIndex = newSvgData.indexOf(drawingNo);
        console.log("parts setDefaultSVGandPartsFunc methods", drawingIndex);
        if (drawingIndex < 0) {
          newSvgData.push(drawingNo);
          setMyself(subItem.drawingNo, false);
        }
      }
      if (subItem.setParts && subItem.setParts.length > 0) {
        subItem.setParts.forEach((subItem2: any) => {
          if (item.item_no === subItem2.item_no) {
            const id = subItem2.uniqueID;
            const index = newTableData.indexOf(id);
            if (index < 0) {
              newTableData.push(subItem2.uniqueID);
            }

            const drawingNo = String(subItem2.drawingNo);
            const drawingIndex = newSvgData.indexOf(drawingNo);
            if (drawingIndex < 0) {
              newSvgData.push(drawingNo);
              setMyself(subItem2.drawingNo, false);
            }
          }
        });
      }
    });
  });
  return {
    newTableData: newTableData,
    newSvgData: newSvgData,
  };
};

const getPartInfomationByItemNo = (fullParts: any, message: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (message.item_no === item.item_no) {
      list.push(item);
    }

    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (message.item_no === partsItem.item_no) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

const getPartInfomationByDrawingNo = (fullParts: any, message: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (Number(message.drawingNo) === Number(item.drawingNo)) {
      list.push(item);
    }
    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (Number(message.drawingNo) === Number(partsItem.drawingNo)) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

const getPartInfomationByUniqueID = (fullParts: any, message: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (message.UniqueID === item.UniqueID) {
      list.push(item);
    }

    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (message.UniqueID === partsItem.UniqueID) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

const getPartInfomationFromUniqueID = (fullParts: any, uniqueID: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (item.uniqueID === uniqueID) {
      list.push(item);
    }
    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (partsItem.uniqueID === uniqueID) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

export const getPartsDrawingData = () => {
  return {
    model_no: undefined,
    memo: undefined,
  };
};

export const judgeSuspend = (
  flg: any,
  item: any,
  i: any,
  errorList: any = []
) => {
  if (!flg) {
    item.suspend_exist = "1";
    errorList.push({
      row: i + 1,
      type: "5",
      item_no: item.item_no,
    });
  }
  if (item.replaced_item_no !== undefined && item.replaced_item_no !== "") {
    errorList.push({
      row: i + 1,
      type: "8",
      item_no: item.replaced_item_no,
      replaced_item_no: item.item_no,
    });
  }
  return errorList;
};

export const getPriceParam = (
  dataList: any,
  _data: any = "",
  customer_no: any = ""
) => {
  console.log("getPriceParam", _data);
  let _params = "";
  let _dataList: any = [];
  let _lineNoList: any = [];

  if (_data && _data.length > 0) {
    let max_number = 0;

    // 最後のLine No
    _data.forEach((item: any, index: number) => {
      if (item.line_no) {
        max_number = item.line_no > max_number ? item.line_no : max_number;
      }
    });

    // 各レコードの設定
    _data.forEach((item: any, index: number) => {
      let _item = JSON.parse(JSON.stringify(item));

      // line no 不存在 追加レコード
      if (!_item.line_no && _item.line_no !== 0) {
        max_number = max_number + 1;
        _item.line_no = max_number;
      }

      // 手動で編集の場合は、
      if (_item.manually_update_flg) {
        _lineNoList.push(_item);
      } else {
        // item no重複
        let flg: number = -1;
        for (let i: number = 0; i < _dataList.length; i++) {
          // 重複な番号の調べる
          if (
            _item.item_no === _dataList[i].item_no &&
            !_dataList[i].manually_update_flg
          ) {
            flg = i;
          }
        }
        // item no単一
        if (flg === -1) {
          // 手動で編集ではない
          if (_item.manually_update_flg === undefined) {
            _item.manually_update_flg = false;
          }

          _dataList.push(_item);
        } else {
          // _dataList[flg].order_qty =
          //   Number(_dataList[flg].order_qty) + Number(_item.order_qty);
          _dataList.push(_item);
        }
      }
    });

    console.log("getPriceParam make", _dataList);
    let _param_flag: boolean = true;
    // パラメータの作り
    _dataList.forEach((item: any, index: number) => {
      if (!item.manually_update_flg) {
        if (_param_flag) {
          _param_flag = false;
          _params += `customer_discount_code=`;
          _params += `&customer_no=${encodeURIComponent(
            getValueCheck(customer_no) ? customer_no : ""
          )}`;
          _params += `&proc_date=`;
          // promotion codeの設定改修
          _params += `&promotion_code=`;
        }
        _params += `&line_no=${encodeURIComponent(item.line_no)}`;
        _params += `&item_no=${encodeURIComponent(item.item_no)}`;
        _params += `&order_qty=${encodeURIComponent(item.order_qty)}`;
        // APIにより各ItemNoがpromotion code不要
        // _params += `&promotion_code=`;
      }
    });
  }
  return { params: _params, lineNoList: _lineNoList };
};

export const setPriceAPIResult = (
  response: any,
  lineNoList: any,
  registerData: any
) => {
  let _total: any = 0;
  // price response取得したリスト
  const newList = JSON.parse(JSON.stringify(response.lines));
  // 手動で編集したリスト
  const oldList = JSON.parse(JSON.stringify(lineNoList));
  console.log("setPriceAPIResult", newList, oldList);
  // Claim Lineの最終リスト
  let resultList: any = [];
  // amount と totalの計算
  newList.forEach((item: any) => {
    item.amount = Number(item.order_qty) * Number(item.net_price);
    item.amount = Number(item.amount.toFixed(2));
    // total
    _total += Number(item.amount);

    // 更新：net_price_format
    item.net_price_format = priceOutputByCountryCode(item.net_price);

    const _registerData_list = registerData.filter(
      (subItem: any) => subItem.item_no === item.item_no
    );

    // webmsi情報追加
    if (_registerData_list.length > 0) {
      // webmsi_region
      item.webmsi_region = _registerData_list[0].webmsi_region;
      // webmsi_spec
      item.webmsi_spec = _registerData_list[0].webmsi_spec;
      if (!item["webmsi_spec"] || item["webmsi_spec"] === undefined) {
        item["webmsi_spec"] = "Full";
      }
    }

    // 手動で編集フラグ
    item.manually_update_flg = false;
    resultList[item.line_no - 1] = item;
  });

  // 手動で編集したリストの追加
  if (oldList.length > 0) {
    oldList.forEach((item: any) => {
      item.amount = Number(item.order_qty) * Number(item.net_price);
      item.amount = Number(item.amount.toFixed(2));
      // total
      _total += Number(item.amount);

      // 更新：net_price_format
      item.net_price_format = priceOutputByCountryCode(item.net_price);

      // 手動で編集フラグ
      item.manually_update_flg = true;
      let _old_item_no = resultList[item.line_no - 1];
      console.log(
        "setPriceAPIResult _old_item_no",
        _old_item_no,
        !!_old_item_no
      );

      if (!_old_item_no) {
        // 存在しなければ
        resultList[item.line_no - 1] = item;
        console.log("setPriceAPIResult item.line_no true", item.line_no);
      } else {
        // 存在する場合は
        const dumi = JSON.parse(JSON.stringify(_old_item_no));
        resultList[item.line_no - 1] = item;
        dumi.line_no = resultList.length;
        resultList[resultList.length] = dumi;
      }
      console.log("setPriceAPIResult resultList", resultList);
    });
  }

  let _arr: any = [];
  resultList.forEach((item: any) => {
    if (item) {
      _arr.push(item);
    }
  });

  return {
    _total: _total,
    newList: newList,
    resultList: _arr,
  };
};

const getValueCheck = (data: any) => data !== null && data !== undefined;

export const editAdditionalItem = (response: any, qty: any) => {
  let returnResponse: any = JSON.parse(JSON.stringify(response));
  let itemList = [] as any;
  let item: {
    item_no: "";
    description: "";
    order_qty: Number;
    suspend_flg: Boolean;
    replaced_item_no: "";
  };
  if (response) {
    if (response.relative_qty > 0) {
      item = {
        item_no: response.alternate_item_no,
        description: response.alternate_description,
        order_qty: response.relative_qty * qty,
        suspend_flg: response.alternate_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_01_qty > 0) {
      item = {
        item_no: response.additional_item_01,
        description: response.additional_item_01_description,
        order_qty: response.additional_item_01_qty * qty,
        suspend_flg: response.additional_item_01_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_02_qty > 0) {
      item = {
        item_no: response.additional_item_02,
        description: response.additional_item_02_description,
        order_qty: response.additional_item_02_qty * qty,
        suspend_flg: response.additional_item_02_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_03_qty > 0) {
      item = {
        item_no: response.additional_item_03,
        description: response.additional_item_03_description,
        order_qty: response.additional_item_03_qty * qty,
        suspend_flg: response.additional_item_03_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_04_qty > 0) {
      item = {
        item_no: response.additional_item_04,
        description: response.additional_item_04_description,
        order_qty: response.additional_item_04_qty * qty,
        suspend_flg: response.additional_item_04_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_05_qty > 0) {
      item = {
        item_no: response.additional_item_05,
        description: response.additional_item_05_description,
        order_qty: response.additional_item_05_qty * qty,
        suspend_flg: response.additional_item_05_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_06_qty > 0) {
      item = {
        item_no: response.additional_item_06,
        description: response.additional_item_06_description,
        order_qty: response.additional_item_06_qty * qty,
        suspend_flg: response.additional_item_06_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_07_qty > 0) {
      item = {
        item_no: response.additional_item_07,
        description: response.additional_item_07_description,
        order_qty: response.additional_item_07_qty * qty,
        suspend_flg: response.additional_item_07_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_08_qty > 0) {
      item = {
        item_no: response.additional_item_08,
        description: response.additional_item_08_description,
        order_qty: response.additional_item_08_qty * qty,
        suspend_flg: response.additional_item_08_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_09_qty > 0) {
      item = {
        item_no: response.additional_item_09,
        description: response.additional_item_09_description,
        order_qty: response.additional_item_09_qty * qty,
        suspend_flg: response.additional_item_09_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
    if (response.additional_item_10_qty > 0) {
      item = {
        item_no: response.additional_item_10,
        description: response.additional_item_10_description,
        order_qty: response.additional_item_10_qty * qty,
        suspend_flg: response.additional_item_10_suspend_flg,
        replaced_item_no: response.item_no,
      };
      itemList.push(item);
    }
  }
  returnResponse.itemList = itemList.slice(0);
  return returnResponse;
};
